import gql from 'graphql-tag';

export const LOG_PHONE_NUMBER = gql`
  query logChangePhoneNumbers($userId: String!) {
    logChangePhoneNumbersCMS(userId: $userId) {
      id
      oldPhoneNumber
      newPhoneNumber
      userId
      user {
        id
      }
      dataNumber
      createdAt
      updatedAt
    }
  }
`;
