import React, { memo, useCallback, useMemo, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { Text, TextInput, Button } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { VerificationRequests_requestVerificationAdvance_row as Data } from '../../../../generated/VerificationRequests';
import { Table } from '../../../../core-ui';
import { Modal } from '../../../../components';
import {
  changePhoneNumber,
  changePhoneNumberVariables,
} from '../../../../generated/changePhoneNumber';
import { LOG_PHONE_NUMBER } from '../../graphql/queries';
import { CHANGE_PHONE_NUMBER } from '../../graphql/mutations';
import { useSnackbar } from '../../context';
import {
  logChangePhoneNumbers,
  logChangePhoneNumbersVariables,
} from '../../../../generated/logChangePhoneNumbers';

type Attachment = { name: string; image: string };
type Props = {
  data: Data;
  unclaimLoading?: boolean;
  viewMode?: boolean;
  onClose: () => void;
  refetchProfile: () => void;
};

const OwnerEditForm = memo((props: Props) => {
  const { data, viewMode = false, onClose, refetchProfile } = props;
  const { openSnackbar } = useSnackbar();

  const { data: logData } = useQuery<
    logChangePhoneNumbers,
    logChangePhoneNumbersVariables
  >(LOG_PHONE_NUMBER, {
    variables: {
      userId: data.userId ?? '',
    },
  });

  const [selectedTab, setSelectedTab] = useState('FORM');
  const [submitError, setSubmitError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const paginatedData = useMemo(() => {
    if (logData) {
      let newData =
        logData?.logChangePhoneNumbersCMS &&
        logData.logChangePhoneNumbersCMS.slice(
          page,
          page === 0 ? rowsPerPage : page * rowsPerPage,
        );
      return newData;
    }
  }, [logData, page, rowsPerPage]);

  const onRowsChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowsPerPage(Number(event.currentTarget.value));
      setPage(0);
    },
    [],
  );

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  //=============================================

  const [newPhone, setNewPhone] = useState('');
  const [dataFormId, setDataFormId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const checkFormatError = (newPhone: string) => {
    if (
      (!checkPhoneNumber(newPhone) ||
        newPhone.length < 8 ||
        newPhone.length > 14) &&
      newPhone !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  //=============================================
  const onError = useCallback(() => {
    setSubmitError(true);
  }, []);

  const checkPhoneNumber = (text: string) => {
    const phoneRegex = /^\d+$/g;
    return phoneRegex.test(text);
  };

  const [updateData, { loading }] = useMutation<
    changePhoneNumber,
    changePhoneNumberVariables
  >(CHANGE_PHONE_NUMBER, {
    onCompleted: (changeNumberData) => {
      if (changeNumberData?.changePhoneNumber.status === 'fail') {
        setSubmitError(true);
        setModalOpen(false);
        setErrorMessage(changeNumberData.changePhoneNumber.message);
      } else {
        setSubmitError(false);
        setNewPhone('');
        setDataFormId('');
        openSnackbar({
          variant: 'success',
          message: t(['Data telah diperbarui.', 'Data has been updated.']),
        });
        setModalOpen(false);
        onClose();
      }
    },
    onError: onError,
  });

  const onUpdateNumber = useCallback(() => {
    updateData({
      variables: {
        oldPhoneNumber: data.user?.phoneNumber || '',
        newPhoneNumber: newPhone,
        googleSheetFormNumber: dataFormId,
      },
      refetchQueries: [
        {
          query: LOG_PHONE_NUMBER,
          variables: { where: { userId: { equals: data.userId } } },
        },
      ],
    }).then(() => refetchProfile());
    closeModal;
  }, [
    updateData,
    data.user,
    newPhone,
    dataFormId,
    data.userId,
    refetchProfile,
    closeModal,
  ]);

  return (
    <>
      <View style={styles.root}>
        <View style={styles.header}>
          <View style={styles.row}>
            <TouchableOpacity
              data-testid="list-daftar-data"
              style={[
                styles.tab,
                selectedTab === 'FORM' ? styles.selectedTab : {},
              ]}
              onPress={() => setSelectedTab('FORM')}
            >
              <Text
                color={selectedTab === 'FORM' ? 'black' : 'grey'}
                bold
                size="m"
              >
                {'Form'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              data-testid="list-dalam-masa-pengerjaan"
              style={[
                styles.tab,
                selectedTab === 'RIWAYAT' ? styles.selectedTab : {},
              ]}
              onPress={() => setSelectedTab('RIWAYAT')}
            >
              <Text
                color={selectedTab === 'RIWAYAT' ? 'black' : 'grey'}
                bold
                size="m"
              >
                {t(['Riwayat', 'History'])}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {selectedTab === 'FORM' ? (
          <View>
            <View
              style={
                viewMode
                  ? styles.viewMode
                  : [styles.borderTopZero, styles.border, styles.lPadding]
              }
            >
              <TextInput
                containerStyle={styles.lBottomSpacing}
                value={data.user?.store?.id || '-'}
                onChangeText={() => {}}
                label={t(['Kode Toko', 'Store Code'])}
                disabled
              />
              <TextInput
                containerStyle={styles.lBottomSpacing}
                value={data.user?.store?.storeManagerBulk?.storeName || '-'}
                onChangeText={() => {}}
                label={t(['Nama Toko', 'Store Name'])}
                disabled
              />
              <TextInput
                containerStyle={styles.lBottomSpacing}
                value={data.user?.profile?.name || '-'}
                onChangeText={() => {}}
                label={t(['Nama Pemilik Toko', 'Store Owner Name'])}
                disabled
              />

              <TextInput
                value={data.user?.phoneNumber || '-'}
                onChangeText={() => {}}
                containerStyle={styles.lBottomSpacing}
                label={t(['No. Hape Lama', 'Old Phone Number'])}
                disabled
              />

              <TextInput
                value={newPhone}
                containerStyle={styles.lBottomSpacing}
                onChangeText={setNewPhone}
                error={checkFormatError(newPhone)}
                label={t(['No. Hape Baru', 'New Phone Number'])}
                helperText={
                  newPhone !== ''
                    ? !checkPhoneNumber(newPhone)
                      ? t([
                          'No. HP yang Anda masukkan tidak sesuai format (e.g. 085156263898)',
                          'Phone Number is in the wrong format (e.g. 085156263898)',
                        ])
                      : newPhone.length < 8
                      ? t(['Minimal 8 digit angka', 'Min. 8 numbers'])
                      : newPhone.length > 14
                      ? t(['Maksimal 14 angka', 'Max. 14 numbers'])
                      : ''
                    : ''
                }
              />

              <TextInput
                value={dataFormId}
                containerStyle={styles.lBottomSpacing}
                onChangeText={setDataFormId}
                label={t([
                  'Nomor Data Form di Google Sheet',
                  'Form Data Number in Google Sheet',
                ])}
              />
              {submitError && (
                <Text style={{ color: colors.text.red }}>
                  {errorMessage ||
                    t([
                      'Gagal mengganti nomor telpon. Mohon coba lagi nanti.',
                      'Failed to change the phone number. Please try again later.',
                    ])}
                </Text>
              )}
            </View>

            <View
              style={[
                styles.row,
                styles.lBottomSpacing,
                { justifyContent: 'flex-end' },
              ]}
            >
              <Button
                style={styles.buttonWidth}
                preset="primary"
                title={t(['Simpan', 'Save'])}
                onPress={openModal}
                disabled={
                  checkFormatError(newPhone) ||
                  newPhone === '' ||
                  dataFormId === ''
                }
              />
            </View>
          </View>
        ) : (
          <View>
            <Table
              data={paginatedData || []}
              dataCount={logData?.logChangePhoneNumbersCMS.length || 0}
              onChangePage={setPage}
              onChangeRowsPerPage={onRowsChange}
              page={page}
              rowsPerPage={rowsPerPage}
              structure={{
                updatedAt: {
                  headerTitle: t(['Tanggal Perubahan', 'Change Date']),
                  valuePreProcessor: (value) => new Date(value as string),
                },
                oldPhoneNumber: {
                  headerTitle: t(['No. HP Sebelum', 'Old Phone Number']),
                },
                newPhoneNumber: {
                  headerTitle: t(['No. HP Sesudah', 'New Phone Number']),
                },
                dataNumber: {
                  headerTitle: t(['Nomor Data', 'Data Number']),
                },
              }}
            />
          </View>
        )}
      </View>
      <View>
        <Modal
          open={modalOpen}
          onClose={closeModal}
          title={t(['Simpan Perubahan', 'Save Changes'])}
          titleSize="xl"
          TextComponent={Text}
        >
          <Text style={styles.bottomContainer}>
            {t([
              'Apakah Anda sudah yakin dengan perubahan ini?',
              'Are you sure with this change?',
            ])}
          </Text>
          <View style={styles.confirmationModalButton}>
            <Button
              title={t(['Tidak', 'No'])}
              preset="secondary"
              onPress={closeModal}
            />
            <Button
              title={t(['Ya', 'Yes'])}
              onPress={onUpdateNumber}
              isLoading={loading}
            />
          </View>
        </Modal>
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.background.white,
    flexDirection: 'row',
    height: 40,
    shadowColor: colors.background.shadow,
  },
  headerTitle: { alignItems: 'center', flexDirection: 'row' },
  root: { width: 529, height: 1000, backgroundColor: colors.background.white },
  row: { flexDirection: 'row' },
  buttonWidth: {
    width: 30,
  },
  borderTopZero: {
    borderTopWidth: 0,
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  confirmationModalButton: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: spacing.m,
    paddingRight: spacing.l,
    paddingBottom: spacing.m,
  },
  lBottomSpacing: {
    marginBottom: spacing.l,
  },
  lPadding: {
    padding: spacing.l,
  },
  tab: {
    justifyContent: 'flex-start',
    height: '100%',
    marginHorizontal: 12,
  },
  selectedTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.button.background.primary,
  },
  bottomContainer: {
    marginTop: 24,
    padding: 24,
    width: 529,
    backgroundColor: colors.background.white,
  },
  viewMode: {
    borderTopColor: colors.button.border.primary,
    borderTopWidth: 1,
    paddingTop: spacing.l,
    paddingBottom: spacing.m,
  },
});

export default OwnerEditForm;
