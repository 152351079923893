import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_VILLAGES } from '../../features/verification/graphql/queries';
import { Villages, VillagesVariables } from '../../generated/Villages';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Kelurahan', 'Select Urban Village']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  districtID?: string;
};

const VillageDropdown = memo((props: Props) => {
  let { title = defaultTitle, districtID, ...otherProps } = props;

  const [getVillages, { data, loading, error }] = useLazyQuery<
    Villages,
    VillagesVariables
  >(GET_VILLAGES);
  const fetchVillages = useCallback(() => {
    if (districtID) {
      getVillages({ variables: { districtID } });
    }
  }, [districtID, getVillages]);

  useEffect(fetchVillages, [districtID, fetchVillages]);

  const options = useMemo(
    () =>
      districtID
        ? data?.villageByDistrictId.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          })) ?? []
        : [],
    [data, districtID],
  );

  return (
    <Dropdown
      data-testid="village-dropdown"
      error={error}
      errorAction={t([
        'mengambil data kelurahan',
        'retrieve the urban village data',
      ])}
      onRetryPress={fetchVillages}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default VillageDropdown;
