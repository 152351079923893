import gql from 'graphql-tag';

export const GET_AUTOMATIC_REPORT_REQUEST_AGENT_EMAIL_LIST = gql`
  query AutomaticReportRequestAgentEmails {
    automaticReportRequestAgentEmailsCMS {
      id
      brandId
      brand {
        id
        name
      }
      email
      activeSendEmail
    }
  }
`;
