import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { ApolloError } from 'apollo-client';
import { logEvent } from 'expo-firebase-analytics';

import { Button } from '../../core-ui';
import {
  Option,
  Header,
  HeaderNavigationBar,
  ErrorMessage,
  ErrorModal,
  ConfirmationModal,
} from '../../components';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { theme, colors } from '../../constants/theme';
import { GET_BRAND_PAGE } from '../../graphql/queries';
import {
  DELETE_BRAND_RANGE,
  ADD_BRAND_RANGE,
  UPDATE_BRAND_PAGE,
  UPLOAD,
} from '../../graphql/mutations';
import {
  BrandPage,
  BrandPageVariables,
  BrandPage_brand_brandPage,
  BrandPage_brand_brandPage_brandRange,
  BrandPage_brand_logos,
} from '../../generated/BrandPage';
import {
  UpdateBrandPage,
  UpdateBrandPageVariables,
} from '../../generated/UpdateBrandPage';
import {
  AddBrandRange,
  AddBrandRangeVariables,
} from '../../generated/AddBrandRange';
import {
  DeleteBrandRange,
  DeleteBrandRangeVariables,
} from '../../generated/DeleteBrandRange';
import { StatusActive, FolderType } from '../../generated/globalTypes';
import { Upload, UploadVariables } from '../../generated/Upload';
import { routePaths } from '../../constants/routes';
import { pageTitle } from '../../constants/pageTitle';

import {
  StepOne,
  UploadStep,
  StepFour,
  StepFive,
  StepSix,
  StepSeven,
  StatusStep,
} from './components/NewBrandForm';

type Logo = Omit<BrandPage_brand_logos, '__typename'>;
type BrandRange = Omit<
  BrandPage_brand_brandPage_brandRange,
  '__typename' | 'image'
> & {
  image: FileWithPreview | string | null;
};
type BrandPageState = Omit<
  BrandPage_brand_brandPage,
  '__typename' | 'brandRange' | 'headerImage'
> & {
  brandRange: Array<BrandRange>;
  headerImage: FileWithPreview | string | null;
};

export default function BrandPagesScene() {
  const history = useHistory();
  let [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  let [isModalOpen, setModalOpen] = useState(false);
  let [logos, setLogos] = useState<Array<Logo>>([]);
  let [brandPageData, setBrandPageData] = useState<BrandPageState | null>(null);
  let [editable, setEditable] = useState<Array<boolean>>([]);
  let [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  let [selectedRangeId, setSelectedRangeId] = useState('');
  let [selectedRangeIndex, setSelectedRangeIndex] = useState<number | null>(
    null,
  );
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.BrandPagesScene });
    };
    eventLog();
  }, []);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );
  const onUpdateBrandPageError = useMemo(() => {
    return openErrorModal(
      t(['mengubah data halaman brand', 'update the brand page data']),
    );
  }, [openErrorModal]);
  const onAddRangeError = useMemo(() => {
    return openErrorModal(t(['membuat data range baru', 'add the range data']));
  }, [openErrorModal]);
  const onDeleteRangeError = useMemo(() => {
    return openErrorModal(t(['menghapus data range', 'delete the range data']));
  }, [openErrorModal]);
  const onUploadImageError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);

  let [
    getBrandPage,
    { data, loading, error, refetch: refetchBrandPage },
  ] = useLazyQuery<BrandPage, BrandPageVariables>(GET_BRAND_PAGE, {
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetchBrandPage();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetchBrandPage]);

  let [updateBrandPage, { loading: updateLoading }] = useMutation<
    UpdateBrandPage,
    UpdateBrandPageVariables
  >(UPDATE_BRAND_PAGE, {
    onCompleted: refetchData,
    onError: onUpdateBrandPageError,
  });

  let [addBrandRange, { loading: addLoading }] = useMutation<
    AddBrandRange,
    AddBrandRangeVariables
  >(ADD_BRAND_RANGE, {
    onCompleted: refetchData,
    onError: onAddRangeError,
  });

  let [deleteBrandRange, { loading: deleteLoading }] = useMutation<
    DeleteBrandRange,
    DeleteBrandRangeVariables
  >(DELETE_BRAND_RANGE, {
    onCompleted: refetchData,
    onError: onDeleteRangeError,
  });

  let [uploadImage] = useMutation<Upload, UploadVariables>(UPLOAD, {
    onError: onUploadImageError,
  });

  useEffect(() => {
    if (selectedBrand) {
      getBrandPage({
        variables: {
          brandID: selectedBrand.value.toString(),
        },
      });
    }
  }, [selectedBrand, getBrandPage]);

  useEffect(() => {
    if (data?.brand.brandPage) {
      setLogos(data.brand.logos);
      setBrandPageData(data.brand.brandPage);
      //NOTE: setting all ranges to uneditable, user has to click Edit button to enable edit
      setEditable(
        data.brand.brandPage.brandRange.map(() => {
          return false;
        }),
      );
    } else {
      setBrandPageData(null);
    }
  }, [data]);

  let handleBrandPageChange = useCallback(
    (key: keyof BrandPageState, value: string | Logo | FileWithPreview) => {
      if (brandPageData) {
        setBrandPageData({ ...brandPageData, [key]: value });
      }
    },
    [brandPageData],
  );

  let handleInputChange = useCallback(
    (
      rangeId: string,
      key: keyof BrandRange,
      newValue: string | Date | FileWithPreview,
    ) => {
      if (brandPageData) {
        let brandRange = brandPageData.brandRange.map((item) => {
          if (item.id === rangeId) {
            let newRange = {
              ...item,
              [key]: newValue,
            };
            return newRange;
          } else {
            return item;
          }
        });
        setBrandPageData({ ...brandPageData, brandRange });
      }
    },
    [brandPageData],
  );

  let onSubmit = useCallback(
    async (brandRange: BrandRange) => {
      let rangeImageLink = '';
      if (brandRange.image) {
        if (typeof brandRange.image === 'object') {
          const { data } = await uploadImage({
            variables: {
              file: brandRange.image.file,
              folder: FolderType.BRAND_RANGE,
            },
          });
          rangeImageLink = data?.upload.link ?? '';
        } else {
          rangeImageLink = brandRange.image;
        }
      }
      if (brandPageData && brandRange.id === '') {
        addBrandRange({
          variables: {
            brandPageID: brandPageData.id,
            rangeName: brandRange.name,
            description: brandRange.description,
            availableIn: brandRange.availableIn,
            rangeImage: rangeImageLink,
            liveDate: brandRange.liveDate,
            orderNow: brandRange.rangeId,
          },
        });
      } else if (brandPageData) {
        let headerImageLink = '';
        if (brandPageData.headerImage) {
          if (typeof brandPageData.headerImage === 'object') {
            const { data } = await uploadImage({
              variables: {
                file: brandPageData.headerImage.file,
                folder: FolderType.BRAND_PAGE,
              },
            });
            headerImageLink = data?.upload.link ?? '';
          } else {
            headerImageLink = brandPageData.headerImage;
          }
        }
        updateBrandPage({
          variables: {
            brandPageID: brandPageData.id,
            logoLibraryID: brandPageData.logoLibrary?.id,
            headerImage: headerImageLink,
            about: brandPageData.about,
            rangeID: brandRange.id,
            rangeName: brandRange.name,
            description: brandRange.description,
            availableIn: brandRange.availableIn,
            rangeImage: rangeImageLink,
            liveDate: brandRange.liveDate,
            orderNow: brandRange.rangeId,
            status: brandPageData.status,
          },
        });
      }
    },
    [brandPageData, uploadImage, addBrandRange, updateBrandPage],
  );

  let onEdit = useCallback(
    (idx: number) => {
      setEditable(
        editable.map((value, index) => {
          if (idx === index) {
            return !value;
          }
          return value;
        }),
      );
    },
    [editable],
  );

  let onDeleteRange = useCallback(async () => {
    if (selectedRangeId) {
      await deleteBrandRange({ variables: { rangeID: selectedRangeId } });
      setDeleteModalOpen(false);
    } else if (brandPageData && selectedRangeIndex) {
      let brandRange: Array<BrandRange> = brandPageData.brandRange;
      brandRange.splice(selectedRangeIndex, 1);
      setBrandPageData({ ...brandPageData, brandRange });
    }
  }, [selectedRangeId, brandPageData, selectedRangeIndex, deleteBrandRange]);

  let onAddNewRange = useCallback(() => {
    if (brandPageData) {
      let brandRange: Array<BrandRange> = [
        ...brandPageData.brandRange,
        {
          id: '',
          name: '',
          availableIn: '',
          liveDate: new Date(),
          description: '',
          image: '',
          rangeId: '',
        },
      ];
      setBrandPageData({ ...brandPageData, brandRange });
      setEditable([...editable, true]);
    }
  }, [brandPageData, editable]);

  let openDeleteModal = useCallback(() => setDeleteModalOpen(true), []);
  let closeDeleteModal = useCallback(() => setDeleteModalOpen(false), []);

  const brandsQueryVariables = useMemo(
    () => ({
      where: { NOT: [{ brandPage: null }] },
    }),
    [],
  );

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header
        title="Homepage"
        subtitle="Brand Pages"
        subtitleColor="default"
        style={styles.header}
      />
      <StepOne
        brandsQueryVariables={brandsQueryVariables}
        selectedNamePage={selectedBrand}
        onSelectNamePage={setSelectedBrand}
        title={t(['Pilih Brand yang Ada', 'Select Existing Brand'])}
        dropdownTitle={t(['Pilih Brand', 'Select Brand'])}
        renderAddButton={() => (
          <Button
            data-testid="add-new-brand-page"
            title={t(['+ Tambah Halaman Brand Baru', '+ Add New Brand Page'])}
            onPress={() => history.push(routePaths.newBrandPage)}
            style={styles.addButton}
          />
        )}
      />

      {loading && (
        <View style={styles.center}>
          <ActivityIndicator />
        </View>
      )}
      {!!error && (
        <ErrorMessage
          action={t([
            'mengambil data halaman brand',
            'retrieve the brand page data',
          ])}
          error={error}
          onPress={() =>
            selectedBrand &&
            getBrandPage({
              variables: {
                brandID: selectedBrand.value.toString(),
              },
            })
          }
        />
      )}
      {!!brandPageData && (
        <View style={styles.row}>
          <UploadStep
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            headerImage={brandPageData?.headerImage ?? null}
            setHeaderImage={(image: FileWithPreview) =>
              handleBrandPageChange('headerImage', image)
            }
            logoImage={brandPageData?.logoLibrary ?? null}
            setLogoImage={(logo: Logo) => {
              handleBrandPageChange('logoLibrary', logo);
              setModalOpen(false);
            }}
            logoLibrary={logos}
            editable={!!brandPageData}
          />
          <StatusStep
            status={brandPageData?.status ?? StatusActive.ACTIVE}
            setStatus={(value) => handleBrandPageChange('status', value)}
          />
        </View>
      )}
      {!!brandPageData &&
        brandPageData.brandRange.map((item, index) => {
          return (
            <View key={index}>
              <View style={styles.row}>
                {index === 0 ? (
                  <StepFour
                    index={index}
                    about={brandPageData?.about || ''}
                    setAbout={(value: string) =>
                      handleBrandPageChange('about', value)
                    }
                    editable={editable?.[index]}
                  />
                ) : (
                  <View style={styles.leftPadding} />
                )}
                <StepFive
                  index={index}
                  showTitle={index === 0}
                  editable={editable?.[index]}
                  brandID={selectedBrand?.subValue}
                  selectedRangeName={item.name}
                  onSelectRangeName={(option: Option) => {
                    handleInputChange(item.id, 'name', option.label);
                  }}
                  description={item.description}
                  setDescription={(description: string) => {
                    handleInputChange(item.id, 'description', description);
                  }}
                  availableIn={item.availableIn}
                  setAvailableIn={(availableIn: string) => {
                    handleInputChange(item.id, 'availableIn', availableIn);
                  }}
                />
                <StepSix
                  index={index}
                  showTitle={index === 0}
                  rangeImage={item.image}
                  setRangeImage={(image: FileWithPreview) => {
                    setSelectedRangeId(item.id);
                    handleInputChange(item.id, 'image', image);
                  }}
                  editable={editable?.[index]}
                />
                <View>
                  <StepSeven
                    index={index}
                    showTitle={index === 0}
                    brandID={selectedBrand?.subValue}
                    liveDate={new Date(item.liveDate)}
                    setLiveDate={(date: Date) => {
                      handleInputChange(item.id, 'liveDate', date);
                    }}
                    selectedRange={{ label: item.rangeId, value: item.rangeId }}
                    onSelectRange={({ value }) => {
                      handleInputChange(item.id, 'rangeId', value.toString());
                    }}
                    editable={editable?.[index]}
                  />
                </View>
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  data-testid={`submit-button-${index}`}
                  disabled={
                    !editable[index] ||
                    !item.name ||
                    !item.description ||
                    !item.image ||
                    !item.availableIn ||
                    !item.rangeId
                  }
                  title={t(['Simpan', 'Submit'])}
                  onPress={() => {
                    onSubmit(item);
                    setSelectedRangeId(item.id);
                  }}
                  style={styles.button}
                  isLoading={
                    item.id === selectedRangeId && (updateLoading || addLoading)
                  }
                />
                <Button
                  data-testid={`edit-cancel-button-${index}`}
                  title={
                    editable[index]
                      ? t(['Batal', 'Cancel'])
                      : t(['Ubah', 'Edit'])
                  }
                  onPress={() => onEdit(index)}
                  preset="secondary"
                  style={styles.button}
                />
                <Button
                  data-testid={`delete-button-${index}`}
                  title={t(['Hapus', 'Delete'])}
                  onPress={() => {
                    setSelectedRangeId(item.id);
                    setSelectedRangeIndex(index);
                    openDeleteModal();
                  }}
                  isLoading={deleteLoading}
                  preset="warning"
                />
              </View>
              <View style={styles.separator} />
            </View>
          );
        })}

      {!!brandPageData && (
        <View style={styles.buttonContainer}>
          <Button
            data-testid="add-new-range"
            preset="transparent"
            title={t(['+ Tambah', '+ New'])}
            onPress={onAddNewRange}
          />
        </View>
      )}
      <ConfirmationModal
        data-testid="confirmation-modal"
        keepOpenAfterConfirm
        title={t([
          'Apakah anda yakin ingin menghapus range ini?',
          'Are you sure you want to delete this range?',
        ])}
        loading={deleteLoading}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={onDeleteRange}
      />
      <ErrorModal
        action={errorAction}
        open={errorOpen}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: theme.spacing.xlarge,
  },
  header: {
    marginBottom: theme.spacing.small,
  },
  row: {
    flexDirection: 'row',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: theme.spacing.small,
  },
  addButton: {
    flex: 1,
    marginTop: 45,
    marginRight: theme.spacing.xlarge,
  },
  separator: {
    borderBottomWidth: 1,
    borderColor: colors.separator,
    marginVertical: theme.spacing.medium,
  },
  leftPadding: {
    flex: 1,
    marginRight: theme.spacing.xlarge,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
