import gql from 'graphql-tag';

import { SOCIAL_MEDIA_FRAGMENT } from '../fragments';

export const GET_SOCIAL_MEDIAS = gql`
  query SocialMedias {
    socialMediaAll(orderBy: { order: asc }) {
      ...SocialMediaFragment
    }
  }
  ${SOCIAL_MEDIA_FRAGMENT}
`;
