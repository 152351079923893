import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ApolloError } from 'apollo-client';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { ConfirmationModal, ErrorMessage, ErrorModal } from '../../components';
import { Button, Table, Text } from '../../core-ui';
import { GET_BULK_INPUTS } from '../../graphql/queries';
import {
  SEND_POINT_TO_DATABASE,
  CANCEL_BULK_POINT,
} from '../../graphql/mutations';
import { BulkPoints, BulkPointsVariables } from '../../generated/BulkPoints';
import { StatusBulkPoint } from '../../generated/globalTypes';
import {
  SendPointToDatabase,
  SendPointToDatabaseVariables,
} from '../../generated/SendPointToDatabase';
import {
  CancelBulkPoint,
  CancelBulkPointVariables,
} from '../../generated/CancelBulkPoint';
import { routePaths } from '../../constants/routes';

type Props = { refetchToggle: boolean; onSubmit: () => void };

export const BulkLoyaltyInputTable = ({ refetchToggle, onSubmit }: Props) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [bulkPointId, setBulkPointId] = useState('');
  const [cancelOpen, setCancelOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeSendModal = useCallback(() => setSendOpen(false), []);
  const closeCancelModal = useCallback(() => setCancelOpen(false), []);
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);

  const { data, loading, error, refetch } = useQuery<
    BulkPoints,
    BulkPointsVariables
  >(GET_BULK_INPUTS, {
    pollInterval: 5000,
    variables: { skip: page * rowsPerPage, first: rowsPerPage },
  });

  const refetchData = useCallback(
    (skip: number = page * rowsPerPage) => {
      const asyncRefetch = async () => {
        refetch({ skip, first: rowsPerPage });
      };
      asyncRefetch();
    },
    [page, rowsPerPage, refetch],
  );

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowsPerPage(Number(event.currentTarget.value));
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const onSubmitCompleted = useCallback(() => {
    setSendOpen(false);
    setCancelOpen(false);
    setBulkPointId('');
    onSubmit();
  }, [onSubmit]);
  const onSendError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengirim data ke database', 'send data to database']));
    setErrorInstance(error);
  }, []);
  const onCancelError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunggah berkas', 'upload file']));
    setErrorInstance(error);
  }, []);
  const [sendToDatabase, { loading: sendLoading }] = useMutation<
    SendPointToDatabase,
    SendPointToDatabaseVariables
  >(SEND_POINT_TO_DATABASE, {
    onCompleted: onSubmitCompleted,
    onError: onSendError,
  });
  const [cancel, { loading: cancelLoading }] = useMutation<
    CancelBulkPoint,
    CancelBulkPointVariables
  >(CANCEL_BULK_POINT, {
    onCompleted: onSubmitCompleted,
    onError: onCancelError,
  });

  const onSendPress = useCallback((id: string) => {
    setBulkPointId(id);
    setSendOpen(true);
  }, []);
  const onConfirmSend = useCallback(() => {
    sendToDatabase({ variables: { bulkPointId } });
  }, [bulkPointId, sendToDatabase]);

  const onCancelPress = useCallback((id: string) => {
    setBulkPointId(id);
    setCancelOpen(true);
  }, []);
  const onConfirmCancel = useCallback(() => {
    cancel({ variables: { bulkPointId } });
  }, [bulkPointId, cancel]);

  return (
    <>
      <ConfirmationModal
        keepOpenAfterConfirm
        title={t([
          'Apakah anda yakin ingin mengirim data ke database?',
          'Are you sure you want to send this data to database?',
        ])}
        open={sendOpen}
        onClose={closeSendModal}
        onConfirm={onConfirmSend}
        loading={sendLoading}
      />
      <ConfirmationModal
        keepOpenAfterConfirm
        title={t([
          'Apakah anda yakin ingin batal mengirim data ke database?',
          'Are you sure you want to cancel sending this data to database?',
        ])}
        open={cancelOpen}
        onClose={closeCancelModal}
        onConfirm={onConfirmCancel}
        loading={cancelLoading}
      />
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <Table
        data-testid="bulk-point-table"
        data={data?.bulkPointAdvanceSearch.row ?? []}
        dataCount={data?.bulkPointAdvanceSearch.total ?? 0}
        loading={loading}
        error={!!error}
        page={page}
        onChangePage={setPage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        structure={{
          createdAt: {
            headerTitle: t(['Tanggal Unggah', 'Upload Date']),
            valuePreProcessor: (data) =>
              data ? new Date(data as string) : null,
          },
          fileName: {
            'data-testid': 'table-file-name',
            headerTitle: t(['Nama Berkas', 'File Name']),
          },
          status: {
            'data-testid': 'table-status',
            headerTitle: 'Status',
            render: ({ status }) => {
              switch (status) {
                case StatusBulkPoint.ON_PROGRESS:
                  return (
                    <Text bold color="pending">
                      {t(['Sedang Diproses', 'On Progress'])}
                    </Text>
                  );
                case StatusBulkPoint.ON_COMMIT:
                  return (
                    <Text bold color="pending">
                      {t(['Sedang Dikirim', 'On Commit'])}
                    </Text>
                  );
                case StatusBulkPoint.READY_TO_REVIEW:
                  return (
                    <Text bold color="link">
                      {t(['Siap Diperiksa', 'Ready for Review'])}
                    </Text>
                  );
                case StatusBulkPoint.DONE:
                  return (
                    <Text bold color="accepted">
                      {t(['Selesai', 'Done'])}
                    </Text>
                  );
                case StatusBulkPoint.CANCELLED:
                  return (
                    <Text bold color="error">
                      {t(['Dibatalkan', 'Cancelled'])}
                    </Text>
                  );
                default:
                  return (
                    <Text bold color="error">
                      {t(['Gagal', 'Failed'])}
                    </Text>
                  );
              }
            },
          },
          details: {
            align: 'center',
            headerTitle: t(['Rincian', 'Detail']),
            render: ({ id, status, countValid, countInvalid, details }) => {
              switch (status) {
                case StatusBulkPoint.READY_TO_REVIEW:
                  return (
                    <Button
                      data-testid="details-table-button"
                      preset="transparent"
                      onPress={() =>
                        history.push(routePaths.bulkLoyalty + '/' + id)
                      }
                      title={t(
                        [
                          '{valid}{validText}{separator}{invalid}{invalidTextId}',
                          '{valid}{validText}{separator}{invalid}{invalidTextEn}',
                        ],
                        {
                          valid: countValid || '',
                          invalid: countInvalid || '',
                          validText: !!countValid ? ' valid' : '',
                          separator: !!countValid && !!countInvalid ? ', ' : '',
                          invalidTextId: !!countInvalid ? ' tidak valid' : '',
                          invalidTextEn: !!countInvalid ? ' invalid' : '',
                        },
                      )}
                    />
                  );
                case StatusBulkPoint.FAIL: {
                  if (details === 'Format header is different') {
                    return (
                      <Text>
                        {t([
                          'Format dokumen tidak sesuai',
                          'Invalid file format',
                        ])}
                      </Text>
                    );
                  } else if (details === 'Occur error from system') {
                    return (
                      <Text>
                        {t([
                          'Terjadi kesalahan saat memproses dokumen',
                          'An error occured while processing the file',
                        ])}
                      </Text>
                    );
                  } else if (details === 'Invalid Row') {
                    return (
                      <Button
                        data-testid="details-table-button"
                        preset="transparent"
                        onPress={() =>
                          history.push(routePaths.bulkLoyalty + '/' + id)
                        }
                        title={t(
                          [
                            '{valid}{validText}{separator}{invalid}{invalidTextId}',
                            '{valid}{validText}{separator}{invalid}{invalidTextEn}',
                          ],
                          {
                            valid: countValid || '',
                            invalid: countInvalid || '',
                            validText: !!countValid ? ' valid' : '',
                            separator:
                              !!countValid && !!countInvalid ? ', ' : '',
                            invalidTextId: !!countInvalid ? ' tidak valid' : '',
                            invalidTextEn: !!countInvalid ? ' invalid' : '',
                          },
                        )}
                      />
                    );
                  }
                }
                default:
                  return (
                    <Text bold color="link">
                      -
                    </Text>
                  );
              }
            },
          },
          action: {
            align: 'center',
            headerTitle: t(['Aksi', 'Action']),
            render: ({ id, status, countInvalid, details }) =>
              status === StatusBulkPoint.READY_TO_REVIEW ? (
                <View style={styles.actionRow}>
                  <Button
                    preset="transparent"
                    title={t(['kirim ke database', 'send to database'])}
                    onPress={() => onSendPress(id)}
                    disabled={sendLoading || cancelLoading || !!countInvalid}
                    isLoading={sendLoading && bulkPointId === id}
                  />
                  <Text bold color="link">
                    {' '}
                    |{' '}
                  </Text>
                  <Button
                    preset="transparent"
                    title={t(['batal', 'cancel'])}
                    onPress={() => onCancelPress(id)}
                    disabled={sendLoading || cancelLoading}
                    isLoading={cancelLoading && bulkPointId === id}
                  />
                </View>
              ) : details === 'Invalid Row' ? (
                <View style={styles.actionRow}>
                  <Button
                    preset="transparent"
                    title={t(['silahkan cek kembali', 'please review again'])}
                    disabled
                  />
                </View>
              ) : (
                <Text bold color="link">
                  -
                </Text>
              ),
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          error={error}
          action={t([
            'mengambil data masukkan point jumlah besar',
            'retrieve the bulk point input data',
          ])}
          onPress={refetchData}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  actionRow: { flexDirection: 'row', alignItems: 'center' },
});
