import React, { useCallback, memo } from 'react';
import { Switch as MSwitch, makeStyles } from '@material-ui/core';
import { ActivityIndicator } from 'react-native';

import { colors } from '../constants/theme';

const useStyles = makeStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
  },
  switchBase: {
    padding: 3,
    '&$checked': {
      transform: 'translateX(12px)',
      '& + $track': {
        backgroundColor: colors.switch.checked,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
    color: colors.switch.thumb,
  },
  track: {
    borderRadius: 16 / 2,
    backgroundColor: colors.switch.unchecked,
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
});

type Props = {
  'data-testid'?: string;
  checked?: boolean;
  disabled?: boolean;
  onPress?: (check: boolean) => void;
  loading?: boolean;
};

const Switch = memo((props: Props) => {
  let { checked = false, disabled, onPress, loading } = props;
  const classes = useStyles();
  const onChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
      onPress?.(check);
    },
    [onPress],
  );

  if (loading) {
    return <ActivityIndicator size="small" />;
  }

  return (
    <MSwitch
      classes={classes}
      color="secondary"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
});

export default Switch;
