import React, { memo } from 'react';
import { View, StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import { makeStyles } from '@material-ui/core';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import { colors, spacing } from '../constants/theme';

import Text from './Text';

type Props = {
  onPress: () => void;
  placeholder?: string;
  style?: ViewProps['style'];
  'data-testid'?: string;
};

const useStyles = makeStyles({
  root: {
    color: colors.filter.icon,
  },
});

const Filter = memo((props: Props) => {
  const classes = useStyles();
  let { placeholder, style, onPress } = props;

  return (
    <TouchableOpacity
      style={[styles.wrapper, style]}
      onPress={onPress}
      data-testid={props['data-testid']}
    >
      <Text color="secondaryBlack" numberOfLines={1}>
        {placeholder ? placeholder : t(['Saring', 'Filter'])}
      </Text>
      <FilterListRoundedIcon classes={classes} />
      {!!placeholder && <View style={styles.indicator} testID="indicator" />}
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.filter.background,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.filter.border,
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.xxs,
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.filter.indicator,
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

export default Filter;
