import gql from 'graphql-tag';

export const CHANGE_PHONE_NUMBER = gql`
  mutation changePhoneNumber(
    $oldPhoneNumber: String!
    $newPhoneNumber: String!
    $googleSheetFormNumber: String!
  ) {
    changePhoneNumber(
      oldPhoneNumber: $oldPhoneNumber
      newPhoneNumber: $newPhoneNumber
      googleSheetFormNumber: $googleSheetFormNumber
    ) {
      status
      message
    }
  }
`;
