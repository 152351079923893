import { useQuery } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, Table } from '../../core-ui';
import {
  GetAgentRequestByStore,
  GetAgentRequestByStoreVariables,
} from '../../generated/GetAgentRequestByStore';
import {
  CmsRequestStatus,
  OrderByArg,
  RejectedBy,
  RequestStatus,
} from '../../generated/globalTypes';
import { GET_AGENT_REQUEST_BY_STORE } from '../../graphql/queries';
import { getFormattedDate } from '../../helpers';

type Props = {
  brandId: string;
  storeId: string;
};

export default function AgentApplicationHistory({ storeId, brandId }: Props) {
  let [usedData, setUsedData] = useState<
    GetAgentRequestByStore['requestAgentsCMS']
  >();
  let [page, setPage] = useState(0);
  let [rowNumber, setRowNumber] = useState(5);
  const [orderByType, setOrderByType] = useState('createdAt');
  const [order, setOrder] = useState<OrderByArg>(OrderByArg.asc);

  const { data, loading } = useQuery<
    GetAgentRequestByStore,
    GetAgentRequestByStoreVariables
  >(GET_AGENT_REQUEST_BY_STORE, {
    variables: {
      brandId,
      storeId,
      orderBy: { [orderByType]: order },
    },
  });

  useEffect(() => {
    let initPageRecord = page * rowNumber;
    let endPageRecord = initPageRecord + rowNumber;
    setUsedData(data?.requestAgentsCMS.slice(initPageRecord, endPageRecord));
  }, [data, rowNumber, page]);

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowNumber(Number(event.currentTarget.value));
      setPage(0);
    },
    [setRowNumber, setPage],
  );

  const onRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: string) => {
      //Note: sort by property, without forcing go back to createdAt without demand
      if (orderByType === property) {
        if (order === OrderByArg.asc) {
          setOrder(OrderByArg.desc);
        } else {
          setOrder(OrderByArg.asc);
        }
      } else {
        setOrder(OrderByArg.asc);
        setOrderByType(property);
      }
    },
    [orderByType, order],
  );

  return (
    <View style={styles.root}>
      <Table
        data={usedData || []}
        dataCount={data?.requestAgentsCMS.length || 0}
        page={page}
        rowsPerPage={rowNumber}
        onChangePage={(pageNumber) => {
          setPage(pageNumber);
        }}
        onChangeRowsPerPage={onChangeRowsPerPage}
        order={order}
        onRequestSort={onRequestSort}
        orderBy={orderByType}
        loading={loading}
        isHighlightedHead={true}
        override={true}
        separatorType2={true}
        noHeaderUpperCase={true}
        structure={{
          createdAt: {
            align: 'left',
            headerTitle: t(['Tanggal\nPengajuan', 'Tanggal Pengajuan']),
            render: ({ createdAt }) => {
              return <Text>{getFormattedDate(new Date(createdAt))}</Text>;
            },
          },
          storeNameAndCode: {
            headerTitle: t(['Nama dan\nKode Toko', 'toko code']),
            render: ({ store }) => (
              <View>
                <Text bold>{store.storeManager?.storeName}</Text>
                <Text>{store.managerStoreCode}</Text>
              </View>
            ),
          },
          assignDateAgent: {
            align: 'left',
            headerTitle: t([
              'Tanggal Assign\nDistributor',
              'Tanggal Assign\nDistributor',
            ]),

            render: ({ assignDateAgent }) => {
              return (
                <Text>
                  {assignDateAgent
                    ? getFormattedDate(new Date(assignDateAgent))
                    : '-'}
                </Text>
              );
            },
          },
          agentName: {
            align: 'left',
            headerTitle: t([
              'Nama Distributor\nTujuan',
              'Nama Distributor\nTujuan',
            ]),
            render: ({ agentName }) => <Text>{agentName}</Text>,
          },
          status: {
            align: 'left',
            headerTitle: t(['Status\nPengajuan', 'Status\nPengajuan']),
            render: ({ rejectedBy, cmsRequestStatus, store, status }) => {
              if (!store.storeManager?.statusActive) {
                return (
                  <Text bold color="accepted">
                    {t(['Inactive', 'Inactive'])}
                  </Text>
                );
              } else {
                if (cmsRequestStatus === CmsRequestStatus.CANCEL) {
                  return (
                    <Text bold color="error">
                      {t(['Dibatalkan\nOleh Sistem ', 'Rejected by System'])}
                    </Text>
                  );
                } else if (cmsRequestStatus === CmsRequestStatus.APPROVED) {
                  return (
                    <Text bold color="accepted">
                      {t(['Diterima\nDistributor', 'Approved by Distributor'])}
                    </Text>
                  );
                } else if (cmsRequestStatus === CmsRequestStatus.REVIEW) {
                  return (
                    <Text bold color="waiting">
                      {t(['Menunggu\nDistributor', 'Review'])}
                    </Text>
                  );
                } else if (rejectedBy === RejectedBy.PRINCIPAL) {
                  return (
                    <Text bold color="error">
                      {t(['Ditolak\noleh Principal', 'Ditolak oleh Principal'])}
                    </Text>
                  );
                } else if (rejectedBy === RejectedBy.DISTRIBUTOR) {
                  return (
                    <Text bold color="error">
                      {t([
                        'Ditolak\noleh Distributor',
                        'Ditolak oleh Distributor',
                      ])}
                    </Text>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.REJECT &&
                  status === 'REJECTED'
                ) {
                  return (
                    <Text bold color="error">
                      {t(['Ditolak\noleh Principal', 'Ditolak oleh Principal'])}
                    </Text>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.REJECT &&
                  status === 'REQUEST'
                ) {
                  return (
                    <Text bold color="error">
                      {t([
                        'Ditolak\noleh Distributor',
                        'Ditolak oleh Distributor',
                      ])}
                    </Text>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.UNMATCHED ||
                  status === RequestStatus.UNMATCHED
                ) {
                  return (
                    <Text bold color="error">
                      {t(['Tidak Lagi Terhubung', 'Tidak Lagi Terhubung'])}
                    </Text>
                  );
                } else {
                  return (
                    <Text bold color="waiting">
                      {t(['Menunggu\nPrincipal', 'Pending'])}
                    </Text>
                  );
                }
              }
            },
          },
          approveDateAgent: {
            headerTitle: t([
              'Tanggal\nApprove/Reject',
              'last approve/reject date (Principal/Distributor)',
            ]),
            render: ({ approveDateAgent, rejectDateAgent }) => {
              let date = approveDateAgent
                ? getFormattedDate(new Date(approveDateAgent as string))
                : rejectDateAgent
                ? getFormattedDate(new Date(rejectDateAgent as string))
                : '-';
              return (
                <View style={styles.minWidth80}>
                  <Text>{date}</Text>
                </View>
              );
            },
          },
          rejectReason: {
            align: 'left',
            headerTitle: t(['Alasan jika\nReject', 'Alasan jika Reject']),
            render: ({ rejectReason, status }) => (
              <Text>
                {status === RequestStatus.CANCELED
                  ? 'Melebihi batas waktu konfirmasi'
                  : rejectReason || '-'}
              </Text>
            ),
          },
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    marginHorizontal: 50,
    marginTop: 30,
  },
  minWidth80: { minWidth: 90 },
});
