import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { correctIcon } from '../../../assets';
import { Text } from '../../core-ui';
import { ErrorModal } from '../../components';
import { GET_COMPETITION, GET_EVENT } from '../../graphql/queries';
import {
  SUBMIT_COMPETITION_ONLINE_FORM,
  SUBMIT_EVENT_ONLINE_FORM,
} from '../../graphql/mutations';
import {
  formCompetition,
  formCompetitionVariables,
} from '../../generated/formCompetition';
import { formEvent, formEventVariables } from '../../generated/formEvent';
import {
  SubmitCompetitionOnlineForm,
  SubmitCompetitionOnlineFormVariables,
} from '../../generated/SubmitCompetitionOnlineForm';
import {
  SubmitEventOnlineForm,
  SubmitEventOnlineFormVariables,
} from '../../generated/SubmitEventOnlineForm';

import EventOnlineForm from './EventOnlineForm';
import CompetitionOnlineForm from './CompetitionOnlineForm';

const ERRORS = {
  registrationClosed: 'The registration time has passed',
  quotaFull: 'The quota for participant is full',
  quotaRemaining: 'The remaining quota for participation is only for ',
  accountAlreadyRegisterToForm:
    'Account already register to this event/competition form',
};

export default function WebView() {
  let splittedUrl = useMemo(() => document.URL.split('//')[1].split('/'), []);
  let host = useMemo(() => splittedUrl[0], [splittedUrl]);
  const localhost = 'dev.cms.dbo.id';
  let formType = useMemo(() => splittedUrl[1], [splittedUrl]);
  let link = useMemo(() => splittedUrl.slice(2).join('/'), [splittedUrl]);

  let [participantName, setParticipantName] = useState('');
  let [storeName, setStoreName] = useState('');
  let [phoneNumber, setPhoneNumber] = useState('');
  let [totalAttendees, setTotalAttendees] = useState('');
  const [submitDone, setSubmitDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const onSubmitCompleted = useCallback(() => setSubmitDone(true), []);
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback((error: ApolloError) => {
    const errMessage = error.graphQLErrors[0]?.message;
    if (errMessage.includes(ERRORS.registrationClosed)) {
      setErrorMessage(
        t(['Registrasi telah ditutup.', 'Registration has been closed']),
      );
    } else if (errMessage.includes(ERRORS.quotaFull)) {
      setErrorMessage(
        t(['Kuota peserta sudah penuh.', 'Attendee quota is full.']),
      );
    } else if (errMessage.includes(ERRORS.accountAlreadyRegisterToForm)) {
      setErrorMessage(
        t([
          'Nomor telepon sudah terdaftar pada event/kompetisi ini.',
          'Phone number already registered to this event/competition.',
        ]),
      );
    } else if (errMessage.includes(ERRORS.quotaRemaining)) {
      const remaining =
        errMessage.split(ERRORS.quotaRemaining)[1]?.split(' people')[0] ?? '';
      setErrorMessage(
        t(
          [
            'Kuota peserta hanya tersisa untuk {remaining} orang.',
            'Remaining quota is only for {remaining} people.',
          ],
          { remaining },
        ),
      );
    }
    setErrorOpen(true);
    setErrorInstance(error);
  }, []);

  const [
    competitionFormSubmit,
    { loading: submitCompetitionloading },
  ] = useMutation<
    SubmitCompetitionOnlineForm,
    SubmitCompetitionOnlineFormVariables
  >(SUBMIT_COMPETITION_ONLINE_FORM, {
    onCompleted: onSubmitCompleted,
    onError: openErrorModal,
  });
  const [eventFormSubmit, { loading: submitEventLoading }] = useMutation<
    SubmitEventOnlineForm,
    SubmitEventOnlineFormVariables
  >(SUBMIT_EVENT_ONLINE_FORM, {
    onCompleted: onSubmitCompleted,
    onError: openErrorModal,
  });

  let [getCompetition, { data: competitionData }] = useLazyQuery<
    formCompetition,
    formCompetitionVariables
  >(GET_COMPETITION);
  let [getEvent, { data: eventData }] = useLazyQuery<
    formEvent,
    formEventVariables
  >(GET_EVENT);

  useEffect(() => {
    if (splittedUrl[splittedUrl.length - 2].includes('competition')) {
      getCompetition({
        variables: {
          link: host.includes('localhost')
            ? `https://${localhost}/${formType}/${link}`
            : `https://${host}/${formType}/${link}`,
        },
      });
    } else {
      getEvent({
        variables: {
          where: {
            link: host.includes('localhost')
              ? `https://${localhost}/${formType}/${link}`
              : `https://${host}/${formType}/${link}`,
          },
        },
      });
    }
  }, [getCompetition, getEvent, host, formType, link, splittedUrl]);

  let event = eventData && eventData.formEvent;
  let competition = competitionData && competitionData.formCompetitionAdmin;

  const submitDisabled = useMemo(() => {
    if (event && event.maxParticipant) {
      if (Number(totalAttendees) > event.maxParticipant) {
        return true;
      }
    }
    return false;
  }, [event, totalAttendees]);

  const onSubmitCompetitionForm = useCallback(() => {
    if (competition) {
      competitionFormSubmit({
        variables: {
          formId: competition.id,
          name: participantName,
          storeName,
          phoneNumber,
        },
      });
    }
  }, [
    competition,
    participantName,
    storeName,
    phoneNumber,
    competitionFormSubmit,
  ]);

  const onSubmitEventForm = useCallback(() => {
    if (event) {
      eventFormSubmit({
        variables: {
          formId: event.id,
          name: participantName,
          storeName,
          phoneNumber,
          attendees: Number(totalAttendees),
        },
      });
    }
  }, [
    event,
    participantName,
    storeName,
    phoneNumber,
    totalAttendees,
    eventFormSubmit,
  ]);

  if (submitDone) {
    return (
      <View style={styles.container}>
        <Image
          source={correctIcon}
          style={styles.image}
          resizeMethod={'auto'}
        />
        <Text size="medium" style={styles.title}>
          {t(['Selamat', 'Congratulation'])}
        </Text>
        <Text>
          {t([
            'Anda telah berhasil mendaftar.',
            'You have registered successfully.',
          ])}
        </Text>
      </View>
    );
  }

  return (
    <View>
      <ErrorModal
        action={t(['mendaftar', 'register'])}
        message={errorMessage}
        open={errorOpen}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      {event && (
        <EventOnlineForm
          eventName={event.name}
          venue={event.venue}
          eventDate={new Date(event.event?.eventDate)}
          maxParticipant={event.maxParticipant?.toString() || ''}
          latitude={Number(event.latitude)}
          longitude={Number(event.longitude)}
          location={event.address || ''}
          participantName={participantName}
          storeName={storeName}
          phoneNumber={phoneNumber}
          totalAttendees={totalAttendees}
          submitFormLoading={submitEventLoading}
          submitDisabled={submitDisabled}
          setParticipantName={setParticipantName}
          setStoreName={setStoreName}
          setPhoneNumber={setPhoneNumber}
          setTotalAttendees={setTotalAttendees}
          onSubmitForm={onSubmitEventForm}
        />
      )}
      {competition && (
        <CompetitionOnlineForm
          image={competition.image}
          competitionName={competition.name}
          prize={competition.prize || ''}
          prizeValue={competition.prizeValue.toString()}
          toEnter={competition.terms || ''}
          participantName={participantName}
          storeName={storeName}
          phoneNumber={phoneNumber}
          submitFormLoading={submitCompetitionloading}
          setParticipantName={setParticipantName}
          setStoreName={setStoreName}
          setPhoneNumber={setPhoneNumber}
          onSubmitForm={onSubmitCompetitionForm}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 8,
    fontWeight: 'bold',
  },
  image: {
    width: 48,
    height: 36,
    marginBottom: 16,
  },
});
