import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Clipboard,
  ActivityIndicator,
} from 'react-native';

import {
  Box,
  DateTimePicker,
  ErrorMessage,
  ErrorModal,
  ConfirmationModal,
} from '../../../components';
import { spacing } from '../../../constants/theme';
import { Button, Switch, Text } from '../../../core-ui';
import { MissionCategory } from '../../../generated/globalTypes';
import {
  LoyaltyMissionPoints,
  LoyaltyMissionPoints_loyaltyMissionPoints as LoyaltyMissionPointsData,
} from '../../../generated/LoyaltyMissionPoints';
import {
  UpdateLoyaltyPointMission,
  UpdateLoyaltyPointMissionVariables,
} from '../../../generated/UpdateLoyaltyPointMission';
import { UPDATE_LOYALTY_POINT_MISSION } from '../../../graphql/mutations';
import { GET_LOYALTY_POINT_MISSION } from '../../../graphql/queries/gamification';

let MissionList = ({
  item,
}: {
  item: LoyaltyMissionPointsData;
  // index: number;
}) => {
  return (
    <View style={[styles.row, styles.missionListContent]}>
      <Text style={styles.textNameMission}>{item.name}</Text>
      <View style={[styles.row, styles.missionIdContainer]}>
        <Text>{item.missionId}</Text>
        <Button
          preset="transparent"
          title="Salin"
          onPress={() => Clipboard.setString(item.missionId)}
          disableRipple={false}
        />
      </View>
    </View>
  );
};
export default function MissionEditor() {
  let [startDate, setStartDate] = useState(new Date());
  let [endDate, setEndDate] = useState(new Date());
  let [active, setActive] = useState(false);

  let [disableSave, setDisableSave] = useState(false);

  let [mainMission, setMainMission] = useState<
    Array<LoyaltyMissionPointsData>
  >();
  let [, setOtherMission] = useState<Array<LoyaltyMissionPointsData>>();

  const [errorOpen, setErrorOpen] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {
    loading: missionLoading,
    error: missionError,
    refetch: missionRefetch,
  } = useQuery<LoyaltyMissionPoints>(GET_LOYALTY_POINT_MISSION, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (result) => {
      let mainMission = result.loyaltyMissionPoints.filter(
        (item) => item.category === MissionCategory.MISI_UTAMA,
      );
      let otherMission = result.loyaltyMissionPoints.filter(
        (item) => item.category === MissionCategory.MISI_TAMBAHAN,
      );
      setMainMission(mainMission);
      setOtherMission(otherMission);
      setStartDate(result.loyaltyMissionPoints[0].startDate);
      setEndDate(result.loyaltyMissionPoints[0].endDate);
      setActive(result.loyaltyMissionPoints[0].active);
    },
  });

  const [
    editLoyaltyMission,
    { loading: editLoading, error: editError },
  ] = useMutation<
    UpdateLoyaltyPointMission,
    UpdateLoyaltyPointMissionVariables
  >(UPDATE_LOYALTY_POINT_MISSION, {
    onCompleted: () => {
      refetchMissions();
    },
    onError: () => {
      setErrorOpen(true);
    },
  });

  const onSave = useCallback(() => {
    ///////////////////////////////////////////////////////////////
    // make date time to 00:00:00
    //
    ///////////////////////////////////////////////////////////////

    let startDateConvert = new Date(startDate);
    startDateConvert.setHours(0, 0, 0, 0);

    let endDateConvert = new Date(endDate);
    endDateConvert.setHours(23, 59, 59, 0);

    editLoyaltyMission({
      variables: {
        activeStatus: active,
        date: {
          startDate: startDateConvert,
          endDate: endDateConvert,
        },
      },
    });
  }, [editLoyaltyMission, active, startDate, endDate]);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);

  const refetchMissions = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await missionRefetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [missionRefetch]);

  useEffect(() => {
    refetchMissions();
  }, [refetchMissions]);

  useEffect(() => {
    if (new Date(startDate) <= new Date(endDate)) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [startDate, endDate, disableSave]);

  const FlatListMission = ({
    title,
    data,
  }: {
    title: string;
    data: Array<LoyaltyMissionPointsData>;
  }) => {
    return (
      <FlatList
        data={data}
        renderItem={MissionList}
        ListHeaderComponent={
          <View style={[styles.row, styles.headerMission]}>
            <Text bold style={{ flex: 0.6 }}>
              {title}
            </Text>
            <Text bold style={{ flex: 0.4 }}>
              Mission ID
            </Text>
          </View>
        }
      />
    );
  };

  {
    return missionLoading ? (
      <ActivityIndicator />
    ) : (
      <>
        <View style={styles.container}>
          <View style={styles.switchContainer}>
            <Switch
              checked={active}
              onPress={setActive}
              loading={editLoading}
            />
            <Text bold style={styles.switchText}>
              {active
                ? t(['Aktif', 'Active'])
                : t(['Tidak Aktif', 'Non Active'])}
            </Text>
          </View>
          <Box>
            <View style={[styles.row, styles.filterBoxContainer]}>
              <View style={styles.row}>
                <View style={styles.datePicker}>
                  <DateTimePicker
                    type="date"
                    data-testid="start-date"
                    selectedDate={startDate}
                    onChange={setStartDate}
                    label={t(['Dari', 'From'])}
                    disabled={!active}
                  />
                </View>
                <DateTimePicker
                  type="date"
                  data-testid="end-date"
                  selectedDate={endDate}
                  onChange={setEndDate}
                  label={t(['Hingga', 'To'])}
                  disabled={!active}
                  shouldDisableDate={(date) => {
                    return date ? new Date(startDate) > new Date(date) : false;
                  }}
                />
              </View>
              <Button
                style={styles.submitButton}
                title="Simpan"
                disabled={disableSave}
                data-testid={`['data-testid']-submit`}
                isLoading={editLoading}
                onPress={() => {
                  setConfirmationModalOpen(true);
                }}
              />
            </View>
          </Box>

          <Box style={styles.box}>
            <FlatListMission title="Misi Utama" data={mainMission || []} />
          </Box>

          {/* <Box style={styles.box}>
            <FlatListMission title="Misi Tambahan" data={otherMission || []} />
          </Box> */}
          {missionError && (
            <View style={styles.errorRoot}>
              <ErrorMessage
                action="Mengambil data misi"
                error={missionError}
                onPress={refetchMissions}
              />
            </View>
          )}
        </View>
        <ConfirmationModal
          data-testid="update-modal"
          open={confirmationModalOpen}
          onClose={() => setConfirmationModalOpen(false)}
          onConfirm={onSave}
          loading={editLoading}
          title={
            active
              ? 'Apakah anda yakin ingin mengaktifkan misi?'
              : 'Apakah anda yakin ingin menonaktifkan misi?'
          }
        />
        <ErrorModal
          open={errorOpen}
          action="update misi"
          error={editError}
          onClose={closeErrorModal}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.small,
    maxWidth: '50%',
  },
  errorRoot: { alignItems: 'center', paddingTop: spacing.medium },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing.small,
  },
  switchText: {
    marginLeft: 12,
  },
  row: {
    flexDirection: 'row',
  },
  filterBoxContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  submitButton: {
    minWidth: 120,
  },
  datePicker: {
    marginRight: 12,
  },
  box: {
    marginTop: 12,
  },
  missionListContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textNameMission: {
    flex: 0.6,
  },
  missionIdContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 0.4,
  },
  headerMission: {
    flex: 1,
    justifyContent: 'space-between',
  },
});
