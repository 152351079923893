import React, { useState, useCallback, useEffect, useMemo } from 'react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import {
  ArchiveStatus,
  CompetitionOrderByType,
  StatsCompetition,
} from '../../generated/globalTypes';
import { Table, Text } from '../../core-ui';
import { ErrorMessage, TextPopover, ErrorModal } from '../../components';
import { theme, colors } from '../../constants/theme';
import { to12Hh } from '../../helpers';
import { GET_COMPETITIONS } from '../../graphql/queries';
import { ARCHIVE_COMPETITION } from '../../graphql/mutations';
import {
  ArchiveCompetitionVariables,
  ArchiveCompetition,
} from '../../generated/ArchiveCompetition';
import {
  CompetitionsVariables,
  Competitions,
} from '../../generated/Competitions';
import { CompetitionFragment } from '../../generated/CompetitionFragment';
import { useTableSort } from '../../hooks';

type Props = {
  searchByUser?: string;
  searchByRegion?: string;
  searchByCity?: string;
  searchByDateFrom?: Date;
  searchByDateUntil?: Date;
  searchArchived?: boolean;
  searchByStats?: string;
  searchByCompetition?: string;
  searchByBrand?: string;
  searchByUserNumberFrom?: string;
  searchByUserNumberUntil?: string;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: CompetitionsVariables;
  setEditCompetition?: (value: CompetitionFragment) => void;
};

const DboNewsCompetitionTable = (props: Props) => {
  let {
    searchByUser,
    searchByRegion,
    searchByCity,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
    searchByCompetition,
    searchByStats,
    searchByUserNumberFrom,
    searchByUserNumberUntil,
    searchByBrand,
    refetchToggle,
    hideEdit,
    queryVariables,
    setEditCompetition,
  } = props;
  const [page, setPage] = useState(0);
  const [archiveID, setArchiveID] = useState<string | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    CompetitionOrderByType
  >();

  const statsVariable = useMemo(
    () =>
      searchByStats === StatsCompetition.ALL_USERS_WHO_ENTERED
        ? {
            stats: StatsCompetition.ALL_USERS_WHO_ENTERED,
            registerUser:
              searchByUserNumberFrom && searchByUserNumberUntil
                ? {
                    countFrom: Number(searchByUserNumberFrom),
                    countTo: Number(searchByUserNumberUntil),
                  }
                : undefined,
          }
        : searchByStats === StatsCompetition.ALL_COMPETITION_WINNERS
        ? { stats: StatsCompetition.ALL_COMPETITION_WINNERS }
        : {},
    [searchByStats, searchByUserNumberFrom, searchByUserNumberUntil],
  );

  let [getCompetitions, { data, loading, error, refetch }] = useLazyQuery<
    Competitions,
    CompetitionsVariables
  >(GET_COMPETITIONS, {
    variables: {
      skip: page * rowsPerPage,
      first: rowsPerPage,
      archived: ArchiveStatus.NORMAL,
      orderBy: orderByVariable,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            user: searchByUser,
            region: searchByRegion,
            city: searchByCity,
            name: searchByCompetition,
            date:
              searchByDateFrom && searchByDateUntil
                ? {
                    startDate: searchByDateFrom,
                    endDate: searchByDateUntil,
                  }
                : undefined,
            brandId: searchByBrand,
            archived: searchArchived ? undefined : ArchiveStatus.NORMAL,
            ...statsVariable,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [
      searchByUser,
      searchByRegion,
      searchByCity,
      searchByCompetition,
      searchByDateFrom,
      searchByDateUntil,
      searchByBrand,
      searchArchived,
      queryVariables,
      refetch,
      page,
      statsVariable,
      rowsPerPage,
    ],
  );

  useEffect(() => {
    setPage(0);
  }, [
    searchByUser,
    searchByStats,
    searchByRegion,
    searchByCity,
    searchByCompetition,
    searchByDateFrom,
    searchByDateUntil,
    searchByBrand,
    searchByUserNumberFrom,
    searchByUserNumberUntil,
    searchArchived,
  ]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  let [
    archiveCompetition,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchiveCompetition, ArchiveCompetitionVariables>(
    ARCHIVE_COMPETITION,
    {
      onCompleted: refetchCurrentPage,
      onError: openErrorModal,
    },
  );

  const onArchivePress = useCallback(
    (id: string) => {
      setArchiveID(id);
      archiveCompetition({ variables: { id } });
    },
    [archiveCompetition],
  );

  useEffect(() => {
    // NOTE: this is triggered when any search props has been changed; page needs to be reset
    getCompetitions();
  }, [getCompetitions]);

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onViewPress = useCallback(
    (competition: CompetitionFragment) => {
      if (setEditCompetition) {
        setEditCompetition(competition);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditCompetition],
  );

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : ({
            action: {
              headerTitle: t(['AKSI', 'ACTION']),
              headerTitleColor: 'link',
              displayText: t(['lihat', 'view']),
              onPress: onViewPress,
            },
          } as const),
    [hideEdit, onViewPress],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const currentTime = new Date().getTime();

  return (
    <>
      <Table
        data-testid="competition-table"
        page={page}
        error={!!error}
        data={data?.competitionAdvanceSearch.row ?? []}
        loading={loading}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onChangeRowsPerPage={onRowsChange}
        onChangePage={onChangePage}
        dataCount={data?.competitionAdvanceSearch.total || 0}
        structure={{
          [CompetitionOrderByType.appUser]: {
            headerTitle: t(['PENGGUNA', 'USERS']),
            orderable: true,
          },
          [CompetitionOrderByType.city]: {
            headerTitle: t(['KOTA', 'CITY']),
            orderable: true,
          },
          [CompetitionOrderByType.time]: {
            headerTitle: t([
              'WAKTU KOMPETISI DIBUAT',
              'COMPETITION CREATION TIME',
            ]),
            render: ({ eventDate }) => (
              <Text>{to12Hh(new Date(eventDate))}</Text>
            ),
            orderable: true,
            compareFn: (a, b) => {
              let itemA = new Date(a.eventDate).toTimeString();
              let itemB = new Date(b.eventDate).toTimeString();
              return itemA < itemB ? -1 : 1;
            },
          },
          [CompetitionOrderByType.eventDate]: {
            headerTitle: t(['TANGGAL', 'DATE']),
            valuePreProcessor: (value) => new Date(value as string), // NOTE: value is Date ISO String
            orderable: true,
          },
          [CompetitionOrderByType.closeDate]: {
            headerTitle: t(['AKHIR', 'ENDS']),
            valuePreProcessor: (value) => new Date(value as string), // NOTE: value is Date ISO String
            orderable: true,
          },
          [CompetitionOrderByType.name]: {
            headerTitle: t(['KOMPETISI', 'COMPETITION']),
            orderable: true,
          },
          medsos: {
            headerTitle: t(['MASUK', 'ENTER']),
            render: ({ medsos, externalLink, formLink }) => {
              let howToEnter = medsos.map((item) =>
                item.socialMediaType?.toString(),
              );
              externalLink && howToEnter.push(externalLink);
              formLink && howToEnter.push(`Form ${formLink.id}`);
              return <Text>{howToEnter.join(', ')}</Text>;
            },
          },
          [CompetitionOrderByType.brandName]: {
            headerTitle: t(['MEREK', 'BRANDS']),
            dataPath: 'brand.name',
            orderable: true,
          },
          [CompetitionOrderByType.buContactName]: {
            dataPath: 'brand.businessUnitContact.name',
            headerTitle: t(['Principal', 'Principal']),
            orderable: true,
          },
          [CompetitionOrderByType.submit]: {
            headerTitle: t(['PENDAFTAR', 'REGISTERED USER']),
            render: ({ formLink }) => (
              <Text>{formLink?.submitFormCompetition?.length || 0}</Text>
            ),
            orderable: true,
          },
          [CompetitionOrderByType.winners]: {
            orderable: true,
            headerTitle: t(['PEMENANG', 'WINNERS']),
            render: ({ winners }) => {
              let winnersList = winners.map((winner) => winner.name);
              if (winnersList.length > 3) {
                let label = winnersList.slice(0, 3).join(', ');
                label += t(['+ {data} lainnya', '+ {data} others'], {
                  data: winnersList.length - 3,
                });
                return (
                  <TextPopover label={label} content={winnersList.join(', ')} />
                );
              } else {
                return <Text>{winnersList.join(', ')}</Text>;
              }
            },
          },
          [CompetitionOrderByType.competitionStatus]: {
            orderable: true,
            headerTitle: t(['STATUS', 'STATUS']),
            render: ({ liveDate, endLiveDate }) => {
              if (currentTime < new Date(liveDate).getTime()) {
                return (
                  <View style={styles.pending}>
                    <Text color="contrast">{t(['TUNDA', 'PENDING'])}</Text>
                  </View>
                );
              } else if (currentTime < new Date(endLiveDate).getTime()) {
                return (
                  <View style={styles.live}>
                    <Text color="contrast">{t(['LIVE', 'LIVE'])}</Text>
                  </View>
                );
              } else {
                return (
                  <View style={styles.end}>
                    <Text color="contrast">{t(['SELESAI', 'END'])}</Text>
                  </View>
                );
              }
            },
          },
          ...action,
          archive: {
            headerTitle: t(['ARSIP', 'ARCHIVE']),
            headerTitleColor: 'link',
            render: ({ id, status }) =>
              id === archiveID && archiveLoading ? (
                <ActivityIndicator size="small" />
              ) : (
                <TouchableOpacity
                  data-testid="competition-archive-toggle"
                  onPress={() => onArchivePress(id)}
                  style={styles.actionSpacing}
                >
                  {status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon data-testid="competition-normal" />
                  ) : (
                    <FolderFilledIcon data-testid="competition-archived" />
                  )}
                </TouchableOpacity>
              ),
          },
        }}
      />

      {!!error && (
        <ErrorMessage
          error={error}
          action={t([
            'mengambil data kompetisi',
            'retrieve the competition data',
          ])}
          onPress={refetchCurrentPage}
        />
      )}
      <ErrorModal
        open={errorOpen}
        error={archiveError}
        action={t([
          'mengubah status arsip data kompetisi',
          'change archive status of the competition data',
        ])}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  actionSpacing: { paddingLeft: theme.spacing.xsmall },
  pending: {
    width: 60,
    height: 36,
    backgroundColor: colors.status.pending,
    alignItems: 'center',
    justifyContent: 'center',
  },
  live: {
    width: 60,
    height: 36,
    backgroundColor: colors.status.live,
    alignItems: 'center',
    justifyContent: 'center',
  },
  end: {
    width: 60,
    height: 36,
    backgroundColor: colors.status.end,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default DboNewsCompetitionTable;
