import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  ViewProps,
} from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { ApolloError } from 'apollo-client';

import { Text, TextInput, Dropzone, Button } from '../../core-ui';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { fonts, colors, spacing } from '../../constants/theme';
import {
  Option,
  StepHeader,
  Modal,
  BrandDropdown,
  ErrorModal,
} from '../../components';
import LiveDateRadioGroup from '../../components/LiveDateRadioGroup';
import Header from '../../components/Header';
import {
  UPLOAD,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
} from '../../graphql/mutations';
import {
  CreateDocument,
  CreateDocumentVariables,
} from '../../generated/CreateDocument';
import { Orientation, FolderType } from '../../generated/globalTypes';
import { Upload, UploadVariables } from '../../generated/Upload';
import {
  UpdateDocument,
  UpdateDocumentVariables,
} from '../../generated/UpdateDocument';
import { LibraryDocument } from '../../generated/LibraryDocument';

type Props = {
  onSubmitCompleted: () => void;
  editDocument: LibraryDocument | null;
};

let useStyles = makeStyles({
  icon: {
    color: '#ffffff',
  },
});

type ThumbnailOrientation = Orientation.VERTICAL | Orientation.HORIZONTAL;

export default function LibraryUploadContent(props: Props) {
  let { onSubmitCompleted, editDocument } = props;
  let [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  let [liveDate, setLiveDate] = useState(new Date());
  let [editable, setEditable] = useState(true);
  let [downloadFile, setDownloadFile] = useState<File>();
  let [docName, setDocName] = useState('');
  let [showPreview, setShowPreview] = useState(false);
  let [numPages, setNumPages] = useState(0);
  let [pdf, setPdf] = useState<string | FileWithPreview | null>(null);
  let [image, setImage] = useState<string | FileWithPreview | null>(null);
  let [pageNumber, setPageNumber] = useState(1);
  let [orientation, setOrientation] = useState<ThumbnailOrientation>(
    Orientation.VERTICAL,
  );
  let [pdfRatio, setPdfRatio] = useState(1);
  let [dropzoneError, setDropzoneError] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );

  const onCreateError = useMemo(() => {
    return openErrorModal(t(['membuat dokumen', 'create document']));
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(t(['mengubah dokumen', 'update document']));
  }, [openErrorModal]);
  const onUploadError = useMemo(() => {
    return openErrorModal(
      t(['mengunggah dokumen atau gambar', 'upload document or image']),
    );
  }, [openErrorModal]);

  const wrappedSetSelectedBrand = useCallback(
    (selected: Option) => setSelectedBrand(selected.value),
    [setSelectedBrand],
  );

  const clearForm = useCallback(() => {
    setSelectedBrand(null);
    setLiveDate(new Date());
    setDocName('');
    setPdf(null);
    setImage(null);
    setOrientation(Orientation.VERTICAL);
  }, []);

  useEffect(() => {
    if (editDocument) {
      setSelectedBrand(editDocument.brandId);
      setLiveDate(editDocument.liveDate);
      setDocName(editDocument.documentName);
      setPdf(editDocument.url);
      setImage(editDocument.thumbnail);
      setOrientation(editDocument.thumbnailOrientation);
      setDownloadFile(undefined);
      setEditable(false);
    }
  }, [editDocument]);

  const [createDocument, { loading: createDocumentLoading }] = useMutation<
    CreateDocument,
    CreateDocumentVariables
  >(CREATE_DOCUMENT, {
    onCompleted: () => {
      onSubmitCompleted();
      clearForm();
    },
    onError: onCreateError,
  });

  const [updateDocument, { loading: updateDocumentLoading }] = useMutation<
    UpdateDocument,
    UpdateDocumentVariables
  >(UPDATE_DOCUMENT, {
    onCompleted: () => {
      onSubmitCompleted();
      setEditable(false);
      clearForm();
    },
    onError: onUpdateError,
  });

  const [upload, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, {
    onError: onUploadError,
  });

  const [previewHeight, setPreviewHeight] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(0);

  let classes = useStyles();

  const openPreviewModal = () => setShowPreview(true);
  const closePreviewModal = () => setShowPreview(false);

  const setPreviewPageSize: ViewProps['onLayout'] = ({
    nativeEvent: { layout },
  }) => {
    if (!previewHeight && !previewWidth) {
      setPreviewWidth(layout.width);
      // NOTE: -40 for the header (download and close button) and -40 for the footer (prev and next)
      setPreviewHeight(layout.height - 80);
    }
  };

  const submitDisabled = useMemo(
    () => !selectedBrand || !docName || !pdf || !image || dropzoneError,
    [selectedBrand, docName, pdf, image, dropzoneError],
  );

  const previewPageRatio = useMemo(() => previewWidth / previewHeight, [
    previewWidth,
    previewHeight,
  ]);

  let changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  let previousPage = () => {
    changePage(-1);
  };
  let nextPage = () => {
    changePage(1);
  };

  let onSubmit = useCallback(async () => {
    if (!!selectedBrand && !!docName && !!pdf && !!image) {
      let pdfLink: string | undefined;
      let imageLink: string | undefined;
      let linksPromises: Array<undefined | ReturnType<typeof upload>> = [];
      linksPromises.push(
        typeof pdf !== 'string' && pdf
          ? upload({
              variables: { file: pdf.file, folder: FolderType.DOCUMENT },
            })
          : undefined,
      );
      linksPromises.push(
        typeof image !== 'string' && image
          ? upload({
              variables: { file: image.file, folder: FolderType.DOCUMENT },
            })
          : undefined,
      );

      const results = await Promise.all(linksPromises);

      if (results[0]) {
        pdfLink = results[0].data?.upload.link ?? '';
      }
      if (results[1]) {
        imageLink = results[1].data?.upload.link ?? '';
      }

      if (editDocument) {
        updateDocument({
          variables: {
            documentId: editDocument.id,
            brandId: selectedBrand,
            documentName: docName,
            url: pdfLink,
            thumbnail: imageLink,
            thumbnailOrientation: orientation,
            liveDate,
          },
        });
      } else {
        createDocument({
          variables: {
            brandId: selectedBrand,
            documentName: docName,
            url: pdfLink ?? '',
            thumbnail: imageLink ?? '',
            thumbnailOrientation: orientation,
            liveDate,
          },
        });
      }
    }
  }, [
    editDocument,
    selectedBrand,
    docName,
    pdf,
    image,
    orientation,
    liveDate,
    upload,
    createDocument,
    updateDocument,
  ]);

  const onDocumentLoadSuccess = useCallback(
    async (pdfFile: pdfjs.PDFDocumentProxy) => {
      setNumPages(pdfFile.numPages);
      const raw = await pdfFile.getData();
      const filename = docName.endsWith('.pdf') ? docName : docName + '.pdf';
      setDownloadFile(
        new File([raw.buffer], filename, { type: 'application/pdf' }),
      );
    },
    [docName],
  );
  const downloadPdf = useCallback(() => {
    let filename = docName.endsWith('.pdf') ? docName : docName + '.pdf';
    if (downloadFile) {
      saveAs(downloadFile, filename);
    } else if (editDocument?.url) {
      saveAs(editDocument.url, filename);
    }
  }, [docName, downloadFile, editDocument]);

  let pdfSource = !!pdf
    ? typeof pdf === 'string'
      ? pdf
      : pdf.preview
    : undefined;

  const onEditPress = useCallback(() => {
    setEditable(!editable);
    window.scrollTo({ top: 170 });
  }, [editable]);

  return (
    <>
      {showPreview ? (
        <Modal
          open
          onClose={closePreviewModal}
          contentStyle={styles.previewModal}
          fullWidth
        >
          <View style={styles.previewWrapper} onLayout={setPreviewPageSize}>
            <View style={[styles.rowView, styles.previewHeader]}>
              <Text size="medium" color="contrast">
                {docName}
              </Text>
              <View style={[styles.rowView, styles.previewIconWrapper]}>
                <TouchableOpacity onPress={downloadPdf}>
                  <GetAppIcon
                    color="primary"
                    classes={{ colorPrimary: classes.icon }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  data-testid="library-close-modal"
                  onPress={closePreviewModal}
                >
                  <CloseIcon
                    color="primary"
                    classes={{ colorPrimary: classes.icon }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={[styles.flex, styles.pdfPreview]}>
              <Document
                data-testid="library-preview-modal-document"
                loading={<Text>{t(['Memuat...', 'Loading...'])}</Text>}
                error={
                  <Text>
                    {t([
                      'Oops, gagal memuat PDF.',
                      'Oops, failed to load PDF.',
                    ])}
                  </Text>
                }
                file={pdfSource}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  height={
                    pdfRatio > previewPageRatio ? undefined : previewHeight
                  }
                  width={pdfRatio > previewPageRatio ? previewWidth : undefined}
                  renderAnnotationLayer={false}
                  onLoadProgress={() => {
                    <ActivityIndicator />;
                  }}
                />
              </Document>
            </View>
            <View style={[styles.rowView, styles.previewActionWrapper]}>
              <Button
                data-testid="library-previous-page-modal"
                disableTouchRipple={true}
                preset="transparent"
                title={t(['Sebelumnya', 'Previous'])}
                disabled={pageNumber <= 1}
                onPress={previousPage}
              />
              <Text color="contrast" style={styles.previewActionSpacing}>
                {t(['Halaman', 'Page'])} {pageNumber || (numPages ? 1 : '--')}{' '}
                {t(['dari', 'of'])} {numPages || '--'}
              </Text>
              <Button
                data-testid="library-next-page-modal"
                disableTouchRipple={true}
                preset="transparent"
                title={t(['Selanjutnya', 'Next'])}
                disabled={pageNumber >= numPages}
                onPress={nextPage}
              />
            </View>
          </View>
        </Modal>
      ) : null}
      <View style={styles.container}>
        <View style={styles.headerWrapper}>
          <Header
            title={t(['Perpustakaan', 'Library'])}
            subtitle={t(['Unggah konten', 'Upload Content'])}
            subtitleColor="default"
          />
          <Text style={styles.subTitle}>
            {t(['Semua Brand', 'All Brands'])}
          </Text>
        </View>
        <View style={[styles.rowView, styles.stepsWrapper]}>
          <View style={[styles.flex, styles.stepsSpacing]}>
            <StepHeader
              step={1}
              title={t(['Pilih Merek Tersedia', 'Select Existing Brand'])}
              style={styles.stepHeader}
            />
            <BrandDropdown
              data-testid="library-brand-dropdown"
              title="Select Brand"
              selectedOption={selectedBrand ?? undefined}
              onSelect={wrappedSetSelectedBrand}
              disabled={!editable}
            />
          </View>
          <View style={[styles.flex, styles.stepsSpacing]}>
            <StepHeader
              step={2}
              title={t(['Nama Document', 'Document Name'])}
              style={styles.stepHeader}
            />
            <TextInput
              data-testid="library-name"
              value={docName}
              onChangeText={setDocName}
              containerStyle={styles.removeBorder}
              disabled={!editable}
            />
          </View>
          <View style={[styles.flex, styles.stepsSpacing]}>
            <StepHeader
              step={3}
              title={t(['Unggah file PDF A4', 'Upload A4 PDF file'])}
              style={styles.stepHeader}
            />
            <Dropzone
              data-testid="library-pdf"
              note="Maximum file size: 8mb"
              maxFileSize={8000000}
              imageSize={{ height: 200, width: 200 }}
              type="pdf"
              containerStyle={styles.bigDropzone}
              source={pdfSource}
              getPreview={setPdf}
              onDropSuccess={setDownloadFile}
              getOrientation={setOrientation}
              disabled={!editable}
              getPreviewRatio={setPdfRatio}
              getError={setDropzoneError}
            />
            {!!pdf && (
              <>
                <View style={[styles.rowView, styles.xsmallTopPadding]}>
                  <Button
                    data-testid="library-pdf-download"
                    title={t(['Unduh', 'Download'])}
                    preset="transparent"
                    onPress={downloadPdf}
                    style={styles.pdfActionButton}
                  />
                  <View style={styles.separator} />
                  <Button
                    data-testid="library-pdf-preview"
                    title={t(['Pratinjau', 'Preview'])}
                    preset="transparent"
                    onPress={openPreviewModal}
                    style={styles.pdfActionButton}
                  />
                </View>
                <Dropzone
                  data-testid="library-pdf-2"
                  maxFileSize={8000000}
                  type="pdf"
                  containerStyle={styles.smallDropzone}
                  getPreview={setPdf}
                  withBorder={false}
                  disabled={!editable}
                  getError={setDropzoneError}
                />
              </>
            )}
          </View>
          <View style={styles.flex}>
            <StepHeader
              step={4}
              title={t(['Unggah Thumbnail', 'Upload Thumbnail'])}
              style={styles.stepHeader}
            />
            <Dropzone
              data-testid="library-image"
              note={t([
                'Ukuran: 350 x 495px format .jpg',
                'Size: 350 x 495px .jpg format',
              ])}
              imageSize={{ height: 495, width: 350 }}
              type="image"
              containerStyle={styles.bigDropzone}
              source={
                typeof image === 'string' ? image : image && image.preview
              }
              getPreview={setImage}
              disabled={!editable}
              getError={setDropzoneError}
            />
            {!!image && (
              <View style={styles.mediumTopPadding}>
                <Dropzone
                  data-testid="library-image-2"
                  type="image"
                  containerStyle={styles.smallDropzone}
                  getPreview={setImage}
                  withBorder={false}
                  disabled={!editable}
                  getError={setDropzoneError}
                />
              </View>
            )}
          </View>
        </View>
        <View style={styles.lastStepWrapper}>
          <StepHeader
            step={5}
            title={t(['Tanggal Tayang', 'Go Live Date'])}
            style={styles.stepHeader}
          />
          <View style={styles.liveWrapper}>
            <Text bold size="small" color="link" style={styles.liveText}>
              {t(['Tayang di Aplikasi', 'Go Live on App'])}:
            </Text>
            <LiveDateRadioGroup
              liveDate={new Date(liveDate)}
              setLiveDate={setLiveDate}
              disabled={!editable}
            />
            <View style={[styles.rowView, styles.liveActionWrapper]}>
              <View style={[styles.flex, styles.saveButton]}>
                <Button
                  data-testid="library-submit"
                  isLoading={
                    createDocumentLoading ||
                    updateDocumentLoading ||
                    uploadLoading
                  }
                  title={t(['Simpan', 'Submit'])}
                  onPress={onSubmit}
                  disabled={submitDisabled}
                />
              </View>
              <View style={styles.flex}>
                <Button
                  data-testid="library-edit-cancel"
                  preset="secondary"
                  title={
                    editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])
                  }
                  onPress={onEditPress}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    fontSize: fonts.sizes.large,
  },
  subTitle: {
    marginTop: 12,
  },
  previewModal: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 0,
  },
  previewWrapper: {
    flex: 1,
    height: '90vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    width: 2,
    height: 16,
    backgroundColor: colors.button.primary.background,
  },
  previewHeader: {
    width: '100%',
    height: 40,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    paddingLeft: spacing.medium,
    justifyContent: 'space-between',
  },
  previewIconWrapper: {
    marginRight: 32,
    width: 70,
    justifyContent: 'space-between',
  },
  previewActionWrapper: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
  },
  previewActionSpacing: { paddingHorizontal: spacing.medium },
  headerWrapper: {
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 52,
  },
  stepsWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  removeBorder: { borderWidth: 0 },
  xsmallTopPadding: { paddingTop: spacing.xsmall },
  bigDropzone: { height: 200, width: 200 },
  smallDropzone: { width: 120 },
  pdfActionButton: {
    padding: 0,
    minWidth: 100,
  },
  mediumTopPadding: { paddingTop: spacing.medium },
  lastStepWrapper: { alignSelf: 'flex-end', marginTop: 40 },
  liveWrapper: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    minHeight: 200,
    padding: 8,
  },
  liveActionWrapper: {
    justifyContent: 'space-between',
    paddingLeft: 48,
    paddingRight: 16,
    marginTop: spacing.medium,
  },
  stepsSpacing: { paddingRight: spacing.xlarge },
  stepHeader: { marginBottom: spacing.small },
  liveText: { paddingBottom: spacing.xsmall },
  saveButton: { marginRight: spacing.small },
  pdfPreview: { justifyContent: 'center' },
});
