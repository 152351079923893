import React, { useState, useCallback, useEffect, useMemo } from 'react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  ArchiveStatus,
  FormCompetitionOrderByType,
  FormEventOrderByType,
} from '../../generated/globalTypes';
import { Table, Text, Button } from '../../core-ui';
import { theme, colors } from '../../constants/theme';
import { to12Hh } from '../../helpers';
import { ErrorMessage, ErrorModal } from '../../components';
import { useTableSort } from '../../hooks';
import { FormType } from '../../components/dropdowns/FormLinkDropdown';
import {
  ARCHIVE_COMPETITION_ONLINE_FORM,
  ARCHIVE_EVENT_ONLINE_FORM,
} from '../../graphql/mutations';
import {
  GET_COMPETITION_ONLINE_FORMS,
  GET_EVENT_ONLINE_FORMS,
} from '../../graphql/queries';
import {
  CompetitionOnlineForms,
  CompetitionOnlineFormsVariables,
} from '../../generated/CompetitionOnlineForms';
import {
  EventOnlineForms,
  EventOnlineFormsVariables,
} from '../../generated/EventOnlineForms';
import {
  ArchiveCompetitionOnlineForm,
  ArchiveCompetitionOnlineFormVariables,
} from '../../generated/ArchiveCompetitionOnlineForm';
import {
  ArchiveEventOnlineForm,
  ArchiveEventOnlineFormVariables,
} from '../../generated/ArchiveEventOnlineForm';
import { CompetitionFormFragment } from '../../generated/CompetitionFormFragment';
import { EventFormFragment } from '../../generated/EventFormFragment';
import { routePaths } from '../../constants/routes';

type Props<T extends FormType> = {
  'data-testid'?: string;
  type: T | null;
  searchByID?: number;
  searchByRegion?: string;
  searchByCity?: string;
  searchByDateFrom?: Date;
  searchByDateUntil?: Date;
  searchArchived?: boolean;
  searchByName?: string;
  searchByBrand?: string;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: T extends 'competition'
    ? CompetitionOnlineFormsVariables
    : T extends 'event'
    ? EventOnlineFormsVariables
    : undefined; // TODO: Information Online Form Variables
  onSubmitCompleted?: () => void;
  setEditCompetition?: (value: CompetitionFormFragment) => void;
  setEditEvent?: (value: EventFormFragment) => void;
};

const OnlineFormTable = <T extends FormType>(props: Props<T>) => {
  let {
    type,
    searchByID,
    searchByRegion,
    searchByCity,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
    searchByName,
    searchByBrand,
    hideEdit,
    queryVariables,
    refetchToggle,
    onSubmitCompleted,
    setEditCompetition,
    setEditEvent,
    'data-testid': dataCy,
  } = props;
  const [page, setPage] = useState(0);
  const [archiveID, setArchiveID] = useState<number | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    order: competitionOrder,
    orderBy: competitionOrderBy,
    orderByVariable: competitionOrderByVariable,
    onRequestSort: competitionOnRequestSort,
  } = useTableSort<FormCompetitionOrderByType>();

  const {
    order: eventOrder,
    orderBy: eventOrderBy,
    orderByVariable: eventOrderByVariable,
    onRequestSort: eventOnRequestSort,
  } = useTableSort<FormEventOrderByType>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);
  const history = useHistory();

  let {
    data: competitionData,
    loading: competitionLoading,
    error: competitionError,
    refetch: refetchCompetitionForm,
  } = useQuery<CompetitionOnlineForms, CompetitionOnlineFormsVariables>(
    GET_COMPETITION_ONLINE_FORMS,
    {
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
        orderBy: competitionOrderByVariable,
        ...queryVariables,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  let {
    data: eventData,
    loading: eventLoading,
    error: eventError,
    refetch: refetchEventForm,
  } = useQuery<EventOnlineForms, EventOnlineFormsVariables>(
    GET_EVENT_ONLINE_FORMS,
    {
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
        orderBy: eventOrderByVariable,
        ...queryVariables,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const variables = {
        id: searchByID?.toString(),
        name: searchByName,
        region: searchByRegion,
        city: searchByCity,
        type: searchByName,
        brandID: searchByBrand,
        date:
          searchByDateFrom && searchByDateUntil
            ? {
                startDate: searchByDateFrom,
                endDate: searchByDateUntil,
              }
            : undefined,
        first: first ?? rowsPerPage,
        skip: skip ?? page * rowsPerPage,
        archiveStatus: searchArchived ? undefined : ArchiveStatus.NORMAL,
        ...queryVariables,
      };

      switch (type) {
        case 'competition': {
          return refetchCompetitionForm?.({
            ...variables,
            orderBy: competitionOrderByVariable,
          });
        }
        case 'event': {
          return refetchEventForm?.({
            ...variables,
            orderBy: eventOrderByVariable,
          });
        }
        case 'information': {
          // TODO: refetch Information Request Forms
          return;
        }
      }
    },
    [
      type,
      searchByID,
      searchByRegion,
      searchByCity,
      searchByDateFrom,
      searchByDateUntil,
      searchArchived,
      searchByName,
      searchByBrand,
      queryVariables,
      page,
      rowsPerPage,
      competitionOrderByVariable,
      eventOrderByVariable,
      refetchCompetitionForm,
      refetchEventForm,
    ],
  );

  useEffect(() => {
    setPage(0);
  }, [
    searchByID,
    searchByRegion,
    searchByCity,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
    searchByName,
    searchByBrand,
  ]);

  const onCompleted = useCallback(() => {
    refetchData(page * rowsPerPage);
    onSubmitCompleted?.();
  }, [refetchData, onSubmitCompleted, page, rowsPerPage]);

  let [
    archiveCompetitionForm,
    {
      loading: archiveCompetitionFormLoading,
      error: archiveCompetitionFormError,
    },
  ] = useMutation<
    ArchiveCompetitionOnlineForm,
    ArchiveCompetitionOnlineFormVariables
  >(ARCHIVE_COMPETITION_ONLINE_FORM, { onCompleted, onError: openErrorModal });
  let [
    archiveEventForm,
    { loading: archiveEventFormLoading, error: archiveEventFormError },
  ] = useMutation<ArchiveEventOnlineForm, ArchiveEventOnlineFormVariables>(
    ARCHIVE_EVENT_ONLINE_FORM,
    { onCompleted, onError: openErrorModal },
  );

  const onArchivePress = useCallback(
    (id: number) => {
      setArchiveID(id);
      switch (type) {
        case 'competition': {
          return archiveCompetitionForm({ variables: { formID: id } });
        }
        case 'event': {
          return archiveEventForm({ variables: { formID: id } });
        }
        case 'information': {
          // TODO: archive Information Request Form
          return;
        }
      }
    },
    [setArchiveID, archiveCompetitionForm, archiveEventForm, type],
  );

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onViewEventPress = useCallback(
    (event: EventFormFragment) => {
      if (setEditEvent) {
        setEditEvent(event);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditEvent],
  );
  const onViewCompetitionPress = useCallback(
    (competition: CompetitionFormFragment) => {
      if (setEditCompetition) {
        setEditCompetition(competition);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditCompetition],
  );

  const action = useMemo(() => {
    if (hideEdit) {
      return {};
    }
    switch (type) {
      case 'competition': {
        return {
          action: {
            headerTitle: t(['AKSI', 'ACTION']),
            headerTitleColor: 'link',
            displayText: t(['lihat', 'view']),
            onPress: onViewCompetitionPress,
          },
        } as const;
      }
      case 'event': {
        return {
          action: {
            headerTitle: t(['AKSI', 'ACTION']),
            headerTitleColor: 'link',
            displayText: t(['lihat', 'view']),
            onPress: onViewEventPress,
          },
        } as const;
      }
      case 'information': {
      }
    }
  }, [type, hideEdit, onViewCompetitionPress, onViewEventPress]);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  return (
    <>
      {type === 'event' ? (
        <Table
          order={eventOrder}
          orderBy={eventOrderBy}
          onRequestSort={eventOnRequestSort}
          page={page}
          data-testid={dataCy}
          data={eventData?.formEventAdvanceSearch.row ?? []}
          error={!!eventError}
          loading={eventLoading}
          dataCount={eventData?.formEventAdvanceSearch.total || 0}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onRowsChange}
          structure={{
            [FormEventOrderByType.id]: {
              headerTitle: t(['FORMULIR', 'FORM']),
              orderable: true,
            },
            [FormEventOrderByType.name]: {
              headerTitle: t(['EVENT', 'EVENT']),
              orderable: true,
            },
            [FormEventOrderByType.time]: {
              headerTitle: t(['WAKTU', 'TIME']),
              render: ({ event }) =>
                !!event && <Text>{to12Hh(new Date(event.eventDate))}</Text>,
              orderable: true,
              //NOTE: this is to order data by time only
              compareFn: (a, b) => {
                let itemA = new Date(a.event?.eventDate).toTimeString();
                let itemB = new Date(b.event?.eventDate).toTimeString();
                if (itemA < itemB) {
                  return -1;
                } else if (itemA > itemB) {
                  return 1;
                } else {
                  return 0;
                }
              },
            },
            [FormEventOrderByType.date]: {
              headerTitle: t(['TANGGAL', 'DATE']),
              dataPath: 'event.eventDate',
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : value, // NOTE: value is Date ISO String, but event may be undefined
              orderable: true,
            },
            [FormEventOrderByType.brandName]: {
              headerTitle: t(['BRAND', 'BRAND']),
              dataPath: 'event.brand.name',
              orderable: true,
            },
            [FormEventOrderByType.venue]: {
              headerTitle: t(['TEMPAT', 'VENUE']),
              orderable: true,
            },
            [FormEventOrderByType.city]: {
              headerTitle: t(['KOTA', 'CITY']),
              dataPath: 'event.city',
              orderable: true,
            },
            maxParticipant: {
              headerTitle: t([
                'MAKS PENGUNJUNG PER TOKO',
                'MAX ATTENDEES PER TOKO',
              ]),
            },
            [FormEventOrderByType.businessUnitContactName]: {
              headerTitle: t(['PRINCIPAL', 'PRINCIPAL']),
              dataPath: 'event.brand.businessUnitContact.name',
              orderable: true,
            },
            [FormEventOrderByType.eventStatus]: {
              headerTitle: t(['STATUS', 'STATUS']),
              orderable: true,
              render: ({ eventStatus }) => {
                switch (eventStatus) {
                  case 'PENDING': {
                    return (
                      <View style={styles.pending}>
                        <Text color="contrast">{t(['TUNDA', 'PENDING'])}</Text>
                      </View>
                    );
                  }
                  case 'LIVE': {
                    return (
                      <View style={styles.live}>
                        <Text color="contrast">{t(['LIVE', 'LIVE'])}</Text>
                      </View>
                    );
                  }
                  case 'END': {
                    return (
                      <View style={styles.end}>
                        <Text color="contrast">{t(['SELESAI', 'END'])}</Text>
                      </View>
                    );
                  }
                  default: {
                    return null;
                  }
                }
              },
            },
            [FormEventOrderByType.attendees]: {
              headerTitle: t(['PENDAFTAR', 'SUBMISSION']),
              render: ({ totalAttendees, id, name }) =>
                !!totalAttendees ? (
                  <Button
                    preset="transparent"
                    title={totalAttendees.toString()}
                    onPress={() =>
                      history.push(routePaths.eventFormSubmissions, {
                        formId: id,
                        name,
                      })
                    }
                    style={styles.button}
                  />
                ) : (
                  <Text>-</Text>
                ),
              orderable: true,
            },
            ...action,
            archive: {
              headerTitle: t(['ARSIP', 'ARCHIVE']),
              headerTitleColor: 'link',
              render: ({ id, status }) =>
                id === archiveID && archiveEventFormLoading ? (
                  <ActivityIndicator size="small" />
                ) : (
                  <TouchableOpacity
                    data-testid={`${dataCy}-archive-toggle`}
                    onPress={() => onArchivePress(id)}
                    style={styles.actionSpacing}
                  >
                    {status === ArchiveStatus.NORMAL ? (
                      <FolderOutlinedIcon data-testid={`${dataCy}-normal`} />
                    ) : (
                      <FolderFilledIcon data-testid={`${dataCy}-archived`} />
                    )}
                  </TouchableOpacity>
                ),
            },
          }}
        />
      ) : type === 'competition' ? (
        <Table
          order={competitionOrder}
          orderBy={competitionOrderBy}
          onRequestSort={competitionOnRequestSort}
          page={page}
          data-testid={dataCy}
          data={competitionData?.formCompetitionAdvanceSearch.row ?? []}
          error={!!competitionError}
          loading={competitionLoading}
          dataCount={competitionData?.formCompetitionAdvanceSearch.total || 0}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onRowsChange}
          structure={{
            [FormCompetitionOrderByType.id]: {
              headerTitle: t(['FORMULIR', 'FORM']),
              orderable: true,
            },
            [FormCompetitionOrderByType.name]: {
              headerTitle: t(['KOMPETISI', 'COMPETITION']),
              orderable: true,
            },
            [FormCompetitionOrderByType.time]: {
              headerTitle: t(['WAKTU', 'TIME']),
              render: ({ competition }) =>
                !!competition && (
                  <Text>{to12Hh(new Date(competition.eventDate))}</Text>
                ),
              orderable: true,
            },
            [FormCompetitionOrderByType.liveDate]: {
              headerTitle: t(['LIVE', 'LIVE']),
              dataPath: 'competition.liveDate',
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : value, // NOTE: value is Date ISO String, but competition may be undefined
              orderable: true,
            },
            [FormCompetitionOrderByType.endLiveDate]: {
              headerTitle: t(['AKHIR', 'ENDS']),
              dataPath: 'competition.endLiveDate',
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : value, // NOTE: value is Date ISO String, but competition may be undefined
              orderable: true,
            },
            [FormCompetitionOrderByType.brandName]: {
              headerTitle: t(['BRAND', 'BRAND']),
              dataPath: 'competition.brand.name',
              orderable: true,
            },
            [FormCompetitionOrderByType.city]: {
              headerTitle: t(['KOTA', 'CITY']),
              dataPath: 'competition.city',
              orderable: true,
            },
            [FormCompetitionOrderByType.link]: {
              headerTitle: t(['FORMULIR PENDAFTARAN', 'ENTRY FORM']),
              orderable: true,
            },
            [FormCompetitionOrderByType.buContactName]: {
              headerTitle: t(['PRINCIPAL', 'PRINCIPAL']),
              dataPath: 'competition.brand.businessUnitContact.name',
              orderable: true,
            },
            [FormCompetitionOrderByType.competitionStatus]: {
              headerTitle: t(['STATUS', 'STATUS']),
              orderable: true,
              render: ({ competitionStatus }) => {
                switch (competitionStatus) {
                  case 'PENDING': {
                    return (
                      <View style={styles.pending}>
                        <Text color="contrast">{t(['TUNDA', 'PENDING'])}</Text>
                      </View>
                    );
                  }
                  case 'LIVE': {
                    return (
                      <View style={styles.live}>
                        <Text color="contrast">{t(['LIVE', 'LIVE'])}</Text>
                      </View>
                    );
                  }
                  case 'END': {
                    return (
                      <View style={styles.end}>
                        <Text color="contrast">{t(['SELESAI', 'END'])}</Text>
                      </View>
                    );
                  }
                  default: {
                    return null;
                  }
                }
              },
            },
            submission: {
              headerTitle: t(['PENDAFTAR', 'SUBMISSION']),
              render: ({ submitFormCompetition, name, id }) =>
                !!submitFormCompetition && (
                  <Button
                    preset="transparent"
                    title={submitFormCompetition.length.toString()}
                    onPress={() =>
                      history.push(routePaths.competitionFormSubmissions, {
                        formId: id,
                        name,
                      })
                    }
                    style={styles.button}
                  />
                ),
              orderable: false, // TODO: request sort to backend
            },
            ...action,
            archive: {
              headerTitle: t(['ARSIP', 'ARCHIVE']),
              headerTitleColor: 'link',
              render: ({ id, status }) =>
                id === archiveID && archiveCompetitionFormLoading ? (
                  <ActivityIndicator size="small" />
                ) : (
                  <TouchableOpacity
                    data-testid={`${dataCy}-archive-toggle`}
                    onPress={() => onArchivePress(id)}
                    style={styles.actionSpacing}
                  >
                    {status === ArchiveStatus.NORMAL ? (
                      <FolderOutlinedIcon data-testid={`${dataCy}-normal`} />
                    ) : (
                      <FolderFilledIcon data-testid={`${dataCy}-archived`} />
                    )}
                  </TouchableOpacity>
                ),
            },
          }}
        />
      ) : // TODO: render table for Information Request Forms
      null}
      {((type === 'event' && !!eventError) || // TODO: add logic for Information error
        (type === 'competition' && !!competitionError)) && (
        <View style={styles.errorRoot}>
          <ErrorMessage
            action={t([
              'mengambil data formulir online',
              'retrieve the online form data',
            ])}
            error={eventError || competitionError}
            onPress={refetchData}
          />
        </View>
      )}
      <ErrorModal
        open={errorOpen}
        action={t([
          'mengubah status arsip data formulir online',
          'change archive status of the online form data',
        ])}
        error={archiveCompetitionFormError || archiveEventFormError}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  errorRoot: { alignItems: 'center', paddingTop: theme.spacing.medium },
  actionSpacing: { paddingLeft: theme.spacing.xsmall },
  pending: {
    width: 60,
    height: 36,
    backgroundColor: colors.status.pending,
    alignItems: 'center',
    justifyContent: 'center',
  },
  live: {
    width: 60,
    height: 36,
    backgroundColor: colors.status.live,
    alignItems: 'center',
    justifyContent: 'center',
  },
  end: {
    width: 60,
    height: 36,
    backgroundColor: colors.status.end,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: { justifyContent: 'flex-start' },
});

export default OnlineFormTable;
