import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useLazyQuery } from '@apollo/react-hooks';
import { logEvent } from 'expo-firebase-analytics';

import { Table, Text } from '../../core-ui';
import {
  HeaderNavigationBar,
  Header,
  ErrorMessage,
  SearchByText,
  SearchHeader,
  SearchByDateRange,
} from '../../components';
import { colors, spacing } from '../../constants/theme';
import { GET_TOKO_USERS } from '../../graphql/queries';
import {
  TokoUsers,
  TokoUsersVariables,
  TokoUsers_UserStoreAdvanceSearch_row_store,
} from '../../generated/TokoUsers';
import { pageTitle } from '../../constants/pageTitle';
import {
  FlagCreateUser,
  StatusActive,
  StatusVerify,
} from '../../generated/globalTypes';
import { toPascalCase } from '../../features/verification/helpers';
import { useGetNetworkerror } from '../../hooks';

const roleList = {
  TOKO_OWNER: {
    en: 'Store Owner',
    id: 'Pemilik Toko',
  },
  SUPERVISOR: {
    en: 'Supervisor',
    id: 'Pengawas',
  },
  STAFF: {
    en: 'Staff',
    id: 'Staf',
  },
};

const accessList = {
  CAN_ORDER: {
    en: 'Can Order',
    id: 'Dapat Memesan',
  },
  ADD_STAFF: {
    en: 'Add Staff',
    id: 'Menambah Staf',
  },
  SPEND_POINTS: {
    en: 'Spend Points',
    id: 'Menggunakan Poin',
  },
};

export default function TokoUserScene() {
  let [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // NOTE: these are used for filter state
  const [searchStoreName, setSearchStoreName] = useState('');
  const [searchStoreCode, setSearchStoreCode] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(null);
  const [selectedDateUntil, setSelectedDateUntil] = useState<Date | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);

  // NOTE: these are used to filter the queries
  const [searchByStoreName, setSearchByStoreName] = useState('');
  const [searchByStoreCode, setSearchByStoreCode] = useState('');
  const [searchByUser, setSearchByUser] = useState('');
  const [searchByPhoneNumber, setSearchByPhoneNumber] = useState('');
  const [searchByEmail, setSearchByEmail] = useState('');
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);

  let [getTokoUser, { data, loading, error }] = useLazyQuery<
    TokoUsers,
    TokoUsersVariables
  >(GET_TOKO_USERS, {
    variables: { skip: page * rowsPerPage, first: rowsPerPage },
    notifyOnNetworkStatusChange: true,
  });

  let isError = useGetNetworkerror({
    error,
  });

  const refetchData = useCallback(
    (currentPage = page) =>
      getTokoUser({
        variables: {
          skip: currentPage * rowsPerPage,
          first: rowsPerPage,
          storeName: searchByStoreName,
          storeCode: searchByStoreCode,
          profileName: searchByUser,
          phoneNumber: searchByPhoneNumber,
          profileEmail: searchByEmail,
          registrationDate:
            searchByDateFrom && searchByDateUntil
              ? {
                  startDate: searchByDateFrom?.toISOString(),
                  endDate: searchByDateUntil?.toISOString(),
                }
              : null,
        },
      }),
    [
      page,
      rowsPerPage,
      searchByStoreName,
      searchByStoreCode,
      searchByUser,
      searchByPhoneNumber,
      searchByEmail,
      searchByDateFrom,
      searchByDateUntil,
      getTokoUser,
    ],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.TokoUserScene });
    };
    eventLog();
  }, []);

  useEffect(() => {
    getTokoUser();
  }, [getTokoUser]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  //NOTE: this is so that store names only shown once
  let mappedData = useMemo(() => {
    if (isError) {
      return [];
    }
    return (
      data?.UserStoreAdvanceSearch.row?.map((user, index) => {
        let role = t(['{idRole}', '{enRole}'], {
          idRole: roleList[user.role as keyof typeof roleList].id,
          enRole: roleList[user.role as keyof typeof roleList].en,
        });
        if (
          index &&
          user.store?.managerStoreId ===
            data?.UserStoreAdvanceSearch.row[index - 1].store?.managerStoreId
        ) {
          return {
            ...user,
            role,
            store: {},
          };
        } else {
          return { ...user, role };
        }
      }) ?? []
    );
  }, [data, isError]);

  const onChangePage = useCallback(
    (newPage: number) => {
      refetchData(newPage);
      setPage(newPage);
    },
    [refetchData],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const submitSearch = useCallback(() => {
    setSearchByStoreName(searchStoreName);
    setSearchByStoreCode(searchStoreCode);
    setSearchByUser(searchUser);
    setSearchByPhoneNumber(searchPhoneNumber);
    setSearchByEmail(searchEmail);
    setSearchByDateFrom(selectedDateFrom ?? null);
    setSearchByDateUntil(selectedDateUntil ?? null);
    setPage(0);
  }, [
    searchEmail,
    searchPhoneNumber,
    searchStoreCode,
    searchStoreName,
    searchUser,
    selectedDateFrom,
    selectedDateUntil,
  ]);

  const clearSearch = useCallback(() => {
    setSearchStoreName('');
    setSearchStoreCode('');
    setSearchUser('');
    setSearchPhoneNumber('');
    setSearchEmail('');
    setSelectedDateFrom(null);
    setSelectedDateUntil(null);

    setSearchByStoreName('');
    setSearchByStoreCode('');
    setSearchByUser('');
    setSearchByPhoneNumber('');
    setSearchByEmail('');
    setSearchByDateFrom(null);
    setSearchByDateUntil(null);
  }, []);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header
        title={t(['Toko', 'Toko'])}
        subtitle={t(['Pengguna Toko', 'Toko User'])}
        subtitleColor="default"
        style={styles.header}
      />
      <SearchHeader
        onSearchPress={submitSearch}
        onClearPress={clearSearch}
        disabled={searchDisabled}
      >
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-toko"
            label={t(['Cari Toko', 'Search Toko'])}
            value={searchStoreName ?? ''}
            setValue={setSearchStoreName}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-store-code"
            label={t(['Cari Kode Toko', 'Search Toko Code'])}
            value={searchStoreCode ?? ''}
            setValue={setSearchStoreCode}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-user"
            label={t(['Cari Pengguna', 'Search by User'])}
            value={searchUser ?? ''}
            setValue={setSearchUser}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-phone"
            label={t(['Cari Nomor Telepon', 'Search by Phone Number'])}
            value={searchPhoneNumber ?? ''}
            setValue={setSearchPhoneNumber}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-email"
            label={t(['Cari Email', 'Search by Email'])}
            value={searchEmail ?? ''}
            setValue={setSearchEmail}
          />
        </View>
        <View style={styles.separator}>
          <SearchByDateRange
            data-testid="slider-search-registration-date"
            title={t([
              'Cari Tanggal Registrasi',
              'Search by Registration Date',
            ])}
            from={selectedDateFrom}
            until={selectedDateUntil}
            setFrom={setSelectedDateFrom}
            setUntil={setSelectedDateUntil}
            setDisabled={setSearchDisabled}
          />
        </View>
      </SearchHeader>
      <View style={styles.tableSeparator} />
      <Table
        data-testid="toko-user-table"
        error={!!error}
        loading={loading}
        data={mappedData}
        dataCount={(data && data.UserStoreAdvanceSearch.total) || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        structure={{
          storeId: {
            headerTitle: t(['kode toko', 'toko code']),
            dataPath: 'store.managerStoreCode',
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          registrationDate: {
            headerTitle: t(['tanggal registrasi', 'registration date']),
            dataPath: 'store.createdAt',
            valuePreProcessor: (value) =>
              value ? new Date(value as string) : '-',
          },
          storeName: {
            headerTitle: t(['nama toko', 'toko name']),
            dataPath: 'store.storeManagerBulk.storeName',
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          storeAddres: {
            headerTitle: t(['alamat toko', 'toko address']),
            dataPath: 'store.storeManagerBulk.address',
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          user: {
            headerTitle: t(['nama user', 'user name']),
            dataPath: 'profile.name',
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          invited: {
            headerTitle: t(['diundang', 'invited']),
            dataPath: 'profile.user.createFrom',
            valuePreProcessor: (value) =>
              value === FlagCreateUser.MY_TEAM
                ? t(['Ya', 'Yes'])
                : t(['Tidak', 'No']),
          },
          role: {
            headerTitle: t(['Peran', 'Role']),
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          phoneNumber: {
            headerTitle: t(['No HP', 'Phone Number']),
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          email: {
            headerTitle: 'email',
            dataPath: 'profile.email',
            valuePreProcessor: (value) => (value ? value : '-'),
          },
          accessUser: {
            headerTitle: t(['hak akses', 'access user']),
            dataPath: 'accessUser',
            render: ({ accessUser }) => {
              return accessUser.map((value, index) => {
                let access = {
                  idAccess: accessList[value].id,
                  enAccess: accessList[value].en,
                };
                return (
                  <Text key={index}>
                    {index === accessUser.length - 1
                      ? t(['{idAccess}', '{enAccess}'], access)
                      : t(['{idAccess}, ', '{enAccess}, '], access)}
                  </Text>
                );
              });
            },
          },
          tokoStatus: {
            headerTitle: t(['status toko', 'toko status']),
            dataPath: 'store.CMSStoreStatus',
            render: ({ store }) => {
              let typedStore = store as TokoUsers_UserStoreAdvanceSearch_row_store;
              if (typedStore.CMSStoreStatus) {
                return (
                  <Text
                    bold
                    color={
                      typedStore.CMSStoreStatus === StatusVerify.WAITING
                        ? 'disabled'
                        : typedStore.CMSStoreStatus === StatusVerify.UNVERIFIED
                        ? 'error'
                        : 'accepted'
                    }
                  >
                    {typedStore.CMSStoreStatus.split('_')
                      .map(toPascalCase)
                      .join(' ') ?? '-'}
                  </Text>
                );
              }
            },
          },
          userStatus: {
            headerTitle: t(['status user', 'user status']),
            render: ({ status }) => {
              if (status === StatusActive.ACTIVE) {
                return (
                  <Text bold color="accepted">
                    {t(['Aktif', 'Active'])}
                  </Text>
                );
              } else {
                return (
                  <Text bold color="error">
                    {t(['Tidak Aktif', 'Inactive'])}
                  </Text>
                );
              }
            },
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          action={t(['mengambil data toko', 'retrieve the toko data'])}
          error={error}
          onPress={refetchData}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    paddingBottom: spacing.large,
    marginBottom: spacing.medium,
  },
  tableSeparator: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginBottom: spacing.small,
    marginTop: spacing.medium,
  },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
});
