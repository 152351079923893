import React, {
  CSSProperties,
  memo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { spacing, colors } from '../constants/theme';
import {
  Tags,
  StoreCategoryDropdown,
  ProvinceDropdown,
  CityDropdown,
  DistrictDropdown,
  VillageDropdown,
  Dropdown,
} from '../components';
import { VerificationRequestsVariables } from '../../../generated/VerificationRequests';
import {
  TypeVerify,
  FilterUserStatusVerifyInput,
  FilterStoreStatusVerifyInput,
  FilterViewDataInput,
} from '../../../generated/globalTypes';

import Text from './Text';
import Button from './Button';
import DatePicker from './DatePicker';
import TextInput from './TextInput';

type Props = {
  wip?: boolean;
  open: boolean;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  fullWidth?: boolean;
  resetToggle?: boolean;
  setPlaceholder: (placeholder: string) => void;
  setFilter: (
    variables: VerificationRequestsVariables,
    action: 'set' | 'reset',
  ) => void;
  onClose: () => void;
};

type DialogTitleProps = {
  children: string;
  onClose: () => void;
};

const useStyles = makeStyles({
  paperFullWidth: {
    maxWidth: '40%',
  },
});

const DialogTitle = memo((props: DialogTitleProps) => {
  const { children, onClose } = props;
  return (
    <View style={styles.title}>
      <Text bold size="xl">
        {children}
      </Text>
      {onClose ? (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </View>
  );
});

export const defaultFilter = {
  type: null,
  registerDateInput: null,
  storeFilter: null,
  userFilter: null,
  status: undefined,
} as const;

const FilterModal = (props: Props) => {
  let classes = useStyles();
  let {
    wip,
    open,
    style,
    contentStyle,
    fullWidth,
    resetToggle = false,
    setPlaceholder,
    setFilter,
    onClose,
  } = props;

  let [width, setWidth] = useState<number>(0);
  let [selectedType, setSelectedType] = useState<Array<TypeVerify> | null>(
    null,
  );
  const prevSelectedType = useRef(selectedType);
  let [registeredDateFrom, setRegisteredDateFrom] = useState<Date | null>(null);
  const prevRegisteredDateFrom = useRef(registeredDateFrom);
  let [registeredDateUntil, setRegisteredDateUntil] = useState<Date | null>(
    null,
  );
  const prevRegisteredDateUntil = useRef(registeredDateUntil);
  let [verifiedDateFrom, setVerifiedDateFrom] = useState<Date | null>(null);
  const prevVerifiedDateFrom = useRef(verifiedDateFrom);
  let [verifiedDateUntil, setVerifiedDateUntil] = useState<Date | null>(null);
  const prevVerifiedDateUntil = useRef(verifiedDateUntil);
  let [selectedViewData, setSelectedViewData] = useState<string | null>(null);
  const prevSelectedViewData = useRef(selectedViewData);
  let [selectedProvince, setSelectedProvince] = useState<string | null>(null);
  const prevSelectedProvince = useRef(selectedProvince);
  let [selectedCity, setSelectedCity] = useState<string | null>(null);
  const prevSelectedCity = useRef(selectedCity);
  let [selectedDistrict, setSelectedDistrict] = useState<string | null>(null);
  const prevSelectedDistrict = useRef(selectedDistrict);
  let [selectedVillage, setSelectedVillage] = useState<string | null>(null);
  const prevSelectedVillage = useRef(selectedVillage);
  let [tokoCode, setTokoCode] = useState<string | null>(null);
  const prevTokoCode = useRef(tokoCode);
  let [tokoName, setTokoName] = useState<string | null>(null);
  const prevTokoName = useRef(tokoName);
  let [tokoEmail, setTokoEmail] = useState<string | null>(null);
  const prevTokoEmail = useRef(tokoEmail);
  let [tokoPhone, setTokoPhone] = useState<string | null>(null);
  const prevTokoPhone = useRef(tokoPhone);
  let [tokoAddress, setTokoAddress] = useState<string | null>(null);
  const prevTokoAddress = useRef(tokoAddress);
  let [selectedTokoStatus, setSelectedTokoStatus] = useState<
    Array<FilterStoreStatusVerifyInput> | undefined
  >();
  let [selectedOwnerStatus, setSelectedOwnerStatus] = useState<
    Array<FilterUserStatusVerifyInput> | undefined
  >();
  const prevSelectedTokoStatus = useRef(selectedTokoStatus);
  const prevSelectedOwnerStatus = useRef(selectedOwnerStatus);
  let [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const prevSelectedCategory = useRef(selectedCategory);
  let [ownerName, setOwnerName] = useState<string | null>(null);
  const prevOwnerName = useRef(ownerName);
  let [ownerEmail, setOwnerEmail] = useState<string | null>(null);
  const prevOwnerEmail = useRef(ownerEmail);
  let [ownerPhone, setOwnerPhone] = useState<string | null>(null);
  const prevOwnerPhone = useRef(ownerPhone);
  // let [accNumber, setAccNumber] = useState<string | null>(null);
  // const prevAccNumber = useRef(accNumber);
  const [shouldUpdateRef, setShouldUpdateRef] = useState(true);
  const [viewDataName, setViewDataName] = useState('');
  const [provinceName, setProvinceName] = useState('');
  const [cityName, setCityName] = useState('');
  const [districtName, setDistrictName] = useState('');
  const [villageName, setVillageName] = useState('');
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    if (open && shouldUpdateRef) {
      setShouldUpdateRef(false); // NOTE: only update the refs at the first render when modal is opened
      // NOTE: can't set the value directly as selectedType because it refers to the memory of the array, not the value
      prevSelectedType.current = !!selectedType ? [...selectedType] : null;
      prevRegisteredDateFrom.current = registeredDateFrom;
      prevRegisteredDateUntil.current = registeredDateUntil;
      prevSelectedViewData.current = selectedViewData;
      prevSelectedProvince.current = selectedProvince;
      prevSelectedCity.current = selectedCity;
      prevSelectedDistrict.current = selectedDistrict;
      prevSelectedVillage.current = selectedVillage;
      prevTokoCode.current = tokoCode;
      prevTokoName.current = tokoName;
      prevTokoEmail.current = tokoEmail;
      prevTokoPhone.current = tokoPhone;
      prevTokoAddress.current = tokoAddress;
      prevOwnerName.current = ownerName;
      prevOwnerEmail.current = ownerEmail;
      prevOwnerPhone.current = ownerPhone;
      // prevAccNumber.current = accNumber;
      prevVerifiedDateFrom.current = verifiedDateFrom;
      prevVerifiedDateUntil.current = verifiedDateUntil;
      prevSelectedTokoStatus.current = selectedTokoStatus;
      prevSelectedOwnerStatus.current = selectedOwnerStatus;
      prevSelectedCategory.current = selectedCategory;
    }
  }, [
    open,
    selectedType,
    registeredDateFrom,
    registeredDateUntil,
    selectedViewData,
    selectedProvince,
    selectedCity,
    selectedDistrict,
    selectedVillage,
    tokoCode,
    tokoName,
    tokoEmail,
    tokoPhone,
    tokoAddress,
    verifiedDateFrom,
    verifiedDateUntil,
    selectedTokoStatus,
    selectedOwnerStatus,
    selectedCategory,
    ownerName,
    ownerEmail,
    ownerPhone,
    // accNumber,
    shouldUpdateRef,
  ]);

  const resetToBlank = useCallback(() => {
    setSelectedType(null);
    setRegisteredDateFrom(null);
    setRegisteredDateUntil(null);
    setSelectedViewData(null);
    setSelectedProvince(null);
    setSelectedCity(null);
    setSelectedDistrict(null);
    setSelectedVillage(null);
    setTokoCode(null);
    setTokoName(null);
    setTokoEmail(null);
    setTokoPhone(null);
    setTokoAddress(null);
    setOwnerName(null);
    setOwnerEmail(null);
    setOwnerPhone(null);
    // setAccNumber(null);
    setVerifiedDateFrom(null);
    setVerifiedDateUntil(null);
    setSelectedTokoStatus(undefined);
    setSelectedOwnerStatus(undefined);
    setSelectedCategory(null);
  }, []);

  const revertChanges = useCallback(() => {
    setSelectedType(prevSelectedType.current);
    setRegisteredDateFrom(prevRegisteredDateFrom.current);
    setRegisteredDateUntil(prevRegisteredDateUntil.current);
    setSelectedViewData(prevSelectedViewData.current);
    setSelectedProvince(prevSelectedProvince.current);
    setSelectedCity(prevSelectedCity.current);
    setSelectedDistrict(prevSelectedDistrict.current);
    setSelectedVillage(prevSelectedVillage.current);
    setTokoCode(prevTokoCode.current);
    setTokoName(prevTokoName.current);
    setTokoEmail(prevTokoEmail.current);
    setTokoPhone(prevTokoPhone.current);
    setTokoAddress(prevTokoAddress.current);
    setOwnerName(prevOwnerName.current);
    setOwnerPhone(prevOwnerPhone.current);
    setOwnerEmail(prevOwnerEmail.current);
    // setAccNumber(prevAccNumber.current);
    setVerifiedDateFrom(prevVerifiedDateFrom.current);
    setVerifiedDateUntil(prevVerifiedDateUntil.current);
    setSelectedTokoStatus(prevSelectedTokoStatus.current);
    setSelectedOwnerStatus(prevSelectedOwnerStatus.current);
    setSelectedCategory(prevSelectedCategory.current);
  }, [
    prevSelectedType,
    prevRegisteredDateFrom,
    prevRegisteredDateUntil,
    prevSelectedViewData,
    prevSelectedProvince,
    prevSelectedCity,
    prevSelectedDistrict,
    prevSelectedVillage,
    prevTokoCode,
    prevTokoName,
    prevTokoEmail,
    prevTokoPhone,
    prevTokoAddress,
    prevVerifiedDateFrom,
    prevVerifiedDateUntil,
    prevSelectedTokoStatus,
    prevSelectedOwnerStatus,
    prevSelectedCategory,
    prevOwnerName,
    prevOwnerEmail,
    prevOwnerPhone,
    // prevAccNumber,
  ]);

  const onSetFilter = useCallback(() => {
    let texts = [];
    selectedType && selectedType.length && texts.push(selectedType);
    selectedViewData && texts.push(viewDataName);
    if (
      !selectedType ||
      selectedType.length === 0 ||
      (selectedType[0] && selectedType[0] === TypeVerify.TOKO) ||
      (selectedType[1] && selectedType[1] === TypeVerify.TOKO)
    ) {
      selectedProvince && texts.push(provinceName);
      selectedCity && texts.push(cityName);
      selectedVillage && texts.push(villageName);
      selectedDistrict && texts.push(districtName);
      selectedTokoStatus &&
        selectedTokoStatus.length &&
        texts.push(selectedTokoStatus);
      selectedCategory && texts.push(categoryName);
      !!tokoCode && texts.push(tokoCode);
      !!tokoName && texts.push(tokoName);
      !!tokoEmail && texts.push(tokoEmail);
      !!tokoPhone && texts.push(tokoPhone);
      !!tokoAddress && texts.push(tokoAddress);
    }
    if (
      !selectedType ||
      selectedType.length === 0 ||
      (selectedType[0] && selectedType[0] === TypeVerify.USER) ||
      (selectedType[1] && selectedType[1] === TypeVerify.USER)
    ) {
      selectedOwnerStatus &&
        selectedOwnerStatus.length &&
        texts.push(selectedOwnerStatus);
      !!ownerName && texts.push(ownerName);
      !!ownerEmail && texts.push(ownerEmail);
      !!ownerPhone && texts.push(ownerPhone);
    }
    // !!accNumber && texts.push(accNumber);
    setPlaceholder(texts.join(', '));
    const registerStartDate = registeredDateFrom
      ? new Date(registeredDateFrom.setHours(0, 0, 0, 0))
      : null;
    const registerEndDate = registeredDateUntil
      ? new Date(registeredDateUntil.setHours(23, 59, 59, 59))
      : null;
    setFilter(
      {
        type:
          selectedType && selectedType.length === 1 ? selectedType[0] : null,
        registerDateInput:
          !!registerStartDate && !!registerEndDate
            ? { startDate: registerStartDate, endDate: registerEndDate }
            : null,
        confirmDateInput:
          !!verifiedDateFrom && !!verifiedDateUntil
            ? { startDate: verifiedDateFrom, endDate: verifiedDateUntil }
            : null,
        storeFilter:
          !selectedType ||
          (selectedType && !selectedType.length) ||
          selectedType?.includes(TypeVerify.TOKO)
            ? !!tokoCode ||
              !!tokoName ||
              !!tokoEmail ||
              !!tokoPhone ||
              !!tokoAddress ||
              !!selectedCategory ||
              !!selectedProvince ||
              !!selectedCity ||
              !!selectedDistrict ||
              !!selectedVillage
              ? {
                  storeCode: tokoCode ?? undefined,
                  storeName: tokoName ?? undefined,
                  storeEmail: tokoEmail ?? undefined,
                  storePhoneNumber: tokoPhone ?? undefined,
                  storeCategoryId: selectedCategory ?? undefined,
                  address: tokoAddress ?? undefined,
                  provinceId: selectedProvince ?? undefined,
                  cityId: selectedCity ?? undefined,
                  districtId: selectedDistrict ?? undefined,
                  urbanVillageId: selectedVillage ?? undefined,
                }
              : null
            : null,
        userFilter:
          !selectedType ||
          (selectedType && !selectedType.length) ||
          selectedType?.includes(TypeVerify.USER)
            ? !!ownerName || !!ownerEmail || !!ownerPhone
              ? {
                  name: ownerName ?? undefined,
                  phoneNumber: ownerPhone ?? undefined,
                  email: ownerEmail ?? undefined,
                  // accNumber: accNumber ?? undefined,
                }
              : null
            : null,
        userStatus:
          (selectedOwnerStatus?.length ?? 0) > 0
            ? selectedOwnerStatus
            : undefined,
        storeStatus:
          (selectedTokoStatus?.length ?? 0) > 0
            ? selectedTokoStatus
            : undefined,
        viewData: selectedViewData
          ? (selectedViewData as FilterViewDataInput)
          : FilterViewDataInput.ALL,
      },
      'set',
    );
    setShouldUpdateRef(true);
    onClose();
  }, [
    selectedType,
    registeredDateFrom,
    registeredDateUntil,
    selectedViewData,
    selectedProvince,
    selectedCity,
    selectedDistrict,
    selectedVillage,
    tokoCode,
    tokoName,
    tokoEmail,
    tokoPhone,
    tokoAddress,
    ownerName,
    ownerEmail,
    ownerPhone,
    // accNumber,
    verifiedDateFrom,
    verifiedDateUntil,
    selectedOwnerStatus,
    selectedTokoStatus,
    selectedCategory,
    viewDataName,
    provinceName,
    cityName,
    villageName,
    districtName,
    categoryName,
    setPlaceholder,
    setFilter,
    onClose,
  ]);

  const resetFilters = useCallback(() => {
    setPlaceholder('');
    setFilter(defaultFilter, 'reset');
    resetToBlank();
    setShouldUpdateRef(true);
  }, [setPlaceholder, setFilter, resetToBlank]);

  const onReset = useCallback(() => {
    resetFilters();
    onClose();
  }, [resetFilters, onClose]);

  useEffect(() => {
    if (resetToggle) {
      resetFilters();
    }
  }, [resetToggle, resetFilters]);

  const onCloseModal = useCallback(() => {
    revertChanges();
    setShouldUpdateRef(true);
    onClose();
  }, [onClose, revertChanges]);

  const createDropdownChangeHandler = useCallback(
    (
      setValue: (value: string) => void,
      setLabel?: (label: string) => void,
      ...setToNull: Array<(v: null) => void>
    ) => (value: string, label: string) => {
      setValue(value);
      setLabel?.(label);
      for (let setter of setToNull) {
        setter(null);
      }
    },
    [],
  );
  const onProvinceChange = useMemo(
    () =>
      createDropdownChangeHandler(
        setSelectedProvince,
        setProvinceName,
        setSelectedCity,
        setSelectedDistrict,
        setSelectedVillage,
      ),
    [createDropdownChangeHandler],
  );
  const onCityChange = useMemo(
    () =>
      createDropdownChangeHandler(
        setSelectedCity,
        setCityName,
        setSelectedDistrict,
        setSelectedVillage,
      ),
    [createDropdownChangeHandler],
  );
  const onDistrictChange = useMemo(
    () =>
      createDropdownChangeHandler(
        setSelectedDistrict,
        setDistrictName,
        setSelectedVillage,
      ),
    [createDropdownChangeHandler],
  );
  const onVillageChange = useMemo(
    () => createDropdownChangeHandler(setSelectedVillage, setVillageName),
    [createDropdownChangeHandler],
  );
  const onCategoryChange = useMemo(
    () => createDropdownChangeHandler(setSelectedCategory, setCategoryName),
    [createDropdownChangeHandler],
  );
  const onViewDataChange = useMemo(
    () => createDropdownChangeHandler(setSelectedViewData, setViewDataName),
    [createDropdownChangeHandler],
  );
  const setDisabled = useMemo(
    () =>
      !!((registeredDateFrom ? 1 : 0) ^ (registeredDateUntil ? 1 : 0)) ||
      !!((verifiedDateFrom ? 1 : 0) ^ (verifiedDateUntil ? 1 : 0)),
    [
      registeredDateFrom,
      registeredDateUntil,
      verifiedDateFrom,
      verifiedDateUntil,
    ],
  );

  const OWNER_TAGS = [
    FilterUserStatusVerifyInput.UNVERIFIED,
    FilterUserStatusVerifyInput.VERIFIED,
    FilterUserStatusVerifyInput.WAITING,
    FilterUserStatusVerifyInput.UPDATED,
  ];

  const TOKO_TAGS = [
    FilterStoreStatusVerifyInput.UNVERIFIED,
    FilterStoreStatusVerifyInput.VERIFIED,
    FilterStoreStatusVerifyInput.VERIFIED_MERGE,
    FilterStoreStatusVerifyInput.WAITING,
    FilterStoreStatusVerifyInput.REVALIDATION,
    FilterStoreStatusVerifyInput.UPDATED,
  ];

  const STORETAGS = TOKO_TAGS;

  const OWNERTAGS = OWNER_TAGS;

  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
      fullWidth={fullWidth}
      classes={classes}
      style={StyleSheet.flatten(style) as CSSProperties}
    >
      <DialogTitle onClose={onCloseModal}>
        {t(['Saring', 'Filter by'])}
      </DialogTitle>
      <DialogContent
        style={
          StyleSheet.flatten([styles.spacing, contentStyle]) as CSSProperties
        }
      >
        <View
          style={{ minWidth: width, flex: 1 }}
          onLayout={({ nativeEvent }) => {
            if (width === 0) {
              setWidth(nativeEvent.layout.width);
            }
          }}
        >
          {!wip && (
            <View style={[styles.row, styles.largeBottomPadding]}>
              <View style={styles.flex}>
                <Text bold size="m" style={styles.mediumBottomPadding}>
                  {t(['Lihat Data', 'View Data'])}
                </Text>
                <Dropdown
                  data-testid="filter-lihat-data-all"
                  options={[
                    { label: 'All', value: FilterViewDataInput.ALL },
                    {
                      label: 'Toko Only',
                      value: FilterViewDataInput.STORE_ONLY,
                    },
                    {
                      label: 'Owner Only',
                      value: FilterViewDataInput.USER_ONLY,
                    },
                  ]}
                  placeholder="All"
                  value={selectedViewData}
                  onChange={onViewDataChange}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
              </View>
              <View style={styles.flex} />
            </View>
          )}
          <View style={[styles.row, styles.largeBottomPadding]}>
            <View style={styles.flex}>
              <Text bold size="m" style={styles.mediumBottomPadding}>
                {t(['Tipe Toko', 'Store Type'])}
              </Text>
              <Tags
                data-testid="filter-tipe-toko"
                selectedTags={selectedType ?? []}
                setSelectedTags={setSelectedType}
                tags={[TypeVerify.TOKO]}
                customTitles={['TOKO']}
              />
            </View>
            <View style={styles.flex}>
              {!wip && (
                <>
                  <Text bold size="m" style={styles.mediumBottomPadding}>
                    {t(['Status', 'Status'])}
                  </Text>
                  <Tags
                    data-testid="filter-status-toko"
                    selectedTags={selectedTokoStatus ?? []}
                    setSelectedTags={setSelectedTokoStatus}
                    tags={STORETAGS}
                  />
                </>
              )}
            </View>
          </View>
          <View style={[styles.row, styles.largeBottomPadding]}>
            <View style={styles.flex}>
              <Text bold size="m" style={styles.mediumBottomPadding}>
                {t(['Tipe Owner', 'Owner Type'])}
              </Text>
              <Tags
                data-testid="filter-tipe-owner"
                selectedTags={selectedType ?? []}
                setSelectedTags={setSelectedType}
                tags={[TypeVerify.USER]}
                customTitles={['OWNER']}
              />
            </View>
            <View style={styles.flex}>
              {!wip && (
                <>
                  <Text bold size="m" style={styles.mediumBottomPadding}>
                    {t(['Status', 'Status'])}
                  </Text>
                  <Tags
                    data-testid="filter-status-owner"
                    selectedTags={selectedOwnerStatus ?? []}
                    setSelectedTags={setSelectedOwnerStatus}
                    tags={OWNERTAGS}
                  />
                </>
              )}
            </View>
          </View>
          <Text bold size="m" style={styles.mediumBottomPadding}>
            {t(['Tanggal Verifikasi Dibuat', 'Verification Create Date'])}
          </Text>
          <View style={[styles.row, styles.largeBottomPadding]}>
            <DatePicker
              data-testid="filter-dari-tgl-verifikasi-dibuat"
              selectedDate={registeredDateFrom}
              onChange={setRegisteredDateFrom}
              label={t(['Dari', 'From'])}
              style={[styles.flex, styles.mediumRightPadding]}
            />
            <DatePicker
              data-testid="filter-sampai-tgl-verifikasi-dibuat"
              minDate={registeredDateFrom ?? undefined}
              selectedDate={registeredDateUntil}
              onChange={setRegisteredDateUntil}
              label={t(['Sampai', 'Until'])}
              style={styles.flex}
            />
          </View>
          {(!selectedType ||
            (selectedType && !selectedType.length) ||
            selectedType?.includes(TypeVerify.TOKO)) && (
            <>
              <Text bold size="m" style={styles.mediumBottomPadding}>
                {t(['Lokasi', 'Location'])}
              </Text>
              <View style={styles.row}>
                <ProvinceDropdown
                  data-testid="filter-form-provinsi"
                  label={t(['Provinsi', 'Province'])}
                  value={selectedProvince}
                  onChange={onProvinceChange}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
                <CityDropdown
                  data-testid="filter-form-kota"
                  label={t(['Kota', 'City'])}
                  value={selectedCity}
                  onChange={onCityChange}
                  provinceID={selectedProvince ?? undefined}
                  containerStyle={styles.flex}
                />
              </View>
              <View style={[styles.row, styles.largeBottomPadding]}>
                <DistrictDropdown
                  data-testid="filter-form-kecamatan"
                  label={t(['Kecamatan', 'District'])}
                  value={selectedDistrict}
                  onChange={onDistrictChange}
                  cityID={selectedCity ?? undefined}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
                <VillageDropdown
                  data-testid="filter-form-kelurahan"
                  label={t(['Kelurahan', 'Village'])}
                  value={selectedVillage}
                  onChange={onVillageChange}
                  districtID={selectedDistrict ?? undefined}
                  containerStyle={styles.flex}
                />
              </View>
            </>
          )}
          {(!selectedType ||
            (selectedType && !selectedType.length) ||
            selectedType?.includes(TypeVerify.TOKO)) && (
            <>
              <Text bold size="m" style={styles.mediumBottomPadding}>
                {t(['Rincian Toko', 'Toko Details'])}
              </Text>
              <View style={styles.row}>
                <TextInput
                  data-testid="filter-field-kode-toko"
                  value={tokoCode ?? ''}
                  onChangeText={setTokoCode}
                  label={t(['Kode Toko DBO', 'DBO Toko Code'])}
                  placeholder={t(['Kode Toko', 'Toko Code'])}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
                <TextInput
                  data-testid="filter-form-nama-toko-dbo"
                  value={tokoName ?? ''}
                  onChangeText={setTokoName}
                  label={t(['Nama Toko DBO', 'DBO Toko Name'])}
                  placeholder={t(['Nama Toko', 'Toko Name'])}
                  containerStyle={styles.flex}
                />
              </View>
              <View style={styles.row}>
                <TextInput
                  data-testid="filter-form-email"
                  value={tokoEmail ?? ''}
                  onChangeText={setTokoEmail}
                  label={t(['Email', 'Email'])}
                  placeholder={t(['Email', 'Email'])}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
                <TextInput
                  data-testid="filter-form-telepon"
                  value={tokoPhone ?? ''}
                  onChangeText={setTokoPhone}
                  label={t(['Telepon', 'Phone'])}
                  placeholder={t(['Telepon', 'Phone'])}
                  containerStyle={styles.flex}
                />
              </View>
              <View style={[styles.row, styles.largeBottomPadding]}>
                <TextInput
                  data-testid="filter-form-alamat"
                  value={tokoAddress ?? ''}
                  onChangeText={setTokoAddress}
                  label={t(['Alamat', 'Address'])}
                  placeholder={t(['Alamat', 'Address'])}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
                <StoreCategoryDropdown
                  data-testid="filter-form-kategori-toko"
                  label={t(['Kategori Toko', 'Store Category'])}
                  value={selectedCategory ?? ''}
                  onChange={onCategoryChange}
                  containerStyle={styles.flex}
                />
              </View>
            </>
          )}
          {(!selectedType ||
            (selectedType && !selectedType.length) ||
            selectedType?.includes(TypeVerify.USER)) && (
            <>
              <Text bold size="m" style={styles.mediumBottomPadding}>
                {t(['Rincian Pemilik', 'Owner Detail'])}
              </Text>
              <View style={styles.row}>
                <TextInput
                  data-testid="filter-form-nama-pemilik-toko"
                  value={ownerName ?? ''}
                  onChangeText={setOwnerName}
                  label={t(['Nama Pemilik', 'Owner Name'])}
                  placeholder={t(['Nama Pemilik', 'Owner Name'])}
                  containerStyle={[styles.flex, styles.mediumRightPadding]}
                />
                <TextInput
                  data-testid="filter-form-telepon-pemilik"
                  value={ownerPhone ?? ''}
                  onChangeText={setOwnerPhone}
                  label={t(['Telepon Pemilik', 'Owner Phone'])}
                  placeholder={t(['Telepon Pemilik', 'Owner Phone'])}
                  containerStyle={[styles.flex]}
                />
              </View>
              <View style={[styles.row, styles.largeBottomPadding]}>
                <TextInput
                  data-testid="filter-form-email-pemilik"
                  value={ownerEmail ?? ''}
                  onChangeText={setOwnerEmail}
                  label={t(['Email Pemilik', 'Owner Email'])}
                  placeholder={t(['Email', 'Email'])}
                  containerStyle={[styles.flex]}
                />
                {/* <TextInput
                  data-testid="filter-modal-owner-account"
                  value={accNumber ?? ''}
                  onChangeText={setAccNumber}
                  label={t(['Nomor Akun', 'Account No.'])}
                  placeholder={t(['Nomor Akun', 'Account No.'])}
                  containerStyle={styles.flex}
                /> */}
              </View>
            </>
          )}
          {!wip && (
            <>
              <Text bold size="m" style={styles.mediumBottomPadding}>
                {t(['Tanggal Verifikasi', 'Verified Date'])}
              </Text>
              <View style={[styles.row, styles.largeBottomPadding]}>
                <DatePicker
                  data-testid="filter-dari-tgl-verifikasi"
                  selectedDate={verifiedDateFrom}
                  onChange={setVerifiedDateFrom}
                  label={t(['Dari', 'From'])}
                  style={[styles.flex, styles.mediumRightPadding]}
                />
                <DatePicker
                  data-testid="filter-sampai-tgl-verifikasi"
                  minDate={verifiedDateFrom ?? undefined}
                  selectedDate={verifiedDateUntil}
                  onChange={setVerifiedDateUntil}
                  label={t(['Sampai', 'Until'])}
                  style={styles.flex}
                />
              </View>
            </>
          )}
        </View>
      </DialogContent>
      <DialogActions
        style={StyleSheet.flatten(styles.actions) as CSSProperties}
      >
        <Button
          data-testid="filter-button-reset"
          preset="secondary"
          title={t(['Reset', 'Reset'])}
          onPress={onReset}
        />
        <Button
          disabled={setDisabled}
          data-testid="filter-button-filter"
          title={t(['Atur Filter', 'Set Filter'])}
          onPress={onSetFilter}
        />
      </DialogActions>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  spacing: {
    padding: spacing.l,
  },
  row: {
    flexDirection: 'row',
    paddingBottom: spacing.m,
  },
  flex: {
    flex: 1,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing.s,
    marginHorizontal: spacing.l,
    borderBottomColor: colors.filter.border,
    borderBottomWidth: 1,
  },
  actions: {
    paddingRight: spacing.l,
    paddingBottom: spacing.m,
  },
  mediumRightPadding: {
    paddingRight: spacing.m,
  },
  // xxsBottomSpacing: {
  //   paddingBottom: spacing.xxs,
  // },
  mediumBottomPadding: {
    paddingBottom: spacing.m,
  },
  largeBottomPadding: {
    paddingBottom: spacing.l,
  },
});

export default memo(FilterModal);
