import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  Dropdown,
  ErrorMessage,
  ErrorModal,
  MissionDropdown,
  Option,
  RoleDropdown,
  SearchByDateRange,
  SearchByText,
  SearchHeader,
} from '../../../components';
import { colors, spacing } from '../../../constants/theme';
import { Button, Table } from '../../../core-ui';
import {
  DownloadMissionActivityLog,
  DownloadMissionActivityLogVariables,
} from '../../../generated/DownloadMissionActivityLog';
import { Role } from '../../../generated/globalTypes';
import {
  MissionActivityLog,
  MissionActivityLogVariables,
} from '../../../generated/MissionActivityLog';
import {
  DOWNLOAD_ACTIVITY_MISSION_LOG,
  GET_ACTIVITY_MISSION_LOG,
} from '../../../graphql/queries';

import FilterContainer from './FilterContainer';

export default function ActivityLog() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [minHeight, setMinHeight] = useState(0);

  const [storeCode, setStoreCode] = useState<string | null>(null);
  const [storeName, setStoreName] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);
  const [dateFromTopUp, setDateFromTopUp] = useState<Date | null>(null);
  const [dateUntilTopUp, setDateUntilTopUp] = useState<Date | null>(null);
  const [dateFromRegister, setDateFromRegister] = useState<Date | null>(null);
  const [dateUntilRegister, setDateUntilRegister] = useState<Date | null>(null);
  const [
    dateFromVerifiedStore,
    setDateFromVerifiedStore,
  ] = useState<Date | null>(null);
  const [
    dateUntilVerifiedStore,
    setDateUntilVerifiedStore,
  ] = useState<Date | null>(null);
  const [
    dateFromVerifiedOwner,
    setDateFromVerifiedOwner,
  ] = useState<Date | null>(null);
  const [
    dateUntilVerifiedOwner,
    setDateUntilVerifiedOwner,
  ] = useState<Date | null>(null);
  const [
    dateFromKYCSubmission,
    setDateFromKYCSubmission,
  ] = useState<Date | null>(null);
  const [
    dateUntilKYCSubmission,
    setDateUntilKYCSubmission,
  ] = useState<Date | null>(null);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [selectedTopUpStatus, setSelectedTopUpStatus] = useState<Option | null>(
    null,
  );
  const [
    selectedVerifyStatus,
    setSelectedVerifyStatus,
  ] = useState<Option | null>(null);
  const [
    selectedOwnerVerifyStatus,
    setSelectedOnwerVerifyStatus,
  ] = useState<Option | null>(null);
  const [selectedMission, setSelectedMission] = useState<Option | null>(null);

  const [queryVariables, setQueryVariables] = useState<
    Partial<MissionActivityLogVariables>
  >({});

  const [searchDisabled, setSearchDisabled] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);

  let {
    data: logData,
    loading: logLoading,
    error: logError,
    refetch: refetchLog,
  } = useQuery<MissionActivityLog, MissionActivityLogVariables>(
    GET_ACTIVITY_MISSION_LOG,
    {
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const saveData = useCallback((logData: DownloadMissionActivityLog) => {
    saveAs(
      logData.downloadMissionActivityLog.link,
      `Mission Loyalty Point Log ${new Date()}.csv`,
    );
  }, []);

  const [getExportLink, { loading: exportLinkLoading }] = useLazyQuery<
    DownloadMissionActivityLog,
    DownloadMissionActivityLogVariables
  >(DOWNLOAD_ACTIVITY_MISSION_LOG, {
    onCompleted: saveData,
    onError: () => {
      setErrorOpen(true);
    },
  });

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetchLog?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [refetchLog, rowsPerPage, page, queryVariables],
  );

  const refetchLink = useCallback(() => {
    getExportLink({
      variables: {
        ...queryVariables,
      },
    });
  }, [getExportLink, queryVariables]);

  const onExportPress = useCallback(() => {
    refetchLink();
  }, [refetchLink]);

  const onSearch = useCallback(() => {
    setQueryVariables({
      storeCode: storeCode || undefined,
      storeName: storeName || undefined,
      role: selectedRole || undefined,
      finishedDate:
        dateFrom && dateUntil
          ? {
              startDate: new Date(dateFrom.setHours(7)),
              endDate: new Date(dateUntil.setHours(7)),
            }
          : undefined,
      topUpDate:
        dateFromTopUp && dateUntilTopUp
          ? {
              startDate: new Date(dateFromTopUp.setHours(7)),
              endDate: new Date(dateUntilTopUp.setHours(7)),
            }
          : undefined,
      registerDate:
        dateFromRegister && dateUntilRegister
          ? {
              startDate: new Date(dateFromRegister.setHours(7)),
              endDate: new Date(dateUntilRegister.setHours(7)),
            }
          : undefined,
      verificationStoreDate:
        dateFromVerifiedStore && dateUntilVerifiedStore
          ? {
              startDate: new Date(dateFromVerifiedStore.setHours(7)),
              endDate: new Date(dateUntilVerifiedStore.setHours(7)),
            }
          : undefined,
      ownerVerificationDate:
        dateFromVerifiedOwner && dateUntilVerifiedOwner
          ? {
              startDate: new Date(dateFromVerifiedOwner.setHours(7)),
              endDate: new Date(dateUntilVerifiedOwner.setHours(7)),
            }
          : undefined,
      kycDate:
        dateFromKYCSubmission && dateUntilKYCSubmission
          ? {
              startDate: new Date(dateFromKYCSubmission.setHours(7)),
              endDate: new Date(dateUntilKYCSubmission.setHours(7)),
            }
          : undefined,
      verificationStatus:
        selectedVerifyStatus?.value === 'Ya'
          ? true
          : selectedVerifyStatus?.value === 'Tidak'
          ? false
          : undefined,
      ownerVerificationStatus:
        selectedOwnerVerifyStatus?.value === 'Ya'
          ? true
          : selectedOwnerVerifyStatus?.value === 'Tidak'
          ? false
          : undefined,
      missionName: selectedMission?.value || undefined,
      topUpStatus:
        selectedTopUpStatus?.value === 'Ya'
          ? true
          : selectedTopUpStatus?.value === 'Tidak'
          ? false
          : undefined,
    });
  }, [
    storeCode,
    storeName,
    dateFrom,
    dateUntil,
    selectedRole,
    dateFromTopUp,
    dateUntilTopUp,
    dateFromKYCSubmission,
    dateUntilKYCSubmission,
    dateFromVerifiedOwner,
    dateUntilVerifiedOwner,
    selectedTopUpStatus,
    selectedMission,
    selectedVerifyStatus,
    selectedOwnerVerifyStatus,
    dateFromRegister,
    dateUntilRegister,
    dateFromVerifiedStore,
    dateUntilVerifiedStore,
  ]);

  const onClear = useCallback(() => {
    setStoreCode(null);
    setStoreName(null);
    setSelectedRole(null);
    setDateFrom(null);
    setDateUntil(null);
    setDateFromTopUp(null);
    setDateUntilTopUp(null);
    setDateFromRegister(null);
    setDateUntilRegister(null);
    setDateFromVerifiedStore(null);
    setDateUntilVerifiedStore(null);
    setDateFromKYCSubmission(null);
    setDateUntilKYCSubmission(null);
    setDateFromVerifiedOwner(null);
    setDateUntilVerifiedOwner(null);
    setSelectedVerifyStatus(null);
    setSelectedOnwerVerifyStatus(null);
    setSelectedMission(null);
    setSelectedTopUpStatus(null);

    setQueryVariables({
      storeCode: undefined,
      storeName: undefined,
      role: undefined,
      finishedDate: undefined,
      topUpDate: undefined,
      registerDate: undefined,
      verificationStoreDate: undefined,
      ownerVerificationDate: undefined,
      kycDate: undefined,
      verificationStatus: undefined,
      ownerVerificationStatus: undefined,
      missionName: undefined,
      topUpStatus: undefined,
    });
  }, []);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const topUpStatus = useMemo(
    () => [
      { label: 'Ya', value: 'Ya' },
      { label: 'Tidak', value: 'Tidak' },
    ],
    [],
  );

  const tableStructure = useMemo(
    () => ({
      endDateMission: {
        headerTitle: 'Tanggal Misi Selesai',
        dataPath: 'finishedDate',
      },
      storeCode: {
        headerTitle: 'Kode Toko',
        dataPath: 'store.managerStoreCode',
      },
      storeName: {
        headerTitle: 'Nama Toko',
        dataPath: 'store.storeName',
      },
      storeVerifiedStatus: {
        headerTitle: 'Status Verifikasi Toko',
        dataPath: 'store.storeVerified',
      },
      verifiedStoreDate: {
        headerTitle: 'Tanggal Verifikasi Toko',
        dataPath: 'store.verifiedDate',
      },
      ownerVerifiedStatus: {
        headerTitle: 'Status Verifikasi Owner',
        dataPath: 'user.ownerVerified',
      },
      submittedKycDate: {
        headerTitle: 'Tanggal Submit KYC',
        dataPath: 'user.profile.uploadAt',
      },
      verifiedOwnerDate: {
        headerTitle: 'Tanggal Verifikasi Owner',
        dataPath: 'user.verifiedDate',
      },
      userName: {
        headerTitle: 'Nama User',
        dataPath: 'user.profile.name',
      },
      role: {
        headerTitle: 'Role User',
        dataPath: 'user.role',
      },
      registrationDate: {
        headerTitle: 'Tanggal Registrasi User',
        dataPath: 'user.createdAt',
      },
      missionId: {
        headerTitle: 'ID Mission',
        dataPath: 'loyaltyMissionPoint.missionId',
      },
      missionName: {
        headerTitle: 'Nama Mission',
        dataPath: 'loyaltyMissionPoint.name',
      },
      point: {
        headerTitle: 'Poin Reward Didapat',
        dataPath: 'loyaltyMissionPoint.point',
      },
      topUpStatus: {
        headerTitle: 'Sudah di Top Up',
        render: ({ topUpStatus }: { topUpStatus: boolean }) => {
          return topUpStatus ? 'Ya' : 'Tidak';
        },
      },
      topUpDate: {
        headerTitle: 'Tanggal Top Up',
        dataPath: 'topUpDate',
      },
    }),
    [],
  );

  const setRole = useCallback((option: Option) => {
    setSelectedRole(option ? (option.value as Role) : null);
  }, []);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.separator}>
          <SearchHeader
            data-testid="search-olform"
            onSearchPress={onSearch}
            onClearPress={onClear}
            buttonContainerStyle={styles.searchButtonContainer}
            disabled={searchDisabled}
            isCollapsed={true}
          >
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByText
                data-testid="search-actvLog-storeCode"
                label={t(['Cari Kode Toko', 'Search By Store Code'])}
                value={storeCode || ''}
                setValue={setStoreCode}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByText
                data-testid="search-actvLog-storeName"
                label="Cari Nama Toko"
                value={storeName || ''}
                setValue={setStoreName}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <RoleDropdown
                data-testid="search-actvLog-role"
                selectedOption={selectedRole}
                onSelect={setRole}
                title="Cari Role"
                type="side"
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <MissionDropdown
                data-testid="search-actvLog-missionName"
                title="Cari Nama Mission"
                type="side"
                onSelect={setSelectedMission}
                selectedOption={selectedMission?.value}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <Dropdown
                data-testid="search-actvLog-topUp"
                type="side"
                title="Sudah di Top Up"
                options={topUpStatus}
                onSelect={setSelectedTopUpStatus}
                selectedOption={selectedTopUpStatus?.value}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <Dropdown
                data-testid="slider-search-status"
                type="side"
                title={t([
                  'Cari Status Verifikasi',
                  'Search by Verification Status',
                ])}
                options={topUpStatus}
                onSelect={setSelectedVerifyStatus}
                selectedOption={selectedVerifyStatus?.label ?? undefined}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <Dropdown
                data-testid="search-actvLog-verif-owner"
                type="side"
                title={t([
                  'Cari Status Verifikasi Owner',
                  'Search by Owner Verification Status',
                ])}
                options={topUpStatus}
                onSelect={setSelectedOnwerVerifyStatus}
                selectedOption={selectedOwnerVerifyStatus?.label ?? undefined}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByDateRange
                title="Cari Rentang Tanggal Mission Selesai"
                data-testid="search-actvLog-date"
                from={dateFrom}
                until={dateUntil}
                setFrom={setDateFrom}
                setUntil={setDateUntil}
                setDisabled={setSearchDisabled}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByDateRange
                title="Cari Rentang Tanggal Mission Sudah di Top Up"
                data-testid="search-actvLog-dateTopup"
                from={dateFromTopUp}
                until={dateUntilTopUp}
                setFrom={setDateFromTopUp}
                setUntil={setDateUntilTopUp}
                setDisabled={setSearchDisabled}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByDateRange
                title="Cari Rentang Tanggal Registrasi"
                data-testid="search-actvLog-dateRegistration"
                from={dateFromRegister}
                until={dateUntilRegister}
                setFrom={setDateFromRegister}
                setUntil={setDateUntilRegister}
                setDisabled={setSearchDisabled}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByDateRange
                title="Cari Rentang Tanggal Verifikasi Toko"
                data-testid="search-actvLog-dateVerifiedStore"
                from={dateFromVerifiedStore}
                until={dateUntilVerifiedStore}
                setFrom={setDateFromVerifiedStore}
                setUntil={setDateUntilVerifiedStore}
                setDisabled={setSearchDisabled}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByDateRange
                title="Cari Rentang Tanggal Verifikasi Owner"
                data-testid="search-actvLog-date"
                from={dateFromVerifiedOwner}
                until={dateUntilVerifiedOwner}
                setFrom={setDateFromVerifiedOwner}
                setUntil={setDateUntilVerifiedOwner}
                setDisabled={setSearchDisabled}
              />
            </FilterContainer>
            <FilterContainer minHeight={minHeight} setMinHeight={setMinHeight}>
              <SearchByDateRange
                title="Cari Rentang Tanggal Submit KYC"
                data-testid="search-actvLog-date"
                from={dateFromKYCSubmission}
                until={dateUntilKYCSubmission}
                setFrom={setDateFromKYCSubmission}
                setUntil={setDateUntilKYCSubmission}
                setDisabled={setSearchDisabled}
              />
            </FilterContainer>
          </SearchHeader>
          <View style={styles.exportButtonContainer}>
            <Button
              data-testid="activity-log-download"
              title={t(['Ekspor Semua Data', 'Export All Data'])}
              onPress={onExportPress}
              style={styles.exportButton}
              isLoading={exportLinkLoading}
            />
          </View>
        </View>
        <Table
          data-testid="store-list-table"
          data={logData?.missionActivityLog.row ?? []}
          dataCount={logData?.missionActivityLog.total ?? 0}
          error={!!logError}
          loading={logLoading}
          structure={{
            ...tableStructure,
            topUpDate: {
              ...tableStructure.topUpDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            endDateMission: {
              ...tableStructure.endDateMission,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            storeVerifiedStatus: {
              ...tableStructure.storeVerifiedStatus,
              valuePreProcessor: (value) => (value ? 'Ya' : 'Tidak'),
            },
            verifiedStoreDate: {
              ...tableStructure.verifiedStoreDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            ownerVerifiedStatus: {
              ...tableStructure.ownerVerifiedStatus,
              valuePreProcessor: (value) => (value ? 'Ya' : 'Tidak'),
            },
            submittedKycDate: {
              ...tableStructure.submittedKycDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            verifiedOwnerDate: {
              ...tableStructure.verifiedOwnerDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
            registrationDate: {
              ...tableStructure.registrationDate,
              valuePreProcessor: (value) =>
                value ? new Date(value as string) : '-',
            },
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={onRowsChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
        {!!logError && (
          <ErrorMessage
            error={logError}
            action="mengambil data log mission"
            onPress={refetchCurrentPage}
          />
        )}
      </View>
      <ErrorModal
        open={errorOpen}
        action="mengunduh file"
        onClose={closeErrorModal}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.medium,
  },
  searchButtonContainer: {
    marginTop: spacing.large,
  },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  exportButtonContainer: {
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: spacing.medium,
  },
  exportButton: {
    padding: 0,
    minWidth: 200,
  },
});
