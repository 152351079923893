import React, { memo } from 'react';
import { View } from 'react-native';
import {
  TableFooter as MTableFooter,
  TableRow,
  makeStyles,
  TablePagination,
  TableCell,
  TableCellProps,
  TablePaginationProps,
} from '@material-ui/core';

const useStyles = makeStyles({
  row: { height: 32 },
  withOutBottomBorder: { borderBottom: 0 },
});

type Props = {
  'data-testid'?: string;
  page?: number;
  disableLoadMore: boolean;
  rowsPerPage?: number;
  rowsPerPageOptions?: TablePaginationProps['rowsPerPageOptions'];
  count?: number;
  onChangePage: (page: number) => void;
  cellClasses?: TableCellProps['classes'];
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  paginationProps?: Omit<
    TablePaginationProps,
    | 'rowsPerPageOptions'
    | 'className'
    | 'count'
    | 'colSpan'
    | 'page'
    | 'rowsPerPage'
    | 'onChangePage'
    | 'onChangeRowsPerPage'
  >;
};

const TableFooter = memo((props: Props) => {
  let {
    page,
    disableLoadMore,
    onChangePage,
    count,
    cellClasses,
    rowsPerPage,
    onChangeRowsPerPage,
    paginationProps,
    rowsPerPageOptions,
  } = props;

  const classes = useStyles();
  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    onChangePage(newPage);
  };
  return (
    <MTableFooter data-testid={props['data-testid']}>
      <TableRow className={classes.row}>
        <TableCell
          classes={cellClasses}
          className={classes.withOutBottomBorder}
          align="center"
        >
          {!disableLoadMore && !!count && (
            <View testID="footerLoadMore">
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions || [5, 10, 15, 20]}
                className={classes.withOutBottomBorder}
                count={count || 0}
                page={page || 0}
                rowsPerPage={rowsPerPage || 10}
                SelectProps={{
                  inputProps: {
                    'aria-label': t(['baris per halaman', 'rows per page']),
                  },
                  native: true,
                }}
                onChangePage={onPageChange}
                onChangeRowsPerPage={onChangeRowsPerPage}
                {...paginationProps}
              />
            </View>
          )}
        </TableCell>
      </TableRow>
    </MTableFooter>
  );
});

export default TableFooter;
