import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { StyleSheet, View, Image, Clipboard } from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import {
  StepHeader,
  UsersDropdown,
  Option,
  RegionDropdown,
  CityDropdown,
  BrandContactDropdown,
  LiveDateRadioGroup,
  BrandDropdown,
  RangeDropdown,
  ProductDropdown,
  ErrorModal,
  UnitDropdown,
} from '../../components';
import {
  Text,
  Dropzone,
  TextInput,
  Button,
  Switch,
  Checkbox,
} from '../../core-ui';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { spacing, theme } from '../../constants/theme';
import { emailIcon } from '../../../assets';
import {
  CREATE_PROMOTION,
  UPDATE_PROMOTION,
  UPLOAD,
} from '../../graphql/mutations';
import {
  CHECK_PROMOTION_CODE,
  EMAIL_PROMOTION_TO_BU,
} from '../../graphql/queries';
import {
  CreatePromotion,
  CreatePromotionVariables,
} from '../../generated/CreatePromotion';
import { Upload, UploadVariables } from '../../generated/Upload';
import {
  UpdatePromotion,
  UpdatePromotionVariables,
} from '../../generated/UpdatePromotion';
import { FolderType } from '../../generated/globalTypes';
import {
  EmailPromotionToBU,
  EmailPromotionToBUVariables,
} from '../../generated/EmailPromotionToBU';
import {
  CheckPromotionsCode,
  CheckPromotionsCodeVariables,
} from '../../generated/CheckPromotionsCode';

type Props = {
  promotionId?: string;
  selectedUser: string | null;
  selectedRegion: string | null;
  regionName: string | null;
  cityName: string | null;
  heroImage: FileWithPreview | string | null;
  optionalImages: Array<FileWithPreview | string | null>;
  promotionName: string;
  promotionCode: string | null;
  description: string;
  selectedBrand: string | null;
  brandId: string | null;
  selectedRange: string | null;
  selectedProduct: string | null;
  quantity: string;
  unit: string | null;
  unitIndex: string | null;
  termAndCondition: string;
  brandContact: string | null;
  liveDate: Date;
  endDate: Date;
  salesmanAppDeepLink: string;
  descriptionSalesmanApp: string;
  showPromotionInMobile: boolean;
  sendPushNotification: boolean;
  getSelectedRegion: (values: Array<string>) => void;
  getSelectedUnit: (values: Array<string>) => void;
  setSelectedUser: (value: Option) => void;
  setRegionName: (value: Option) => void;
  setCityName: (value: Option | null) => void;
  setHeroImage: (value: FileWithPreview | string) => void;
  setOptionalImages: (value: Array<FileWithPreview | string | null>) => void;
  setPromotionName: (value: string) => void;
  setPromotionCode: (value: string | null) => void;
  setDescription: (value: string) => void;
  setDescriptionSalesmanApp: (value: string) => void;
  setSelectedBrand: (value: Option) => void;
  setBrandId: (value: string) => void;
  setSelectedRange: (value: Option) => void;
  setSelectedProduct: (value: Option) => void;
  setQuantity: (value: string) => void;
  setUnit: (value: Option) => void;
  setTermAndCondition: (value: string) => void;
  setBrandContact: (value: Option) => void;
  setLiveDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
  onSubmitCompleted: () => void;
  setSalesmanAppDeepLink: (value: string) => void;
  setShowPromotionInMobile: (value: boolean) => void;
  setSendPushNotification: (value: boolean) => void;
};

export default function PromotionForm(props: Props) {
  let {
    promotionId,
    selectedUser,
    selectedRegion,
    regionName,
    cityName,
    heroImage,
    optionalImages,
    promotionName,
    promotionCode,
    description,
    selectedBrand,
    brandId,
    selectedRange,
    selectedProduct,
    quantity,
    unit,
    unitIndex,
    termAndCondition,
    brandContact,
    liveDate,
    endDate,
    salesmanAppDeepLink,
    descriptionSalesmanApp,
    showPromotionInMobile,
    sendPushNotification,
    getSelectedRegion,
    getSelectedUnit,
    setSelectedUser,
    setRegionName,
    setCityName,
    setHeroImage,
    setOptionalImages,
    setPromotionName,
    setPromotionCode,
    setDescription,
    setSelectedBrand,
    setBrandId,
    setSelectedProduct,
    setSelectedRange,
    setQuantity,
    setUnit,
    setTermAndCondition,
    setBrandContact,
    setLiveDate,
    setEndDate,
    onSubmitCompleted,
    setSalesmanAppDeepLink,
    setDescriptionSalesmanApp,
    setShowPromotionInMobile,
    setSendPushNotification,
  } = props;

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const onSelectBrand = useCallback(
    (option: Option) => {
      option.label === 'DBO' &&
        setBrandContact({
          label: '-',
          subLabel: '-',
          subValue: '-',
          value: '-',
        });
      setSelectedBrand(option);
      setBrandId(option.subValue || '');
      setDisableBrandContact(option.label === 'DBO');
    },
    [setBrandId, setSelectedBrand, setBrandContact],
  );

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );
  const onCreateError = useMemo(() => {
    return openErrorModal(t(['membuat data promosi', 'create promotion data']));
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(
      t(['mengubah data promosi', 'update promotion data']),
    );
  }, [openErrorModal]);
  const onUploadError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);
  const onSendEmailError = useMemo(() => {
    return openErrorModal(
      t(['mengirim email ke principal', 'send email to principal']),
    );
  }, [openErrorModal]);
  const onCheckPromotionCodeError = useMemo(() => {
    return openErrorModal(t(['cek kode', 'check code']));
  }, [openErrorModal]);

  const [generatePromotionCode, setGeneratePromotionCode] = useState(false);
  const [validPromotionCode, setValidPromotionCode] = useState<boolean | null>(
    null,
  );
  let [editable, setEditable] = useState(true);
  let [disableBrandContact, setDisableBrandContact] = useState(false);
  let disabled =
    !selectedUser ||
    !regionName ||
    !cityName ||
    !selectedBrand ||
    !heroImage ||
    !promotionName ||
    !description ||
    !termAndCondition ||
    !descriptionSalesmanApp;
  let isMeetMinCharacter = !!promotionCode && promotionCode.length >= 7;

  useEffect(() => {
    if (promotionId) {
      setEditable(false);
    }
  }, [promotionId]);

  const resetValidPromotionCode = useCallback(() => {
    if (validPromotionCode != null) {
      setValidPromotionCode(null);
    }
  }, [validPromotionCode, setValidPromotionCode]);

  const onMutationCompleted = useCallback(() => {
    onSubmitCompleted();
    resetValidPromotionCode();
  }, [onSubmitCompleted, resetValidPromotionCode]);

  let [createPromotion, { loading: createPromotionLoading }] = useMutation<
    CreatePromotion,
    CreatePromotionVariables
  >(CREATE_PROMOTION, {
    onCompleted: onMutationCompleted,
    onError: onCreateError,
  });

  let [updatePromotion, { loading: updatePromotionLoading }] = useMutation<
    UpdatePromotion,
    UpdatePromotionVariables
  >(UPDATE_PROMOTION, {
    onCompleted: onMutationCompleted,
    onError: onUpdateError,
  });

  let [uploadImage, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, { onError: onUploadError });

  const [emailPromotion] = useLazyQuery<
    EmailPromotionToBU,
    EmailPromotionToBUVariables
  >(EMAIL_PROMOTION_TO_BU, { onError: onSendEmailError });

  let [checkPromotionCode] = useLazyQuery<
    CheckPromotionsCode,
    CheckPromotionsCodeVariables
  >(CHECK_PROMOTION_CODE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ checkUniquePromotionCode }) => {
      let { exist } = checkUniquePromotionCode;
      setValidPromotionCode(isMeetMinCharacter && !exist);
    },
    onError: onCheckPromotionCodeError,
  });

  let onSubmit = useCallback(async () => {
    if (!!selectedUser && !!regionName && !!cityName && !!selectedBrand) {
      // NOTE: these 2 are the ones we will submit to the mutation
      let heroImageLink = '';
      let optionalImagesLinks: Array<string> = [];
      // NOTE: we use  this array for Promise.all
      let uploadPromises: Array<
        ReturnType<typeof uploadImage> | undefined
      > = [];

      if (heroImage) {
        if (typeof heroImage === 'string') {
          // NOTE: string means it comes from the server
          heroImageLink = heroImage;
          uploadPromises.push(undefined); // NOTE: necessary to keep the array length to be 3
        } else if (typeof heroImage === 'object') {
          // NOTE: object means it is a new image, add to upload promise array
          uploadPromises.push(
            uploadImage({
              variables: { file: heroImage.file, folder: FolderType.PROMOTION },
            }),
          );
        }
      }
      for (let img of optionalImages) {
        if (img) {
          if (typeof img === 'string') {
            // NOTE: string means it comes from the server
            optionalImagesLinks.push(img);
            uploadPromises.push(undefined); // NOTE: necessary to keep the array length to be 3
          } else if (typeof img === 'object') {
            // NOTE: object means it is a new image, add to upload promise array
            uploadPromises.push(
              uploadImage({
                variables: { file: img.file, folder: FolderType.PROMOTION },
              }),
            );
          }
        }
      }
      const results = await Promise.all(uploadPromises); // NOTE: results will be an array of 3 entries
      if (results[0]) {
        heroImageLink = results[0].data?.upload.link ?? '';
      }
      if (results[1]?.data?.upload.link) {
        optionalImagesLinks.push(results[1].data.upload.link);
      }
      if (results[2]?.data?.upload.link) {
        optionalImagesLinks.push(results[2].data.upload.link);
      }

      let variables = {
        brandId: selectedBrand,
        productId: selectedProduct,
        rangeId: selectedRange,
        promotionName,
        appUser: selectedUser,
        region: regionName,
        city: cityName,
        termAndCondition,
        heroImage: heroImageLink,
        optionalImages: optionalImagesLinks,
        description,
        descriptionSalesman: descriptionSalesmanApp,
        urlDeeplinkSalesman: salesmanAppDeepLink,
        liveDate: new Date(liveDate),
        endLiveDate: new Date(endDate),
        productQuantity: quantity ? Number(quantity) : null,
        unitIndex: unitIndex ? Number(unitIndex) : null,
        businessUnitContactId: brandContact === '-' ? null : brandContact,
        showPromotionInMobile,
        promotionCode,
        isGeneratePromotionCode: generatePromotionCode,
      };
      if (promotionId) {
        updatePromotion({
          variables: {
            ...variables,
            promotionId,
            descriptionSalesman: descriptionSalesmanApp,
            urlDeeplinkSalesman: salesmanAppDeepLink,
          },
        });
      } else {
        createPromotion({
          variables: {
            ...variables,
            sendPushNotification,
          },
        });
      }
    }
  }, [
    selectedUser,
    regionName,
    cityName,
    selectedBrand,
    selectedProduct,
    selectedRange,
    brandContact,
    description,
    descriptionSalesmanApp,
    salesmanAppDeepLink,
    endDate,
    heroImage,
    liveDate,
    optionalImages,
    promotionId,
    promotionName,
    quantity,
    unitIndex,
    termAndCondition,
    showPromotionInMobile,
    sendPushNotification,
    promotionCode,
    generatePromotionCode,
    uploadImage,
    createPromotion,
    updatePromotion,
  ]);

  const onCopyText = useCallback(() => {
    Clipboard.setString(salesmanAppDeepLink);
  }, [salesmanAppDeepLink]);

  let onEdit = useCallback(() => {
    setEditable(!editable);
    window.scrollTo({ top: 170 });
  }, [editable]);

  const onSelectRegion = useCallback(
    (selected: Option) => {
      setRegionName(selected);
      setCityName(null);
    },
    [setRegionName, setCityName],
  );

  let onSendEmailPress = useCallback(() => {
    if (promotionId) {
      emailPromotion({ variables: { promotionId } });
    }
  }, [promotionId, emailPromotion]);

  const onSetOptionalImage = useCallback(
    (index: number) => (image: FileWithPreview) => {
      setOptionalImages(
        optionalImages.map((optionalImage, loopIndex) =>
          index === loopIndex ? image : optionalImage,
        ),
      );
    },
    [optionalImages, setOptionalImages],
  );

  const renderStepOne = () => (
    <View style={styles.stepSpacing}>
      <StepHeader
        step={1}
        title={t(['Pilih Pengguna', 'Select Users'])}
        style={styles.stepHeader}
      />
      <UsersDropdown
        data-testid="users-dropdown"
        disabled={!editable}
        selectedOption={selectedUser ?? undefined}
        onSelect={setSelectedUser}
      />
    </View>
  );
  const renderStepTwoToThree = () => (
    <View style={styles.stepSpacing}>
      <StepHeader
        step={2}
        title={t(['Pilih Daerah', 'Select Region'])}
        style={styles.stepHeader}
      />
      <View style={styles.dropdownSpacing}>
        <RegionDropdown
          data-testid="region-dropdown"
          dataKey="label"
          disabled={!editable}
          selectedOption={regionName ?? undefined}
          getSelectedValues={getSelectedRegion}
          onSelect={onSelectRegion}
        />
      </View>
      <StepHeader
        step={3}
        title={t(['Pilih Kota', 'Select City'])}
        style={styles.stepHeader}
      />
      <CityDropdown
        data-testid="city-dropdown"
        dataKey="label"
        provincesID={selectedRegion ? [selectedRegion] : []}
        disabled={!editable}
        selectedOption={cityName ?? undefined}
        onSelect={setCityName}
      />
    </View>
  );
  const renderStepFour = () => (
    <View style={styles.stepSpacing}>
      <StepHeader
        step={4}
        title={t(['Tambahkan Karya Seni', 'Add Artwork'])}
        style={styles.stepHeader}
      />
      <Text bold color="link" size="small" style={styles.label}>
        {t([
          'Ukuran: 480 x 1400px format .gif atau .png',
          'Size: 480 x 1400px .gif or .png format',
        ])}
      </Text>
      <Text bold style={styles.label}>
        {t(['Gambar Utama', 'Hero Image'])}
      </Text>
      <Dropzone
        data-testid="hero-image"
        withUploadText
        disabled={!editable}
        source={heroImage}
        getPreview={setHeroImage}
        imageSize={{ width: 480, height: 1440 }}
        containerStyle={styles.imageContainer}
        accept={['image/png', 'image/gif']}
      />
      <View style={styles.optionalImageWrapper}>
        <Text bold style={styles.label}>
          {t(['Gambar Opsional', 'Optional Images'])}
        </Text>
        <Dropzone
          withUploadText
          disabled={!editable}
          source={optionalImages[0]}
          getPreview={onSetOptionalImage(0)}
          imageSize={{ width: 480, height: 1440 }}
          containerStyle={styles.imageContainer}
          accept={['image/png', 'image/gif']}
        />
      </View>
      <Dropzone
        withUploadText
        disabled={!editable}
        source={optionalImages[1]}
        getPreview={onSetOptionalImage(1)}
        imageSize={{ width: 480, height: 1440 }}
        containerStyle={styles.imageContainer}
        accept={['image/png', 'image/gif']}
      />
    </View>
  );

  const renderPromotionCodeError = () => {
    if (!!promotionCode && !isMeetMinCharacter) {
      return (
        <Text color="error">
          {t(['Minimal 7 Karakter', 'Minimum 7 Characters'])}
        </Text>
      );
    }
    if (validPromotionCode != null) {
      if (validPromotionCode) {
        return (
          <Text color="accepted">{t(['Kode Tersedia', 'Code Available'])}</Text>
        );
      } else {
        return (
          <Text color="error">
            {t(['Kode Sudah Digunakan', 'Code Already Used'])}
          </Text>
        );
      }
    }
    return null;
  };
  const renderStepFive = () => (
    <View style={styles.flex}>
      <StepHeader
        step={5}
        title={t(['Lengkapi Data', 'Complete Details'])}
        style={styles.stepHeader}
      />
      <TextInput
        data-testid="promotion-name"
        label={t(['Nama Promosi', 'Promotion Name'])}
        value={promotionName}
        onChangeText={setPromotionName}
        maxLength={50}
        containerStyle={styles.field}
        disabled={!editable}
      />
      <TextInput
        multiline
        rows={4}
        label={t([
          'Deskripsi - Konten Toko App',
          'Description - Toko App Content',
        ])}
        value={description}
        onChangeText={setDescription}
        maxLength={255}
        containerStyle={styles.field}
        placeholder={t(['Masukkan teks...', 'Enter text here...'])}
        disabled={!editable}
      />
      <TextInput
        multiline
        rows={4}
        label={t([
          'Deskripsi - Konten Salesman App',
          'Description - Salesman App Content',
        ])}
        value={descriptionSalesmanApp}
        onChangeText={setDescriptionSalesmanApp}
        maxLength={255}
        containerStyle={styles.field}
        placeholder={t(['Masukkan teks...', 'Enter text here...'])}
        disabled={!editable}
      />
      <Text bold style={styles.smallBottomSpacing}>
        {t(['Pesan Sekarang', 'Order Now'])}
      </Text>
      <View style={styles.smallBottomSpacing}>
        <BrandDropdown
          data-testid="brand-dropdown"
          disabled={!editable}
          selectedOption={selectedBrand ?? undefined}
          onSelect={onSelectBrand}
          type="basic"
          showLocalBrandData
        />
      </View>
      <View style={styles.smallBottomSpacing}>
        <RangeDropdown
          data-testid="range-dropdown"
          brandID={brandId ?? undefined}
          disabled={!editable}
          selectedOption={selectedRange ?? undefined}
          onSelect={setSelectedRange}
          title={t([
            'Pilih Range (Jika Ada Produk)',
            'Select Range (If Applicable)',
          ])}
          type="basic"
        />
      </View>
      <View style={styles.field}>
        <ProductDropdown
          data-testid="product-dropdown"
          disabled={!editable}
          brandID={brandId ?? undefined}
          rangeID={selectedRange ?? undefined}
          selectedOption={selectedProduct ?? undefined}
          onSelect={setSelectedProduct}
          title={t([
            'Pilih Produk (Jika Ada Produk)',
            'Select Product (If Applicable)',
          ])}
          type="basic"
        />
      </View>
      <TextInput
        data-testid="quantity"
        label={t(['Kuantitas (Jika Ada Produk)', 'Quantity (If Applicable)'])}
        value={quantity}
        onChangeText={setQuantity}
        containerStyle={styles.field}
        disabled={!editable}
        type="number"
      />
      <View style={styles.field}>
        <Text bold style={styles.smallBottomSpacing}>
          {t([
            'Satuan Kuantitas (Jika Ada Produk)',
            'Quantity Unit (If Applicable)',
          ])}
        </Text>
        <UnitDropdown
          data-testid="unit-dropdown"
          disabled={!editable}
          dataKey="label"
          getSelectedValues={getSelectedUnit}
          brandID={brandId ?? undefined}
          rangeID={selectedRange ?? undefined}
          productID={selectedProduct ?? undefined}
          selectedOption={unit ?? undefined}
          onSelect={setUnit}
          type="basic"
        />
      </View>
      <TextInput
        data-testid="terms-and-conditions"
        multiline
        rows={4}
        label={t(['Syarat & Ketentuan', 'Terms & Conditions'])}
        value={termAndCondition}
        onChangeText={setTermAndCondition}
        containerStyle={styles.field}
        placeholder={t(['Masukkan teks...', 'Enter text here...'])}
        disabled={!editable}
      />
      <View style={styles.field}>
        <View style={styles.row}>
          <Text bold style={[styles.label, styles.flex]}>
            {t(['Brand', 'Brand'])}
          </Text>
          <Text bold style={[styles.label, { flex: 2 }]}>
            {t(['Principal', 'Principal'])}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.flex}>
            <BrandContactDropdown
              data-testid="brand-contact-dropdown"
              disabled={!editable || disableBrandContact}
              selectedOption={brandContact ?? undefined}
              onSelect={setBrandContact}
              isOptional
            />
          </View>
          <Button
            disabled={!promotionId}
            preset="transparent"
            title="Send"
            onPress={onSendEmailPress}
            icon={
              <Image
                source={{ uri: emailIcon }}
                style={[styles.emailIcon, { opacity: !promotionId ? 0.3 : 1 }]}
                resizeMode="contain"
              />
            }
          />
        </View>
      </View>
      <View style={styles.field}>
        <TextInput
          label={t([
            'URL Deeplink - Konten Salesman App',
            'URL Deeplink - Salesman App Content',
          ])}
          value={salesmanAppDeepLink}
          onChangeText={setSalesmanAppDeepLink}
          containerStyle={styles.field}
          disabled={true}
        />
        <Button
          onPress={onCopyText}
          style={styles.copyLink}
          title={t(['Salin', 'Copy'])}
        />
      </View>
      <Text bold style={styles.label}>
        {t(['Input Kode Promosi', 'Promotion Code Input'])}
      </Text>
      <TextInput
        data-testid="promotion-code-textinput"
        value={promotionCode || ''}
        onChangeText={(text) => {
          resetValidPromotionCode();
          if (text === '') {
            setPromotionCode(null);
          } else {
            const regex = new RegExp('^[\u0021-\u007E]*$');
            if (text.match(regex)) {
              setPromotionCode(text.toLocaleUpperCase());
            }
          }
        }}
        rightButton={
          <Button
            disabled={
              generatePromotionCode || !promotionCode || !isMeetMinCharacter
            }
            preset="transparent"
            title={t(['Cek Kode', 'Check Code'])}
            onPress={() => {
              if (promotionCode) {
                checkPromotionCode({
                  variables: { promotionCode: promotionCode },
                });
              }
            }}
            titleTextProps={{ numberOfLines: 1 }}
          />
        }
        containerStyle={styles.smallBottomSpacing}
        disabled={generatePromotionCode || !editable}
      />
      {renderPromotionCodeError()}
      <Checkbox
        data-testid="generate-promotion-code-checkbox"
        labelNode={
          <Text bold size="default">
            {t(['Gunakan Kode Promo Acak', 'Use Random Promotion Code'])}
          </Text>
        }
        checked={generatePromotionCode}
        onPress={() => {
          resetValidPromotionCode();
          setGeneratePromotionCode(!generatePromotionCode);
          setPromotionCode(null);
        }}
        disabled={!editable}
      />
    </View>
  );

  const renderLive = () => (
    <View style={styles.liveContainer}>
      <View style={styles.switchContainer}>
        <Text bold>{t(['Tampil di Toko App', 'Show in Toko App'])}</Text>
        <Switch
          checked={showPromotionInMobile}
          onPress={setShowPromotionInMobile}
          disabled={!editable}
        />
      </View>
      <View style={styles.switchContainer}>
        <Text bold>{t(['Push Notifications', 'Push Notifications'])}</Text>
        <Switch
          checked={sendPushNotification}
          onPress={setSendPushNotification}
          disabled={!editable || !!promotionId}
        />
      </View>
      <Text bold color="link">
        {t(['Tayang di Aplikasi', 'Go Live on App'])}:
      </Text>
      <View style={styles.field}>
        <LiveDateRadioGroup
          withEndDate
          liveDate={new Date(liveDate)}
          setLiveDate={setLiveDate}
          endDate={endDate}
          setEndDate={setEndDate}
          disabled={!editable}
        />
      </View>
      <View style={[styles.row, styles.field]}>
        <View style={[styles.flex, styles.buttonSpacing]}>
          <Button
            data-testid="submit-button"
            disabled={disabled || !editable}
            isLoading={
              uploadLoading || createPromotionLoading || updatePromotionLoading
            }
            title={t(['Simpan', 'Submit'])}
            onPress={onSubmit}
          />
        </View>
        <View style={styles.flex}>
          <Button
            data-testid="edit-cancel-button"
            preset="secondary"
            title={editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])}
            onPress={onEdit}
          />
        </View>
      </View>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </View>
  );

  return (
    <>
      <View style={styles.row}>
        {renderStepOne()}
        {renderStepTwoToThree()}
        {renderStepFour()}
        {renderStepFive()}
      </View>
      <View style={styles.liveView}>{renderLive()}</View>
    </>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  stepHeader: {
    marginBottom: spacing.small,
  },
  imageContainer: {
    width: 240,
    height: 120,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  field: {
    marginBottom: spacing.small,
  },
  stepSpacing: {
    flex: 1,
    marginRight: spacing.xlarge,
  },
  dropdownSpacing: {
    marginBottom: spacing.large,
  },
  label: {
    marginBottom: spacing.xsmall,
  },
  optionalImageWrapper: {
    marginTop: spacing.xlarge,
    marginBottom: spacing.medium,
  },
  liveView: {
    alignItems: 'flex-end',
  },
  liveContainer: {
    maxWidth: 320,
    marginTop: spacing.large,
    borderColor: theme.colors.border.primary,
    borderWidth: 1,
    padding: spacing.medium,
  },
  buttonSpacing: {
    marginLeft: 46,
    marginRight: spacing.small,
  },
  smallBottomSpacing: {
    marginBottom: spacing.xsmall,
  },
  emailIcon: { width: 38, height: 38 },
  copyLink: {
    color: theme.colors.text.link,
    marginTop: spacing.xxsmall,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing.small,
  },
});
