import React, { ReactNode, useState } from 'react';
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  StyleSheet,
} from 'react-native';

import { Text } from '../../../core-ui';
import { colors } from '../../../constants/theme';
import { Header } from '../../../components';

type SearchProps = {
  'data-testid'?: string;
  children?: ReactNode;
  title?: string;
  containerStyle?: StyleProp<ViewStyle>;
  initActiveTab?: ActiveContentGamification;
  onChangeTab: (tab: ActiveContentGamification) => void;
};

export enum ActiveContentGamification {
  EDITOR = 'editor',
  TOP_UP_REWARD = 'topUpReward',
  LOG = 'log',
  HISTORY = 'history',
}

export default function HeaderGamification(props: SearchProps) {
  let {
    title,
    containerStyle,
    initActiveTab = ActiveContentGamification.EDITOR,
    onChangeTab,
  } = props;

  const [activeContent, setActiveContent] = useState<ActiveContentGamification>(
    initActiveTab,
  );
  return (
    <View style={containerStyle}>
      {title && <Header title={title} />}
      <View
        style={{
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderBottomColor: colors.border.primary,
          marginTop: 20,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContentGamification.EDITOR);
            onChangeTab(ActiveContentGamification.EDITOR);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContentGamification.EDITOR && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContentGamification.EDITOR}
              size="mediumLarge"
            >
              Mission Editor
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContentGamification.TOP_UP_REWARD);
            onChangeTab(ActiveContentGamification.TOP_UP_REWARD);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContentGamification.TOP_UP_REWARD && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContentGamification.TOP_UP_REWARD}
              size="mediumLarge"
            >
              Top Up Reward
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContentGamification.LOG);
            onChangeTab(ActiveContentGamification.LOG);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContentGamification.LOG && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContentGamification.LOG}
              size="mediumLarge"
            >
              Activity Log
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setActiveContent(ActiveContentGamification.HISTORY);
            onChangeTab(ActiveContentGamification.HISTORY);
          }}
        >
          <View
            style={[
              styles.headButton,
              activeContent === ActiveContentGamification.HISTORY && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={activeContent === ActiveContentGamification.HISTORY}
              size="mediumLarge"
            >
              Activity History
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  headButton: {
    paddingBottom: 16,
    marginRight: 36,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
});
