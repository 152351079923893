import React, { ComponentType } from 'react';
import {
  TableCell,
  TableHead as MTableHead,
  TableRow,
  TableCellProps,
  TableSortLabel,
  TableRowProps,
} from '@material-ui/core';

import Text from '../Text';
import { Order } from '../../helpers/getComparator';

import { Props as TableProps } from './Table';

type Props<T, U> = Pick<TableProps<T, U>, 'data' | 'structure'> & {
  'data-testid'?: string;
  order?: Order;
  orderBy?: string | null;
  noUpperCase?: boolean;
  cellClasses?: TableCellProps['classes'];
  rowClasses?: TableRowProps['classes'];
  TextComponent?: ComponentType;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
};

const TableHead = <
  T extends ObjectOf<unknown>, // NOTE: the shape of the data
  U extends { [key in keyof T | string]: unknown } // NOTE: the shape of the flattened data
>(
  props: Props<T, U>,
) => {
  const {
    structure,
    order,
    orderBy,
    noUpperCase,
    cellClasses,
    rowClasses,
    TextComponent = Text,
    onRequestSort,
  } = props;
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort?.(event, property);
  };

  return (
    <MTableHead data-testid={props['data-testid']}>
      <TableRow classes={rowClasses}>
        {Object.keys(structure).map((dataKey) => {
          let {
            disablePadding,
            headerTitle,
            headerTitleColor,
            noHeaderTitle,
            orderable,
            align,
          } = structure[dataKey] ?? {};
          let title = headerTitle ?? dataKey;
          if (!noUpperCase) {
            title = title.toUpperCase();
          }
          return (
            <TableCell
              data-testid={`${props['data-testid']}-cell`}
              align={align}
              key={dataKey.toString()}
              classes={cellClasses}
              padding={disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === dataKey && order}
            >
              {orderable ? (
                <TableSortLabel // NOTE: this causes the table to overflow in Firefox
                  disabled={!orderable}
                  active={orderable && orderBy === dataKey}
                  direction={orderBy === dataKey ? order : undefined}
                  onClick={orderable ? createSortHandler(dataKey) : undefined}
                >
                  <TextComponent bold color={headerTitleColor}>
                    {noHeaderTitle ? '' : title}
                  </TextComponent>
                </TableSortLabel>
              ) : (
                <TextComponent bold color={headerTitleColor}>
                  {noHeaderTitle ? '' : title}
                </TextComponent>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </MTableHead>
  );
};

export default TableHead;
