import React, { useState, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  Header,
  SearchHeader,
  Option,
  SearchByDateRange,
  UsersDropdown,
  RegionDropdown,
  CityDropdown,
  SearchByText,
  BrandDropdown,
  CompetitionStatsDropdown,
  SearchByNumberRange,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { CompetitionFragment } from '../../generated/CompetitionFragment';
import { CompetitionsVariables } from '../../generated/Competitions';
import { StatsCompetition } from '../../generated/globalTypes';

import DboNewsCompetitionTable from './DboNewsCompetitionTable';

type Props = {
  refetchToggle?: boolean;
  hideEdit?: boolean;
  hideArchiveCheck?: boolean;
  queryVariables?: CompetitionsVariables;
  setEditCompetition?: (value: CompetitionFragment) => void;
};

const DboNewsSearchCompetition = (props: Props) => {
  let {
    refetchToggle,
    hideEdit,
    hideArchiveCheck,
    queryVariables,
    setEditCompetition,
  } = props;
  // NOTE: these are used for filter state
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Option | null>(null);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<Option | null>(null);
  const [selectedStats, setSelectedStats] = useState<Option | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);
  const [inputCompetition, setInputCompetition] = useState<string | null>(null);
  const [userNumberFrom, setUserNumberFrom] = useState<string | null>(null);
  const [userNumberUntil, setUserNumberUntil] = useState<string | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);

  // NOTE: these are used to filter the queries
  const [searchArchived, setSearchArchived] = useState(false);
  const [searchByUser, setSearchByUser] = useState<string | null>(null);
  const [searchByStats, setSearchByStats] = useState<string | null>(null);
  const [searchByCompetition, setSearchByCompetition] = useState<string | null>(
    null,
  );
  const [searchByRegion, setSearchByRegion] = useState<string | null>(null);
  const [searchByCity, setSearchByCity] = useState<string | null>(null);
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  const [searchUserNumberFrom, setSearchUserNumberFrom] = useState<
    string | null
  >(null);
  const [searchUserNumberUntil, setSearchUserNumberUntil] = useState<
    string | null
  >(null);

  const clearSearch = useCallback(() => {
    setIncludeArchived(false);
    setSelectedBrand(null);
    setSelectedCity(null);
    setSelectedRegion(null);
    setSelectedStats(null);
    setSelectedUser(null);
    setInputCompetition(null);
    setDateFrom(null);
    setDateUntil(null);
    setUserNumberFrom(null);
    setUserNumberUntil(null);
  }, []);

  const submitSearch = useCallback(() => {
    setSearchArchived(includeArchived);
    setSearchByUser(selectedUser?.value.toString() ?? null);
    setSearchByRegion(selectedRegion ? selectedRegion.label : null);
    setSearchByCity(selectedCity ? selectedCity.label : null);
    if (dateFrom) {
      setSearchByDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
    } else {
      setSearchByDateFrom(null);
    }
    if (dateUntil) {
      setSearchByDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchByDateUntil(null);
    }
    setSearchByStats(selectedStats?.value ?? null);
    if (selectedStats?.value === StatsCompetition.ALL_USERS_WHO_ENTERED) {
      setSearchUserNumberFrom(userNumberFrom);
      setSearchUserNumberUntil(userNumberUntil);
    }
    setSearchByCompetition(inputCompetition);
    setSearchByBrand(selectedBrand?.value.toString() ?? null);
  }, [
    setSearchArchived,
    setSearchByUser,
    setSearchByRegion,
    setSearchByCity,
    setSearchByDateFrom,
    setSearchByDateUntil,
    setSearchByStats,
    setSearchByCompetition,
    setSearchByBrand,
    setSearchUserNumberFrom,
    setSearchUserNumberUntil,
    includeArchived,
    selectedUser,
    selectedRegion,
    selectedCity,
    selectedStats,
    dateFrom,
    dateUntil,
    inputCompetition,
    selectedBrand,
    userNumberFrom,
    userNumberUntil,
  ]);

  const onSelectRegion = useCallback((selected: Option) => {
    setSelectedRegion(selected);
    setSelectedCity(null);
  }, []);

  return (
    <>
      <SearchHeader
        data-testid="search-competition"
        archivable={!hideArchiveCheck}
        title={t(['Kompetisi', 'Competitions'])}
        includeArchived={includeArchived}
        onArchivePress={setIncludeArchived}
        onSearchPress={submitSearch}
        onClearPress={clearSearch}
        disabled={searchDisabled}
      >
        <View>
          <UsersDropdown
            data-testid="search-user-dropdown"
            type="side"
            title={t(['Cari Pengguna', 'Search by Users'])}
            selectedOption={selectedUser?.value}
            onSelect={setSelectedUser}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View>
          <BrandDropdown
            data-testid="search-brand-dropdown"
            type="side"
            title={t(['Cari Merek', 'Search by Brands'])}
            selectedOption={selectedBrand?.value}
            onSelect={setSelectedBrand}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View>
          <RegionDropdown
            data-testid="search-region-dropdown"
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={selectedRegion?.value}
            onSelect={onSelectRegion}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View>
          <CityDropdown
            data-testid="search-city-dropdown"
            type="side"
            provincesID={selectedRegion ? [selectedRegion.value] : []}
            title={t(['Cari Kota', 'Search by City'])}
            selectedOption={selectedCity?.value}
            onSelect={setSelectedCity}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-competition-name"
            label={t(['Cari Kompetisi', 'Search by Competition'])}
            value={inputCompetition ?? ''}
            setValue={setInputCompetition}
          />
        </View>
        <View style={styles.separator}>
          <SearchByDateRange
            data-testid="search-competition-date"
            from={dateFrom}
            until={dateUntil}
            setFrom={setDateFrom}
            setUntil={setDateUntil}
            setDisabled={setSearchDisabled}
          />
        </View>
        <View>
          <CompetitionStatsDropdown
            data-testid="search-competition-stats"
            type="side"
            selectedOption={selectedStats?.value}
            onSelect={setSelectedStats}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        {selectedStats?.value === StatsCompetition.ALL_USERS_WHO_ENTERED ? (
          <View style={[styles.separator, styles.largeBottomSpacing]}>
            <SearchByNumberRange
              data-testid="search-competition-registered"
              title={t([
                'Cari Pengguna yang Terdaftar',
                'Search by Registered User',
              ])}
              from={userNumberFrom || ''}
              until={userNumberUntil || ''}
              setFrom={setUserNumberFrom}
              setUntil={setUserNumberUntil}
            />
          </View>
        ) : (
          <View style={styles.smallBottomSpacing} />
        )}
      </SearchHeader>
      <Header
        withSeparator
        title="Results"
        titleColor="link"
        infotipContent={t([
          'Seluruh data Kompetisi akan ditampilkan pada list berikut. Klik Judul Kolom untuk penyortiran berdasarkan judul kolom yang di kilik. Klik tulisan “Lihat” untuk melihat detail konten judul tersebut. Klik “Icon” Arsip untuk mengirimkan data ke halaman folder arsip. Data yang sudah berada di Folder Arsip tidak akan nampak pada daftar ini, Anda dapat mengembalikan ke daftar ini dengan cara masuk pada menu “Halaman Folder Arsip”.',
          'All Competition data will be displayed in the following list. Click Column Headers for sorting based on the column headings that are clicked. Click "View" to see the detailed content of the title. Click the Archive “Icon” to send the data to the archive folder page. The data that is already in the Archive Folder will not appear in this list, you can return the data to this list by going to the "Archive Folder Page" menu.',
        ])}
        style={styles.topPadding}
      />
      <DboNewsCompetitionTable
        refetchToggle={refetchToggle}
        hideEdit={hideEdit}
        queryVariables={queryVariables}
        setEditCompetition={setEditCompetition}
        searchByUser={searchByUser || undefined}
        searchByRegion={searchByRegion || undefined}
        searchByCity={searchByCity || undefined}
        searchByDateFrom={searchByDateFrom || undefined}
        searchByDateUntil={searchByDateUntil || undefined}
        searchArchived={searchArchived || undefined}
        searchByStats={searchByStats || undefined}
        searchByCompetition={searchByCompetition || undefined}
        searchByBrand={searchByBrand || undefined}
        searchByUserNumberFrom={searchUserNumberFrom || undefined}
        searchByUserNumberUntil={searchUserNumberUntil || undefined}
      />
    </>
  );
};

const styles = StyleSheet.create({
  topPadding: { paddingTop: spacing.xlarge },
  smallBottomSpacing: {
    marginBottom: spacing.small,
  },
  largeBottomSpacing: {
    marginBottom: spacing.large,
  },
  separator: {
    paddingBottom: spacing.small,
    marginBottom: spacing.small,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginVertical: spacing.small,
    width: '65%',
  },
  width65: { width: '65%' },
});

export default DboNewsSearchCompetition;
