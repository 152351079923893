import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import { Button, Dropzone, Text } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { toDdMmYy } from '../../helpers';
import { FileWithPreview } from '../../core-ui/Dropzone';

type Props = {
  id?: string | null;
  edit?: boolean;
  editable: boolean;
  image?: FileWithPreview | string | null;
  liveEndDate?: Date;
  archiveLoading: boolean;
  onDrop?: (file: FileWithPreview) => void;
  onEdit?: () => void;
  onArchive?: () => void;
};

const HomepageSlideRow = (props: Props) => {
  let {
    id,
    edit,
    editable,
    image,
    liveEndDate,
    archiveLoading,
    onDrop,
    onEdit,
    onArchive,
  } = props;

  const editTitle = useMemo(
    () => (edit ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])),
    [edit],
  );

  const disabled = useMemo(() => !edit || !id || !editable, [
    edit,
    id,
    editable,
  ]);

  return (
    <View style={styles.root}>
      <Dropzone
        data-testid="slider-image"
        withUploadText
        source={image}
        containerStyle={[styles.box, edit && styles.edit]}
        placeholderStyle={styles.placeholder}
        getPreview={onDrop}
        disabled={!!image && !edit}
      />
      {!!liveEndDate && !!onEdit && !!onArchive && (
        <>
          <View style={styles.ends}>
            <Text size="small" color="secondary" data-testid="slider-end-date">
              {t(['berakhir: {endDate}', 'ends: {endDate}'], {
                endDate: toDdMmYy(liveEndDate),
              })}
            </Text>
          </View>
          <View style={styles.actions}>
            <Button
              data-testid="slider-edit"
              preset="transparent"
              style={styles.action}
              title={editTitle}
              onPress={onEdit}
              titleTextProps={{ size: 'small' }}
            />
            <Text color="link">|</Text>
            <Button
              data-testid="slider-archive"
              preset="transparent"
              style={styles.action}
              title={t(['Arsip', 'Archive'])}
              onPress={onArchive}
              disabled={disabled}
              isLoading={archiveLoading && edit}
              titleTextProps={{
                size: 'small',
                color: disabled ? 'disabled' : 'link',
              }}
            />
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: { height: 140 },
  box: {
    width: 150,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center',
  },
  edit: { borderColor: colors.homepageSlider.edit },
  ends: { alignSelf: 'flex-end', paddingVertical: spacing.xxsmall },
  actions: { flexDirection: 'row', alignSelf: 'flex-end' },
  action: { height: 24 },
  placeholder: { width: 50, height: 50 },
});

export default HomepageSlideRow;
