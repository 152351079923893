import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  StyleSheet,
  ActivityIndicator,
  View,
  TouchableOpacity,
} from 'react-native';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';

import { colors } from '../../constants/theme';
import {
  Header,
  TextPopover,
  ErrorMessage,
  ErrorModal,
} from '../../components';
import { Table, Text } from '../../core-ui';
import { toDdMmYyyy, to24Hh } from '../../helpers';
import { GET_POP_UPS } from '../../graphql/queries';
import { ARCHIVE_POP_UP, UPDATE_POP_UP } from '../../graphql/mutations';
import { ArchiveStatus } from '../../generated/globalTypes';
import { PopUps, PopUpsVariables } from '../../generated/PopUps';
import {
  ArchivePopUp,
  ArchivePopUpVariables,
} from '../../generated/ArchivePopUp';
import { UpdatePopUp, UpdatePopUpVariables } from '../../generated/UpdatePopUp';
import { PopUpFragment } from '../../generated/PopUpFragment';

type Props = {
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: PopUpsVariables;
  onArchive?: () => void;
};

const CustomPopUpTable = (props: Props) => {
  let { refetchToggle, hideEdit, queryVariables, onArchive } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionID, setActionID] = useState<string | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );
  const onArchiveError = useMemo(() => {
    return openErrorModal(
      t([
        'mengubah status arsip pop up',
        'change archive status of the pop up',
      ]),
    );
  }, [openErrorModal]);
  const onEndError = useMemo(() => {
    return openErrorModal(t(['mengakhiri pop up', 'end pop up']));
  }, [openErrorModal]);

  const { data, loading, error, refetch } = useQuery<PopUps, PopUpsVariables>(
    GET_POP_UPS,
    {
      variables: {
        skip: page * rowsPerPage,
        first: rowsPerPage,
        archiveStatus: ArchiveStatus.NORMAL,
        isWhoNotNull: true,
        ...queryVariables,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            archiveStatus: ArchiveStatus.NORMAL,
            isWhoNotNull: true,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [refetch, page, rowsPerPage, queryVariables],
  );

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  const onArchiveCompleted = useCallback(() => {
    refetchCurrentPage();
    onArchive?.();
  }, [refetchCurrentPage, onArchive]);

  const [archive, { loading: archiveLoading }] = useMutation<
    ArchivePopUp,
    ArchivePopUpVariables
  >(ARCHIVE_POP_UP, {
    onCompleted: onArchiveCompleted,
    onError: onArchiveError,
  });

  const [update, { loading: updateLoading }] = useMutation<
    UpdatePopUp,
    UpdatePopUpVariables
  >(UPDATE_POP_UP, { onCompleted: onArchive, onError: onEndError });

  // NOTE: this makes sure that when a new data is created, we refetch the data
  useEffect(() => {
    refetchData();
  }, [refetchToggle, refetchData]);

  const onEndPress = useCallback(
    (popUp: PopUpFragment) => {
      let { __typename, id, name, ...restPopUp } = popUp;
      setActionID(popUp.id);
      update({
        variables: {
          ...restPopUp,
          popUpId: id,
          popUpName: name,
          endDate: new Date(),
        },
      });
    },
    [update],
  );
  const onArchivePress = useCallback(
    (popUpId: string) => {
      setActionID(popUpId);
      archive({ variables: { popUpId } });
    },
    [archive],
  );

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : {
            action: {
              headerTitle: t(['aksi', 'action']),
              render: (popUp: PopUpFragment) => {
                let disabled =
                  new Date().getTime() > new Date(popUp.endDate).getTime();
                return popUp.id === actionID && updateLoading ? (
                  <ActivityIndicator size="small" />
                ) : (
                  <TouchableOpacity
                    data-testid="popup-end"
                    onPress={() => onEndPress(popUp)}
                    disabled={disabled}
                  >
                    <Text bold color={disabled ? 'disabled' : 'error'}>
                      {t(['akhiri', 'end'])}
                    </Text>
                  </TouchableOpacity>
                );
              },
            },
          },
    [hideEdit, updateLoading, actionID, onEndPress],
  );

  return (
    <>
      <Header
        withSeparator
        title={t(['POP UP AKTIF & PENDING ', 'LIVE & PENDING POP UPS'])}
        titleSize="default"
        infotipContent={t([
          'Seluruh data PopUp akan ditampilkan pada daftar berikut. Klik Judul Kolom untuk penyortiran berdasarkan judul kolom yang di kilik. Klik tulisan “Lihat” untuk melihat detail konten judul tersebut. Klik “Icon” Arsip untuk mengirimkan data ke halaman folder arsip. Data yang sudah berada di Folder Arsip tidak akan nampak pada daftar ini, Anda dapat mengembalikan ke daftar ini dengan cara masuk pada menu “Halaman Folder Arsip”.',
          'All PopUp data will be displayed in the following list. Click Column Headers for sorting based on the column headings that are clicked. Click "View" to see the detailed content of the title. Click the Archive “Icon” to send the data to the archive folder page. The data that is already in the Archive Folder will not appear in this list, you can return the data to this list by going to the "Archive Folder Page" menu.',
        ])}
      />
      <Table
        data-testid="popup-table"
        data={(data && data.popUpAdvanceSearch.row) ?? []}
        error={!!error}
        dataCount={(data && data.popUpAdvanceSearch.total) || 0}
        loading={loading}
        page={page}
        onChangeRowsPerPage={onRowsChange}
        onChangePage={onChangePage}
        rowsPerPage={rowsPerPage}
        structure={{
          name: {
            headerTitle: t(['pesan', 'message']),
          },
          who: {
            headerTitle: t(['siapa?', 'who?']),
          },
          region: {
            headerTitle: t(['provinsi', 'region']),
          },
          city: {
            headerTitle: t(['kota', 'city']),
          },
          screen: {
            headerTitle: t(['Halaman', 'Page']),
            render: ({ page, conditionBrand, conditionRange }) => {
              let label = '';
              let content = '';
              if (page) {
                if (conditionBrand && conditionRange) {
                  label = `../${conditionRange.name}`;
                  content = `${page.name} / ${conditionBrand.name} / ${conditionRange.name}`;
                } else if (page.parent) {
                  label = `../${page.name}`;
                  content = `${page.parent} / ${page.name}`;
                } else {
                  return <Text>{page.name}</Text>;
                }
              }
              return <TextPopover label={label} content={content} />;
            },
          },
          startDate: {
            headerTitle: t(['Tanggal Mulai', 'Start Date']),
            render: ({ startDate }) => (
              <Text>
                {toDdMmYyyy(new Date(startDate))} {to24Hh(new Date(startDate))}
              </Text>
            ),
          },
          liveStatus: {
            noHeaderTitle: true,
            render: ({ startDate, endDate }) => {
              let statusText = t(['LIVE', 'LIVE']);
              let statusColor: string = colors.status.live;
              let now = new Date().getTime();
              if (now > new Date(endDate).getTime()) {
                statusText = t(['SELESAI', 'END']);
                statusColor = colors.status.end;
              } else if (now < new Date(startDate).getTime()) {
                statusText = t(['TUNDA', 'PENDING']);
                statusColor = colors.status.pending;
              }
              return (
                <View
                  data-testid="popup-status"
                  style={[styles.statusCell, { backgroundColor: statusColor }]}
                >
                  <Text color="contrast">{statusText.toUpperCase()}</Text>
                </View>
              );
            },
          },
          endDate: {
            headerTitle: t(['Tanggal Berakhir', 'End Date']),
            render: ({ endDate }) => (
              <Text>
                {toDdMmYyyy(new Date(endDate))} {to24Hh(new Date(endDate))}
              </Text>
            ),
          },
          ...action,
          archive: {
            headerTitle: t(['arsip', 'archive']),
            headerTitleColor: 'link',
            render: ({ id, status }) =>
              id === actionID && archiveLoading ? (
                <ActivityIndicator size="small" />
              ) : (
                <TouchableOpacity
                  data-testid="popup-archive"
                  onPress={() => onArchivePress(id)}
                >
                  {status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon data-testid="popup-normal" />
                  ) : (
                    <FolderFilledIcon data-testid="popup-archived" />
                  )}
                </TouchableOpacity>
              ),
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          action={t(['mengambil data pop up', 'retrieve the pop up data'])}
          error={error}
          onPress={refetchData}
        />
      )}
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  statusCell: {
    height: 36,
    width: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CustomPopUpTable;
