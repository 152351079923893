import React, { memo, ReactNode, useMemo } from 'react';
import { Snackbar as MSnackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Text from './Text';
import Button from './Button';

export type Props = {
  open: boolean;
  message: string | ReactNode;
  variant: 'success' | 'error';
  onClose: () => void;
  autoHideDuration?: number;
  buttonTitle?: string;
  onButtonPress?: () => void;
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 720,
  },
});

const Snackbar = memo((props: Props) => {
  const classes = useStyles();
  let {
    open,
    message,
    variant,
    autoHideDuration = 5000,
    buttonTitle,
    onButtonPress,
    onClose,
  } = props;

  const icon = useMemo(
    () => (variant === 'success' ? <CheckCircleIcon /> : <CancelIcon />),
    [variant],
  );

  return (
    <MSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      classes={classes}
    >
      <Alert
        icon={icon}
        elevation={6}
        variant="filled"
        severity={variant}
        classes={classes}
        action={
          buttonTitle &&
          onButtonPress && (
            <Button
              data-testid="snackbar-button"
              preset="transparent"
              title={buttonTitle}
              onPress={onButtonPress}
            />
          )
        }
      >
        {typeof message === 'string' ? (
          <Text color="white" data-testid="snackbar-message">
            {message}
          </Text>
        ) : (
          message
        )}
      </Alert>
    </MSnackbar>
  );
});

export default Snackbar;
