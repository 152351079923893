import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_PROVINCES } from '../../graphql/queries';
import { Provinces } from '../../generated/Provinces';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Provinsi', 'Select Region']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
};

const RegionDropdown = memo((props: Props) => {
  let { title = defaultTitle, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<Provinces>(GET_PROVINCES, {
    notifyOnNetworkStatusChange: true,
  });
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      data?.provinces.map(({ id, name }) => ({
        label: name,
        value: id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <Dropdown
      data-testid="region-dropdown"
      error={error}
      errorAction={t(['mengambil data provinsi', 'retrieve the region data'])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default RegionDropdown;
