import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text, Button } from '../core-ui';
import { spacing } from '../constants/theme';

import Modal from './Modal';

type Props = {
  'data-testid'?: string;
  open: boolean;
  keepOpenAfterConfirm?: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  title: string;
};

const ConfirmationModal = memo((props: Props) => {
  let {
    open,
    keepOpenAfterConfirm = false,
    loading,
    onConfirm,
    onClose,
    onCancel,
    title,
  } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <View style={styles.center}>
        <Text size="medium" style={styles.title}>
          {title}
        </Text>
        <View style={styles.rowView}>
          <Button
            data-testid={`${props['data-testid']}-confirm`}
            preset="transparent"
            title={t(['Ya, lanjutkan', 'Yes, continue'])}
            isLoading={loading}
            onPress={() => {
              onConfirm();
              !keepOpenAfterConfirm && onClose();
            }}
          />
          <Button
            data-testid={`${props['data-testid']}-cancel`}
            preset="transparent"
            title={t(['Tidak, batalkan', 'No, cancel'])}
            onPress={onCancel ?? onClose}
            disabled={loading}
          />
        </View>
      </View>
    </Modal>
  );
});

const styles = StyleSheet.create({
  rowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  center: {
    alignItems: 'center',
    marginTop: spacing.small,
  },
  title: {
    marginVertical: spacing.small,
    textAlign: 'center',
  },
});

export default ConfirmationModal;
