import gql from 'graphql-tag';

import {
  COMPETITION_FRAGMENT,
  EVENT_FRAGMENT,
  NEWS_FRAGMENT,
} from '../fragments';

export const GET_EVENTS = gql`
  query Events(
    $first: Int
    $skip: Int
    $user: String
    $region: String
    $city: String
    $name: String
    $date: DateFilter
    $archived: ArchiveStatus
    $brandId: ID
    $orderBy: EventOrderBy
  ) {
    eventAdvanceSearch(
      user: $user
      region: $region
      city: $city
      eventName: $name
      date: $date
      archiveStatus: $archived
      brandId: $brandId
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
    ) {
      row {
        ...EventFragment
      }
      total
    }
  }
  ${EVENT_FRAGMENT}
`;

export const GET_NEWS = gql`
  query News(
    $first: Int
    $skip: Int
    $appUser: String
    $region: String
    $city: String
    $newsItem: String
    $brandId: ID
    $date: DateFilter
    $archiveStatus: ArchiveStatus
    $viewers: CountRange
    $orderBy: NewsOrderBy
  ) {
    newsAdvanceSearch(
      user: $appUser
      region: $region
      city: $city
      newsName: $newsItem
      brandId: $brandId
      date: $date
      viewers: $viewers
      archiveStatus: $archiveStatus
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
    ) {
      row {
        ...NewsFragment
      }
      total
    }
  }
  ${NEWS_FRAGMENT}
`;

export const GET_COMPETITIONS = gql`
  query Competitions(
    $first: Int
    $skip: Int
    $user: String
    $region: String
    $city: String
    $name: String
    $date: DateFilter
    $archived: ArchiveStatus
    $brandId: ID
    $stats: StatsCompetition
    $registerUser: CountRange
    $orderBy: CompetitionOrderBy
  ) {
    competitionAdvanceSearch(
      user: $user
      region: $region
      city: $city
      competitionName: $name
      date: $date
      archiveStatus: $archived
      brandId: $brandId
      stats: $stats
      registerUser: $registerUser
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
    ) {
      row {
        ...CompetitionFragment
      }
      total
    }
  }
  ${COMPETITION_FRAGMENT}
`;

export const GET_MONTHLY_EVENT = gql`
  query MonthlyEvent($dateFrom: DateTime!, $dateUntil: DateTime!) {
    monthlyEventsCMS(dateFrom: $dateFrom, dateUntil: $dateUntil) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const GET_MONTHLY_COMPETITION = gql`
  query MonthlyCompetition($dateFrom: DateTime!, $dateUntil: DateTime!) {
    monthlyCompetitionsCMS(dateFrom: $dateFrom, dateUntil: $dateUntil) {
      ...CompetitionFragment
    }
  }
  ${COMPETITION_FRAGMENT}
`;

export const GET_MONTHLY_NEWS = gql`
  query MonthlyNews($dateFrom: DateTime!, $dateUntil: DateTime!) {
    newsAdmin(dateFrom: $dateFrom, dateUntil: $dateUntil) {
      ...NewsFragment
    }
  }
  ${NEWS_FRAGMENT}
`;
