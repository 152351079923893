import React, { useCallback, useEffect, useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Text } from '../../core-ui';
import { colors } from '../../constants/theme';
import { pageTitle } from '../../constants/pageTitle';

import ReviewAgentApplication, {
  ReviewAgentApplicationProps,
} from './ReviewAgentApplication';
import AgentApplicationHistory from './AgentApplicationHistory';

type Props = {
  review: ReviewAgentApplicationProps;
  disabled: boolean;
};

enum ActiveScreen {
  DECISION = 'decision',
  HISTORY = 'history',
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    paddingRight: 10,
    alignSelf: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function SceneSelector({ review, disabled }: Props) {
  const history = useHistory();
  let classes = useStyles();
  // console.log(review);

  let [currentActiveScreen, setCurrentActiveScreen] = useState<ActiveScreen>(
    ActiveScreen.DECISION,
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.TokoVerification });
    };
    eventLog();
  }, []);

  useEffect(() => {
    disabled && setCurrentActiveScreen(ActiveScreen.HISTORY);
  }, [disabled]);

  const onBack = useCallback(() => {
    if (history.location.pathname !== '/') {
      history.push('/');
    }
  }, [history]);

  return (
    <View style={styles.root}>
      <View style={styles.row}>
        <IconButton
          data-testid="header-back-button"
          onClick={onBack}
          classes={{ root: classes.root }}
          disableTouchRipple={true}
        >
          <ArrowBackIcon fontSize="default" />
        </IconButton>
        {!disabled && (
          <TouchableOpacity
            data-testid="list-daftar-data"
            disabled={disabled}
            onPress={() => {
              setCurrentActiveScreen(ActiveScreen.DECISION);
            }}
          >
            <View
              style={[
                styles.button,
                currentActiveScreen === ActiveScreen.DECISION && {
                  borderBottomColor: colors.button.primary.background,
                },
              ]}
            >
              <Text
                bold={currentActiveScreen === ActiveScreen.DECISION}
                size="medium"
                style={styles.title}
              >
                {t([
                  'Pengajuan Distributor Untuk Toko',
                  'Distributor Application for Toko',
                ])}
              </Text>
            </View>
          </TouchableOpacity>
        )}

        <TouchableOpacity
          data-testid="list-dalam-masa-pengerjaan"
          onPress={() => setCurrentActiveScreen(ActiveScreen.HISTORY)}
        >
          <View
            style={[
              styles.button,
              currentActiveScreen === ActiveScreen.HISTORY && {
                borderBottomColor: colors.button.primary.background,
              },
            ]}
          >
            <Text
              bold={currentActiveScreen === ActiveScreen.HISTORY}
              size="medium"
              style={styles.title}
            >
              {t(['Log History', 'Log History'])}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.contentWrapper}>
        {currentActiveScreen === ActiveScreen.DECISION && (
          <ReviewAgentApplication
            data={review.data}
            inputPlaceholder={t(['Cari Distributor', 'Look up Distributor'])}
            selectedDistributor={review.selectedDistributor ?? undefined}
            rejectReason={review.rejectReason}
            submitLoading={review.submitLoading}
            submitDisabled={review.submitDisabled}
            onSelectDistributor={review.onSelectDistributor}
            onSetRejectReason={review.onSetRejectReason}
            onSubmit={review.onSubmit}
            onReject={review.onReject}
          />
        )}
        {currentActiveScreen === ActiveScreen.HISTORY && (
          <AgentApplicationHistory
            brandId={review.data?.requestAgent.brandId || ''}
            storeId={review.data?.requestAgent.store.id || ''}
          />
        )}
      </View>
    </View>
  );
}

let styles = StyleSheet.create({
  root: {
    // backgroundColor: colors.grey,
    flex: 1,
    overflow: 'hidden',
    marginTop: 36,
  },
  button: {
    marginRight: 32,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  row: {
    flexDirection: 'row',
    marginHorizontal: 50,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  contentWrapper: { flex: 1 },
  title: {
    textAlign: 'center',
    paddingBottom: 15,
  },
});
