import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { Text, TextInput, Button, Dropzone } from '../../core-ui';
import {
  Header,
  RadioGroup,
  Dropdown,
  Option,
  StepHeader,
  ScreenDropdown,
  ErrorModal,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { PopUpFragment } from '../../generated/PopUpFragment';
import {
  LinkTypePopUp,
  PopUpColor,
  FolderType,
} from '../../generated/globalTypes';
import { CREATE_POP_UP, UPDATE_POP_UP, UPLOAD } from '../../graphql/mutations';
import { CreatePopUp, CreatePopUpVariables } from '../../generated/CreatePopUp';
import { UpdatePopUp, UpdatePopUpVariables } from '../../generated/UpdatePopUp';
import { Upload, UploadVariables } from '../../generated/Upload';

type Props = {
  editPopUp: PopUpFragment | null;
  onSubmit: () => void;
};

const CustomPopUpForm = (props: Props) => {
  const { editPopUp, onSubmit } = props;

  const [name, setName] = useState('');
  const [popUpType, setPopUpType] = useState<'text' | 'image'>('text');
  const [title, setTitle] = useState('');
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');
  const [lineThree, setLineThree] = useState('');
  const [linkType, setLinkType] = useState<LinkTypePopUp | null>(null);
  const [color, setColor] = useState<PopUpColor | null>(null);
  const [image, setImage] = useState<string | FileWithPreview | null>(null);
  const [externalLink, setExternalLink] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [screen, setScreen] = useState<string | null>(null);
  const [subScreenOne, setSubScreenOne] = useState<string | null>(null);
  const [subScreenTwo, setSubScreenTwo] = useState<string | null>(null);
  const [internalLinkValid, setInternalLinkValid] = useState(false);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );
  const onCreateError = useMemo(() => {
    return openErrorModal(t(['menmbuat data pop up', 'create pop up data']));
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(t(['mengubah data pop up', 'update pop up data']));
  }, [openErrorModal]);
  const onUploadError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);

  const clearForm = useCallback(() => {
    setName('');
    setPopUpType('text');
    setTitle('');
    setLineOne('');
    setLineTwo('');
    setLineThree('');
    setLinkType(null);
    setColor(null);
    setImage(null);
    setExternalLink('');
    setWhatsapp('');
    setScreen(null);
    setSubScreenOne(null);
    setSubScreenTwo(null);
  }, []);
  const onMutationCompleted = useCallback(() => {
    onSubmit();
    clearForm();
  }, [onSubmit, clearForm]);

  const submitDisabled = useMemo(() => {
    return (
      !name ||
      (popUpType === 'text' &&
        (!title || !color || (!lineOne && !lineTwo && !lineThree))) ||
      (popUpType === 'image' && !image) ||
      (linkType === LinkTypePopUp.EXTERNAL_LINK && !externalLink) ||
      (linkType === LinkTypePopUp.WHATSAPP && !whatsapp) ||
      (linkType === LinkTypePopUp.INTERNAL_LINK && !internalLinkValid)
    );
  }, [
    name,
    popUpType,
    title,
    lineOne,
    lineTwo,
    lineThree,
    linkType,
    color,
    image,
    externalLink,
    whatsapp,
    internalLinkValid,
  ]);

  useEffect(() => {
    if (editPopUp) {
      setName(editPopUp.name);
      setPopUpType(editPopUp.image ? 'image' : 'text');
      setTitle(editPopUp.title ?? '');
      setLineOne(editPopUp.textLine1 ?? '');
      setLineTwo(editPopUp.textLine2 ?? '');
      setLineThree(editPopUp.textLine3 ?? '');
      setLinkType(editPopUp.linkType);
      setColor(editPopUp.color);
      setImage(editPopUp.image);
      setExternalLink(editPopUp.externalLink ?? '');
      setWhatsapp(editPopUp.whatsAppLink ?? '');
      if (editPopUp.internalLink) {
        setScreen(
          editPopUp.internalLink.parent ?? editPopUp.internalLink.id ?? null,
        );
        setSubScreenOne(editPopUp.brandId ?? editPopUp.internalLink.id ?? null);
        setSubScreenTwo(editPopUp.rangeId ?? null);
      }
      setInternalLinkValid(true);
    } else {
      clearForm();
    }
  }, [editPopUp, clearForm]);

  const [createPopUp, { loading: createLoading }] = useMutation<
    CreatePopUp,
    CreatePopUpVariables
  >(CREATE_POP_UP, {
    onCompleted: onMutationCompleted,
    onError: onCreateError,
  });
  const [updatePopUp, { loading: updateLoading }] = useMutation<
    UpdatePopUp,
    UpdatePopUpVariables
  >(UPDATE_POP_UP, {
    onCompleted: onMutationCompleted,
    onError: onUpdateError,
  });
  const [uploadFile, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, { onError: onUploadError });

  const onSubmitPopUp = useCallback(async () => {
    if (linkType) {
      let commonVariables: CreatePopUpVariables = {
        popUpName: name,
        linkTypePopUp: linkType,
      };
      switch (linkType) {
        case LinkTypePopUp.EXTERNAL_LINK: {
          commonVariables = { ...commonVariables, externalLink };
          break;
        }
        case LinkTypePopUp.INTERNAL_LINK: {
          if (subScreenOne && subScreenTwo) {
            commonVariables = {
              ...commonVariables,
              internalLinkId: screen,
              brandId: subScreenOne,
              rangeId: subScreenTwo,
            };
          } else if (subScreenOne) {
            commonVariables = {
              ...commonVariables,
              internalLinkId: subScreenOne,
            };
          } else {
            commonVariables = { ...commonVariables, internalLinkId: screen };
          }
          break;
        }
        case LinkTypePopUp.WHATSAPP: {
          commonVariables = { ...commonVariables, whatsAppLink: whatsapp };
          break;
        }
      }
      if (popUpType === 'image') {
        let imageLink: string | null = null;
        if (typeof image === 'string') {
          imageLink = image;
        } else if (image) {
          let result = await uploadFile({
            variables: { file: image.file, folder: FolderType.POP_UP },
          });
          imageLink = result?.data?.upload.link ?? null;
        }
        commonVariables = {
          ...commonVariables,
          image: imageLink,
          title: null,
          textLine1: null,
          textLine2: null,
          textLine3: null,
          color: null,
        };
      } else {
        commonVariables = {
          ...commonVariables,
          title,
          textLine1: lineOne,
          textLine2: lineTwo,
          textLine3: lineThree,
          color,
          image: null,
        };
      }
      if (editPopUp) {
        updatePopUp({
          variables: { popUpId: editPopUp.id, ...commonVariables },
        });
      } else {
        createPopUp({ variables: commonVariables });
      }
    }
  }, [
    editPopUp,
    name,
    popUpType,
    title,
    lineOne,
    lineTwo,
    lineThree,
    linkType,
    color,
    image,
    externalLink,
    whatsapp,
    screen,
    subScreenOne,
    subScreenTwo,
    createPopUp,
    updatePopUp,
    uploadFile,
  ]);

  useEffect(() => {
    if (popUpType === 'image' && linkType === LinkTypePopUp.WHATSAPP) {
      setLinkType(LinkTypePopUp.EXTERNAL_LINK);
    }
  }, [popUpType, linkType, setLinkType]);

  const createSetSelected = useCallback(
    <T extends unknown>(
      setter: (value: T) => void,
      ...nullSetters: Array<(value: null) => void>
    ) => (option: Option) => {
      option && setter(option.value as T);
      nullSetters.forEach((cb) => cb(null));
    },
    [],
  );

  const linkTypeOptions = useMemo(
    () =>
      [
        {
          label: t(['Tautan Eksternal', 'External Link']),
          value: LinkTypePopUp.EXTERNAL_LINK,
        },
        {
          label: t(['Tautan Internal', 'Internal Link']),
          value: LinkTypePopUp.INTERNAL_LINK,
        },
        { label: t(['Whatsapp', 'Whatsapp']), value: LinkTypePopUp.WHATSAPP },
      ].slice(0, popUpType === 'image' ? 2 : undefined),
    [popUpType],
  );
  const colorOptions = useMemo(
    () => [
      { label: t(['Biru', 'Blue']), value: PopUpColor.BLUE },
      { label: t(['Emas', 'Gold']), value: PopUpColor.GOLD },
      { label: t(['Merah', 'Red']), value: PopUpColor.RED },
    ],
    [],
  );
  const renderLinkInputs = () => {
    switch (linkType) {
      case LinkTypePopUp.EXTERNAL_LINK: {
        return (
          <View style={styles.textLine}>
            <TextInput
              data-testid="popup-form-external"
              value={externalLink}
              onChangeText={setExternalLink}
              placeholder={t(['Masukkan tautan', 'Enter link'])}
            />
          </View>
        );
      }
      case LinkTypePopUp.INTERNAL_LINK: {
        return (
          <ScreenDropdown
            selectedScreen={screen}
            selectedSubScreenOne={subScreenOne}
            selectedSubScreenTwo={subScreenTwo}
            getValidInput={setInternalLinkValid}
            onScreenSelect={createSetSelected(
              setScreen,
              setSubScreenOne,
              setSubScreenTwo,
            )}
            onSubScreenOneSelect={createSetSelected(
              setSubScreenOne,
              setSubScreenTwo,
            )}
            onSubScreenTwoSelect={createSetSelected(setSubScreenTwo)}
          />
        );
      }
      case LinkTypePopUp.WHATSAPP: {
        return (
          <View style={styles.textLine}>
            <TextInput
              data-testid="popup-form-whatsapp"
              value={whatsapp}
              onChangeText={setWhatsapp}
              placeholder={t(['Masukkan no Whatsapp', 'Enter Whatsapp Number'])}
            />
          </View>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <View>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <Header
        withSeparator
        style={styles.header}
        title={t(['BUAT POP UP BARU', 'CREATE NEW CUSTOM POP UP'])}
        titleSize="default"
        infotipContent={t([
          'Pop Up adalah menu yang akan tampil secara otomatis pada beberapa halaman di aplikasi Mobile TokoApp. Anda dapat membuat PopUp dengan cara menulis secara manual (Text based) dan atur warna, atau anda dapat juga untuk mengunggah materi konten berupa file gambar.',
          'Pop Up is a menu that will appear automatically on several pages in the TokoApp Mobile application. You can create PopUp by writing it manually (Text based) and adjusting the color, or you can upload the content in the form as an image file.',
        ])}
      />
      <View style={styles.row}>
        <View style={[styles.flex, styles.name]}>
          <StepHeader step={1} style={styles.stepHeader} />
          <TextInput
            data-testid="popup-form-name"
            label={t(['Nama Pop Up', 'Pop Up Name'])}
            value={name}
            onChangeText={setName}
          />
        </View>
        <View style={styles.flex} />
        <View style={styles.flex} />
        <View style={styles.flex} />
      </View>
      <View style={styles.row}>
        <View style={[styles.left, styles.column]}>
          <StepHeader step={2} style={styles.stepHeader} />
          <RadioGroup
            selectedOption={popUpType}
            setSelectedOption={setPopUpType}
            options={[
              {
                'data-testid': 'popup-form-type-text',
                value: 'text',
                label: (
                  <View style={styles.row}>
                    <View style={[styles.flex, styles.column]}>
                      <View style={[styles.popUpBox, styles.textPopUpBox]}>
                        <Text bold style={styles.popUpBoxTitle}>
                          {t(['JUDUL - Roboto Bold', 'TITLE - Roboto Bold'])}
                        </Text>
                        <Text size="small" style={styles.textLine}>
                          {t([
                            'Teks baris 1 - Karla Regular',
                            'Text line 1 - Karla Regular',
                          ])}
                        </Text>
                        <Text size="small" style={styles.textLine}>
                          {t([
                            'Teks baris 2 - 35 karaker per baris',
                            'Text line 2 - 35 characters per line',
                          ])}
                        </Text>
                        <Text size="small" style={styles.textLine}>
                          {t([
                            'Teks baris 3 - sertakan {link} di sini',
                            'Text line 3 - include {link} here',
                          ])}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.flex}>
                      <View style={[styles.row, styles.textLine]}>
                        <Text bold style={styles.fieldLabel}>
                          {t(['JUDUL', 'TITLE'])}
                        </Text>
                        <TextInput
                          data-testid="popup-form-title"
                          containerStyle={styles.flex}
                          value={title}
                          onChangeText={setTitle}
                          maxLength={20}
                          maxLengthInside
                          disabled={popUpType === 'image'}
                        />
                      </View>
                      <View style={[styles.row, styles.textLine]}>
                        <Text size="small" style={styles.fieldLabel}>
                          {t(['Teks baris 1', 'Text line 1'])}
                        </Text>
                        <TextInput
                          data-testid="popup-form-line1"
                          containerStyle={styles.flex}
                          value={lineOne}
                          onChangeText={setLineOne}
                          maxLength={35}
                          maxLengthInside
                          disabled={popUpType === 'image'}
                        />
                      </View>
                      <View style={[styles.row, styles.textLine]}>
                        <Text size="small" style={styles.fieldLabel}>
                          {t(['Teks baris 2', 'Text line 2'])}
                        </Text>
                        <TextInput
                          data-testid="popup-form-line2"
                          containerStyle={styles.flex}
                          value={lineTwo}
                          onChangeText={setLineTwo}
                          maxLength={35}
                          maxLengthInside
                          disabled={popUpType === 'image'}
                        />
                      </View>
                      <View style={[styles.row, styles.textLine]}>
                        <Text size="small" style={styles.fieldLabel}>
                          {t(['Teks baris 3', 'Text line 3'])}
                        </Text>
                        <TextInput
                          data-testid="popup-form-line3"
                          containerStyle={styles.flex}
                          value={lineThree}
                          onChangeText={setLineThree}
                          maxLength={35}
                          maxLengthInside
                          disabled={popUpType === 'image'}
                        />
                      </View>
                    </View>
                  </View>
                ),
              },
              {
                'data-testid': 'popup-form-type-image',
                value: 'image',
                label: (
                  <View style={styles.row}>
                    <View style={[styles.flex, styles.column]}>
                      <Dropzone
                        data-testid="popup-form-image"
                        withUploadText
                        type="image"
                        source={image}
                        getPreview={setImage}
                        containerStyle={styles.popUpImage}
                        disabled={popUpType === 'text'}
                      />
                    </View>
                    <View style={styles.flex} />
                  </View>
                ),
              },
            ]}
          />
        </View>
        <View style={styles.right}>
          <StepHeader step={3} style={styles.stepHeader} />
          <View style={styles.row}>
            <View style={[styles.row, styles.left, styles.column]}>
              <Text style={styles.dropdownLabel}>
                {t(['Tipe Tautan', 'Link Type'])}
              </Text>
              <View style={styles.flex}>
                <View style={styles.textLine}>
                  <Dropdown
                    data-testid="popup-form-link"
                    title=""
                    type="basic"
                    options={linkTypeOptions}
                    selectedOption={linkType ?? undefined}
                    onSelect={createSetSelected(setLinkType)}
                  />
                </View>
                {renderLinkInputs()}
              </View>
            </View>
            <View style={[styles.row, styles.right, styles.column]}>
              <Text style={styles.dropdownLabel}>{t(['Warna', 'Color'])}</Text>
              <View style={styles.flex}>
                <Dropdown
                  data-testid="popup-form-color"
                  title=""
                  type="basic"
                  options={colorOptions}
                  selectedOption={color ?? undefined}
                  onSelect={createSetSelected(setColor)}
                  disabled={popUpType === 'image'}
                />
              </View>
            </View>
            <Button
              data-testid="popup-form-submit"
              isLoading={createLoading || updateLoading || uploadLoading}
              title={t(['Kirim', 'Submit'])}
              disabled={submitDisabled}
              onPress={onSubmitPopUp}
              style={styles.submitButton}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  submitButton: { width: 73 }, // NOTE: so that the button doesn't change width when loading is true
  header: { paddingBottom: spacing.large },
  flex: { flex: 1 },
  row: { flexDirection: 'row' },
  name: { paddingBottom: spacing.large },
  left: { flex: 3 },
  right: { flex: 2 },
  popUpBox: {
    height: 150,
    width: 250,
    borderWidth: 1,
    borderRadius: 0,
    borderColor: colors.border.dark,
  },
  popUpImage: {
    height: 150,
    width: 248,
    borderWidth: 1,
    borderRadius: 0,
    borderColor: colors.border.dark,
  },
  textPopUpBox: {
    paddingHorizontal: spacing.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdownLabel: { paddingTop: spacing.xsmall, paddingRight: spacing.xsmall },
  fieldLabel: { alignSelf: 'center', width: 80 },
  popUpBoxTitle: { paddingBottom: spacing.xsmall },
  textLine: { paddingBottom: spacing.xxsmall },
  column: { paddingRight: spacing.small },
  stepHeader: { marginBottom: spacing.small },
});

export default CustomPopUpForm;
