import React, { useState, useMemo, useCallback, useEffect, memo } from 'react';
import {
  View,
  StyleSheet,
  ViewProps,
  ActivityIndicator,
  Dimensions,
} from 'react-native';
import { VariableSizeList } from 'react-window';
import {
  MenuItem,
  MenuList,
  makeStyles,
  Popover,
  Button,
  Popper,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CSSProperties } from '@material-ui/styles';
import { ApolloError } from 'apollo-client';

import ErrorMessage from '../ErrorMessage';
import { colors, theme, spacing } from '../../constants/theme';
import { Text, TextInput } from '../../core-ui';

export type Option = {
  label: string;
  value: string;
  subLabel?: string;
  multiLevelValue?: Array<Option>;
  subValue?: string;
};
export type Props = {
  'data-testid'?: string;
  error?: ApolloError;
  errorAction?: string;
  onRetryPress?: () => void;
  multiLevelError?: Array<ApolloError | undefined>;
  multiLevelErrorAction?: Array<string>;
  multiLevelOnRetryPress?: Array<() => void>;
  dataKey?: 'label' | 'value' | 'subValue';
  multiple?: boolean;
  title: string;
  options: Array<Option>;
  selectedOption?: string | null;
  selectedOptions?: Array<string>;
  onSelect: (item: Option, level: number) => void;
  getSelectedValues?: (values: Array<string>) => void;
  getMultiLevelOptions?: (multiLevelOptions: Array<string>) => void;
  disabled?: boolean;
  style?: ViewProps['style'];
  labelStyle?: ViewProps['style'];
  subLabelStyle?: ViewProps['style'];
  type?: 'default' | 'side' | 'basic';
  loading?: boolean;
  levelOneLoading?: boolean;
  levelTwoLoading?: boolean;
  selectedMultiLevelValue?: Array<string>;
  searchable?: boolean;
};

const SEARCH_PAPER_HEIGHT = 201;

let useStyles = makeStyles({
  defaultButton: {
    borderRadius: 0,
    backgroundColor: colors.dropdown.primary.background,
  },
  buttonText: {
    justifyContent: 'flex-start',
    textTransform: 'none',
  },
  sideButton: {
    borderRadius: 0,
    marginRight: theme.spacing.medium,
  },
  buttonLabel: {
    alignItems: 'center',
  },
  defaultMenuItem: {
    textAlign: 'left',
  },
  selectedMenuItem: {
    color: colors.dropdown.primary.selectedItem,
  },
  basicButton: {
    borderRadius: 0,
    border: `1px solid ${colors.dropdown.basic.border}`,
  },
  popper: {
    backgroundColor: colors.popover.secondary.background,
    boxShadow: `3px 3px 15px 3px ${colors.popover.secondary.shadow}`,
    borderRadius: 3,
    marginLeft: theme.spacing.xsmall,
  },
  popperTwo: {
    backgroundColor: colors.popover.secondary.background,
    boxShadow: `3px 3px 15px 3px ${colors.popover.secondary.shadow}`,
    borderRadius: 3,
    marginRight: theme.spacing.xsmall,
  },
  searchablePaper: { maxHeight: SEARCH_PAPER_HEIGHT, overflow: 'hidden' },
});

const windowHeight = Dimensions.get('window').height;
const getTextNumberOfLines = Object.assign(
  // NOTE: when using 14px the canvas measurement results in smaller width, so bump it a bit
  (text: string, width: number, font = '14.7px Roboto'): number => {
    let canvas = getTextNumberOfLines.canvas;
    let context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      context.textAlign = 'left';
      context.textBaseline = 'top';
      let metrics = context.measureText(text);
      return Math.ceil(metrics.width / width);
    }
    return 1;
  },
  // NOTE: create canvas object as a property to re-use
  { canvas: document.createElement('canvas') },
);

const Dropdown = memo((props: Props) => {
  //NOTE: didn't handle multilevel dropdown on side dropdown
  let {
    error,
    errorAction,
    onRetryPress,
    multiLevelError,
    multiLevelErrorAction,
    multiLevelOnRetryPress,
    dataKey = 'value',
    multiple,
    title,
    options,
    selectedOptions,
    onSelect,
    getSelectedValues,
    getMultiLevelOptions,
    selectedOption,
    type = 'default',
    style,
    labelStyle,
    subLabelStyle,
    disabled,
    loading,
    levelOneLoading,
    levelTwoLoading,
    searchable,
  } = props;
  const [searchKeyword, setSearchKeyword] = useState('');
  let [compWidth, setCompWidth] = useState(0);
  let [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  let [level2Anchor, setLevel2Anchor] = useState<HTMLLIElement | null>(null);
  let [level3Anchor, setLevel3Anchor] = useState<HTMLLIElement | null>(null);
  const [levelOneSelected, setLevelOneSelected] = useState<string>('');
  const [levelTwoSelected, setLevelTwoSelected] = useState<string>('');
  // const [levelThreeSelected, setLevelThreeSelected] = useState<string>('');
  const [selected, setSelected] = useState<Array<Option> | null>(null);
  const [levelOneSelectedIndex, setLevelOneSelectedIndex] = useState<
    number | null
  >(null);
  const [levelTwoSelectedIndex, setLevelTwoSelectedIndex] = useState<
    number | null
  >(null);
  // NOTE: haven't implemented Virtualized List for the first and second level of dropdowns as there's an issue with anchorElement and getting the correct ref
  // const [firstHeights, setFirstHeights] = useState<Array<number | undefined>>(
  //   [],
  // );
  // const [secondHeights, setSecondHeights] = useState<Array<number | undefined>>(
  //   [],
  // );
  const [thirdHeights, setThirdHeights] = useState<Array<number | undefined>>(
    [],
  );

  const filterBySearchOptions = useMemo(() => {
    if (searchable && searchKeyword) {
      return options.filter(({ label }) =>
        label.toLowerCase().includes(searchKeyword.toLowerCase()),
      );
    }
    return options;
  }, [options, searchable, searchKeyword]);

  const labelStyleFlex = useMemo(() => {
    if (labelStyle) {
      return StyleSheet.flatten(labelStyle).flex ?? 1;
    }
    return 1;
  }, [labelStyle]);
  const subLabelStyleFlex = useMemo(() => {
    if (subLabelStyle) {
      return StyleSheet.flatten(subLabelStyle).flex ?? 0;
    }
    return 0;
  }, [subLabelStyle]);
  const listWidth = useMemo(() => compWidth - 32, [compWidth]); // NOTE: minus padding

  const generateHeightListFromOptions = useCallback(
    (optionList: Array<Option>) => {
      let heightList: Array<number> = [];
      for (let option of optionList) {
        if (subLabelStyleFlex) {
          const labelNumberOfLines = getTextNumberOfLines(
            option.label,
            (listWidth * labelStyleFlex) / (labelStyleFlex + subLabelStyleFlex),
          );
          const subLabelNumberOfLines = getTextNumberOfLines(
            option.subLabel ?? '',
            (listWidth * subLabelStyleFlex) /
              (labelStyleFlex + subLabelStyleFlex),
          );
          heightList.push(
            12 + Math.max(labelNumberOfLines, subLabelNumberOfLines) * 19,
          ); // NOTE: 19 because lineHeight is 19
        } else {
          heightList.push(
            12 + getTextNumberOfLines(option.label, listWidth) * 19,
          );
        }
      }
      return heightList;
    },
    [listWidth, labelStyleFlex, subLabelStyleFlex],
  );

  useEffect(() => {
    if (levelOneSelectedIndex != null && levelTwoSelectedIndex != null) {
      let firstSelection = options[levelOneSelectedIndex];
      if (firstSelection?.multiLevelValue) {
        let secondSelection =
          firstSelection.multiLevelValue[levelTwoSelectedIndex];
        if (secondSelection?.multiLevelValue) {
          setThirdHeights(
            generateHeightListFromOptions(secondSelection.multiLevelValue),
          );
        }
      }
    }
  }, [
    options,
    levelOneSelectedIndex,
    levelTwoSelectedIndex,
    generateHeightListFromOptions,
  ]);

  const calculateHeights = useCallback(
    (heights: Array<number | undefined>, max: number = windowHeight) => {
      let total = 0;
      for (let height of heights) {
        if (height) {
          total += height;
        }
        if (total >= max) {
          return max;
        }
      }
      return total;
    },
    [],
  );

  // const firstListHeight = useMemo(() => {
  //   return calculateHeights(firstHeights);
  // }, [firstHeights, calculateHeights]);

  // const secondListHeight = useMemo(() => {
  //   return calculateHeights(secondHeights);
  // }, [secondHeights, calculateHeights]);

  const thirdListHeight = useMemo(() => {
    return calculateHeights(thirdHeights, 400);
  }, [thirdHeights, calculateHeights]);

  useEffect(() => {
    if (!selectedOption) {
      setSelected(null);
      return;
    }
    for (let optionOne of options) {
      let { multiLevelValue: multiLevelValueOne } = optionOne;
      if (optionOne[dataKey] === selectedOption) {
        setLevelOneSelected(optionOne.value);
        getMultiLevelOptions?.([optionOne.value]);
        setSelected([optionOne]);
        getSelectedValues?.([optionOne.value]);
      } else if (multiLevelValueOne) {
        for (let optionTwo of multiLevelValueOne) {
          let { multiLevelValue: multiLevelValueTwo } = optionTwo;
          if (optionTwo[dataKey] === selectedOption) {
            setLevelTwoSelected(optionTwo.value);
            setLevelOneSelected(optionOne.value);
            getMultiLevelOptions?.([optionOne.value, optionTwo.value]);
            setSelected([optionOne, optionTwo]);
          } else if (multiLevelValueTwo) {
            for (let optionThree of multiLevelValueTwo) {
              if (optionThree[dataKey] === selectedOption) {
                // setLevelThreeSelected(optionThree.value);
                setLevelTwoSelected(optionTwo.value);
                setLevelOneSelected(optionOne.value);
                getMultiLevelOptions?.([
                  optionOne.value,
                  optionTwo.value,
                  optionThree.value,
                ]);
                setSelected([optionOne, optionTwo, optionThree]);
              }
            }
          }
        }
      }
    }
  }, [
    dataKey,
    setSelected,
    selectedOption,
    setLevelOneSelected,
    setLevelTwoSelected,
    // setLevelThreeSelected,
    getMultiLevelOptions,
    getSelectedValues,
    options,
  ]);

  let handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  let handleClose = useCallback(() => {
    setAnchorEl(null);
    setLevel2Anchor(null);
    setLevel3Anchor(null);
    setSearchKeyword('');
  }, [setAnchorEl, setLevel2Anchor, setLevel3Anchor]);
  let handleSelect = useCallback(
    (item: Option, event: React.MouseEvent<HTMLLIElement>, index: number) => {
      if (multiple) {
        onSelect(item, 1);
      } else {
        if (item.multiLevelValue) {
          setLevel2Anchor(event.currentTarget);
          if (levelOneSelected !== item.value) {
            getMultiLevelOptions?.([item.value]);
            onSelect(item, 1);
            setLevelOneSelected(item.value);
            setLevelOneSelectedIndex(index);
            setLevel3Anchor(null);
          }
        } else {
          onSelect(item, 1);
          setAnchorEl(null);
          setLevel2Anchor(null);
          setLevel3Anchor(null);
        }
      }
    },
    [
      setLevel2Anchor,
      onSelect,
      setAnchorEl,
      multiple,
      getMultiLevelOptions,
      levelOneSelected,
    ],
  );

  let handleLevel2Select = (
    item: Option,
    event: React.MouseEvent<HTMLLIElement>,
    index: number,
  ) => {
    event.stopPropagation();
    if (item.multiLevelValue) {
      setLevel3Anchor(event.currentTarget);
      if (levelTwoSelected !== item.value) {
        getMultiLevelOptions?.([levelOneSelected, item.value]);
        onSelect(item, 2);
        setLevel3Anchor(event.currentTarget);
        setLevelTwoSelected(item.value);
        setLevelTwoSelectedIndex(index);
      }
    } else {
      onSelect(item, 2);
      setAnchorEl(null);
      setLevel2Anchor(null);
    }
  };

  let handleLevel3Select = (
    item: Option,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    event.stopPropagation();
    getMultiLevelOptions?.([levelOneSelected, levelTwoSelected, item.value]);
    // setLevelThreeSelected(item.value);
    onSelect(item, 3);
    setAnchorEl(null);
    setLevel2Anchor(null);
    setLevel3Anchor(null);
  };

  let open = !!anchorEl;
  let classes = useStyles();
  let rootClass;

  const divisor = useMemo(() => (type === 'side' ? 2 : 1), [type]);
  const setComponentWidth: ViewProps['onLayout'] = (e) => {
    if (!compWidth) {
      setCompWidth(e.nativeEvent.layout.width / divisor);
    }
  };

  if (type === 'default') {
    rootClass = classes.defaultButton;
  } else if (type === 'side') {
    rootClass = classes.sideButton;
  } else {
    rootClass = classes.basicButton;
  }

  const multipleSelectedOptions = useMemo(() => {
    let multipleSelectedOpts =
      (selectedOptions
        ?.map((selected: string) =>
          options.find((opt) => opt[dataKey] === selected),
        )
        .filter((v) => !!v) as Array<Option>) ?? [];
    getSelectedValues?.(multipleSelectedOpts.map((opt) => opt?.value));
    return multipleSelectedOpts;
  }, [dataKey, options, selectedOptions, getSelectedValues]);

  const multipleSelected = useMemo(
    () => multipleSelectedOptions.map((opt) => opt?.label),
    [multipleSelectedOptions],
  );

  const displayedTitle = useMemo(() => {
    if (multipleSelected.length) {
      return multipleSelected.join(', ');
    } else if (selected && !selected[selected.length - 1].multiLevelValue) {
      return selected[selected.length - 1].label;
    } else {
      return title;
    }
  }, [selected, multipleSelected, title]);

  const checkSingleSelected = useCallback(
    (item: Option) => selectedOption === item[dataKey],
    [selectedOption, dataKey],
  );

  const checkMultipleSelected = useCallback(
    (item: Option) => !!selectedOptions?.includes(item[dataKey] ?? ''),
    [selectedOptions, dataKey],
  );

  return (
    <>
      <View style={styles.rowView} onLayout={setComponentWidth}>
        <Button
          style={
            StyleSheet.flatten([{ width: compWidth }, style]) as CSSProperties
          }
          classes={{
            text: classes.buttonText,
            label: classes.buttonLabel,
            root: rootClass,
          }}
          onClick={handleClick}
          disabled={disabled}
          data-testid={props['data-testid']}
        >
          <>
            {type !== 'side' ? (
              <>
                <View style={[styles.flex, labelStyle]}>
                  <Text
                    numberOfLines={1}
                    bold={type === 'default'}
                    color={type === 'default' ? 'link' : 'default'}
                    style={styles.leftText}
                  >
                    {displayedTitle}
                  </Text>
                </View>
                {selected?.[selected.length - 1].subLabel && (
                  <View style={subLabelStyle}>
                    <Text
                      numberOfLines={1}
                      bold={type === 'default'}
                      color={type === 'default' ? 'link' : 'default'}
                      style={styles.leftText}
                    >
                      {selected?.[selected.length - 1].subLabel}
                    </Text>
                  </View>
                )}
                <ExpandMoreIcon color="action" fontSize="default" />
              </>
            ) : (
              <>
                <SearchIcon color="inherit" fontSize="default" />
                <Text
                  numberOfLines={1}
                  style={[styles.flex, styles.leftText, styles.sideTitle]}
                >
                  {title}
                </Text>
                <ExpandMoreIcon color="inherit" fontSize="default" />
              </>
            )}
            {disabled && <View style={styles.disabled} />}
          </>
        </Button>
        {type === 'side' && (
          <Text style={styles.text}>
            {selected?.[selected.length - 1].label}
          </Text>
        )}
      </View>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: type !== 'side' ? 'bottom' : 'top',
          horizontal: type !== 'side' ? 'center' : 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: type !== 'side' ? 'center' : 'left',
        }}
        classes={{ paper: searchable ? classes.searchablePaper : '' }}
      >
        {searchable && (
          <View>
            <TextInput
              autoFocus
              value={searchKeyword}
              onChangeText={setSearchKeyword}
            />
          </View>
        )}
        <View style={searchable ? styles.searchableList : undefined}>
          <MenuList>
            {loading ? (
              <View style={{ width: compWidth }}>
                <ActivityIndicator />
              </View>
            ) : error || multiLevelError?.[0] ? (
              <View style={[styles.error, { width: compWidth }]}>
                <ErrorMessage
                  error={error || multiLevelError?.[0]}
                  action={errorAction || multiLevelErrorAction?.[0]}
                  onPress={onRetryPress || multiLevelOnRetryPress?.[0]}
                />
              </View>
            ) : filterBySearchOptions.length === 0 ? (
              <View
                style={{ width: compWidth }}
                data-testid={`${props['data-testid']}-no-data`}
              >
                <Text style={styles.emptyText}>
                  {t(['Tidak ada data', 'No data available'])}
                </Text>
              </View>
            ) : (
              filterBySearchOptions.map((item, index) => {
                let checked = multiple
                  ? checkMultipleSelected(item)
                  : checkSingleSelected(item);
                return (
                  <MenuItem
                    data-testid={`${props['data-testid']}`}
                    key={index}
                    style={
                      StyleSheet.flatten([
                        { width: compWidth },
                        style,
                      ]) as CSSProperties
                    }
                    classes={{
                      root: type !== 'side' ? classes.defaultMenuItem : '',
                      selected: classes.selectedMenuItem,
                    }}
                    value={item.value}
                    onClick={(event) => handleSelect(item, event, index)}
                    selected={selected?.[0].value === item.value}
                  >
                    {item.multiLevelValue ? (
                      <View style={styles.multiLevelItem}>
                        <View style={[styles.flex, labelStyle]}>
                          <Text
                            color={
                              selected?.[0].value === item.value
                                ? 'link'
                                : 'default'
                            }
                            style={styles.leftText}
                          >
                            {item.label}
                          </Text>
                        </View>
                        <ChevronRightIcon color="inherit" fontSize="default" />
                      </View>
                    ) : (
                      <>
                        <View style={[styles.flex, labelStyle]}>
                          <Text
                            color={
                              selected?.[0].value === item.value
                                ? 'link'
                                : 'default'
                            }
                          >
                            {item.label}
                          </Text>
                        </View>
                        <View style={subLabelStyle}>
                          <Text
                            color={
                              selected?.[0].value === item.value
                                ? 'link'
                                : 'default'
                            }
                          >
                            {item.subLabel}
                          </Text>
                        </View>
                      </>
                    )}
                    <Popper
                      open={item.value === levelOneSelected && !!level2Anchor}
                      anchorEl={level2Anchor}
                      placement="right-start"
                      style={{ zIndex: 1301 }} // Popover has zIndex 1300
                      className={classes.popper}
                      modifiers={{
                        preventOverflow: {
                          enabled: true,
                          boundariesElement: 'viewport',
                          padding: spacing.xsmall,
                        },
                      }}
                    >
                      <MenuList>
                        {levelOneLoading ? (
                          <View style={{ width: compWidth }}>
                            <ActivityIndicator />
                          </View>
                        ) : multiLevelError?.[1] ? (
                          <View style={[styles.error, { width: compWidth }]}>
                            <ErrorMessage
                              error={multiLevelError?.[1]}
                              action={multiLevelErrorAction?.[1]}
                              onPress={multiLevelOnRetryPress?.[1]}
                            />
                          </View>
                        ) : (
                          item.multiLevelValue?.map(
                            (item: Option, index: number) => (
                              <View key={index}>
                                <MenuItem
                                  data-testid={`${props['data-testid']}`}
                                  onClick={(event) => {
                                    handleLevel2Select(item, event, index);
                                  }}
                                  style={
                                    StyleSheet.flatten([
                                      { width: compWidth },
                                      style,
                                    ]) as CSSProperties
                                  }
                                  classes={{
                                    root:
                                      type !== 'side'
                                        ? classes.defaultMenuItem
                                        : '',
                                    selected: classes.selectedMenuItem,
                                  }}
                                  key={index}
                                  value={item.value}
                                  selected={selected?.[1]?.value === item.value}
                                >
                                  {item.multiLevelValue ? (
                                    <View style={styles.multiLevelItem}>
                                      <View style={[styles.flex, labelStyle]}>
                                        <Text
                                          color={
                                            selected?.[1]?.value === item.value
                                              ? 'link'
                                              : 'default'
                                          }
                                          style={styles.leftText}
                                        >
                                          {item.label}
                                        </Text>
                                      </View>
                                      <ChevronRightIcon
                                        color="inherit"
                                        fontSize="default"
                                      />
                                    </View>
                                  ) : (
                                    <>
                                      <View style={[styles.flex, labelStyle]}>
                                        <Text
                                          color={
                                            selected?.[1]?.value === item.value
                                              ? 'link'
                                              : 'default'
                                          }
                                        >
                                          {item.label}
                                        </Text>
                                      </View>
                                      <View style={subLabelStyle}>
                                        <Text
                                          color={
                                            selected?.[1]?.value === item.value
                                              ? 'link'
                                              : 'default'
                                          }
                                        >
                                          {item.subLabel}
                                        </Text>
                                      </View>
                                    </>
                                  )}
                                </MenuItem>
                                <Popper
                                  disablePortal
                                  open={
                                    item.value === levelTwoSelected &&
                                    !!level3Anchor
                                  }
                                  anchorEl={level3Anchor}
                                  placement="left-start"
                                  className={classes.popperTwo}
                                  modifiers={{
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: 'viewport',
                                      padding: spacing.xsmall,
                                    },
                                  }}
                                >
                                  <MenuList>
                                    {levelTwoLoading ? (
                                      <View style={{ width: compWidth }}>
                                        <ActivityIndicator />
                                      </View>
                                    ) : (
                                      <VariableSizeList
                                        itemData={item.multiLevelValue ?? []}
                                        width={compWidth}
                                        height={thirdListHeight}
                                        itemCount={
                                          item.multiLevelValue?.length ?? 0
                                        }
                                        itemSize={(index) =>
                                          thirdHeights[index] ?? 31
                                        }
                                      >
                                        {({
                                          index: thirdIndex,
                                          style: thirdListStyle,
                                          data: thirdData,
                                        }: {
                                          index: number;
                                          style: React.CSSProperties;
                                          data: Array<Option>;
                                        }) => {
                                          let thirdItem = thirdData[thirdIndex];
                                          return (
                                            <MenuItem
                                              data-testid={`${props['data-testid']}`}
                                              key={thirdIndex}
                                              onClick={(event) =>
                                                handleLevel3Select(
                                                  thirdItem,
                                                  event,
                                                )
                                              }
                                              style={
                                                StyleSheet.flatten([
                                                  { width: compWidth },
                                                  style,
                                                  thirdListStyle,
                                                ]) as CSSProperties
                                              }
                                              classes={{
                                                root:
                                                  type !== 'side'
                                                    ? classes.defaultMenuItem
                                                    : '',
                                                selected:
                                                  classes.selectedMenuItem,
                                              }}
                                              value={thirdItem.value}
                                              selected={
                                                selected?.[2]?.value ===
                                                thirdItem.value
                                              }
                                            >
                                              <View
                                                style={[
                                                  styles.flex,
                                                  labelStyle,
                                                ]}
                                              >
                                                <Text
                                                  color={
                                                    selected?.[2]?.value ===
                                                    thirdItem.value
                                                      ? 'link'
                                                      : 'default'
                                                  }
                                                >
                                                  {thirdItem.label}
                                                </Text>
                                              </View>
                                              <View style={subLabelStyle}>
                                                <Text
                                                  color={
                                                    selected?.[2]?.value ===
                                                    thirdItem.value
                                                      ? 'link'
                                                      : 'default'
                                                  }
                                                >
                                                  {thirdItem.subLabel}
                                                </Text>
                                              </View>
                                            </MenuItem>
                                          );
                                        }}
                                      </VariableSizeList>
                                    )}
                                  </MenuList>
                                </Popper>
                              </View>
                            ),
                          )
                        )}
                      </MenuList>
                    </Popper>
                    {multiple && checked && (
                      <DoneIcon
                        htmlColor={colors.icon.primary}
                        style={StyleSheet.flatten(styles.checkIcon)}
                      />
                    )}
                  </MenuItem>
                );
              })
            )}
          </MenuList>
        </View>
      </Popover>
    </>
  );
});

let styles = StyleSheet.create({
  disabled: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.dropdown.disabled,
  },
  rowView: { flexDirection: 'row', minHeight: 36 },
  multiLevelItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  text: { alignSelf: 'center' },
  leftText: { textAlign: 'left' },
  flex: { flex: 1 },
  sideTitle: { paddingLeft: spacing.small },
  checkIcon: { width: 19, height: 19 },
  emptyText: { textAlign: 'center' },
  error: { padding: spacing.xsmall },
  searchableList: { maxHeight: SEARCH_PAPER_HEIGHT - 37, overflow: 'scroll' },
});

export default Dropdown;
