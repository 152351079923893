import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_APP_USERS } from '../../graphql/queries';
import { AppUsers } from '../../generated/AppUsers';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Pengguna', 'Select Users']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  'data-testid'?: string;
  title?: string;
};

const UsersDropdown = memo((props: Props) => {
  let { title = defaultTitle, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<AppUsers>(GET_APP_USERS, {
    notifyOnNetworkStatusChange: true,
  });
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      data?.appUserAPI.map(({ name }) => ({ label: name, value: name })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t([
        'mengambil data pengguna toko app',
        'retrieve the toko app user data',
      ])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default UsersDropdown;
