import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  StyleSheet,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';

import { Table, Text } from '../../core-ui';
import { ErrorMessage, Modal, TextPopover, ErrorModal } from '../../components';
import { theme } from '../../constants/theme';
import { GET_SLIDERS } from '../../graphql/queries';
import { ARCHIVE_SLIDER } from '../../graphql/mutations';
import { useTableSort } from '../../hooks';
import {
  ArchiveStatus,
  CardSliderOrderByType,
  Time,
} from '../../generated/globalTypes';
import { SliderFragment } from '../../generated/SliderFragment';
import { Sliders, SlidersVariables } from '../../generated/Sliders';
import {
  ArchiveCardSlider,
  ArchiveCardSliderVariables,
} from '../../generated/ArchiveCardSlider';
import ConfirmationModal from '../../components/ConfirmationModal';

type Props = {
  searchArchived?: boolean;
  searchByUser?: string;
  searchByBrand?: string;
  searchByRegion?: string;
  searchByCity?: string;
  searchByTime?: Time;
  searchByPromotion?: string;
  searchByDateFrom?: Date;
  searchByDateUntil?: Date;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: SlidersVariables;
  onArchive?: (id?: string | null) => void;
  setEditSlider?: (value: SliderFragment | null) => void;
};

const HomepageSliderTable = (props: Props) => {
  let {
    searchArchived,
    searchByUser,
    searchByBrand,
    searchByRegion,
    searchByCity,
    searchByTime,
    searchByPromotion,
    searchByDateFrom,
    searchByDateUntil,
    refetchToggle,
    hideEdit,
    queryVariables,
    onArchive: onArchiveCallback,
    setEditSlider,
  } = props;

  // NOTE: to display the artwork modal
  const [viewArtwork, setViewArtwork] = useState<string | null>(null);
  // NOTE: data to be archived/unarchived
  const [archiveData, setArchiveData] = useState<SliderFragment | null>(null);
  // NOTE: data to be archived when there is a conflict trying to unarchive
  const [existingData, setExistingData] = useState<SliderFragment | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);
  const openArtworkModal = useCallback((link: string) => setViewArtwork(link), [
    setViewArtwork,
  ]);
  const closeArtworkModal = useCallback(() => setViewArtwork(null), [
    setViewArtwork,
  ]);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    CardSliderOrderByType
  >();

  const { data, loading, error, refetch } = useQuery<Sliders, SlidersVariables>(
    GET_SLIDERS,
    {
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
        archiveStatus: ArchiveStatus.NORMAL,
        orderBy: orderByVariable,
        ...queryVariables,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  // NOTE: the query below is used to handle unarchive conflict
  const [
    getSlider,
    { loading: loadingSearch, refetch: refetchSearch },
  ] = useLazyQuery<Sliders, SlidersVariables>(GET_SLIDERS, {
    notifyOnNetworkStatusChange: true,
  });

  const refetchSliders = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            user: searchByUser,
            region: searchByRegion,
            city: searchByCity,
            cardSliderName: searchByPromotion,
            brandId: searchByBrand,
            timeActive: searchByTime,
            date:
              searchByDateFrom && searchByDateUntil
                ? {
                    startDate: searchByDateFrom,
                    endDate: searchByDateUntil,
                  }
                : undefined,
            archiveStatus: searchArchived ? undefined : ArchiveStatus.NORMAL,
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [
      searchArchived,
      searchByUser,
      searchByBrand,
      searchByRegion,
      searchByCity,
      searchByTime,
      searchByPromotion,
      searchByDateFrom,
      searchByDateUntil,
      queryVariables,
      refetch,
      page,
      rowsPerPage,
    ],
  );

  const refetchCurrentPage = useCallback(() => {
    refetchSliders(page * rowsPerPage);
  }, [refetchSliders, page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [
    searchArchived,
    searchByUser,
    searchByBrand,
    searchByRegion,
    searchByCity,
    searchByTime,
    searchByPromotion,
    searchByDateFrom,
    searchByDateUntil,
  ]);

  useEffect(() => getSlider(), [getSlider]);

  useEffect(() => refetchSliders(), [refetchToggle, refetchSliders]);

  const [
    archive,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchiveCardSlider, ArchiveCardSliderVariables>(
    ARCHIVE_SLIDER,
    {
      onCompleted: () => {
        refetchCurrentPage();
        onArchiveCallback?.(archiveData?.id);
      },
      onError: openErrorModal,
    },
  );

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const onArchivePress = useCallback(
    async (slider: SliderFragment) => {
      setArchiveData(slider);
      // NOTE: if slider is archived, need to check for conflicts with active slider
      if (slider.status === ArchiveStatus.ARCHIVED) {
        let { data: existing } = await refetchSearch({
          timeActive: slider.timeActive,
          numberOrder: slider.numberOrder,
          archiveStatus: ArchiveStatus.NORMAL,
        });
        // NOTE: if conflict is found, save the conflicting data and stop this process
        if (existing.cardSliderAdvanceSearch.row.length > 0) {
          setExistingData(existing.cardSliderAdvanceSearch.row[0]);
          return;
        }
      }
      await archive({ variables: { cardSliderId: slider.id } });
      setArchiveData(null);
    },
    [archive, refetchSearch],
  );

  const timeActiveToString = useCallback((time: unknown) => {
    switch (time as Time) {
      case Time.ALL_DAY:
        return t(['Sepanjang hari', 'All day']);
      case Time.MORNING:
        return t(['Pagi', 'Morning']);
      case Time.DAY:
        return t(['Siang', 'Day']);
      case Time.EVENING:
        return t(['Malam', 'Evening']);
    }
  }, []);
  const stringToDate = useCallback(
    (isoStringDate: unknown) => new Date(isoStringDate as string),
    [],
  );

  const closeExistingData = useCallback(() => setExistingData(null), []);
  const confirmExistingData = useCallback(async () => {
    if (existingData && archiveData) {
      await Promise.all([
        archive({ variables: { cardSliderId: existingData.id } }), // NOTE: archive the conflicting active slider
        archive({ variables: { cardSliderId: archiveData.id } }), // NOTE: unarchive the slider in request
      ]);
      setExistingData(null);
      setArchiveData(null);
      setEditSlider?.(archiveData);
    }
  }, [existingData, archiveData, archive, setEditSlider]);

  const onViewPress = useCallback(
    (slider: SliderFragment) => {
      if (setEditSlider) {
        setEditSlider(slider);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditSlider],
  );

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : ({
            action: {
              headerTitle: t(['aksi', 'action']),
              headerTitleColor: 'link',
              displayText: t(['ubah', 'edit']),
              disabled: ({ status }: SliderFragment) =>
                status === ArchiveStatus.ARCHIVED,
              onPress: onViewPress,
            },
          } as const),
    [hideEdit, onViewPress],
  );

  return (
    <>
      <Modal open={!!viewArtwork} onClose={closeArtworkModal} title=" ">
        {viewArtwork && (
          <Image
            source={{ uri: viewArtwork }}
            style={{ width: 400, height: 400 }} // TODO: get proper image size
            resizeMode="contain"
          />
        )}
      </Modal>
      <ConfirmationModal
        data-testid="slider-unarchive"
        open={!!existingData}
        onClose={closeExistingData}
        onConfirm={confirmExistingData}
        title={t(
          [
            'Anda akan mengganti promosi yang sedang live pada waktu {timeActive} slot nomor {numberOrder}. Apakah anda yakin ingin melanjutkan?',
            'You are going to replace the current live promotion slider on {timeActive} slot number {numberOrder}. Are you sure you want to continue?',
          ],
          {
            timeActive: archiveData
              ? timeActiveToString(archiveData.timeActive)
              : '',
            numberOrder: archiveData?.numberOrder,
          },
        )}
      />
      <Table
        data-testid="slider-table"
        page={page}
        data={data?.cardSliderAdvanceSearch.row ?? []}
        loading={loading}
        error={!!error}
        dataCount={data?.cardSliderAdvanceSearch.total || 0}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        rowsPerPage={rowsPerPage}
        structure={{
          [CardSliderOrderByType.appUser]: {
            orderable: true,
            headerTitle: t(['pengguna', 'users']),
            render: ({ appUser }) => {
              if (appUser.length === 0) {
                return null;
              }

              let nameOnly = appUser.map(({ name }) => name);
              let label = nameOnly.slice(0, 3).join(', ');
              if (appUser.length <= 3) {
                return <Text>{label}</Text>;
              }

              label += t([', + {data} lainnya', ', + {data} others'], {
                data: appUser.length - 3,
              });

              return (
                <TextPopover label={label} content={nameOnly.join(', ')} />
              );
            },
          },
          [CardSliderOrderByType.city]: {
            orderable: true,
            headerTitle: t(['kota', 'city']),
            render: ({ city }) => {
              if (city.length === 0) {
                return null;
              }

              let nameOnly = city.map(({ name }) => name);
              let label = nameOnly.slice(0, 3).join(', ');
              if (city.length <= 3) {
                return <Text>{label}</Text>;
              }

              label += t([', + {data} lainnya', ', + {data} others'], {
                data: city.length - 3,
              });

              return (
                <TextPopover label={label} content={nameOnly.join(', ')} />
              );
            },
          },
          [CardSliderOrderByType.timeActive]: {
            headerTitle: t(['waktu', 'zone']),
            valuePreProcessor: timeActiveToString,
            orderable: true,
          },
          [CardSliderOrderByType.liveDate]: {
            headerTitle: t(['mulai', 'starts']),
            valuePreProcessor: stringToDate,
            orderable: true,
          },
          [CardSliderOrderByType.endLiveDate]: {
            headerTitle: t(['berakhir', 'ends']),
            valuePreProcessor: stringToDate,
            orderable: true,
          },
          [CardSliderOrderByType.name]: {
            headerTitle: t(['promosi', 'promotion']),
            orderable: true,
          },
          [CardSliderOrderByType.businessUnitContactName]: {
            headerTitle: t(['Principal', 'Principal']),
            dataPath: 'brand.businessUnitContact.name',
            orderable: true,
          },
          artwork: {
            headerTitle: t(['gambar', 'artwork']),
            displayText: t(['lihat', 'view']),
            onPress: ({ image }) => image && openArtworkModal(image),
          },
          ...action,
          archive: {
            headerTitle: t(['arsipkan', 'archive']),
            headerTitleColor: 'link',
            render: (slider) =>
              (slider.id === archiveData?.id ||
                slider.id === existingData?.id) &&
              (loadingSearch || archiveLoading) ? (
                <ActivityIndicator
                  data-testid="slider-archive-toggle-loading"
                  size="small"
                />
              ) : (
                <TouchableOpacity
                  data-testid="slider-archive-toggle"
                  onPress={() => onArchivePress(slider)}
                  style={styles.actionSpacing}
                >
                  {slider.status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon data-testid="slider-normal" />
                  ) : (
                    <FolderFilledIcon data-testid="slider-archived" />
                  )}
                </TouchableOpacity>
              ),
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          action={t([
            'mengambil data slider beranda',
            'retrieve the homepage slider data',
          ])}
          error={error}
          onPress={refetchSliders}
        />
      )}
      <ErrorModal
        action={t([
          'mengubah status arsip data slider beranda',
          'change archive status of the homepage slider data',
        ])}
        open={errorOpen}
        error={archiveError}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  actionSpacing: { paddingLeft: theme.spacing.xsmall },
});

export default HomepageSliderTable;
