import React, { memo } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { View, StyleSheet } from 'react-native';

import { Text, TextInput } from '../core-ui';
import { spacing } from '../constants/theme';
import type { Props as TextInputProps } from '../core-ui/TextInput';

type Props = Partial<TextInputProps> & {
  'data-testid'?: string;
  label: string;
  value: string;
  setValue: (value: string) => void;
};

const SearchByText = memo((props: Props) => {
  let { label, value, setValue, ...otherProps } = props;
  return (
    <View style={[styles.flex, styles.row, styles.root]}>
      <View style={[styles.flex, styles.row]}>
        <SearchIcon color="inherit" fontSize="default" />
        <Text style={styles.label}>{label}</Text>
      </View>
      <View style={styles.flex}>
        <TextInput
          data-testid={props['data-testid']}
          value={value}
          onChangeText={setValue}
          {...otherProps}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: { height: 36, paddingHorizontal: spacing.xsmall },
  flex: { flex: 1 },
  row: { flexDirection: 'row', alignItems: 'center' },
  label: { paddingLeft: spacing.small },
});

export default SearchByText;
