import React, { useMemo } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { Text, Button } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { BRANDS } from '../../graphql/queries';
import { Brands } from '../../generated/Brands';
import { ErrorMessage } from '../../components';
import { routePaths } from '../../constants/routes';

import BusinessUnitContacts from './BusinessUnitContacts';
import RequestAgentReportEmail from './RequestAgentReportEmail';
import SocialMediaLinks from './SocialMediaLinks';

type Props = { setPrincipalIncomplete: (value: boolean) => void };

export default function TokoAppContent({ setPrincipalIncomplete }: Props) {
  const history = useHistory();
  const goTo = (path: string) => history.push(path);

  const errorAction = useMemo(
    () => t(['mengambil data Brand', 'retrieve the Brand data']),
    [],
  );
  const { data, loading, error, refetch } = useQuery<Brands>(BRANDS, {
    notifyOnNetworkStatusChange: true,
  });

  return (
    <View>
      <Text
        data-testid="toko-app-content"
        bold
        style={[styles.header, styles.paddingBottom]}
      >
        {t(['Konten TOKO APP', 'TOKO APP Content'])}
      </Text>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['BERANDA BERITA DBO', 'HOMEPAGE DBO NEWS'])}</Text>
              <Button
                data-testid="dbo-news"
                preset="transparent"
                title={`+ / ${t(['Ubah', 'Edit'])}`}
                onPress={() => goTo(routePaths.dboNews)}
              />
            </View>
            <Text>
              {t(['Tambah & hapus berita', 'Add & delete news item'])}
            </Text>
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['SLIDER BERANDA', 'HOMEPAGE SLIDER'])}</Text>
              <Button
                data-testid="homepage-slider"
                preset="transparent"
                title={`+ / ${t(['Ubah', 'Edit'])}`}
                onPress={() => goTo(routePaths.homepageSlider)}
              />
            </View>
            <View style={[styles.row, styles.textSpacing]}>
              <Text style={styles.left}>
                {t(['Gambar slider', 'Slider artwork'])}:
              </Text>
              <Text style={styles.right}>
                {t(['hanya 5 x promo brand', '5 x brand promos only'])}
              </Text>
            </View>
            <View style={[styles.row, styles.textSpacing]}>
              <Text style={styles.left}>{t(['Lebar maks', 'Max width'])}:</Text>
              <Text style={styles.right}>
                {t(['hingga 900px', 'up to 900px wide'])}
              </Text>
            </View>
            <View style={[styles.row, styles.textSpacing]}>
              <Text style={styles.left}>
                {t(['Tinggi tetap', 'Fixed height'])}:
              </Text>
              <Text style={styles.right}>
                {t(['hanya 370px', 'only 370px high'])}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.left}>
                {t(['Format file', 'File format'])}:
              </Text>
              <Text style={styles.right}>.png</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['HALAMAN BRAND', 'BRAND PAGES'])}</Text>
              <Button
                data-testid="brand-pages"
                preset="transparent"
                title={`+ / ${t(['Ubah', 'Edit'])}`}
                onPress={() => goTo(routePaths.brandPages)}
              />
            </View>
            {loading && <ActivityIndicator />}
            {!!error && (
              <ErrorMessage
                // NOTE: since this error is specific for this section, we can hard code the error message into the prop
                action={errorAction}
                error={error}
                onPress={refetch}
              />
            )}
            {data && (
              <View style={[styles.row, styles.wrap]}>
                {data.brands.map(({ name }, index) => {
                  return (
                    <Text key={index} style={(styles.left, styles.brands)}>
                      {name.toUpperCase()}
                    </Text>
                  );
                })}
              </View>
            )}
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['HALAMAN PROMOSI', 'PROMOTIONS PAGE'])}</Text>
              <Button
                data-testid="promotions"
                preset="transparent"
                title={`+ / ${t(['Ubah', 'Edit'])}`}
                onPress={() => goTo(routePaths.promotions)}
              />
            </View>
            <Text>{t(['Ubah Promosi Brand', 'Change Brand Promotions'])}</Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={[styles.leftContainer, styles.section]}>
          <View style={styles.title}>
            <Text bold>{t(['PERPUSTAKAAN DOKUMEN', 'DOCUMENT LIBRARY'])}</Text>
            <Button
              data-testid="library"
              preset="transparent"
              title={`+ / ${t(['Ubah', 'Edit'])}`}
              onPress={() => goTo(routePaths.library)}
            />
          </View>
          <View style={[styles.row, styles.textSpacing]}>
            <Text style={styles.left}>{t(['Ukuran', 'Size'])}:</Text>
            <Text style={styles.right}>
              {t([
                'PDF A4 + thumbnail 65 x 35px',
                'A4 PDF’s + 65 x 35px thumbnails',
              ])}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.left}>
              {t(['Ukuran maks berkas', 'Max file size'])}:
            </Text>
            <Text style={styles.right}>8mb</Text>
          </View>
        </View>

        <View style={[styles.rightContainer, styles.section]}>
          <View style={styles.title}>
            <Text bold>
              {t(['PERPUSTAKAAN LOGO & ICON', 'LOCO & ICON LIBRARY'])}
            </Text>
            <Button
              data-testid="logo"
              preset="transparent"
              title={`+ / ${t(['Ubah', 'Edit'])}`}
              onPress={() => goTo(routePaths.logoLibrary)}
            />
          </View>

          <Text style={styles.textSpacing}>
            {t(['Semua logo brand', 'All brand logos'])}:
          </Text>
          <View style={[styles.row, styles.textSpacing]}>
            <Text style={styles.left}>{t(['Format', 'Format'])}:</Text>
            <Text style={styles.right}>.png</Text>
          </View>
          <View style={[styles.row, styles.textSpacing, styles.paddingBottom]}>
            <Text style={styles.left}>{t(['Tinggi maks', 'Max height'])}:</Text>
            <Text style={styles.right}>50px</Text>
          </View>
          <Text style={styles.textSpacing}>
            {t(['Semua icon media sosial', 'All social media icons'])}:
          </Text>
          <View style={[styles.row, styles.textSpacing]}>
            <Text style={styles.left}>{t(['Format', 'Format'])}:</Text>
            <Text style={styles.right}>.png</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.left}>
              {t(['Diameter maks', 'Max diameter'])}:
            </Text>
            <Text style={styles.right}>40px</Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['DATABASE', 'DATABASE'])}</Text>
              <Button
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                // TODO: navigate
                onPress={() => {}}
              />
            </View>
            <Text>
              {t(['Tambah, Perbarui, Ekspor', 'Add, Update, Export'])}
            </Text>
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['PESAN POP UP', 'POP UP MESSAGE'])}</Text>
              <Button
                data-testid="popup"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.popUp)}
              />
            </View>
            <Text>{t(['Kelola pesan pop up', 'Manage Pop Up messages'])}</Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['FORMULIR ONLINE', 'ONLINE FORMS'])}</Text>
              <Button
                data-testid="olform"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => {
                  goTo(routePaths.onlineForms);
                }}
              />
            </View>
            <Text>
              {t([
                'Kelola formulir untuk mengumpulkan informasi, peserta, pemesanan',
                'Manage forms for collecting information, entries, bookings',
              ])}
            </Text>
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['FOLDER ARSIP', 'ARCHIVE FOLDER'])}</Text>
              <Button
                data-testid="archive"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.archive)}
              />
            </View>
            <Text>
              {t(['Cari file yang diarsipkan', 'Search for archived files'])}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>
                {t(['PERMINTAAN DISTRIBUTOR TOKO', 'DISTRIBUTOR REQUEST TOKO'])}
              </Text>
              <Button
                data-testid="agent-request-status"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.agentRequestStatus)}
              />
            </View>
            <Text>
              {t([
                'Lihat status permintaan distributor toko',
                'View toko distributor request status',
              ])}
            </Text>
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['PENGGUNA TOKO', 'TOKO USER'])}</Text>
              <Button
                data-testid="toko-user"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.tokoUser)}
              />
            </View>
            <Text>
              {t(['Lihat daftar pengguna TOKO', 'View list of TOKO User'])}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['POIN LOYALTI', 'LOYALTY POINT'])}</Text>
              <Button
                data-testid="loyalty-point"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.loyaltyStoreList)}
              />
            </View>
            <Text>{t(['Kelola poin loyalty', 'Manage loyalty point'])}</Text>
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['GAMIFICATION', 'GAMIFICATION'])}</Text>
              <Button
                data-testid="gamification"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.gamification)}
              />
            </View>
            <Text>Pusat kontrol & data untuk Gamifikasi Toko App</Text>
          </View>

          {/* <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['NOTIFIKASI', 'NOTIFICATIONS'])}</Text>
              <Button
                data-testid="notifications"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.automaticNotificationList)}
              />
            </View>
            <Text>
              {t([
                'Lihat dan ubah notifikasi',
                'View and edit push notifications',
              ])}
            </Text>
          </View> */}
          {/* <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['DAFTAR TIER USER', 'USER TIER LIST'])}</Text>
              <Button
                data-testid="userTierList"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.userTierList)}
              />
            </View>
            <Text>
              {t([
                'Lihat Daftar, Log History & Update Tier user pada Toko App',
                `View List, Log History & Update User's tier in Toko App`,
              ])}
            </Text>
          </View> */}
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>
                {t(['UNGGAH POIN JUMLAH BESAR', 'BULK POINT UPLOAD'])}
              </Text>
              <Button
                data-testid="bulk-point"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.bulkLoyalty)}
              />
            </View>
            <Text>
              {t([
                'Kelola poin loyalty dalam jumlah besar',
                'Manage loyalty point in bulk',
              ])}
            </Text>
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>{t(['DAFTAR DEEP LINK', 'DEEP LINK LIST'])}</Text>
              <Button
                data-testid="deep-link-list"
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.deepLinkList)}
              />
            </View>
            <Text>{t(['Lihat daftar deep link ', 'View deep link list'])}</Text>
          </View>
        </View>
      </View>

      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <View data-testid="requestAgent" style={styles.section}>
            <RequestAgentReportEmail />
          </View>
        </View>

        <View style={styles.rightContainer}>
          <View data-testid="principal" style={styles.section}>
            <BusinessUnitContacts
              setPrincipalIncomplete={setPrincipalIncomplete}
            />
          </View>
        </View>
      </View>
      <View style={styles.sectionRow}>
        <View style={styles.leftContainer}>
          <SocialMediaLinks />
        </View>

        <View style={styles.rightContainer}>
          <View style={styles.section}>
            <View style={styles.title}>
              <Text bold>
                {t(['KODE REFERRAL SALESMAN', 'SALESMAN REFERRAL CODE'])}
              </Text>
              <Button
                preset="transparent"
                title={t(['Lanjut', 'Next'])}
                onPress={() => goTo(routePaths.salesmanReferralCode)}
              />
            </View>
            <Text>
              {t([
                'Lihat History Penggunaan Kode Referral Salesman pada Toko App',
                'View Salesman Referral Code Usage History in Toko App',
              ])}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    borderBottomWidth: 2,
    borderBottomColor: colors.border.primary,
  },
  paddingBottom: {
    paddingBottom: spacing.small,
  },
  section: {
    // flex: 1,
    paddingTop: spacing.xsmall,
    paddingBottom: spacing.medium,
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing.xsmall,
  },
  sectionRow: {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 2,
  },
  leftContainer: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginRight: spacing.medium,
  },
  rightContainer: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginLeft: spacing.medium,
  },
  textSpacing: {
    marginBottom: spacing.xxsmall,
  },
  wrap: {
    flexWrap: 'wrap',
  },
  brands: { minWidth: 110, marginBottom: spacing.xsmall },
});
