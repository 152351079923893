import React, {
  useState,
  useCallback,
  useMemo,
  ReactNode,
  useEffect,
} from 'react';
import { View, StyleSheet, TouchableOpacity, ViewProps } from 'react-native';
import FolderFilledIcon from '@material-ui/icons/Folder';
import { logEvent } from 'expo-firebase-analytics';

import { HeaderNavigationBar, Header } from '../../components';
import { Text } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { ArchiveStatus } from '../../generated/globalTypes';
import SearchCompetition from '../news/DboNewsSearchCompetition';
import SearchEvent from '../news/DboNewsSearchEvent';
import SearchNews from '../news/SearchNews';
import SearchSlider from '../homepageSlider/HomepageSliderSearch';
import SearchLibrary from '../library/LibrarySearch';
import SearchPromotion from '../promotions/PromotionSearch';
import SearchOnlineForm from '../onlineForms/OnlineFormSearch';
import SearchPopUp from '../popUp/CustomPopUpTable';
import { pageTitle } from '../../constants/pageTitle';

const ArchiveFolder = (props: {
  dataCy: string;
  title: string;
  active: boolean;
  style?: ViewProps['style'];
  onPress: () => void;
}) => {
  let { dataCy, title, active, style, onPress } = props;
  return (
    <View
      style={[
        styles.flex,
        styles.folderWrapper,
        active && styles.border,
        style,
      ]}
    >
      <TouchableOpacity onPress={onPress}>
        <View style={[styles.row, styles.folder]} data-testid={dataCy}>
          <FolderFilledIcon />
          <Text style={styles.folderTitle}>{title}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const archiveList = [
  {
    key: 'popUp',
    title: t(['Pop Up', 'Pop Up']),
    component: (
      <SearchPopUp
        hideEdit
        queryVariables={{
          archiveStatus: ArchiveStatus.ARCHIVED,
          isWhoNotNull: false,
        }}
      />
    ),
  },
  {
    key: 'competition',
    title: t(['Kabar DBO - Kompetisi', 'DBO News - Competition']),
    component: (
      <SearchCompetition
        hideEdit
        hideArchiveCheck
        queryVariables={{ archived: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'event',
    title: t(['Kabar DBO - Event', 'DBO News - Event']),
    component: (
      <SearchEvent
        hideEdit
        hideArchiveCheck
        queryVariables={{ archived: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'news',
    title: t(['Kabar DBO - Berita', 'DBO News - News']),
    component: (
      <SearchNews
        hideEdit
        hideArchiveCheck
        queryVariables={{ archiveStatus: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'slider',
    title: t(['Slider Beranda', 'Homepage Sliders']),
    component: (
      <SearchSlider
        hideEdit
        hideArchiveCheck
        queryVariables={{ archiveStatus: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'library',
    title: t(['Perpustakaan Dokumen', 'Document Library']),
    component: (
      <SearchLibrary
        hideEdit
        hideArchiveCheck
        queryVariables={{ archived: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'promotion',
    title: t(['Semua Promosi', 'See All Promotions']),
    component: (
      <SearchPromotion
        hideEdit
        hideArchiveCheck
        queryVariables={{ archived: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'competitionForm',
    title: t(['Formulir Online - Kompetisi', 'Online Forms - Competition']),
    component: (
      <SearchOnlineForm
        type="competition"
        hideEdit
        hideArchiveCheck
        queryVariables={{ archiveStatus: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
  {
    key: 'eventForm',
    title: t(['Formulir Online - Event', 'Online Forms - Event']),
    component: (
      <SearchOnlineForm
        type="event"
        hideEdit
        hideArchiveCheck
        queryVariables={{ archiveStatus: ArchiveStatus.ARCHIVED }}
      />
    ),
  },
];

const ArchiveScene = () => {
  const [selected, setSelected] = useState('');
  const [tableComponent, setTableComponent] = useState<ReactNode>(null);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.ArchiveScene });
    };
    eventLog();
  }, []);

  const createOnPress = useCallback(
    (key: string, component: ReactNode) => () => {
      setSelected(key);
      setTableComponent(component);
    },
    [],
  );

  const folders = useMemo(() => {
    let rows = [];
    let index = 0;
    while (index < archiveList.length) {
      let sliced = archiveList.slice(index, index + 5);
      let placeholders = new Array(5 - sliced.length).fill(null);
      rows.push(
        <View style={styles.folderRow}>
          {sliced.map(({ key, title, component }, loopIndex) => (
            <ArchiveFolder
              key={key}
              dataCy={`archive-${key}`}
              title={title}
              active={selected === key}
              onPress={createOnPress(key, component)}
              style={loopIndex < 4 && styles.spacing}
            />
          ))}
          {placeholders.map((_, loopIndex) => (
            <View key={loopIndex} style={[styles.flex, styles.folderWrapper]} />
          ))}
        </View>,
      );
      index += 5;
    }
    return rows;
  }, [selected, createOnPress]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header title="Archive" style={styles.header} />
      <Text>
        {t([
          'Akses berkas yang diarsipkan di sini',
          'Access archived files here',
        ])}
      </Text>
      <View style={[styles.flex, styles.section]}>{folders}</View>
      <View>{tableComponent}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  root: { padding: spacing.xlarge },
  header: { paddingBottom: spacing.small },
  row: { flexDirection: 'row' },
  folderRow: { flexDirection: 'row', paddingTop: spacing.medium },
  folderWrapper: {
    justifyContent: 'center',
    padding: spacing.small,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  folder: { alignItems: 'center' },
  folderTitle: { paddingLeft: spacing.small },
  spacing: { marginRight: spacing.medium },
  border: { borderColor: colors.border.dark },
  section: { paddingBottom: spacing.xlarge },
});

export default ArchiveScene;
