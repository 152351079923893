import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  StyleSheet,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';

import { Table } from '../../core-ui';
import { ErrorMessage, ErrorModal } from '../../components';
import { useTableSort } from '../../hooks';
import {
  LibraryDocuments,
  LibraryDocumentsVariables,
} from '../../generated/LibraryDocuments';
import {
  ArchiveDocument,
  ArchiveDocumentVariables,
} from '../../generated/ArchiveDocument';
import {
  ArchiveStatus,
  DocumentAdvanceSearchOrderByType,
} from '../../generated/globalTypes';
import { LIBRARY_DOCUMENTS } from '../../graphql/queries';
import { ARCHIVE_DOCUMENT } from '../../graphql/mutations';
import { theme } from '../../constants/theme';
import { LibraryDocument } from '../../generated/LibraryDocument';

type Props = {
  'data-testid'?: string;
  searchArchived?: boolean;
  searchByBrand?: string;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: LibraryDocumentsVariables;
  onSubmit?: () => void;
  setEditDocument?: (value: LibraryDocument) => void;
};

const LibraryTable = (props: Props) => {
  let {
    searchArchived,
    searchByBrand,
    refetchToggle,
    hideEdit,
    queryVariables,
    onSubmit,
    setEditDocument,
  } = props;
  const [page, setPage] = useState(0);
  const [archiveID, setArchiveID] = useState<string | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    DocumentAdvanceSearchOrderByType
  >();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  const [getDocuments, { data, loading, error, refetch }] = useLazyQuery<
    LibraryDocuments,
    LibraryDocumentsVariables
  >(LIBRARY_DOCUMENTS, {
    variables: {
      skip: page * rowsPerPage,
      first: rowsPerPage,
      archived: ArchiveStatus.NORMAL,
      orderBy: orderByVariable,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            brandID: searchByBrand,
            archived:
              searchArchived === false ? ArchiveStatus.NORMAL : undefined,
            orderBy: orderByVariable,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [
      searchByBrand,
      searchArchived,
      queryVariables,
      refetch,
      rowsPerPage,
      page,
      orderByVariable,
    ],
  );

  useEffect(() => {
    setPage(0);
  }, [searchByBrand, searchArchived]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  let [
    archiveDocument,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchiveDocument, ArchiveDocumentVariables>(ARCHIVE_DOCUMENT, {
    onCompleted: () => {
      refetchCurrentPage();
      onSubmit?.();
    },
    onError: openErrorModal,
  });

  const onArchivePress = useCallback(
    (id: string) => {
      setArchiveID(id);
      archiveDocument({ variables: { id } });
    },
    [archiveDocument],
  );

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onViewPress = useCallback(
    (document: LibraryDocument) => {
      if (setEditDocument) {
        setEditDocument(document);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditDocument],
  );

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : ({
            action: {
              noHeaderTitle: true,
              displayText: t(['lihat', 'view']),
              onPress: onViewPress,
            },
          } as const),
    [hideEdit, onViewPress],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  return (
    <>
      <Table
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        page={page}
        data={data?.documentAdvanceSearch.row ?? []}
        data-testid={props['data-testid']}
        error={!!error}
        loading={loading}
        rowsPerPage={rowsPerPage}
        dataCount={data?.documentAdvanceSearch.total || 0}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        structure={{
          thumbnail: {
            noHeaderTitle: true,
            render: ({ thumbnail }) => (
              <Image
                source={{ uri: thumbnail }}
                style={styles.thumbnail}
                resizeMode="contain"
                resizeMethod="resize"
              />
            ),
          },
          [DocumentAdvanceSearchOrderByType.brandName]: {
            dataPath: 'brand.name',
            headerTitle: t(['Brand', 'Brand']),
            orderable: true,
          },
          [DocumentAdvanceSearchOrderByType.documentName]: {
            headerTitle: t(['Dokumen', 'Document']),
            orderable: true,
          },
          ...action,
          archive: {
            headerTitle: t(['arsip', 'archive']),
            headerTitleColor: 'link',
            render: ({ id, status }) =>
              archiveLoading && id === archiveID ? (
                <ActivityIndicator size="small" />
              ) : (
                <TouchableOpacity
                  data-testid={`${props['data-testid']}-archive-toggle`}
                  onPress={() => onArchivePress(id)}
                  style={styles.actionSpacing}
                >
                  {status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon
                      data-testid={`${props['data-testid']}-normal`}
                    />
                  ) : (
                    <FolderFilledIcon
                      data-testid={`${props['data-testid']}-archived`}
                    />
                  )}
                </TouchableOpacity>
              ),
          },
        }}
      />
      {!!error && <ErrorMessage error={error} onPress={refetchCurrentPage} />}
      <ErrorModal
        open={errorOpen}
        error={archiveError}
        action={t([
          'mengubah status arsip dokumen',
          'change archive status of the document',
        ])}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  thumbnail: { width: 140, height: 140 },
  actionSpacing: { paddingLeft: theme.spacing.xsmall },
});

export default LibraryTable;
