import React, {
  ChangeEvent,
  KeyboardEvent,
  memo,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import { TextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { fonts, colors, spacing } from '../constants/theme';

import Text from './Text';

type Props = TextFieldProps & {
  'data-testid'?: string;
  label?: string;
  labelSize?: keyof typeof fonts.sizes;
  labelStyle?: StyleProp<TextStyle>;
  error?: boolean;
  mismatch?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  rightNode?: ReactNode;
  leftNode?: ReactNode;
  InputProps?: TextFieldProps['InputProps'];
  onChangeText: (text: string) => void;
  onSubmitEditing?: () => void;
};

const useStyles = makeStyles({
  root: {
    fontFamily: 'Inter',
    fontSize: fonts.sizes.s,
    color: colors.textInput.value,
    backgroundColor: colors.textInput.background.primary,
    borderColor: colors.textInput.border.primary,
    borderRadius: 2,
    '&$focused $notchedOutline': {
      borderColor: colors.textInput.border.focus,
    },
    '&$disabled $notchedOutline': {
      borderColor: colors.textInput.border.primary,
    },
  },
  focused: {},
  disabled: { backgroundColor: colors.textInput.background.disabled },
  input: { padding: `${spacing.s}px ${spacing.m}px` },
  multiline: { padding: `${spacing.xxs}px ${spacing.m}px` },
  error: { borderColor: colors.textInput.border.error },
  notchedOutline: {
    borderColor: ({ error, mismatch }: Props) =>
      error
        ? colors.textInput.border.error
        : mismatch
        ? colors.textInput.border.mismatch
        : colors.textInput.border.primary,
  },
});

const TextInput = memo((props: Props) => {
  const inputClasses = useStyles(props);
  let {
    label,
    labelSize = 'xs',
    labelStyle,
    mismatch,
    containerStyle,
    rightNode,
    leftNode,
    InputProps,
    type,
    multiline,
    onChangeText,
    onSubmitEditing,
    required,
    ...otherProps
  } = props;

  let onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (type === 'coordinate') {
        if (value[0] === '-') {
          onChangeText('-' + value.slice(1).replace(/[^\d\.]/, ''));
        } else {
          onChangeText(value.replace(/[^\d\.]/, ''));
        }
      } else {
        onChangeText(value);
      }
    },
    [type, onChangeText],
  );

  let onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && onSubmitEditing) {
        onSubmitEditing();
      }
    },
    [onSubmitEditing],
  );

  const startAdornment = useMemo(() => {
    if (leftNode) {
      return (
        <View
          style={[styles.adornmentRoot, multiline && styles.multilineAdornment]}
        >
          {leftNode}
        </View>
      );
    }
    return null;
  }, [leftNode, multiline]);

  const endAdornment = useMemo(() => {
    if (rightNode) {
      return (
        <View
          style={[styles.adornmentRoot, multiline && styles.multilineAdornment]}
        >
          {rightNode}
        </View>
      );
    }
    return null;
  }, [rightNode, multiline]);

  return (
    <View style={containerStyle}>
      {label && (
        <View style={styles.row}>
          <Text size={labelSize} style={[styles.label, labelStyle]}>
            {label}
          </Text>
          {required && (
            <Text
              size={labelSize}
              style={[styles.label, labelStyle, styles.red]}
            >
              *
            </Text>
          )}
        </View>
      )}
      <TextField
        data-testid={props['data-testid']}
        onChange={onChange}
        onKeyDown={onKeyDown}
        variant="outlined"
        type={type}
        inputProps={{ 'data-testid': `${props['data-testid']}-input` }}
        InputProps={{
          classes: inputClasses,
          endAdornment,
          startAdornment,
          ...InputProps,
        }}
        multiline={multiline}
        {...otherProps}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  row: { flexDirection: 'row' },
  red: { color: colors.text.red },
  label: { paddingBottom: spacing.xxs },
  adornmentRoot: {
    flexDirection: 'row',
  },
  multilineAdornment: {
    alignSelf: 'flex-start',
    paddingTop: spacing.xxs,
  },
});

export default TextInput;
