import React, { CSSProperties, useCallback, memo } from 'react';
import clsx from 'clsx';
import { View, StyleSheet } from 'react-native';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  makeStyles,
  InputAdornment,
  createMuiTheme,
  IconButton,
} from '@material-ui/core';
import { ThemeProvider, Theme, createStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import { colors, fonts, spacing } from '../constants/theme';
import { Text } from '../core-ui';

// NOTE: These block is to enable us override some styles that are missing from the .d.ts
type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersBasePicker: {
    pickerView: {
      minWidth?: number;
      minHeight?: number;
    };
  };
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      minWidth?: number;
      minHeight?: number;
    };
  };
};

declare module '@material-ui/core/styles/overrides' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends CustomType {}
}
// END NOTE

type Props = {
  'data-testid'?: string;
  selectedDate: Date | null;
  onChange: (date: Date) => void;
  onMonthChange?: (date: Date | null) => void | Promise<void>;
  label?: string;
  withoutLabel?: boolean;
  type?: 'date' | 'datetime' | 'calendar';
  markedDates?: Array<Date>;
  disabled?: boolean;
  disablePast?: boolean;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    day: {
      width: 32,
      height: 32,
      padding: 8, // NOTE: this centers the day
      margin: '0 2px',
      fontSize: theme.typography.caption.fontSize,
      color: 'inherit',
      borderRadius: '50%',
    },
    marked: {
      backgroundColor: colors.dateTimePicker.markedDateBackground,
    },
    selected: {
      border: `1px solid ${colors.dateTimePicker.selectedDateBorder}`,
    },
    today: {
      color: colors.dateTimePicker.today,
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
    input: {
      fontSize: fonts.sizes.default,
      maxHeight: 36,
    },
  }),
);

const theme = createMuiTheme({
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: 0,
        minHeighgt: 0,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 0,
        minHeight: 0,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: { width: 32 },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.dateTimePicker.primary,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: colors.dateTimePicker.primary,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: colors.dateTimePicker.primary,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: colors.dateTimePicker.primary,
      },
      thumb: {
        borderColor: colors.dateTimePicker.primary,
      },
      noPoint: {
        backgroundColor: colors.dateTimePicker.primary,
      },
    },
  },
});

const DateTimePicker = memo((props: Props) => {
  let classes = useStyles();
  let {
    selectedDate,
    label = t(['Tanggal/Waktu', 'Date/Time']),
    type = 'datetime',
    withoutLabel = false,
    markedDates,
    disabled,
    disablePast = type !== 'calendar',
    shouldDisableDate,
    onMonthChange,
    'data-testid': dataCy,
  } = props;

  let onChange = useCallback(
    (date: MaterialUiPickersDate) => {
      date && props.onChange(date);
    },
    [props],
  );

  let renderDay = useCallback(
    (
      day: MaterialUiPickersDate,
      selectedDay: MaterialUiPickersDate,
      isInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      let isMarked =
        markedDates
          ?.map((value) => value.toDateString())
          .includes(day?.toDateString() ?? '') && isInCurrentMonth;
      let isSelected = day?.valueOf() === selectedDay?.valueOf();
      let isToday = day?.toDateString() === new Date().toDateString();
      let isDisabled = dayComponent.props.disabled || !isInCurrentMonth;

      const dayClassName = clsx(classes.day, {
        [classes.today]: isToday && !isSelected,
        [classes.disabled]: isDisabled,
        [classes.marked]: isMarked,
        [classes.selected]: isSelected && isInCurrentMonth,
      });

      return (
        <IconButton
          data-testid={
            isInCurrentMonth && day
              ? `${dataCy}-day-${day.getDate()}`
              : undefined
          }
          className={dayClassName}
          disabled={isDisabled || disabled}
        >
          {isInCurrentMonth && day?.getDate()}
        </IconButton>
      );
    },
    [
      dataCy,
      markedDates,
      disabled,
      classes.today,
      classes.day,
      classes.marked,
      classes.selected,
      classes.disabled,
    ],
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <View>
          {type !== 'calendar' && !withoutLabel && (
            <View style={styles.label}>
              <Text bold>{label}</Text>
            </View>
          )}
          <View style={styles.container}>
            <View style={styles.border}>
              <DatePicker
                onMonthChange={onMonthChange}
                disabled={disabled}
                disableToolbar={type === 'calendar'}
                value={selectedDate}
                onChange={onChange}
                disablePast={disablePast}
                shouldDisableDate={shouldDisableDate}
                minDateMessage="" // NOTE: to remove the default message which messes up layout
                format="dd/MM/yyyy"
                variant={type === 'calendar' ? 'static' : 'inline'}
                autoOk
                style={StyleSheet.flatten(styles.datepicker) as CSSProperties}
                inputProps={{ 'data-testid': props['data-testid'] }}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }}
                renderDay={renderDay}
              />
            </View>
            {type === 'datetime' && (
              <>
                <Text style={styles.separator}>/</Text>
                <View style={styles.border}>
                  <TimePicker
                    data-testid={`${dataCy}-time`}
                    disabled={disabled}
                    value={selectedDate}
                    onChange={onChange}
                    variant="inline"
                    ampm={false}
                    autoOk
                    style={
                      StyleSheet.flatten(styles.timepicker) as CSSProperties
                    }
                    InputProps={{
                      className: classes.input,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </View>
              </>
            )}
          </View>
        </View>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  border: {
    borderWidth: 1,
    borderColor: colors.dateTimePicker.border,
  },
  datepicker: {
    paddingLeft: spacing.xsmall,
    width: 108,
  },
  timepicker: {
    paddingLeft: spacing.xsmall,
    width: 68,
  },
  label: {
    marginBottom: spacing.xsmall,
  },
  separator: {
    margin: 4,
  },
});

export default DateTimePicker;
