import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';
import { useHistory } from 'react-router';

import {
  HeaderNavigationBar,
  Header,
  ErrorMessage,
  SearchHeader,
  RegionDropdown,
  CityDropdown,
  Option,
  BrandDropdown,
  SearchByText,
  Dropdown,
  SearchByDateRange,
  ProgressBarModal,
} from '../../components';
import { spacing, colors, fonts, theme } from '../../constants/theme';
import {
  AgentRequests,
  AgentRequestsVariables,
  AgentRequests_requestAgentAdvanceSearch_row,
} from '../../generated/AgentRequests';
import {
  CREATE_REQUEST_AGENT_DOWNLOAD_ORDER,
  GET_AGENT_REQUESTS,
  GET_RA_DOWNLOAD_PROGRESS,
  GET_TOTAL_REQUEST_AGENT_STATUS,
} from '../../graphql/queries';
import { Button, Table, Text } from '../../core-ui';
import {
  CmsRequestStatus,
  RequestStatus,
  RoleAdmin,
  CmsRequestStatusFilter,
  RejectedBy,
  RequestAgentFrom,
  NewCmsRequestStatusFilter,
  RequestAgentDownloadStatus,
  SortOrder,
  // RequestAgentFrom,
} from '../../generated/globalTypes';
import { pageTitle } from '../../constants/pageTitle';
import { useAuth } from '../../context';
import { useTableSort } from '../../hooks';
import {
  CalculateRequestAgent,
  CalculateRequestAgentVariables,
} from '../../generated/CalculateRequestAgent';
import { convertToCurrencyRupiah, getFormattedDate } from '../../helpers';
import {
  GetRADwonloadProgress,
  GetRADwonloadProgressVariables,
} from '../../generated/GetRADwonloadProgress';
import {
  CreateRequestAgentDownloadOrder,
  CreateRequestAgentDownloadOrderVariables,
} from '../../generated/CreateRequestAgentDownloadOrder';

const statusOptions = [
  {
    label: t(['Diterima oleh Distributor', 'Approved By Distributor']),
    value: CmsRequestStatusFilter.ACCEPTED,
  },
  {
    label: t(['Menunggu Distributor', 'Waiting For Distributor']),
    value: CmsRequestStatusFilter.REVIEW,
  },
  {
    label: t(['Menunggu Principal', 'Waiting For Principal']),
    value: CmsRequestStatusFilter.REQUEST,
  },
  {
    label: t(['Ditolak Oleh Distributor', 'Rejected By Distributor']),
    value: CmsRequestStatusFilter.REJECTED_BY_DISTRIBUTOR,
  },
  {
    label: t(['Ditolak Oleh Principal', 'Rejected By Principal']),
    value: CmsRequestStatusFilter.REJECTED_BY_PRINCIPAl,
  },
  {
    label: t(['Dibatalkan Oleh Sistem', 'Rejected By System']),
    value: CmsRequestStatusFilter.CANCELED,
  },
  {
    label: t(['Tidak Lagi Terhubung', 'Tidak Lagi Terhubung']),
    value: CmsRequestStatusFilter.UNMATCHED,
  },
];

export default function AgentRequestStatusScene() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let [page, setPage] = useState(0);
  const history = useHistory();
  const { role } = useAuth();
  const isAdmin = role === RoleAdmin.ADMIN;

  const [isDownloadFailed, setIsDownloadFailed] = useState(false);

  let [current, setCurrent] = useState(0);
  let [max, setMax] = useState(1024);

  // NOTE: these are used for filter state
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<Option | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  const [searchToko, setSearchToko] = useState<string | null>(null);
  const [searchIdToko, setSearchIdToko] = useState<string | null>(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState<Date | null>(null);
  const [selectedDateUntil, setSelectedDateUntil] = useState<Date | null>(null);
  const [
    selectedRequestDateFrom,
    setSelectedRequestDateFrom,
  ] = useState<Date | null>(null);
  const [
    selectedRequestDateUntil,
    setSelectedRequestDateUntil,
  ] = useState<Date | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [
    selectedStatus,
    setSelectedStatus,
  ] = useState<CmsRequestStatusFilter | null>(null);
  let [progressOpen, setProgressOpen] = useState(false);

  // NOTE: these are used to filter the queries
  const [searchByRegion, setSearchByRegion] = useState<string | null>(null);
  const [searchByCity, setSearchByCity] = useState<string | null>(null);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  const [searchByToko, setSearchByToko] = useState<string | null>(null);
  const [searchByIdToko, setSearchByIdToko] = useState<string | null>(null);
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);
  const [requestDateFrom, setRequestDateFrom] = useState<Date | null>(null);
  const [requestDateUntil, setRequestDateUntil] = useState<Date | null>(null);

  const [
    searchStatus,
    setSearchStatus,
  ] = useState<CmsRequestStatusFilter | null>(null);
  const [
    searchNewStatus,
    setSearchNewStatus,
  ] = useState<NewCmsRequestStatusFilter | null>(null);

  const { order, orderBy, onRequestSort } = useTableSort();

  let orderByVariable = useMemo(() => {
    if (order && orderBy) {
      return { [orderBy]: order };
    }
    return { createdAt: SortOrder.desc };
  }, [order, orderBy]);

  let { data, loading, error, refetch } = useQuery<
    AgentRequests,
    AgentRequestsVariables
  >(GET_AGENT_REQUESTS, {
    variables: {
      skip: page * rowsPerPage,
      first: rowsPerPage,
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  let {
    data: dataTotalRequestAgent,
    loading: loadingTotalRequestAgent,
  } = useQuery<CalculateRequestAgent, CalculateRequestAgentVariables>(
    GET_TOTAL_REQUEST_AGENT_STATUS,
    {
      variables: {
        registrationDate:
          searchByDateFrom && searchByDateUntil
            ? {
                startDate: searchByDateFrom.toISOString(),
                endDate: searchByDateUntil.toISOString(),
              }
            : null,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: isAdmin,
    },
  );

  const refetchData = useCallback(
    (currentPage = page) =>
      refetch?.({
        skip: currentPage * rowsPerPage,
        first: rowsPerPage,
        provinceId: searchByRegion,
        cityId: searchByCity,
        storeName: searchByToko,
        storeCode: searchByIdToko,
        newStatus: searchNewStatus,
        brandId: searchByBrand,
        orderBy: orderByVariable,
        status: searchStatus,
        registrationDate:
          searchByDateFrom && searchByDateUntil
            ? {
                startDate: searchByDateFrom?.toISOString(),
                endDate: searchByDateUntil?.toISOString(),
              }
            : null,
        requestDate:
          requestDateFrom && requestDateUntil
            ? {
                startDate: requestDateFrom?.toISOString(),
                endDate: requestDateUntil?.toISOString(),
              }
            : null,
      }),
    [
      page,
      refetch,
      rowsPerPage,
      searchByRegion,
      searchByCity,
      searchByToko,
      searchByIdToko,
      searchByBrand,
      searchByDateFrom,
      searchByDateUntil,
      orderByVariable,
      searchNewStatus,
      searchStatus,
      requestDateFrom,
      requestDateUntil,
    ],
  );

  const [getDownloadProgress, { data: dataRADownloadProgress }] = useLazyQuery<
    GetRADwonloadProgress,
    GetRADwonloadProgressVariables
  >(GET_RA_DOWNLOAD_PROGRESS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (
      dataRADownloadProgress &&
      dataRADownloadProgress.getRADownloadProgress
    ) {
      let {
        id,
        downloadUrl,
        processedBatch,
        status,
        totalBatch,
      } = dataRADownloadProgress.getRADownloadProgress;
      setCurrent(processedBatch || 0);
      setMax(totalBatch || 0);
      if (status === RequestAgentDownloadStatus.CANCELED) {
        setIsDownloadFailed(true);
      } else if (
        status === RequestAgentDownloadStatus.ON_PROGRESS ||
        status === RequestAgentDownloadStatus.WAITING
      ) {
        getDownloadProgress({ variables: { downloadId: id } });
      } else if (
        status === RequestAgentDownloadStatus.COMPLETED &&
        downloadUrl
      ) {
        fetch(downloadUrl)
          .then((res) => res.blob())
          .then((blob) => {
            if (selectedDateFrom && selectedDateUntil) {
              saveAs(
                blob,
                ' Request Agent ' +
                  new Date(selectedDateFrom) +
                  '-' +
                  new Date(selectedDateUntil) +
                  '.csv',
              );
            } else {
              saveAs(blob, ' RequestAgent.csv');
            }
          });

        setTimeout(() => {
          setProgressOpen(false);
          setCurrent(0);
          setIsDownloadFailed(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDownloadProgress, dataRADownloadProgress]);

  const [
    createRADownloadOrder,
    { loading: createRADownloadOrderLoading },
  ] = useMutation<
    CreateRequestAgentDownloadOrder,
    CreateRequestAgentDownloadOrderVariables
  >(CREATE_REQUEST_AGENT_DOWNLOAD_ORDER, {
    onCompleted: ({ createRequestAgentDownloadOrder }) => {
      let { id } = createRequestAgentDownloadOrder;
      getDownloadProgress({ variables: { downloadId: id } });
      setProgressOpen(true);
    },
  });

  const refetchLink = useCallback(() => {
    setIsDownloadFailed(false);
    setProgressOpen(true);
    createRADownloadOrder({
      variables: {
        input: {
          provinceId: searchByRegion,
          cityId: searchByCity,
          storeName: searchByToko,
          storeCode: searchByIdToko,
          brandId: searchByBrand,
          status: searchStatus,
          newStatus: searchNewStatus,
          registrationDate:
            searchByDateFrom && searchByDateUntil
              ? {
                  startDate: searchByDateFrom,
                  endDate: searchByDateUntil,
                }
              : null,
          requestDate:
            requestDateFrom && requestDateUntil
              ? {
                  startDate: requestDateFrom,
                  endDate: requestDateUntil,
                }
              : null,
        },
      },
    });
  }, [
    createRADownloadOrder,
    searchNewStatus,
    searchByBrand,
    searchByCity,
    searchByIdToko,
    searchByRegion,
    searchByToko,
    searchByDateFrom,
    searchByDateUntil,
    searchStatus,
    requestDateFrom,
    requestDateUntil,
  ]);

  const onExportPress = useCallback(() => {
    // TODO: connect the subscription
    setProgressOpen(true);
    refetchLink();
  }, [refetchLink]);

  useEffect(() => {
    refetchData();
  }, [orderByVariable, refetchData]);

  useEffect(() => {
    setPage(0);
  }, [searchByRegion, searchByCity]);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.AgentRequestStatusScene,
      });
    };
    eventLog();
  }, []);

  const onChangePage = useCallback(
    (newPage: number) => {
      refetchData(newPage);
      setPage(newPage);
    },
    [refetchData],
  );
  const onAssignPress = useCallback(
    (data: AgentRequests_requestAgentAdvanceSearch_row) => {
      history.push(`/assign-distributor/${data.encodedToken}`);
    },
    [history],
  );

  let getTimeLimit = (
    expiredDate: Date,
    currentDate: Date,
  ): { dateLimit: string; timeLimit: string } => {
    let twoDays = 60 * 60 * 1000 * 48;
    let currentDay = currentDate.getDay();
    if (currentDay === 0) {
      currentDate.setDate(currentDate.getDate() - 1);
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setMilliseconds(0);
    } else if (currentDay === 6) {
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setMilliseconds(0);
    }

    let diff = expiredDate.valueOf() - currentDate.valueOf();
    let diffTwoDays = expiredDate.valueOf() - currentDate.valueOf() - twoDays;
    let diffHour = Math.floor(
      (diff > twoDays ? diffTwoDays : diff) / (60 * 60 * 1000),
    );
    let diffMinute = Math.round(
      ((diff > twoDays ? diffTwoDays : diff) % (60 * 60 * 1000)) / (60 * 1000),
    );

    let returnedTime = `${diffHour}j ${diffMinute}m`;

    return {
      // dateLimit: getFormattedDate(expiredDate),
      dateLimit: getFormattedDate(expiredDate),
      timeLimit: returnedTime,
    };
  };

  const action = useMemo(
    () => ({
      action: {
        headerTitle: t(['Aksi', 'action']),
        render: (data: AgentRequests_requestAgentAdvanceSearch_row) => {
          let disabled =
            data.status === RequestStatus.ACCEPTED ||
            data.status === RequestStatus.REJECTED ||
            data.status === RequestStatus.REVIEW ||
            data.cmsRequestStatus !== CmsRequestStatus.WAITING ||
            !data.store.storeVerified ||
            !data.store.storeManager?.statusActive;

          if (
            data.status === RequestStatus.CANCELED &&
            data.isCancelledInAction === true
          ) {
            disabled = true;
          } else if (
            data.status === RequestStatus.CANCELED &&
            data.cmsRequestStatus === CmsRequestStatus.CANCEL
          ) {
            disabled = false;
          }

          return (
            <Button
              onPress={() => onAssignPress(data)}
              style={styles.button}
              preset={disabled ? 'optional' : 'primary'}
              title={disabled ? 'Lihat Riwayat' : 'Tentukan'}
            />
          );
        },
      },
    }),
    [onAssignPress],
  );

  const orderDefinition = useMemo(
    () => ({
      orderDefinition: {
        headerTitle: 'Definisi Order',
        orderable: true,
        render: ({ orderId }: AgentRequests_requestAgentAdvanceSearch_row) => {
          return (
            <View
              style={
                orderId
                  ? {
                      backgroundColor: colors.text.accepted,
                    }
                  : {}
              }
            >
              <Text
                style={{
                  textAlign: 'center',
                }}
              >
                {orderId ? 'Ya' : 'Tidak'}
              </Text>
            </View>
          );
        },
      },
    }),
    [],
  );

  const requestSource = useMemo(
    () => ({
      requestSource: {
        headerTitle: t(['Sumber Request', 'action']),
        orderable: true,
        render: ({
          requestFrom,
        }: AgentRequests_requestAgentAdvanceSearch_row) => {
          if (requestFrom === RequestAgentFrom.REGISTER) {
            return <Text>{'Registrasi'}</Text>;
          } else if (requestFrom === RequestAgentFrom.ORDER) {
            return <Text>{'Review Order'}</Text>;
          } else if (requestFrom === RequestAgentFrom.PROFILE) {
            return <Text>{'Akun Saya'}</Text>;
          } else if (requestFrom === RequestAgentFrom.VERIF_ADMIN) {
            return <Text>{'Verifikasi dari Admin'}</Text>;
          } else {
            return <Text>{'-'}</Text>;
          }
        },
      },
    }),
    [],
  );

  let renderDeadline = ({
    cmsRequestStatus,
    status,
    approvalDeadline,
    agentReassignDate,
  }: AgentRequests_requestAgentAdvanceSearch_row) => {
    let returned: React.ReactNode;
    let currentDate = new Date();
    let limit = { dateLimit: '-', timeLimit: '-' };
    let actionDoneAt: Date | null = null;
    let isOverSLA = approvalDeadline?.overSla || false;
    let deadline: Date | null =
      approvalDeadline && approvalDeadline.deadline
        ? new Date(approvalDeadline.deadline)
        : null;
    if (deadline) {
      limit = getTimeLimit(deadline, currentDate);
    }
    if (agentReassignDate) {
      actionDoneAt = new Date(agentReassignDate);
    }

    if (
      status === RequestStatus.CANCELED ||
      status === RequestStatus.REQUEST ||
      cmsRequestStatus === CmsRequestStatus.WAITING
    ) {
      //IF there is action after cancellation do this
      if (actionDoneAt && deadline) {
        // reassigned distributor after deadline hit
        if (actionDoneAt > deadline) {
          returned = t.frag(['{dateLimit}\n<b>{timeLimit}</b>', ''], {
            dateLimit: limit.dateLimit,
            timeLimit: 'Over SLA',
            b: (child) => (
              <Text color="error" style={{ fontWeight: 'bold' }}>
                {child}
              </Text>
            ),
          });
        } else {
          //reassigned distributor before deadline hit
          returned = t.frag(['{dateLimit}', ''], {
            dateLimit: limit.dateLimit,
          });
        }
      } else {
        //if there is no action then do this
        returned = t.frag(['{dateLimit}\n<b>{timeLimit}</b>', ''], {
          dateLimit: limit.dateLimit,
          timeLimit: isOverSLA ? 'Over SLA' : limit.timeLimit,
          b: (child) => (
            <Text color="error" style={{ fontWeight: 'bold' }}>
              {child}
            </Text>
          ),
        });
      }
    } else {
      //other status goes here
      returned = t.frag(['{dateLimit}', ''], {
        dateLimit: limit.dateLimit,
      });
    }

    return returned;
  };

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const onSelectRegion = useCallback((selected: Option) => {
    setSelectedRegion(selected);
    setSelectedCity(null);
  }, []);

  const setStatus = useCallback((option: Option) => {
    setSelectedStatus(option ? (option.value as CmsRequestStatusFilter) : null);
  }, []);

  const submitSearch = useCallback(() => {
    setSearchByRegion(selectedRegion?.value ?? null);
    setSearchByCity(selectedCity?.value ?? null);
    setSearchByBrand(selectedBrand?.value ?? null);
    setSearchByToko(searchToko);
    setSearchByIdToko(searchIdToko);
    setSearchNewStatus(null);
    setSearchStatus(selectedStatus);
    setSearchByDateFrom(selectedDateFrom ?? null);
    setSearchByDateUntil(selectedDateUntil ?? null);
    setRequestDateFrom(selectedRequestDateFrom);
    setRequestDateUntil(selectedRequestDateUntil);
    setPage(0);
  }, [
    searchIdToko,
    searchToko,
    selectedBrand,
    selectedCity,
    selectedDateFrom,
    selectedDateUntil,
    selectedRegion,
    selectedStatus,
    selectedRequestDateFrom,
    selectedRequestDateUntil,
  ]);

  const clearSearch = useCallback(() => {
    setSelectedRegion(null);
    setSelectedCity(null);
    setSelectedBrand(null);
    setSearchToko('');
    setSearchIdToko('');
    setSelectedStatus(null);
    setSelectedDateFrom(null);
    setSelectedRequestDateFrom(null);
    setSelectedDateUntil(null);
    setSelectedRequestDateUntil(null);

    setSearchByRegion(null);
    setSearchByCity(null);
    setSearchByDateFrom(null);
    setSearchByDateUntil(null);
    setSearchByBrand(null);
    setSearchByIdToko(null);
    setSearchByToko(null);
    setRequestDateFrom(null);
    setRequestDateUntil(null);

    refetch();
  }, [refetch]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar showBackButton={role !== RoleAdmin.PRINCIPAL} />
      <Header
        title={t(['Toko', 'Toko'])}
        subtitle={t([
          'Status Permintaan Distributor',
          'Distributor Request Status',
        ])}
        subtitleColor="default"
        style={styles.header}
      />
      {!isAdmin ? (
        loadingTotalRequestAgent ? (
          <ActivityIndicator />
        ) : (
          <View style={[styles.separator, styles.containerBox]}>
            <View style={[styles.rowKeeper, styles.leftSideContainer]}>
              <View style={[styles.row, styles.boxTotal]}>
                <View>
                  <Text style={styles.boxTitle}>{'Principal'}</Text>
                  <Text style={styles.textTotal}>
                    {t([
                      'Total Request Distributor dari Toko App',
                      'Total Distributor Request from Toko App',
                    ])}
                  </Text>
                </View>
                <Text style={styles.textCount}>
                  {Number(
                    (dataTotalRequestAgent?.calculateRequestAgent.request ||
                      0) +
                      (dataTotalRequestAgent?.calculateRequestAgent.overSLA ||
                        0) +
                      (dataTotalRequestAgent?.calculateRequestAgent
                        .rejectByPrincipal || 0),
                  ).toLocaleString('id-ID')}
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.bottomLeftBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Request Baru', 'Distributor New'])}
                  </Text>
                  <Text style={styles.textTotal}>
                    {t(['Distributor', 'Request'])}
                  </Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.request.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                  <Button
                    title={t(['Lihat Toko', 'View Store'])}
                    onPress={() => {
                      setSearchNewStatus(
                        NewCmsRequestStatusFilter.NOT_PAST_DEADLINE,
                      );
                      setSearchStatus(CmsRequestStatusFilter.REQUEST);
                    }}
                    style={styles.viewStoreButton}
                  />
                </View>
                <View style={styles.bottomBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Request baru', 'New Request'])}
                  </Text>
                  <Text style={styles.overSLAtext}>{'(over SLA)'}</Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.overSLA.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                  <Button
                    title={t(['Lihat Toko', 'View Store'])}
                    onPress={() => {
                      setSearchNewStatus(
                        NewCmsRequestStatusFilter.PAST_DEADLINE,
                      );
                      setSearchStatus(CmsRequestStatusFilter.REQUEST);
                    }}
                    style={styles.viewStoreButton}
                  />
                </View>
                <View style={styles.bottomRightBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Ditolak oleh', 'Rejected by'])}
                  </Text>
                  <Text style={styles.textTotal}>
                    {t(['Principal', 'Principal'])}
                  </Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.rejectByPrincipal.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                </View>
              </View>
            </View>
            {/* --------------------------------------------------------------------------------- */}
            <View style={[styles.rowKeeper, styles.rightSideContainer]}>
              <View style={[styles.row, styles.boxTotal]}>
                <View>
                  <Text style={styles.boxTitle}>{'Distributor'}</Text>
                  <Text style={styles.textTotal}>
                    {t([
                      'Total Toko yang sudah melalui Principal',
                      'Total Store Went through Principal',
                    ])}
                  </Text>
                </View>
                <Text style={styles.textCount}>
                  {Number(
                    (dataTotalRequestAgent?.calculateRequestAgent.cancel || 0) +
                      (dataTotalRequestAgent?.calculateRequestAgent
                        .rejectByDistributor || 0) +
                      (dataTotalRequestAgent?.calculateRequestAgent.approve ||
                        0) +
                      (dataTotalRequestAgent?.calculateRequestAgent.review ||
                        0),
                  ).toLocaleString('id-ID')}
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.bottomLeftBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Diterima oleh', 'Accepted by'])}
                  </Text>
                  <Text style={styles.textTotal}>
                    {t(['Distributor', 'Distributor'])}
                  </Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.approve.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                </View>
                <View style={styles.bottomBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Menunggu', 'Waiting'])}
                  </Text>
                  <Text style={styles.textTotal}>
                    {t(['Distributor', 'Distributor'])}
                  </Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.review.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                </View>

                <View style={styles.bottomBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Ditolak oleh', 'Rejected by'])}
                  </Text>
                  <Text style={styles.textTotal}>
                    {t(['Distributor', 'Distributor'])}
                  </Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.rejectByDistributor.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                </View>
                <View style={styles.bottomRightBoxTotal}>
                  <Text style={styles.textTotal}>
                    {t(['Dibatalkan Sistem', 'Canceled by System'])}
                  </Text>
                  <Text style={styles.overSLAtext}>{'(over SLA)'}</Text>
                  <Text style={styles.textCount}>
                    {dataTotalRequestAgent?.calculateRequestAgent.cancel.toLocaleString(
                      'id-ID',
                    ) || 0}
                  </Text>
                  <Button
                    title={t(['Lihat Toko', 'View Store'])}
                    onPress={() => {
                      setSearchNewStatus(null);
                      setSearchStatus(CmsRequestStatusFilter.CANCELED);
                    }}
                    style={styles.viewStoreButton}
                  />
                </View>
              </View>
            </View>
            {/* <View style={styles.boxTotal}>
              <Text style={styles.textTotal}>
                {t([
                  'Total Pemberian Distributor',
                  'Total Accepted Distributors',
                ])}
              </Text>
              <Text style={styles.textCount}>
                {dataTotalRequestAgent?.calculateRequestAgent.approve || 0}
              </Text>
            </View> */}
          </View>
        )
      ) : null}

      <SearchHeader
        onSearchPress={submitSearch}
        onClearPress={clearSearch}
        disabled={searchDisabled}
      >
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-toko"
            label={t(['Cari Toko', 'Search Toko'])}
            value={searchToko ?? ''}
            setValue={setSearchToko}
          />
        </View>

        {isAdmin ? (
          <View style={styles.separator}>
            <SearchByText
              data-testid="search-by-kode"
              label={t(['Cari Kode Toko', 'Search by Toko Code'])}
              value={searchIdToko ?? ''}
              setValue={setSearchIdToko}
            />
          </View>
        ) : null}
        {/* empty view to make the tanggal pengajuan goes to right */}
        <View />

        {isAdmin ? (
          <View style={styles.separator}>
            <BrandDropdown
              data-testid="search-brand-dropdown"
              type="side"
              title={t(['Cari Merek', 'Search by Brands'])}
              selectedOption={selectedBrand?.value}
              onSelect={setSelectedBrand}
            />
          </View>
        ) : null}

        <View style={styles.separator}>
          <RegionDropdown
            data-testid="search-region-dropdown"
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={selectedRegion?.value}
            onSelect={onSelectRegion}
          />
        </View>

        {!isAdmin ? (
          <View style={styles.separator}>
            <SearchByText
              data-testid="search-by-kode"
              label={t(['Cari Kode Toko', 'Search by Toko Code'])}
              value={searchIdToko ?? ''}
              setValue={setSearchIdToko}
            />
          </View>
        ) : null}

        <View style={styles.separator}>
          <CityDropdown
            data-testid="search-city-dropdown"
            type="side"
            provincesID={selectedRegion ? [selectedRegion.value] : []}
            title={t(['Cari Kota', 'Search by City'])}
            selectedOption={selectedCity?.value}
            onSelect={setSelectedCity}
          />
        </View>

        <View style={styles.separator}>
          <Dropdown
            data-testid="search-request-agent-status"
            type="side"
            title={t(['Cari Status', 'Search by Status'])}
            options={statusOptions}
            selectedOption={selectedStatus ?? undefined}
            onSelect={setStatus}
          />
        </View>

        <View style={styles.separator}>
          <SearchByDateRange
            data-testid="slider-search-registration-date"
            title={t([
              'Cari Tanggal Registrasi',
              'Search by Registration Date',
            ])}
            from={selectedDateFrom}
            until={selectedDateUntil}
            setFrom={(data) => {
              let from: Date = new Date(data);
              from.setHours(0, 0, 0);
              setSelectedDateFrom(from);
            }}
            setUntil={(data) => {
              let until: Date = new Date(data);
              until.setHours(23, 59, 59);
              setSelectedDateUntil(until);
            }}
            setDisabled={setSearchDisabled}
          />
        </View>
        <View style={styles.separator}>
          <SearchByDateRange
            title={t(['Cari Tanggal Pengajuan', 'Search by Request Date'])}
            from={selectedRequestDateFrom}
            until={selectedRequestDateUntil}
            setFrom={(data) => {
              let from: Date = new Date(data);
              from.setHours(0, 0, 0);
              setSelectedRequestDateFrom(from);
            }}
            setUntil={(data) => {
              let until: Date = new Date(data);
              until.setHours(23, 59, 59);
              setSelectedRequestDateUntil(until);
            }}
            setDisabled={setSearchDisabled}
          />
        </View>
      </SearchHeader>

      <View style={styles.exportButtonContainer}>
        <Button
          data-testid="request-agent-download"
          title={t(['Ekspor Semua Data', 'Export All Data'])}
          onPress={onExportPress}
          style={styles.exportButton}
          isLoading={createRADownloadOrderLoading}
        />
      </View>

      <View style={styles.tableSeparator} />
      <Table
        data-testid="agent-request-status-table"
        error={!!error}
        loading={loading}
        dataCount={data?.requestAgentAdvanceSearch.total || 0}
        data={data?.requestAgentAdvanceSearch.row ?? []}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        noHeaderUpperCase={true}
        isHighlightedHead={true}
        override={true}
        separatorType2={true}
        onRequestSort={onRequestSort}
        structure={{
          createdAt: {
            orderable: true,
            headerTitle: t(['Tanggal\nPengajuan', 'distributor request date']),
            render: ({ createdAt }) => {
              return <Text>{getFormattedDate(new Date(createdAt))}</Text>;
            },
          },

          status: {
            orderable: true,
            headerTitle: 'Status\nPengajuan',
            render: ({
              rejectedBy,
              cmsRequestStatus,
              store,
              status,
            }: AgentRequests_requestAgentAdvanceSearch_row) => {
              if (!store.storeManager?.statusActive) {
                return (
                  <Text bold color="accepted">
                    {t(['Inactive', 'Inactive'])}
                  </Text>
                );
              } else {
                if (cmsRequestStatus === CmsRequestStatus.APPROVED) {
                  return (
                    <Text bold color="accepted">
                      {t([
                        'Diterima oleh Distributor',
                        'Approved By Distributor',
                      ])}
                    </Text>
                  );
                } else if (cmsRequestStatus === CmsRequestStatus.REVIEW) {
                  return (
                    <Text bold color="waiting">
                      {t(['Menunggu Distributor', 'Waiting For Distributor'])}
                    </Text>
                  );
                } else if (rejectedBy === RejectedBy.PRINCIPAL) {
                  return (
                    <Text bold color="error">
                      {t(['Ditolak oleh Principal', 'Ditolak oleh Principal'])}
                    </Text>
                  );
                } else if (rejectedBy === RejectedBy.DISTRIBUTOR) {
                  return (
                    <Text bold color="error">
                      {t([
                        'Ditolak oleh Distributor',
                        'Ditolak oleh Distributor',
                      ])}
                    </Text>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.REJECT &&
                  status === 'REJECTED'
                ) {
                  return (
                    <Text bold color="error">
                      {t(['Ditolak oleh Principal', 'Ditolak oleh Principal'])}
                    </Text>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.REJECT &&
                  status === 'REQUEST'
                ) {
                  return (
                    <Text bold color="error">
                      {t([
                        'Ditolak oleh Distributor',
                        'Ditolak oleh Distributor',
                      ])}
                    </Text>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.CANCEL &&
                  status === RequestStatus.CANCELED
                ) {
                  return (
                    <View style={styles.yellowBox}>
                      <Text bold color="error" style={styles.textRejectSystem}>
                        {t([
                          'Dibatalkan oleh Sistem',
                          'Dibatalkan oleh Sistem',
                        ])}
                      </Text>
                    </View>
                  );
                } else if (
                  cmsRequestStatus === CmsRequestStatus.UNMATCHED ||
                  status === RequestStatus.UNMATCHED
                ) {
                  return (
                    <Text bold color="error">
                      {t(['Tidak Lagi Terhubung', 'Tidak Lagi Terhubung'])}
                    </Text>
                  );
                } else {
                  return (
                    <Text bold color="waiting">
                      {t(['Menunggu Principal', 'Waiting For Principal'])}
                    </Text>
                  );
                }
              }
            },
          },
          storeNameAndCode: {
            headerTitle: t(['Nama dan\nKode Toko', 'toko code']),
            render: ({ store }) => (
              <View>
                <Text bold>{store.storeManager?.storeName}</Text>
                <Text>{store.managerStoreCode}</Text>
              </View>
            ),
          },
          provinceAndCityId: {
            headerTitle: t(['Provinsi dan\nKota/Kabupaten', 'Province']),
            render: ({ store }) => (
              <View>
                <Text>{store.provinceName}</Text>
                <Text>{store.cityName}</Text>
              </View>
            ),
          },
          // ...(role === RoleAdmin.PRINCIPAL
          //   ? {
          //       orderDefinition: options.orderDefinition,
          //       orderSource: options.orderSource,
          //     }
          //   : {}),
          ...(role !== RoleAdmin.PRINCIPAL ? orderDefinition : {}),
          ...(role !== RoleAdmin.PRINCIPAL ? requestSource : {}),

          totalAmountOrder: {
            headerTitle: 'Nilai Order\n(Rp)',
            render: ({ orderId, totalOrderAmount }) => {
              return orderId && totalOrderAmount ? (
                <View style={styles.minWidth70}>
                  <Text>{convertToCurrencyRupiah(totalOrderAmount)}</Text>
                </View>
              ) : (
                '-'
              );
            },
          },
          salesmanInfo: {
            headerTitle: 'Info Referral\nSalesman',
            render: ({ salesmanInfo }) =>
              salesmanInfo?.salesmanReferralCode ? (
                <View style={styles.minWidth100}>
                  {t.frag(
                    [
                      '<b>{codeName}</b>\n{distributor}',
                      '<b>{codeName}</b>\n{distributor}',
                    ],
                    {
                      codeName: `${salesmanInfo?.salesmanReferralCode} - ${salesmanInfo.salesmanName}`,
                      distributor: salesmanInfo.distributorName,
                      b: (child: string) => <Text bold>{child}</Text>,
                    },
                  )}
                </View>
              ) : (
                '-'
              ),
          },
          agentName: {
            headerTitle: 'Distributor\nTujuan',
            orderable: true,
            render: ({
              agentName,
            }: AgentRequests_requestAgentAdvanceSearch_row) => {
              return <Text>{agentName || '-'}</Text>;
            },
          },
          assignDateAgent: {
            headerTitle: t([
              'Tanggal Assign\nDistributor',
              'distributor assign date (cms principal)',
            ]),
            render: ({ assignDateAgent }) => {
              return (
                <View style={styles.minWidth80}>
                  <Text>
                    {assignDateAgent
                      ? getFormattedDate(new Date(assignDateAgent))
                      : '-'}
                  </Text>
                </View>
              );
            },
          },

          approveDateAgent: {
            headerTitle: t([
              'Tanggal\nApprove/Reject',
              'last approve/reject date (Principal/Distributor)',
            ]),
            render: ({
              approveDateAgent,
              rejectDateAgent,
            }: AgentRequests_requestAgentAdvanceSearch_row) => {
              let date = approveDateAgent
                ? getFormattedDate(new Date(approveDateAgent as string))
                : rejectDateAgent
                ? getFormattedDate(new Date(rejectDateAgent as string))
                : '-';
              return (
                <View style={styles.minWidth80}>
                  <Text>{date}</Text>
                </View>
              );
            },
          },

          timeLimit: {
            headerTitle: t(['Batas Waktu Approval', 'batas waktu approval']),
            render: (data) => {
              return (
                <View style={styles.minWidth80}>{renderDeadline(data)}</View>
              );
            },
          },
          ...(role === RoleAdmin.PRINCIPAL ? action : {}),
        }}
      />
      {!!error && (
        <ErrorMessage
          action={t([
            'mengambil data permintaan distributor',
            'retrieve the distributor request data',
          ])}
          error={error}
          onPress={refetchData}
        />
      )}
      <ProgressBarModal
        open={progressOpen}
        onClose={() => setProgressOpen(false)}
        progress={
          (current / max) * 100 > 100
            ? 100
            : Number.parseFloat(((current / max) * 100).toFixed(2))
        }
        isFailed={isDownloadFailed}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  button: {
    width: 117,
    textTransform: 'none',
    paddingHorizontal: 10,
  },
  header: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    paddingBottom: spacing.large,
    marginBottom: spacing.medium,
  },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  tableSeparator: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginBottom: spacing.small,
    marginTop: spacing.medium,
  },

  exportButton: {
    padding: 0,
    minWidth: 200,
  },
  viewStoreButton: {
    padding: 0,
    minWidth: 130,
  },
  exportButtonContainer: {
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    marginTop: 8,
  },
  containerBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: spacing.medium,
  },
  boxTotal: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.box.border,
    padding: spacing.small,
    marginHorizontal: spacing.small,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomBoxTotal: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.box.border,
    padding: spacing.small,
    marginTop: spacing.small,
    marginHorizontal: spacing.small,
    minHeight: 150,
    alignItems: 'center',
  },
  bottomLeftBoxTotal: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.box.border,
    padding: spacing.small,
    marginTop: spacing.small,
    marginRight: spacing.small,
    minHeight: 150,
    alignItems: 'center',
  },
  bottomRightBoxTotal: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.box.border,
    padding: spacing.small,
    marginTop: spacing.small,
    marginLeft: spacing.small,
    minHeight: 150,
    alignItems: 'center',
  },
  leftSideContainer: { flex: 2 },
  rightSideContainer: { flex: 3 },
  boxTitle: {
    fontSize: fonts.sizes.mediumLarge,
    fontWeight: 'bold',
    paddingBottom: spacing.xxsmall,
  },
  rowKeeper: {
    flex: 1,
    borderColor: colors.box.border,
    padding: spacing.small,
    marginHorizontal: spacing.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTotal: {
    fontSize: fonts.sizes.default,
    alignItems: 'flex-start',
  },
  overSLAtext: {
    fontSize: fonts.sizes.default,
    alignItems: 'flex-start',
    fontWeight: 'bold',
    color: colors.text.error,
  },
  textCount: {
    fontWeight: 'bold',
    fontSize: fonts.sizes.large,
    lineHeight: 48,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  minWidth70: { minWidth: 70 },
  minWidth80: { minWidth: 90 },
  minWidth100: { minWidth: 100 },
  textRejectSystem: {
    textAlign: 'left',
  },
  yellowBox: {
    maxWidth: '90%',
    minHeight: 50,
    justifyContent: 'center',
    backgroundColor: theme.colors.table.highlightedRow,
  },
});
