import React, { CSSProperties, ReactNode, memo } from 'react';
import {
  StyleProp,
  TextStyle,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Button as MButton, makeStyles, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';

import { Text, TextProps } from '../core-ui';
import { colors } from '../constants/theme';

type Props = ButtonProps & {
  'data-testid'?: string;
  title?: string;
  icon?: ReactNode;
  onPress?: () => void;
  preset?: keyof typeof colors.button;
  style?: StyleProp<TextStyle>;
  disabled?: boolean;
  isLoading?: boolean;
  titleTextProps?: TextProps;
  iconAndText?: boolean;
  disableRipple?: boolean;
};

const useStyles = makeStyles({
  defaultButton: { height: 36, boxShadow: 'none' },
  primary: {
    backgroundColor: colors.button.primary.background,
    '&:hover': {
      backgroundColor: colors.button.primary.background,
    },
  },
  secondary: {
    backgroundColor: colors.button.secondary.background,
    '&:hover': {
      backgroundColor: colors.button.secondary.background,
    },
  },
  optional: {
    backgroundColor: colors.button.optional.background,
    '&:hover': {
      backgroundColor: colors.button.optional.background,
    },
  },
  warning: {
    backgroundColor: colors.button.warning.background,
    '&:hover': {
      backgroundColor: colors.button.warning.background,
    },
  },
  transparent: {
    padding: 0,
    '&:hover': {
      backgroundColor: colors.button.transparent.background,
    },
  },
});

const Button = memo((props: Props) => {
  const classes = useStyles();
  let {
    title,
    icon,
    onPress,
    preset = 'primary',
    style,
    disabled,
    isLoading,
    titleTextProps,
    iconAndText,
    'data-testid': dataCy,
    disableRipple = preset === 'transparent',
    ...otherProps
  } = props;

  return (
    <MButton
      disableElevation
      variant={preset === 'transparent' ? 'text' : 'contained'}
      className={clsx(classes.defaultButton, classes[preset])}
      onClick={!isLoading ? onPress : undefined}
      style={StyleSheet.flatten(style) as CSSProperties}
      disabled={disabled}
      disableRipple={disableRipple}
      data-testid={dataCy}
      {...otherProps}
    >
      {isLoading ? (
        <ActivityIndicator data-testid={`${dataCy}-loading`} />
      ) : (
        <>
          {icon}
          {(!icon || iconAndText) && (
            <Text
              bold
              color={disabled ? 'disabled' : colors.button[preset].text}
              {...titleTextProps}
            >
              {title}
            </Text>
          )}
        </>
      )}
    </MButton>
  );
});

export default Button;
