import React, { ComponentType } from 'react';
import clsx from 'clsx';
import { StyleSheet } from 'react-native';
import {
  TableRow as MTableRow,
  TableCellProps,
  TableCell,
  TableRowProps,
  makeStyles,
} from '@material-ui/core';

import { Button } from '..';
import { toDdMmYy } from '../../helpers';
import { colors } from '../../features/verification/constants/theme';
import { TypeVerify } from '../../generated/globalTypes';
import type { Props as TextProps } from '../Text';
import { theme } from '../../constants/theme';

import type { Props as TableProps } from './Table';

type Props<T, U, P> = Pick<TableProps<T, U, P>, 'structure'> & {
  'data-testid'?: string;
  rowClasses?: TableRowProps['classes'];
  cellClasses?: TableCellProps['classes'];
  flattenedRowData: U;
  rowData: T;
  rowNumber: number;
  override?: boolean;
  TextComponent: ComponentType<P>;
};

const useStyles = makeStyles({
  tokoBorder: {
    borderLeft: `4px solid ${colors.table.row.toko} !important`,
  },
  ownerBorder: {
    borderLeft: `4px solid ${colors.table.row.owner} !important`,
  },
  defaultBorder: {
    borderLeft: `1px solid ${colors.table.row.border}`,
  },
  normalCell: { backgroundColor: theme.colors.table.backgroundWhite },
  removeHorizontalPadding: { padding: '6px 12px 6px 6px' },
  leftMost: {
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.colors.table.innerBorder,
    backgroundColor: theme.colors.table.backgroundWhite,
  },
  rightMost: {
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.colors.table.innerBorder,
    backgroundColor: theme.colors.table.backgroundWhite,
  },
  between: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.colors.table.innerBorder,
    backgroundColor: theme.colors.table.backgroundWhite,
  },
});

const TableRow = <
  T extends ObjectOf<unknown>, // NOTE: the shape of the data
  U extends { [key in keyof T | string]: unknown } // NOTE: the shape of the flattened data
>(
  props: Props<T, U, TextProps>,
) => {
  let {
    cellClasses,
    rowClasses,
    flattenedRowData,
    rowData,
    structure,
    rowNumber,
    override = false,
    TextComponent,
  } = props;
  const {
    tokoBorder,
    ownerBorder,
    defaultBorder,
    leftMost,
    between,
    normalCell,
    removeHorizontalPadding,
    rightMost,
  } = useStyles();

  return (
    <MTableRow data-testid={props['data-testid']} classes={rowClasses}>
      {Object.keys(structure).map((dataKey, index) => {
        let {
          align,
          'data-testid': structureDataCy,
          dataPath,
          disabled,
          displayText,
          onPress,
          render,
          valueProcessor,
          valuePreProcessor,
          getStyle,
          onCellClick,
          withLeftBorder,
          textProps,
        } = structure[dataKey] ?? {};

        let mergedCellClasses: typeof cellClasses = {};
        if (withLeftBorder) {
          let leftBorderStyle = defaultBorder;
          if (rowData.type === TypeVerify.TOKO) {
            leftBorderStyle = tokoBorder;
          } else if (rowData.type === TypeVerify.USER) {
            leftBorderStyle = ownerBorder;
          }
          mergedCellClasses = {
            ...cellClasses,
            root: clsx(cellClasses?.root, leftBorderStyle),
          };
        } else if (override) {
          if (index === 0) {
            mergedCellClasses = {
              ...cellClasses,
              root: clsx(leftMost, normalCell, removeHorizontalPadding),
            };
          } else if (index + 1 === Object.keys(structure).length) {
            mergedCellClasses = {
              ...cellClasses,
              root: clsx(rightMost, normalCell, removeHorizontalPadding),
            };
          } else {
            mergedCellClasses = {
              ...cellClasses,
              root: clsx(between, normalCell, removeHorizontalPadding),
            };
          }
        } else {
          mergedCellClasses = cellClasses;
        }

        const defaultRender = () => {
          let value: unknown;
          if (dataPath) {
            if (Array.isArray(dataPath)) {
              let index = 0;
              do {
                // NOTE: loop through the dataPath array until we get the value or run out of dataPath
                value = flattenedRowData[dataPath[index++]];
              } while (!value && index < dataPath.length);
            } else {
              value = flattenedRowData[dataPath];
            }
          } else {
            value = rowData[dataKey];
          }
          value = valuePreProcessor?.(value) ?? value;
          let displayedValue = '';
          if (displayText) {
            displayedValue = displayText;
          } else {
            switch (typeof value) {
              case 'object': {
                if (value instanceof Date) {
                  let stringDate = toDdMmYy(value);
                  displayedValue =
                    valueProcessor?.(stringDate, rowNumber) ?? stringDate;
                }
                break;
              }
              case 'string': {
                displayedValue = valueProcessor?.(value, rowNumber) ?? value;
                break;
              }
              case 'number': {
                const localizedValue = value.toLocaleString('id');
                displayedValue =
                  valueProcessor?.(localizedValue, rowNumber) ?? localizedValue;
                break;
              }
            }
          }
          if (onPress) {
            const onTextPress = () => onPress?.(rowData);
            return (
              <Button
                size="small"
                preset="transparent"
                disabled={disabled?.(rowData)}
                title={displayedValue}
                onPress={onTextPress}
                style={styles.button}
              />
            );
          } else {
            let textComponentProps: TextProps = {};
            if (textProps instanceof Function) {
              textComponentProps = textProps(rowData);
            } else if (textProps) {
              textComponentProps = textProps;
            }
            return (
              <TextComponent {...textComponentProps}>
                {displayedValue}
              </TextComponent>
            );
          }
        };

        return (
          <TableCell
            key={index}
            align={align}
            classes={mergedCellClasses}
            data-testid={structureDataCy ?? `${props['data-testid']}-cell`}
            style={getStyle?.(rowData)}
            onClick={() => {
              onCellClick?.(rowData);
            }}
          >
            {render ? render(rowData) : defaultRender()}
          </TableCell>
        );
      })}
    </MTableRow>
  );
};

let styles = StyleSheet.create({
  button: { justifyContent: 'flex-start' },
});

export default TableRow;
