import React, {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  MouseEvent,
  TouchEvent,
  useState,
  useCallback,
  memo,
  ReactNode,
} from 'react';
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  LayoutChangeEvent,
} from 'react-native';
import {
  TextField,
  Popper,
  MenuList,
  MenuItem,
  TextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Option } from '../components';
import { fonts, colors, spacing } from '../constants/theme';

import Text from './Text';

export type Props = Partial<TextFieldProps> & {
  'data-testid'?: string;
  value: string;
  onChangeText: (text: string) => void;
  label?: string;
  sublabel?: string;
  placeholder?: string;
  type?: string;
  containerStyle?: StyleProp<ViewStyle>;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  maxLengthInside?: boolean;
  rightButton?: ReactNode;
  disabled?: boolean;
  onSubmitEditing?: () => void;
  maxRows?: number;
  boldLabel?: boolean;
  showSuggestions?: boolean;
  suggestions?: Array<Option>;
  suggestionsLoading?: boolean;
  noDecimalPoint?: boolean;
  onSelectSuggestion?: (value: Option) => void;
  setValue?: (value: string) => void;
  setShowSuggestions?: (value: boolean) => void;
  InputProps?: TextFieldProps['InputProps'];
  errorMessage?: string;
};

const DEFAULT_ROW_NUMBER = 3;

const useStyles = makeStyles({
  root: {
    '&$focused $notchedOutline': {
      borderColor: colors.textInput.activeBorder,
    },
    '&$disabled $notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: colors.textInput.disabled,
    },
    '&$error $notchedOutline': {
      borderColor: colors.textInput.errorBorder,
    },
  },
  focused: {},
  disabled: {},
  notchedOutline: { borderColor: colors.border.primary },
  multiline: { padding: 12 },
  menuItem: { textAlign: 'left' },
});

const TextInput = memo((props: Props) => {
  let { menuItem, ...inputClasses } = useStyles();
  let {
    error,
    errorMessage,
    value,
    label,
    sublabel,
    onChangeText,
    placeholder,
    type,
    containerStyle,
    multiline,
    rows = DEFAULT_ROW_NUMBER,
    maxLength,
    maxLengthInside,
    rightButton,
    disabled,
    onSubmitEditing,
    maxRows,
    boldLabel = true,
    showSuggestions,
    suggestions,
    suggestionsLoading,
    noDecimalPoint,
    onSelectSuggestion,
    setValue,
    setShowSuggestions,
    InputProps,
    ...otherProps
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [compWidth, setCompWidth] = useState(0);
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
      !anchorEl && setAnchorEl(event.currentTarget);
    },
    [anchorEl, setAnchorEl],
  );

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => setCompWidth(e.nativeEvent.layout.width),
    [setCompWidth],
  );

  let onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    if (maxRows) {
      let numberOfLines = newValue.trim().split('\n').length;
      numberOfLines < maxRows
        ? onChangeText(newValue)
        : onChangeText(newValue.trim());
    } else if (maxLength) {
      onChangeText(newValue.slice(0, maxLength));
    } else if (type === 'number') {
      if (noDecimalPoint) {
        onChangeText(newValue.replace(/\D/g, ''));
      } else {
        onChangeText(newValue.replace(/[^\d\.]/g, ''));
      }
    } else {
      onChangeText(newValue);
    }
  };

  let onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && onSubmitEditing) {
      onSubmitEditing();
    }
  };

  let renderBottomLabel = () => {
    if (maxLength && !maxLengthInside) {
      return (
        <Text bold style={styles.bottomLabel}>
          {value.length}/{maxLength}
        </Text>
      );
    } else if (maxRows) {
      let numberOfLines = value !== '' ? value.trim().split('\n').length : 0;
      return (
        <Text bold style={styles.bottomLabel}>
          {numberOfLines}/{maxRows} {t(['baris', 'rows'])}
        </Text>
      );
    }
  };

  const renderSuggestionPopper = () => (
    <Popper
      open={
        !!showSuggestions &&
        ((!!suggestions && suggestions.length > 0) || !!suggestionsLoading) &&
        !!anchorEl
      }
      anchorEl={anchorEl}
      disablePortal
    >
      <View style={styles.popperBox}>
        <MenuList>
          {suggestionsLoading ? (
            <View style={{ width: compWidth }}>
              <ActivityIndicator
                data-testid={`${props['data-testid']}-loading`}
              />
            </View>
          ) : (
            suggestions?.map((item, index) => (
              <MenuItem
                data-testid={`${props['data-testid']}-suggestion-${index}`}
                key={index}
                value={item.value}
                style={{ width: compWidth }}
                classes={{ root: menuItem }}
                onClick={() => {
                  onSelectSuggestion?.(item);
                  setValue?.(item.label);
                  setShowSuggestions?.(false);
                }}
              >
                <Text>{item.label}</Text>
              </MenuItem>
            ))
          )}
        </MenuList>
      </View>
    </Popper>
  );

  const renderErrorPopper = () => (
    <Popper
      open={!!error && !!anchorEl}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <View style={[styles.popperBox, styles.errorContainer]}>
        <Text>{errorMessage}</Text>
      </View>
    </Popper>
  );

  return (
    <View style={containerStyle}>
      {label && (
        <Text style={styles.label}>
          <Text bold={boldLabel}>{label} </Text>
          {sublabel}
        </Text>
      )}
      <View onLayout={onLayout}>
        <TextField
          error={error}
          data-testid={props['data-testid']}
          onClick={handleClick}
          onTouchStart={handleClick} // NOTE: to handle mobile devices
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type={type !== 'number' ? type : undefined}
          variant="outlined"
          multiline={multiline}
          rows={rows}
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{
            style: StyleSheet.flatten([
              styles.input,
              !multiline && styles.height,
            ]) as CSSProperties,
          }}
          InputProps={{
            classes: inputClasses,
            endAdornment:
              maxLength && maxLengthInside ? (
                <Text bold size="small" color="link">
                  {value.length}/{maxLength}
                </Text>
              ) : (
                rightButton && (
                  <InputAdornment position="end">{rightButton}</InputAdornment>
                )
              ),
            ...InputProps,
          }}
          {...otherProps}
        />
      </View>
      {renderBottomLabel()}
      {renderSuggestionPopper()}
      {renderErrorPopper()}
    </View>
  );
});

const styles = StyleSheet.create({
  label: {
    marginBottom: spacing.xsmall,
    color: colors.textInput.label,
  },
  bottomLabel: {
    marginTop: spacing.xsmall,
    alignSelf: 'flex-end',
    color: colors.textInput.bottomLabel,
  },
  height: {
    height: 0,
  },
  input: {
    fontSize: fonts.sizes.default,
    color: colors.textInput.value,
    backgroundColor: colors.textInput.background,
    borderWidth: 0,
  },
  popperBox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.border.primary,
    backgroundColor: colors.textInput.background,
  },
  errorContainer: {
    padding: spacing.xsmall,
    marginTop: spacing.xxsmall,
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 8,
  },
});

export default TextInput;
