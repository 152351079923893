import React, { CSSProperties, ReactNode, memo } from 'react';
import {
  StyleProp,
  TextStyle,
  StyleSheet,
  ActivityIndicator,
  View,
} from 'react-native';
import { Button as MButton, makeStyles, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';

import { colors } from '../constants/theme';

import Text, { Props as TextProps } from './Text';

// NOTE: without omitting the style, we can't use arrays of registered styles from StyleSheet.create
type Props = Omit<ButtonProps, 'style'> & {
  'data-testid'?: string;
  title: string;
  icon?: ReactNode;
  onPress?: () => void;
  preset?: keyof typeof colors.button.background;
  style?: StyleProp<TextStyle>;
  disabled?: boolean;
  isLoading?: boolean;
  large?: boolean;
  iconAndText?: boolean;
  titleTextProps?: TextProps;
};

const useStyles = makeStyles({
  defaultButton: {
    boxShadow: 'none',
    borderRadius: 2,
    textTransform: 'none',
    height: 36,
  },
  large: {
    height: 42,
  },
  primary: {
    backgroundColor: colors.button.background.primary,
    '&:hover': {
      backgroundColor: colors.button.background.primary,
    },
  },
  secondary: {
    border: `1px solid ${colors.button.border.primary}`,
    backgroundColor: colors.button.background.secondary,
    '&:hover': {
      backgroundColor: colors.button.background.secondary,
    },
  },
  orange: {
    backgroundColor: colors.button.background.orange,
    '&:hover': {
      backgroundColor: colors.button.background.orange,
    },
  },
  disabled: {
    backgroundColor: colors.button.background.disabled,
  },
  transparent: {
    backgroundColor: colors.button.background.transparent,
    '&:hover': {
      backgroundColor: colors.button.background.transparent,
    },
  },
});

const Button = memo((props: Props) => {
  const classes = useStyles();
  let {
    title,
    icon,
    onPress,
    preset = 'primary',
    style,
    disabled,
    isLoading,
    large,
    iconAndText,
    titleTextProps,
    ...otherProps
  } = props;

  return (
    <View testID="button">
      <MButton
        disableElevation
        className={clsx(
          classes.defaultButton,
          large && classes.large,
          disabled ? classes.disabled : classes[preset],
        )}
        onClick={!isLoading ? onPress : undefined}
        style={StyleSheet.flatten(style) as CSSProperties}
        disabled={disabled}
        {...otherProps}
      >
        {isLoading ? (
          <View
            testID="loading"
            data-testid={`${props['data-testid']}-loading`}
          >
            <ActivityIndicator />
          </View>
        ) : (
          <>
            {icon}
            {(!icon || iconAndText) && (
              <Text
                bold
                color={
                  preset === 'secondary'
                    ? colors.button.color.secondary
                    : colors.button.color.primary
                }
                {...titleTextProps}
              >
                {title}
              </Text>
            )}
          </>
        )}
      </MButton>
    </View>
  );
});

export default Button;
