import React, { useCallback, memo, ReactNode } from 'react';
import {
  FormControlLabel,
  Checkbox as MCheckbox,
  makeStyles,
} from '@material-ui/core';

import { colors } from '../constants/theme';
import { Text } from '../core-ui';

const useStyles = makeStyles({
  root: {
    height: 18,
    width: 18,
    color: colors.checkbox.unchecked,
    '&$checked': {
      color: colors.checkbox.checked,
    },
  },
  checked: {},
});

type Props = {
  'data-testid'?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelNode?: ReactNode;
  onPress?: (check: boolean) => void;
};

const Checkbox = memo((props: Props) => {
  let { checked = false, disabled, label, labelNode, onPress } = props;
  const classes = useStyles();
  const onChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
      onPress?.(check);
    },
    [onPress],
  );

  return (
    <FormControlLabel
      control={
        <MCheckbox
          color="default"
          classes={classes}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          inputProps={
            { 'data-testid': props['data-testid'] } as React.HTMLAttributes<
              HTMLInputElement
            >
          } // NOTE: somehow this inputProps type doesn't accept data-*
        />
      }
      label={
        labelNode ?? (
          <Text bold size="small">
            {label}
          </Text>
        )
      }
    />
  );
});

export default Checkbox;
