import React, { useEffect } from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { useHistory, Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { logEvent } from 'expo-firebase-analytics';
import { useMutation } from '@apollo/react-hooks';

import { logo } from '../../../../assets';
import { Text, Button } from '../core-ui';
import { colors } from '../constants/theme';
import { routePaths } from '../constants/routes';
import { useAuth } from '../../../context';
import { pageTitle } from '../../../constants/pageTitle';
import { LOGOUT as LOGOUT_MUTATION } from '../../../graphql/mutations';
import { logout as logout_type } from '../../../generated/logout';

import { WorkInProgressScene } from './workInProgress';
import { QueueScene } from './queue';

export default function TokoVerification() {
  let { name, logout } = useAuth();
  let history = useHistory();
  let url = history.location.pathname;
  let tab = url.split('/');
  let selectedTab = tab[tab.length - 1];

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.TokoVerification });
    };
    eventLog();
  }, []);

  const [logoutCMS] = useMutation<logout_type>(LOGOUT_MUTATION);
  const onLogout = async () => {
    await logoutCMS();
    logout();
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View style={styles.headerTitle}>
          <Image source={logo} resizeMode="contain" style={styles.logo} />
          <Text bold size="l">
            {t(['Verifikasi TOKO', 'TOKO Verification'])}
          </Text>
        </View>
        <View style={styles.row}>
          <TouchableOpacity
            data-testid="list-daftar-data"
            style={[
              styles.tab,
              selectedTab === 'list' ? styles.selectedTab : {},
            ]}
            onPress={() => history.push(routePaths.list)}
          >
            <Text
              color={selectedTab === 'list' ? 'black' : 'grey'}
              bold
              size="l"
            >
              {t(['Daftar Data', 'List Of Data'])}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            data-testid="list-dalam-masa-pengerjaan"
            style={[
              styles.tab,
              selectedTab === 'wip' ? styles.selectedTab : {},
            ]}
            onPress={() => history.push(routePaths.wip)}
          >
            <Text
              color={selectedTab === 'wip' ? 'black' : 'grey'}
              bold
              size="l"
            >
              {t(['Dalam Masa Pengerjaan', 'Working In Progress'])}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.logout}>
          <Text size="m" style={{ borderRightWidth: 1, paddingRight: 8 }}>
            Hai,{' '}
            <Text bold size="m">
              {name}
            </Text>
          </Text>
          <Button
            data-testid="btn-logout"
            preset="transparent"
            onPress={onLogout}
            title={t(['Keluar', 'Logout'])}
            titleTextProps={{ color: 'black', size: 'm', bold: false }}
          />
        </View>
      </View>
      <View style={styles.contentWrapper}>
        <Switch>
          <Route path="/" exact>
            <Redirect to={routePaths.list} />
          </Route>
          <Route path={routePaths.list} component={QueueScene} />
          <Route path={routePaths.wip} component={WorkInProgressScene} />
        </Switch>
      </View>
    </View>
  );
}

let styles = StyleSheet.create({
  root: {
    backgroundColor: colors.background.grey,
    flex: 1,
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  header: {
    backgroundColor: colors.background.white,
    flexDirection: 'row',
    height: 80,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    shadowColor: colors.background.shadow,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 16,
    shadowOpacity: 0.04,
    elevation: 5,
    zIndex: 1,
  },
  row: {
    flexDirection: 'row',
    height: '100%',
  },
  contentWrapper: { flex: 1 },
  tab: {
    justifyContent: 'center',
    height: '100%',
    marginHorizontal: 12,
  },
  selectedTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.button.background.primary,
  },
  headerTitle: { alignItems: 'center', flexDirection: 'row' },
  logout: { alignItems: 'center', flexDirection: 'row' },
  logo: { width: 36, height: 36, marginRight: 16 },
});
