import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { saveAs } from 'file-saver';
import DescriptionIcon from '@material-ui/icons/Description';
import { logEvent } from 'expo-firebase-analytics';

import {
  HeaderNavigationBar,
  ErrorMessage,
  ConfirmationModal,
  ErrorModal,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { Text, Button, Table, Infotip } from '../../core-ui';
import {
  GetTemporaryGivenPoint,
  GetTemporaryGivenPointVariables,
} from '../../generated/GetTemporaryGivenPoint';
import { toPascalCase } from '../../features/verification/helpers';
import {
  StatusGivenPoint,
  StatusAddEarnGivenPoint,
  StatusBulkPoint,
} from '../../generated/globalTypes';
import {
  SendPointToDatabaseVariables,
  SendPointToDatabase,
} from '../../generated/SendPointToDatabase';
import {
  SEND_POINT_TO_DATABASE,
  CANCEL_BULK_POINT,
} from '../../graphql/mutations';
import {
  CancelBulkPoint,
  CancelBulkPointVariables,
} from '../../generated/CancelBulkPoint';
import {
  GET_TEMPORARY_GIVEN_POINT,
  DOWNLOAD_INVALID,
} from '../../graphql/queries';
import {
  DownloadInvalidDataCSV,
  DownloadInvalidDataCSVVariables,
} from '../../generated/DownloadInvalidDataCSV';
import { routePaths } from '../../constants/routes';
import { toDdMmYyyy } from '../../helpers';
import { pageTitle } from '../../constants/pageTitle';

const BulkPointReviewScene = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sendOpen, setSendOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [actionsDisabled, setActionsDisabled] = useState(false);

  const openSendModal = useCallback(() => setSendOpen(true), []);
  const closeSendModal = useCallback(() => setSendOpen(false), []);
  const openCancelModal = useCallback(() => setCancelOpen(true), []);
  const closeCancelModal = useCallback(() => setCancelOpen(false), []);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorInstance(error);
      setErrorAction(action);
    },
    [],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.BulkPointReviewScene,
      });
    };
    eventLog();
  }, []);

  const onSendToDatabaseError = useMemo(() => {
    return openErrorModal(
      t(['mengirim data ke database', 'send data to database']),
    );
  }, [openErrorModal]);
  const onCancelBulkPointError = useMemo(() => {
    return openErrorModal(t(['membatalkan poin', 'cancel bulk point']));
  }, [openErrorModal]);

  const { data, loading, error, refetch } = useQuery<
    GetTemporaryGivenPoint,
    GetTemporaryGivenPointVariables
  >(GET_TEMPORARY_GIVEN_POINT, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
      skip: page * rowsPerPage,
      first: rowsPerPage,
    },
  });

  const [sendPointToDatabase, { loading: sendLoading }] = useMutation<
    SendPointToDatabase,
    SendPointToDatabaseVariables
  >(SEND_POINT_TO_DATABASE, {
    variables: {
      bulkPointId: id,
    },
    onError: onSendToDatabaseError,
    onCompleted: (data) => {
      if (data.sendGivenPointToDB.success) {
        setActionsDisabled(true);
        closeSendModal();
      }
    },
  });
  const [
    downloadInvalid,
    { data: downloadData, loading: downloadLoading },
  ] = useLazyQuery<DownloadInvalidDataCSV, DownloadInvalidDataCSVVariables>(
    DOWNLOAD_INVALID,
    {
      variables: { bulkPointId: id },
      onCompleted: (data) => {
        if (data) {
          saveAs(data.downloadInvalidDataCSV.link, 'invalid.csv');
        }
      },
      onError: () => {
        return openErrorModal(t(['mengunduh data', 'downloading data']));
      },
    },
  );

  const [cancelBulkPoint, { loading: cancelLoading }] = useMutation<
    CancelBulkPoint,
    CancelBulkPointVariables
  >(CANCEL_BULK_POINT, {
    variables: {
      bulkPointId: id,
    },
    onError: onCancelBulkPointError,
    onCompleted: (data) => {
      if (data.cancelBulkPoint.success) {
        setActionsDisabled(true);
        closeCancelModal();
      }
    },
  });

  useEffect(() => {
    setActionsDisabled(
      data?.temporaryGivenPoints.row[0].bulkPoint.status !==
        StatusBulkPoint.READY_TO_REVIEW,
    );
  }, [data]);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const onDownloadInvalidData = useCallback(() => {
    if (downloadData) {
      saveAs(downloadData.downloadInvalidDataCSV.link, 'Invalid.csv');
    } else {
      downloadInvalid();
    }
  }, [downloadInvalid, downloadData]);

  const onBackPress = useCallback(() => history.push(routePaths.bulkLoyalty), [
    history,
  ]);

  const sortedData = useMemo(() => {
    return data?.temporaryGivenPoints.row.sort((a, b) => a.no - b.no);
  }, [data]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar
        backDestinationText={t([
          'Masukkan Poin Jumlah Besar',
          'Bulk Point Input',
        ])}
        onBackButtonPress={onBackPress}
      />
      {error && (
        <ErrorMessage
          action={t(['mengambil data file poin', 'fetching point file data'])}
          error={error}
          onPress={refetchData}
        />
      )}
      {!!data?.temporaryGivenPoints.row.length && (
        <>
          <Text bold size="large">
            {data?.temporaryGivenPoints.row[0].bulkPoint.fileName}
          </Text>
          <View style={styles.row}>
            <Button
              data-testid="send-to-db"
              title={t(['Kirim ke Database', 'Send to Database'])}
              onPress={openSendModal}
              style={styles.button}
              disabled={cancelLoading || actionsDisabled}
            />
            {actionsDisabled && (
              <Button
                preset="secondary"
                title={t(['Unduh Data Invalid', 'Download Invalid Data'])}
                titleTextProps={{ color: 'link' }}
                onPress={onDownloadInvalidData}
                style={styles.button}
                isLoading={downloadLoading}
              />
            )}
            {!actionsDisabled && (
              <Button
                data-testid="cancel"
                preset="secondary"
                title={t(['Batal', 'Cancel'])}
                titleTextProps={{ color: 'error' }}
                onPress={openCancelModal}
                isLoading={cancelLoading}
                disabled={sendLoading || actionsDisabled}
                style={styles.button}
              />
            )}
          </View>
          <ConfirmationModal
            data-testid="send-modal"
            keepOpenAfterConfirm
            title={t([
              'Apakah anda yakin ingin mengirim data ke database?',
              'Are you sure you want to send this data to database?',
            ])}
            open={sendOpen}
            onClose={closeSendModal}
            onConfirm={sendPointToDatabase}
            loading={sendLoading}
          />
          <ConfirmationModal
            data-testid="cancel-modal"
            keepOpenAfterConfirm
            title={t([
              'Apakah anda yakin ingin batal mengirim data ke database?',
              'Are you sure you want to cancel sending this data to database?',
            ])}
            open={cancelOpen}
            onClose={closeCancelModal}
            onConfirm={cancelBulkPoint}
            loading={cancelLoading}
          />
          <ErrorModal
            action={errorAction}
            open={errorOpen}
            error={errorInstance}
            onClose={closeErrorModal}
          />
          <Text bold style={styles.smallBottomSpacing}>
            {t(['Pratinjau', 'Preview'])}
          </Text>
          <Table
            data-testid="bulk-point-review-table"
            data={sortedData ?? []}
            dataCount={data.temporaryGivenPoints.total}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={setPage}
            onChangeRowsPerPage={onRowsChange}
            structure={{
              no: {},
              type: {
                headerTitle: t(['tipe', 'type']),
                valueProcessor: toPascalCase,
                textProps: ({ invalidData }) => ({
                  color: invalidData.includes(INVALID_DATA.type)
                    ? 'error'
                    : undefined,
                }),
              },
              typeId: {
                headerTitle: t(['id promo', 'promo id']),
                valueProcessor: (value) => value ?? '-',
                textProps: ({ invalidData }) => ({
                  color: invalidData.includes(INVALID_DATA.typeId)
                    ? 'error'
                    : undefined,
                }),
              },
              name: {
                headerTitle: t(['nama promo', 'promo name']),
                valueProcessor: (value) => value ?? '-',
              },
              codeStore: {
                headerTitle: t(['kode toko', 'store code']),
                render: ({ codeStore, invalidData }) => {
                  return (
                    <Text
                      color={
                        invalidData.includes(INVALID_DATA.codeStore)
                          ? 'error'
                          : undefined
                      }
                    >
                      {codeStore}
                    </Text>
                  );
                },
              },
              storeName: {
                headerTitle: t(['nama toko', 'store name']),
              },
              brand: {
                headerTitle: t(['brand', 'brand']),
                render: ({ brand, invalidData }) => {
                  return (
                    <Text
                      color={
                        invalidData.includes(INVALID_DATA.brand)
                          ? 'error'
                          : undefined
                      }
                    >
                      {brand}
                    </Text>
                  );
                },
              },
              startDate: {
                headerTitle: t([
                  'tanggal mulai (DD/MM/YYYY)',
                  'start date (DD/MM/YYYY)',
                ]),
                render: ({ startDate, invalidData }) => {
                  if (invalidData.includes(INVALID_DATA.startDate)) {
                    return <Text color="error">{startDate}</Text>;
                  } else if (startDate) {
                    return <Text>{toDdMmYyyy(new Date(startDate))}</Text>;
                  }
                },
              },
              endDate: {
                headerTitle: t([
                  'tanggal berakhir (DD/MM/YYYY)',
                  'end date (DD/MM/YYYY)',
                ]),
                render: ({ endDate, invalidData }) => {
                  if (invalidData.includes(INVALID_DATA.endDate)) {
                    return <Text color="error">{endDate}</Text>;
                  } else if (endDate) {
                    return <Text>{toDdMmYyyy(new Date(endDate))}</Text>;
                  }
                },
              },
              totalTransaction: {
                headerTitle: t(['jumlah transaksi', 'total transaction']),
                valueProcessor: (value) => 'Rp. ' + value,
              },
              tonase: {
                headerTitle: t(['tonase', 'tonase']),
                render: ({ tonase }) => <Text>{tonase ? tonase : '-'}</Text>,
              },
              totalPoint: {
                headerTitle: t(['jumlah poin', 'total point']),
                valueProcessor: (value) => value + t([' poin', 'pts']),
                textProps: ({ invalidData }) => ({
                  color: invalidData.includes(INVALID_DATA.totalPoint)
                    ? 'error'
                    : undefined,
                }),
              },
              action: {
                headerTitle: t(['tambah/kurang', 'add/minus']),
                render: ({ action, invalidData }) => {
                  const color = invalidData.includes(INVALID_DATA.action)
                    ? 'error'
                    : undefined;
                  if (action === StatusAddEarnGivenPoint.ADD) {
                    return <Text color={color}>{t(['Tambah', 'Add'])}</Text>;
                  } else {
                    return <Text color={color}>{t(['Kurang', 'Minus'])}</Text>;
                  }
                },
              },
              note: {
                headerTitle: t(['catatan', 'notes']),
              },
              status: {
                noHeaderTitle: true,
                render: ({ status, invalidData }) => {
                  if (status === StatusGivenPoint.VALID) {
                    return (
                      <Text bold color="accepted">
                        {toPascalCase(status)}
                      </Text>
                    );
                  } else if (status === StatusGivenPoint.INVALID) {
                    return (
                      <View style={styles.statusRow}>
                        <Text bold color="error">
                          {toPascalCase(status)}
                        </Text>
                        <Infotip
                          icon={<DescriptionIcon color="inherit" />}
                          popoverProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'right',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'right',
                            },
                          }}
                        >
                          <Text bold color="contrast">
                            {t(['Data invalid: ', 'Invalid data: '])}
                            {invalidData
                              .map((field) => {
                                switch (field) {
                                  case INVALID_DATA.typeId: {
                                    return t(['Id Promo', 'Promo ID']);
                                  }
                                  case INVALID_DATA.codeStore: {
                                    return t(['Kode Toko', 'Store Code']);
                                  }
                                  case INVALID_DATA.startDate: {
                                    return t([
                                      'Tanggal Mulai (dd/mm/yyyy)',
                                      'Start Date (dd/mm/yyyy)',
                                    ]);
                                  }
                                  case INVALID_DATA.endDate: {
                                    return t([
                                      'Tanggal Berakhir (dd/mm/yyyy)',
                                      'End Date (dd/mm/yyyy)',
                                    ]);
                                  }
                                  case INVALID_DATA.totalPoint: {
                                    return t(['Jumlah Poin', 'Total Point']);
                                  }
                                  case INVALID_DATA.action: {
                                    return t(['Tambah/Kurang', 'Add/Minus']);
                                  }
                                  case INVALID_DATA.type: {
                                    return t(['Tipe', 'Type']);
                                  }
                                  case INVALID_DATA.brand: {
                                    return t(['Brand', 'Brand']);
                                  }
                                  default: {
                                    return '';
                                  }
                                }
                              })
                              .join(', ')}
                          </Text>
                        </Infotip>
                      </View>
                    );
                  } else {
                    return (
                      <Text bold color="error">
                        -
                      </Text>
                    );
                  }
                },
              },
            }}
          />
        </>
      )}
    </View>
  );
};

const INVALID_DATA = {
  typeId: 'typeId',
  codeStore: 'codeStore',
  startDate: 'startDate',
  endDate: 'endDate',
  totalPoint: 'totalPoint',
  action: 'action',
  type: 'type',
  brand: 'brand',
};

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    paddingBottom: spacing.medium,
    marginBottom: spacing.medium,
    marginTop: spacing.small,
  },
  button: {
    width: 180,
    marginRight: spacing.small,
  },
  smallBottomSpacing: {
    marginBottom: spacing.small,
  },
  statusRow: { flexDirection: 'row', alignItems: 'center' },
});

export default BulkPointReviewScene;
