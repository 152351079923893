/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessScreen {
  CONDITION = "CONDITION",
  NAVIGATE = "NAVIGATE",
}

export enum AccessUser {
  ADD_STAFF = "ADD_STAFF",
  CAN_ORDER = "CAN_ORDER",
  SPEND_POINTS = "SPEND_POINTS",
}

export enum ArchiveStatus {
  ARCHIVED = "ARCHIVED",
  NORMAL = "NORMAL",
}

export enum CardSliderOrderByType {
  appUser = "appUser",
  businessUnitContactName = "businessUnitContactName",
  city = "city",
  endLiveDate = "endLiveDate",
  liveDate = "liveDate",
  name = "name",
  timeActive = "timeActive",
}

export enum CmsRequestStatus {
  APPROVED = "APPROVED",
  CANCEL = "CANCEL",
  REJECT = "REJECT",
  REVIEW = "REVIEW",
  UNMATCHED = "UNMATCHED",
  WAITING = "WAITING",
}

export enum CmsRequestStatusFilter {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  OVER_SLA = "OVER_SLA",
  REJECTED_BY_DISTRIBUTOR = "REJECTED_BY_DISTRIBUTOR",
  REJECTED_BY_PRINCIPAl = "REJECTED_BY_PRINCIPAl",
  REQUEST = "REQUEST",
  REVIEW = "REVIEW",
  UNMATCHED = "UNMATCHED",
}

export enum CompetitionOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  closeDate = "closeDate",
  competitionStatus = "competitionStatus",
  endLiveDate = "endLiveDate",
  eventDate = "eventDate",
  liveDate = "liveDate",
  name = "name",
  submit = "submit",
  time = "time",
  winners = "winners",
}

export enum CronStatus {
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  FAILED = "FAILED",
  ON_PROCESS = "ON_PROCESS",
}

export enum DocumentAdvanceSearchOrderByType {
  brandName = "brandName",
  documentName = "documentName",
}

export enum DocumentProblemType {
  DATA = "DATA",
  KTP = "KTP",
  OWNER_NAME = "OWNER_NAME",
  SELFIE = "SELFIE",
}

export enum EventOrderByType {
  appUser = "appUser",
  attendees = "attendees",
  brandName = "brandName",
  businessUnitContactName = "businessUnitContactName",
  city = "city",
  endLiveDate = "endLiveDate",
  eventDate = "eventDate",
  liveDate = "liveDate",
  maxParticipant = "maxParticipant",
  name = "name",
  timeEvent = "timeEvent",
  venue = "venue",
}

export enum FilterStoreStatusVerifyInput {
  REVALIDATION = "REVALIDATION",
  UNVERIFIED = "UNVERIFIED",
  UPDATED = "UPDATED",
  VERIFIED = "VERIFIED",
  VERIFIED_MERGE = "VERIFIED_MERGE",
  WAITING = "WAITING",
}

export enum FilterUserStatusVerifyInput {
  UNVERIFIED = "UNVERIFIED",
  UPDATED = "UPDATED",
  VERIFIED = "VERIFIED",
  WAITING = "WAITING",
}

export enum FilterViewDataInput {
  ALL = "ALL",
  STORE_ONLY = "STORE_ONLY",
  USER_ONLY = "USER_ONLY",
}

export enum FlagCreateUser {
  MY_TEAM = "MY_TEAM",
  REGISTER = "REGISTER",
}

export enum FolderType {
  BRAND_IMAGE = "BRAND_IMAGE",
  BRAND_PAGE = "BRAND_PAGE",
  BRAND_RANGE = "BRAND_RANGE",
  CARD_SLIDER = "CARD_SLIDER",
  COMPETITION = "COMPETITION",
  DOCUMENT = "DOCUMENT",
  EVENT = "EVENT",
  LUMPSUM = "LUMPSUM",
  NEWS = "NEWS",
  POP_UP = "POP_UP",
  PROFILE = "PROFILE",
  PROMOTION = "PROMOTION",
}

export enum FormCompetitionOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  competitionStatus = "competitionStatus",
  endLiveDate = "endLiveDate",
  id = "id",
  link = "link",
  liveDate = "liveDate",
  name = "name",
  status = "status",
  time = "time",
}

export enum FormEventOrderByType {
  attendees = "attendees",
  brandName = "brandName",
  businessUnitContactName = "businessUnitContactName",
  city = "city",
  date = "date",
  eventStatus = "eventStatus",
  id = "id",
  name = "name",
  time = "time",
  venue = "venue",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum HistoryType {
  DEDUCT = "DEDUCT",
  EARN = "EARN",
  REDEEM = "REDEEM",
  REFUND = "REFUND",
}

export enum IdCardType {
  KTP = "KTP",
  PASSPORT = "PASSPORT",
  SIM = "SIM",
}

export enum LinkTypePopUp {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  INTERNAL_LINK = "INTERNAL_LINK",
  WHATSAPP = "WHATSAPP",
}

export enum LoyaltySource {
  DOKU = "DOKU",
  TADA = "TADA",
}

export enum LumpSumAction {
  ADD = "ADD",
  DEDUCT = "DEDUCT",
}

export enum LumpSumType {
  COMPETITION = "COMPETITION",
  EVENT = "EVENT",
  PROMOTION = "PROMOTION",
  REWARD = "REWARD",
  TRANSACTION = "TRANSACTION",
}

export enum MediaSocial {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINE = "LINE",
  LINKEDIN = "LINKEDIN",
  OTHER = "OTHER",
  SKYPE = "SKYPE",
  TWITTER = "TWITTER",
  WHATSAPP = "WHATSAPP",
}

export enum MissionCategory {
  MISI_TAMBAHAN = "MISI_TAMBAHAN",
  MISI_UTAMA = "MISI_UTAMA",
  SURVEY = "SURVEY",
}

export enum NewCmsRequestStatusFilter {
  NOT_PAST_DEADLINE = "NOT_PAST_DEADLINE",
  PAST_DEADLINE = "PAST_DEADLINE",
}

export enum NewsOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  clickCount = "clickCount",
  endLiveDate = "endLiveDate",
  liveDate = "liveDate",
  status = "status",
  title = "title",
}

export enum NewsTypeLink {
  EXTERNAL_LINK = "EXTERNAL_LINK",
  INTERNAL_LINK = "INTERNAL_LINK",
  RANGE = "RANGE",
}

export enum OrderByArg {
  asc = "asc",
  desc = "desc",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  CLOSE = "CLOSE",
  OPEN = "OPEN",
  PARTIAL = "PARTIAL",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum OrderType {
  MASTER = "MASTER",
  TEMPORARY = "TEMPORARY",
}

export enum Orientation {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export enum PhoneCategory {
  ANSWERED = "ANSWERED",
  NOT_ANSWERED = "NOT_ANSWERED",
}

export enum PopUpColor {
  BLUE = "BLUE",
  GOLD = "GOLD",
  RED = "RED",
}

export enum PromotionOrderByType {
  appUser = "appUser",
  brandName = "brandName",
  buContactName = "buContactName",
  city = "city",
  endLiveDate = "endLiveDate",
  liveDate = "liveDate",
  name = "name",
  status = "status",
  time = "time",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum RedeemStatus {
  Failed = "Failed",
  Success = "Success",
}

export enum RejectedBy {
  DISTRIBUTOR = "DISTRIBUTOR",
  PRINCIPAL = "PRINCIPAL",
}

export enum RequestAgentDownloadStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  ON_PROGRESS = "ON_PROGRESS",
  WAITING = "WAITING",
}

export enum RequestAgentFrom {
  ORDER = "ORDER",
  PROFILE = "PROFILE",
  REGISTER = "REGISTER",
  VERIF_ADMIN = "VERIF_ADMIN",
}

export enum RequestStatus {
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
  REJECTED = "REJECTED",
  REQUEST = "REQUEST",
  REVIEW = "REVIEW",
  UNMATCHED = "UNMATCHED",
}

export enum RequestVerificationOrderByType {
  confirmAt = "confirmAt",
  confirmBy = "confirmBy",
  createAt = "createAt",
  name = "name",
  region = "region",
  registerAt = "registerAt",
  status = "status",
  type = "type",
  updatedAt = "updatedAt",
}

export enum Role {
  STAFF = "STAFF",
  SUPERVISOR = "SUPERVISOR",
  TOKO_OWNER = "TOKO_OWNER",
}

export enum RoleAdmin {
  ADMIN = "ADMIN",
  MASTER_VERIFICATION_ADMIN = "MASTER_VERIFICATION_ADMIN",
  PRINCIPAL = "PRINCIPAL",
  SUPER_ADMIN = "SUPER_ADMIN",
  SUPER_VERIFICATION_ADMIN = "SUPER_VERIFICATION_ADMIN",
  VERIFICATION_ADMIN = "VERIFICATION_ADMIN",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum StatsCompetition {
  ALL_COMPETITION_WINNERS = "ALL_COMPETITION_WINNERS",
  ALL_USERS_WHO_ENTERED = "ALL_USERS_WHO_ENTERED",
}

export enum StatusActive {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum StatusAddEarnGivenPoint {
  ADD = "ADD",
  MINUS = "MINUS",
}

export enum StatusBulkPoint {
  CANCELLED = "CANCELLED",
  DONE = "DONE",
  FAIL = "FAIL",
  ON_COMMIT = "ON_COMMIT",
  ON_PROGRESS = "ON_PROGRESS",
  READY_TO_REVIEW = "READY_TO_REVIEW",
}

export enum StatusGivenPoint {
  INVALID = "INVALID",
  VALID = "VALID",
}

export enum StatusProgressTempGivenPoint {
  DONE = "DONE",
  READY_TO_REVIEW = "READY_TO_REVIEW",
}

export enum StatusType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum StatusVerify {
  REVALIDATION = "REVALIDATION",
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  VERIFIED_MERGE = "VERIFIED_MERGE",
  WAITING = "WAITING",
}

export enum StorePhotoCategory {
  STORE_PHOTO_FRONT = "STORE_PHOTO_FRONT",
  STORE_PHOTO_INSIDE = "STORE_PHOTO_INSIDE",
}

export enum TadaItemType {
  DIGITAL = "DIGITAL",
  PYSHICAL = "PYSHICAL",
}

export enum TermsAndCondition {
  AGREE = "AGREE",
  DISAGREE = "DISAGREE",
}

export enum Time {
  ALL_DAY = "ALL_DAY",
  DAY = "DAY",
  EVENING = "EVENING",
  MORNING = "MORNING",
}

export enum TypeRollBack {
  VERIFIED = "VERIFIED",
  VERIFIED_MERGE = "VERIFIED_MERGE",
}

export enum TypeVerify {
  TOKO = "TOKO",
  USER = "USER",
}

export enum ValidVisitation {
  GUARANTEED = "GUARANTEED",
  NOT_GUARANTEED = "NOT_GUARANTEED",
}

export enum VerificationClaimType {
  CLAIM = "CLAIM",
  UNCLAIM = "UNCLAIM",
}

export interface AdminOrderByWithRelationInput {
  BulkAgent?: BulkAgentOrderByRelationAggregateInput | null;
  BulkPoint?: BulkPointOrderByRelationAggregateInput | null;
  LogRollBackVerifyStore?: LogRollBackVerifyStoreOrderByRelationAggregateInput | null;
  MissionActivityHistory?: MissionActivityHistoryOrderByRelationAggregateInput | null;
  MissionTopupReward?: MissionTopupRewardOrderByRelationAggregateInput | null;
  RequestAgent?: RequestAgentOrderByRelationAggregateInput | null;
  UpdatedStoreHistory?: UpdatedStoreHistoryOrderByRelationAggregateInput | null;
  UpdatedUserHistory?: UpdatedUserHistoryOrderByRelationAggregateInput | null;
  betaTester?: SortOrder | null;
  brand?: BrandOrderByWithRelationInput | null;
  brandId?: SortOrder | null;
  createdAt?: SortOrder | null;
  email?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
  phoneNumber?: SortOrder | null;
  requestVerification?: RequestVerificationOrderByRelationAggregateInput | null;
  role?: SortOrder | null;
  status?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface AdminWhereInput {
  AND?: AdminWhereInput[] | null;
  BulkAgent?: BulkAgentListRelationFilter | null;
  BulkPoint?: BulkPointListRelationFilter | null;
  LogRollBackVerifyStore?: LogRollBackVerifyStoreListRelationFilter | null;
  MissionActivityHistory?: MissionActivityHistoryListRelationFilter | null;
  MissionTopupReward?: MissionTopupRewardListRelationFilter | null;
  NOT?: AdminWhereInput[] | null;
  OR?: AdminWhereInput[] | null;
  RequestAgent?: RequestAgentListRelationFilter | null;
  UpdatedStoreHistory?: UpdatedStoreHistoryListRelationFilter | null;
  UpdatedUserHistory?: UpdatedUserHistoryListRelationFilter | null;
  betaTester?: BoolNullableFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  phoneNumber?: StringFilter | null;
  requestVerification?: RequestVerificationListRelationFilter | null;
  role?: EnumRoleAdminFilter | null;
  status?: EnumStatusActiveFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface AgreementRegalStatementOrderByWithRelationInput {
  agreementApproveDate?: SortOrder | null;
  agreementStatement?: SortOrder | null;
  agreementStatementApprove?: SortOrder | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  updatedAt?: SortOrder | null;
  user?: UserOrderByWithRelationInput | null;
  userId?: SortOrder | null;
}

export interface AgreementRegalStatementWhereInput {
  AND?: AgreementRegalStatementWhereInput[] | null;
  NOT?: AgreementRegalStatementWhereInput[] | null;
  OR?: AgreementRegalStatementWhereInput[] | null;
  agreementApproveDate?: DateTimeNullableFilter | null;
  agreementStatement?: StringFilter | null;
  agreementStatementApprove?: BoolFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface AutomaticReportRequestAgentEmailListRelationFilter {
  every?: AutomaticReportRequestAgentEmailWhereInput | null;
  none?: AutomaticReportRequestAgentEmailWhereInput | null;
  some?: AutomaticReportRequestAgentEmailWhereInput | null;
}

export interface AutomaticReportRequestAgentEmailOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface AutomaticReportRequestAgentEmailWhereInput {
  AND?: AutomaticReportRequestAgentEmailWhereInput[] | null;
  NOT?: AutomaticReportRequestAgentEmailWhereInput[] | null;
  OR?: AutomaticReportRequestAgentEmailWhereInput[] | null;
  activeSendEmail?: BoolFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringNullableFilter | null;
  id?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BUContactInput {
  email: string;
  idBrand: string;
  name: string;
  phoneNumber: string;
}

export interface BUContactInputWithId {
  email: string;
  id: string;
  idBrand: string;
  name: string;
  phoneNumber: string;
}

export interface BatchCronJobListRelationFilter {
  every?: BatchCronJobWhereInput | null;
  none?: BatchCronJobWhereInput | null;
  some?: BatchCronJobWhereInput | null;
}

export interface BatchCronJobOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BatchCronJobWhereInput {
  AND?: BatchCronJobWhereInput[] | null;
  NOT?: BatchCronJobWhereInput[] | null;
  OR?: BatchCronJobWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  first?: IntFilter | null;
  id?: StringFilter | null;
  last?: IntFilter | null;
  lastSuccessStoreId?: StringNullableFilter | null;
  onProcessStore?: StoreListRelationFilter | null;
  status?: EnumCronStatusFilter | null;
  successfulStore?: StoreListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
  year?: IntFilter | null;
}

export interface BoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface BoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface BrandImageListRelationFilter {
  every?: BrandImageWhereInput | null;
  none?: BrandImageWhereInput | null;
  some?: BrandImageWhereInput | null;
}

export interface BrandImageOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BrandImageOrderByWithRelationInput {
  brand?: BrandOrderByWithRelationInput | null;
  brandId?: SortOrder | null;
  brandPage?: BrandPageOrderByWithRelationInput | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  imageUrl?: SortOrder | null;
  name?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface BrandImageWhereInput {
  AND?: BrandImageWhereInput[] | null;
  NOT?: BrandImageWhereInput[] | null;
  OR?: BrandImageWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  brandPage?: BrandPageWhereInput | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  imageUrl?: StringFilter | null;
  name?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BrandOrderByWithRelationInput {
  AutomaticReportRequestAgentEmail?: AutomaticReportRequestAgentEmailOrderByRelationAggregateInput | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryOrderByRelationAggregateInput | null;
  RequestAgent?: RequestAgentOrderByRelationAggregateInput | null;
  admin?: AdminOrderByWithRelationInput | null;
  brandId?: SortOrder | null;
  brandPage?: BrandPageOrderByWithRelationInput | null;
  businessUnitContact?: BusinessUnitContactOrderByWithRelationInput | null;
  businessUnitContactId?: SortOrder | null;
  cardSlider?: CardSliderOrderByRelationAggregateInput | null;
  competition?: CompetitionOrderByRelationAggregateInput | null;
  createdAt?: SortOrder | null;
  dataDraftOrder?: DataDraftOrderOrderByRelationAggregateInput | null;
  document?: DocumentOrderByRelationAggregateInput | null;
  endDate?: SortOrder | null;
  evenListing?: EventOrderByRelationAggregateInput | null;
  id?: SortOrder | null;
  logos?: BrandImageOrderByRelationAggregateInput | null;
  name?: SortOrder | null;
  news?: NewsOrderByRelationAggregateInput | null;
  pointRate?: SortOrder | null;
  promotion?: PromotionOrderByRelationAggregateInput | null;
  startDate?: SortOrder | null;
  status?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface BrandPageOrderByWithRelationInput {
  about?: SortOrder | null;
  brand?: BrandOrderByWithRelationInput | null;
  brandId?: SortOrder | null;
  brandRange?: BrandRangeOrderByRelationAggregateInput | null;
  createdAt?: SortOrder | null;
  headerImage?: SortOrder | null;
  id?: SortOrder | null;
  logoLibrary?: BrandImageOrderByWithRelationInput | null;
  logoLibraryId?: SortOrder | null;
  name?: SortOrder | null;
  status?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface BrandPageWhereInput {
  AND?: BrandPageWhereInput[] | null;
  NOT?: BrandPageWhereInput[] | null;
  OR?: BrandPageWhereInput[] | null;
  about?: StringNullableFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  brandRange?: BrandRangeListRelationFilter | null;
  createdAt?: DateTimeFilter | null;
  headerImage?: StringNullableFilter | null;
  id?: StringFilter | null;
  logoLibrary?: BrandImageWhereInput | null;
  logoLibraryId?: StringNullableFilter | null;
  name?: StringFilter | null;
  status?: EnumStatusActiveFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BrandRangeListRelationFilter {
  every?: BrandRangeWhereInput | null;
  none?: BrandRangeWhereInput | null;
  some?: BrandRangeWhereInput | null;
}

export interface BrandRangeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BrandRangeWhereInput {
  AND?: BrandRangeWhereInput[] | null;
  NOT?: BrandRangeWhereInput[] | null;
  OR?: BrandRangeWhereInput[] | null;
  availableIn?: StringFilter | null;
  brandPage?: BrandPageWhereInput | null;
  brandPageId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  description?: StringFilter | null;
  id?: StringFilter | null;
  image?: StringNullableFilter | null;
  liveDate?: DateTimeFilter | null;
  name?: StringFilter | null;
  rangeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BrandWhereInput {
  AND?: BrandWhereInput[] | null;
  AutomaticReportRequestAgentEmail?: AutomaticReportRequestAgentEmailListRelationFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  NOT?: BrandWhereInput[] | null;
  OR?: BrandWhereInput[] | null;
  RequestAgent?: RequestAgentListRelationFilter | null;
  admin?: AdminWhereInput | null;
  brandId?: StringFilter | null;
  brandPage?: BrandPageWhereInput | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: StringNullableFilter | null;
  cardSlider?: CardSliderListRelationFilter | null;
  competition?: CompetitionListRelationFilter | null;
  createdAt?: DateTimeFilter | null;
  dataDraftOrder?: DataDraftOrderListRelationFilter | null;
  document?: DocumentListRelationFilter | null;
  endDate?: DateTimeNullableFilter | null;
  evenListing?: EventListRelationFilter | null;
  id?: StringFilter | null;
  logos?: BrandImageListRelationFilter | null;
  name?: StringFilter | null;
  news?: NewsListRelationFilter | null;
  pointRate?: DecimalFilter | null;
  promotion?: PromotionListRelationFilter | null;
  startDate?: DateTimeNullableFilter | null;
  status?: EnumStatusActiveFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BulkAgentListRelationFilter {
  every?: BulkAgentWhereInput | null;
  none?: BulkAgentWhereInput | null;
  some?: BulkAgentWhereInput | null;
}

export interface BulkAgentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BulkAgentWhereInput {
  AND?: BulkAgentWhereInput[] | null;
  NOT?: BulkAgentWhereInput[] | null;
  OR?: BulkAgentWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  countInvalid?: IntFilter | null;
  countValid?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  details?: StringNullableFilter | null;
  fileName?: StringFilter | null;
  id?: StringFilter | null;
  invalidLink?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  status?: EnumStatusBulkPointFilter | null;
  temporaryGivenAgent?: TemporaryGivenAgentListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BulkPointListRelationFilter {
  every?: BulkPointWhereInput | null;
  none?: BulkPointWhereInput | null;
  some?: BulkPointWhereInput | null;
}

export interface BulkPointOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface BulkPointWhereInput {
  AND?: BulkPointWhereInput[] | null;
  NOT?: BulkPointWhereInput[] | null;
  OR?: BulkPointWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  countInvalid?: IntFilter | null;
  countValid?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  details?: StringNullableFilter | null;
  fileName?: StringFilter | null;
  id?: StringFilter | null;
  invalidLink?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  status?: EnumStatusBulkPointFilter | null;
  temporaryGivenPoint?: TemporaryGivenPointListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface BusinessContactBrandInput {
  create?: CreateBuContact | null;
  delete?: DeteleBuContact | null;
  update?: UpdateBUContact | null;
}

export interface BusinessUnitContactOrderByWithRelationInput {
  News?: NewsOrderByRelationAggregateInput | null;
  Promotion?: PromotionOrderByRelationAggregateInput | null;
  RequestAgent?: RequestAgentOrderByRelationAggregateInput | null;
  brand?: BrandOrderByWithRelationInput | null;
  email?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
  phoneNumber?: SortOrder | null;
  requestAgentReportActiveSendEmail?: SortOrder | null;
}

export interface BusinessUnitContactWhereInput {
  AND?: BusinessUnitContactWhereInput[] | null;
  NOT?: BusinessUnitContactWhereInput[] | null;
  News?: NewsListRelationFilter | null;
  OR?: BusinessUnitContactWhereInput[] | null;
  Promotion?: PromotionListRelationFilter | null;
  RequestAgent?: RequestAgentListRelationFilter | null;
  brand?: BrandWhereInput | null;
  email?: StringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  phoneNumber?: StringFilter | null;
  requestAgentReportActiveSendEmail?: BoolFilter | null;
}

export interface CardSliderAppUserListRelationFilter {
  every?: CardSliderAppUserWhereInput | null;
  none?: CardSliderAppUserWhereInput | null;
  some?: CardSliderAppUserWhereInput | null;
}

export interface CardSliderAppUserWhereInput {
  AND?: CardSliderAppUserWhereInput[] | null;
  CardSlider?: CardSliderListRelationFilter | null;
  NOT?: CardSliderAppUserWhereInput[] | null;
  OR?: CardSliderAppUserWhereInput[] | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
}

export interface CardSliderCityListRelationFilter {
  every?: CardSliderCityWhereInput | null;
  none?: CardSliderCityWhereInput | null;
  some?: CardSliderCityWhereInput | null;
}

export interface CardSliderCityWhereInput {
  AND?: CardSliderCityWhereInput[] | null;
  CardSlider?: CardSliderListRelationFilter | null;
  NOT?: CardSliderCityWhereInput[] | null;
  OR?: CardSliderCityWhereInput[] | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
}

export interface CardSliderListRelationFilter {
  every?: CardSliderWhereInput | null;
  none?: CardSliderWhereInput | null;
  some?: CardSliderWhereInput | null;
}

export interface CardSliderOrderBy {
  order: OrderByArg;
  type: CardSliderOrderByType;
}

export interface CardSliderOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface CardSliderRegionListRelationFilter {
  every?: CardSliderRegionWhereInput | null;
  none?: CardSliderRegionWhereInput | null;
  some?: CardSliderRegionWhereInput | null;
}

export interface CardSliderRegionWhereInput {
  AND?: CardSliderRegionWhereInput[] | null;
  CardSlider?: CardSliderListRelationFilter | null;
  NOT?: CardSliderRegionWhereInput[] | null;
  OR?: CardSliderRegionWhereInput[] | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
}

export interface CardSliderWhereInput {
  AND?: CardSliderWhereInput[] | null;
  NOT?: CardSliderWhereInput[] | null;
  OR?: CardSliderWhereInput[] | null;
  active?: BoolFilter | null;
  appUser?: CardSliderAppUserListRelationFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  city?: CardSliderCityListRelationFilter | null;
  createdAt?: DateTimeFilter | null;
  endLiveDate?: DateTimeFilter | null;
  externalLink?: StringNullableFilter | null;
  id?: StringFilter | null;
  image?: StringNullableFilter | null;
  liveDate?: DateTimeFilter | null;
  name?: StringFilter | null;
  numberOrder?: IntFilter | null;
  promotionLink?: PromotionWhereInput | null;
  promotionLinkId?: StringNullableFilter | null;
  region?: CardSliderRegionListRelationFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  timeActive?: EnumTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface CompetitionListRelationFilter {
  every?: CompetitionWhereInput | null;
  none?: CompetitionWhereInput | null;
  some?: CompetitionWhereInput | null;
}

export interface CompetitionOrderBy {
  order: OrderByArg;
  type: CompetitionOrderByType;
}

export interface CompetitionOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface CompetitionSocialMediaCreateInput {
  link: string;
  socialMediaType: MediaSocial;
}

export interface CompetitionSocialMediaDeleteInput {
  id: string;
}

export interface CompetitionSocialMediaInput {
  create?: CompetitionSocialMediaCreateInput[] | null;
  delete?: CompetitionSocialMediaDeleteInput[] | null;
  update?: CompetitionSocialMediaUpdateInput[] | null;
}

export interface CompetitionSocialMediaListRelationFilter {
  every?: CompetitionSocialMediaWhereInput | null;
  none?: CompetitionSocialMediaWhereInput | null;
  some?: CompetitionSocialMediaWhereInput | null;
}

export interface CompetitionSocialMediaUpdateInput {
  id: string;
  link: string;
  socialMediaType: MediaSocial;
}

export interface CompetitionSocialMediaWhereInput {
  AND?: CompetitionSocialMediaWhereInput[] | null;
  NOT?: CompetitionSocialMediaWhereInput[] | null;
  OR?: CompetitionSocialMediaWhereInput[] | null;
  competition?: CompetitionWhereInput | null;
  id?: StringFilter | null;
  link?: StringFilter | null;
  socialMediaType?: EnumMediaSocialNullableFilter | null;
}

export interface CompetitionWhereInput {
  AND?: CompetitionWhereInput[] | null;
  NOT?: CompetitionWhereInput[] | null;
  OR?: CompetitionWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  city?: StringFilter | null;
  closeDate?: DateTimeFilter | null;
  createdAt?: DateTimeFilter | null;
  dedicatedPageImage?: StringNullableFilter | null;
  desc?: StringNullableFilter | null;
  descSalesman?: StringNullableFilter | null;
  endLiveDate?: DateTimeFilter | null;
  eventDate?: DateTimeFilter | null;
  externalButton?: StringNullableFilter | null;
  externalLink?: StringNullableFilter | null;
  formLink?: FormCompetitionWhereInput | null;
  formLinkId?: IntNullableFilter | null;
  id?: StringFilter | null;
  image?: StringNullableFilter | null;
  liveDate?: DateTimeFilter | null;
  loyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  medsos?: CompetitionSocialMediaListRelationFilter | null;
  name?: StringFilter | null;
  region?: StringFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: StringNullableFilter | null;
  winners?: WinnersListRelationFilter | null;
}

export interface CountRange {
  countFrom: number;
  countTo: number;
}

export interface CreateBuContact {
  buContacts: BUContactInput[];
}

export interface DataDraftOrderListRelationFilter {
  every?: DataDraftOrderWhereInput | null;
  none?: DataDraftOrderWhereInput | null;
  some?: DataDraftOrderWhereInput | null;
}

export interface DataDraftOrderOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface DataDraftOrderWhereInput {
  AND?: DataDraftOrderWhereInput[] | null;
  NOT?: DataDraftOrderWhereInput[] | null;
  OR?: DataDraftOrderWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  draftOrder?: DraftOrderWhereInput | null;
  draftOrderId?: StringFilter | null;
  id?: StringFilter | null;
  note?: StringNullableFilter | null;
  products?: ProductListRelationFilter | null;
  promotion?: PromotionListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface DateFilter {
  endDate: any;
  startDate: any;
}

export interface DateTimeFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDateTimeFilter | null;
  notIn?: any[] | null;
}

export interface DateTimeNullableFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
  notIn?: any[] | null;
}

export interface DecimalFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDecimalFilter | null;
  notIn?: any[] | null;
}

export interface DecimalNullableFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDecimalNullableFilter | null;
  notIn?: any[] | null;
}

export interface DeleteBUContact {
  id: string[];
}

export interface DeleteWinners {
  id: string;
}

export interface DeteleBuContact {
  buContacts: DeleteBUContact;
}

export interface DocumentAdvanceSearchOrderByInput {
  order: OrderByArg;
  type: DocumentAdvanceSearchOrderByType;
}

export interface DocumentListRelationFilter {
  every?: DocumentWhereInput | null;
  none?: DocumentWhereInput | null;
  some?: DocumentWhereInput | null;
}

export interface DocumentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface DocumentWhereInput {
  AND?: DocumentWhereInput[] | null;
  NOT?: DocumentWhereInput[] | null;
  OR?: DocumentWhereInput[] | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  documentName?: StringFilter | null;
  id?: StringFilter | null;
  liveDate?: DateTimeFilter | null;
  status?: EnumArchiveStatusFilter | null;
  thumbnail?: StringFilter | null;
  thumbnailOrientation?: EnumOrientationFilter | null;
  updatedAt?: DateTimeFilter | null;
  url?: StringFilter | null;
}

export interface DokuRedeemOrderListRelationFilter {
  every?: DokuRedeemOrderWhereInput | null;
  none?: DokuRedeemOrderWhereInput | null;
  some?: DokuRedeemOrderWhereInput | null;
}

export interface DokuRedeemOrderOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface DokuRedeemOrderWhereInput {
  AND?: DokuRedeemOrderWhereInput[] | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  NOT?: DokuRedeemOrderWhereInput[] | null;
  OR?: DokuRedeemOrderWhereInput[] | null;
  amount?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  deviceId?: StringNullableFilter | null;
  id?: StringFilter | null;
  isRefunded?: BoolFilter | null;
  refundReason?: StringNullableFilter | null;
  sendTrxId?: StringFilter | null;
  transactionId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface DraftOrderListRelationFilter {
  every?: DraftOrderWhereInput | null;
  none?: DraftOrderWhereInput | null;
  some?: DraftOrderWhereInput | null;
}

export interface DraftOrderOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface DraftOrderWhereInput {
  AND?: DraftOrderWhereInput[] | null;
  NOT?: DraftOrderWhereInput[] | null;
  OR?: DraftOrderWhereInput[] | null;
  brandId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  dataDraftOrder?: DataDraftOrderListRelationFilter | null;
  id?: StringFilter | null;
  rangeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface EmailRequestAgentCreateInput {
  brandId: string;
  email: string;
}

export interface EmailRequestAgentDeleteInput {
  id: string;
}

export interface EmailRequestAgentInput {
  create?: EmailRequestAgentCreateInput[] | null;
  delete?: EmailRequestAgentDeleteInput[] | null;
  update?: EmailRequestAgentUpdateInput[] | null;
}

export interface EmailRequestAgentUpdateInput {
  brandId: string;
  email: string;
  id: string;
}

export interface EnumAccessScreenFilter {
  equals?: AccessScreen | null;
  in?: AccessScreen[] | null;
  not?: AccessScreen | null;
  notIn?: AccessScreen[] | null;
}

export interface EnumAccessUserNullableListFilter {
  equals?: AccessUser[] | null;
  has?: AccessUser | null;
  hasEvery?: AccessUser[] | null;
  hasSome?: AccessUser[] | null;
  isEmpty?: boolean | null;
}

export interface EnumArchiveStatusFilter {
  equals?: ArchiveStatus | null;
  in?: ArchiveStatus[] | null;
  not?: ArchiveStatus | null;
  notIn?: ArchiveStatus[] | null;
}

export interface EnumCronStatusFilter {
  equals?: CronStatus | null;
  in?: CronStatus[] | null;
  not?: CronStatus | null;
  notIn?: CronStatus[] | null;
}

export interface EnumDocumentProblemTypeNullableFilter {
  equals?: DocumentProblemType | null;
  in?: DocumentProblemType[] | null;
  not?: DocumentProblemType | null;
  notIn?: DocumentProblemType[] | null;
}

export interface EnumFlagCreateUserNullableFilter {
  equals?: FlagCreateUser | null;
  in?: FlagCreateUser[] | null;
  not?: FlagCreateUser | null;
  notIn?: FlagCreateUser[] | null;
}

export interface EnumGenderNullableFilter {
  equals?: Gender | null;
  in?: Gender[] | null;
  not?: Gender | null;
  notIn?: Gender[] | null;
}

export interface EnumHistoryTypeFilter {
  equals?: HistoryType | null;
  in?: HistoryType[] | null;
  not?: HistoryType | null;
  notIn?: HistoryType[] | null;
}

export interface EnumIdCardTypeNullableFilter {
  equals?: IdCardType | null;
  in?: IdCardType[] | null;
  not?: IdCardType | null;
  notIn?: IdCardType[] | null;
}

export interface EnumLinkTypePopUpNullableFilter {
  equals?: LinkTypePopUp | null;
  in?: LinkTypePopUp[] | null;
  not?: LinkTypePopUp | null;
  notIn?: LinkTypePopUp[] | null;
}

export interface EnumLoyaltySourceFilter {
  equals?: LoyaltySource | null;
  in?: LoyaltySource[] | null;
  not?: LoyaltySource | null;
  notIn?: LoyaltySource[] | null;
}

export interface EnumLumpSumTypeNullableFilter {
  equals?: LumpSumType | null;
  in?: LumpSumType[] | null;
  not?: LumpSumType | null;
  notIn?: LumpSumType[] | null;
}

export interface EnumMediaSocialNullableFilter {
  equals?: MediaSocial | null;
  in?: MediaSocial[] | null;
  not?: MediaSocial | null;
  notIn?: MediaSocial[] | null;
}

export interface EnumMissionCategoryFilter {
  equals?: MissionCategory | null;
  in?: MissionCategory[] | null;
  not?: MissionCategory | null;
  notIn?: MissionCategory[] | null;
}

export interface EnumNewsTypeLinkNullableFilter {
  equals?: NewsTypeLink | null;
  in?: NewsTypeLink[] | null;
  not?: NewsTypeLink | null;
  notIn?: NewsTypeLink[] | null;
}

export interface EnumOrientationFilter {
  equals?: Orientation | null;
  in?: Orientation[] | null;
  not?: Orientation | null;
  notIn?: Orientation[] | null;
}

export interface EnumPhoneCategoryNullableFilter {
  equals?: PhoneCategory | null;
  in?: PhoneCategory[] | null;
  not?: PhoneCategory | null;
  notIn?: PhoneCategory[] | null;
}

export interface EnumPopUpColorNullableFilter {
  equals?: PopUpColor | null;
  in?: PopUpColor[] | null;
  not?: PopUpColor | null;
  notIn?: PopUpColor[] | null;
}

export interface EnumRejectedByNullableFilter {
  equals?: RejectedBy | null;
  in?: RejectedBy[] | null;
  not?: RejectedBy | null;
  notIn?: RejectedBy[] | null;
}

export interface EnumRequestAgentFromNullableFilter {
  equals?: RequestAgentFrom | null;
  in?: RequestAgentFrom[] | null;
  not?: RequestAgentFrom | null;
  notIn?: RequestAgentFrom[] | null;
}

export interface EnumRequestStatusFilter {
  equals?: RequestStatus | null;
  in?: RequestStatus[] | null;
  not?: RequestStatus | null;
  notIn?: RequestStatus[] | null;
}

export interface EnumRoleAdminFilter {
  equals?: RoleAdmin | null;
  in?: RoleAdmin[] | null;
  not?: RoleAdmin | null;
  notIn?: RoleAdmin[] | null;
}

export interface EnumRoleFilter {
  equals?: Role | null;
  in?: Role[] | null;
  not?: Role | null;
  notIn?: Role[] | null;
}

export interface EnumStatusActiveFilter {
  equals?: StatusActive | null;
  in?: StatusActive[] | null;
  not?: StatusActive | null;
  notIn?: StatusActive[] | null;
}

export interface EnumStatusAddEarnGivenPointNullableFilter {
  equals?: StatusAddEarnGivenPoint | null;
  in?: StatusAddEarnGivenPoint[] | null;
  not?: StatusAddEarnGivenPoint | null;
  notIn?: StatusAddEarnGivenPoint[] | null;
}

export interface EnumStatusBulkPointFilter {
  equals?: StatusBulkPoint | null;
  in?: StatusBulkPoint[] | null;
  not?: StatusBulkPoint | null;
  notIn?: StatusBulkPoint[] | null;
}

export interface EnumStatusGivenPointNullableFilter {
  equals?: StatusGivenPoint | null;
  in?: StatusGivenPoint[] | null;
  not?: StatusGivenPoint | null;
  notIn?: StatusGivenPoint[] | null;
}

export interface EnumStatusProgressTempGivenPointNullableFilter {
  equals?: StatusProgressTempGivenPoint | null;
  in?: StatusProgressTempGivenPoint[] | null;
  not?: StatusProgressTempGivenPoint | null;
  notIn?: StatusProgressTempGivenPoint[] | null;
}

export interface EnumStatusVerifyFilter {
  equals?: StatusVerify | null;
  in?: StatusVerify[] | null;
  not?: StatusVerify | null;
  notIn?: StatusVerify[] | null;
}

export interface EnumStorePhotoCategoryNullableFilter {
  equals?: StorePhotoCategory | null;
  in?: StorePhotoCategory[] | null;
  not?: StorePhotoCategory | null;
  notIn?: StorePhotoCategory[] | null;
}

export interface EnumTadaItemTypeNullableFilter {
  equals?: TadaItemType | null;
  in?: TadaItemType[] | null;
  not?: TadaItemType | null;
  notIn?: TadaItemType[] | null;
}

export interface EnumTermsAndConditionFilter {
  equals?: TermsAndCondition | null;
  in?: TermsAndCondition[] | null;
  not?: TermsAndCondition | null;
  notIn?: TermsAndCondition[] | null;
}

export interface EnumTimeFilter {
  equals?: Time | null;
  in?: Time[] | null;
  not?: Time | null;
  notIn?: Time[] | null;
}

export interface EnumTypeRollBackFilter {
  equals?: TypeRollBack | null;
  in?: TypeRollBack[] | null;
  not?: TypeRollBack | null;
  notIn?: TypeRollBack[] | null;
}

export interface EnumTypeVerifyFilter {
  equals?: TypeVerify | null;
  in?: TypeVerify[] | null;
  not?: TypeVerify | null;
  notIn?: TypeVerify[] | null;
}

export interface EnumValidVisitationNullableFilter {
  equals?: ValidVisitation | null;
  in?: ValidVisitation[] | null;
  not?: ValidVisitation | null;
  notIn?: ValidVisitation[] | null;
}

export interface EventListRelationFilter {
  every?: EventWhereInput | null;
  none?: EventWhereInput | null;
  some?: EventWhereInput | null;
}

export interface EventOrderBy {
  order: OrderByArg;
  type: EventOrderByType;
}

export interface EventOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface EventWhereInput {
  AND?: EventWhereInput[] | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  NOT?: EventWhereInput[] | null;
  OR?: EventWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  dedicatedPageImage?: StringNullableFilter | null;
  desc?: StringNullableFilter | null;
  descSalesman?: StringNullableFilter | null;
  endLiveDate?: DateTimeFilter | null;
  eventDate?: DateTimeFilter | null;
  externalButton?: StringNullableFilter | null;
  externalLink?: StringNullableFilter | null;
  formLink?: FormEventWhereInput | null;
  formLinkId?: IntNullableFilter | null;
  id?: StringFilter | null;
  image?: StringNullableFilter | null;
  liveDate?: DateTimeFilter | null;
  name?: StringFilter | null;
  region?: StringFilter | null;
  registrationDeadline?: DateTimeNullableFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: StringNullableFilter | null;
  venue?: StringFilter | null;
}

export interface ExternalAgentOrderByWithRelationInput {
  agentCode?: SortOrder | null;
  city?: SortOrder | null;
  createdAt?: SortOrder | null;
  email?: SortOrder | null;
  id?: SortOrder | null;
  name?: SortOrder | null;
  phoneNumber?: SortOrder | null;
  store?: StoreOrderByRelationAggregateInput | null;
  updatedAt?: SortOrder | null;
}

export interface ExternalAgentWhereInput {
  AND?: ExternalAgentWhereInput[] | null;
  NOT?: ExternalAgentWhereInput[] | null;
  OR?: ExternalAgentWhereInput[] | null;
  agentCode?: StringFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringNullableFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  phoneNumber?: StringFilter | null;
  store?: StoreListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface FilterLoyaltyHistoryInput {
  endDate?: any | null;
  phoneNumber?: string | null;
  redeemSalesmanCode?: string | null;
  redeemStatus?: RedeemStatus | null;
  source?: LoyaltySource | null;
  startDate?: any | null;
  storeCode?: string | null;
  storeName?: string | null;
  userName?: string | null;
}

export interface FilterStoreInput {
  address?: string | null;
  cityId?: string | null;
  districtId?: string | null;
  provinceId?: string | null;
  storeCategoryId?: string | null;
  storeCode?: string | null;
  storeEmail?: string | null;
  storeName?: string | null;
  storePhoneNumber?: string | null;
  urbanVillageId?: string | null;
}

export interface FilterStoreOwnerSalesReferralCodeInput {
  dateOfFiling?: DateFilter | null;
  phoneNumber?: string | null;
  salesmanReferralCode?: string | null;
  storeCode?: string | null;
  storeName?: string | null;
  verificationStatus?: boolean | null;
  verifiedDate?: DateFilter | null;
}

export interface FilterUserInput {
  accNumber?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
}

export interface FormCompetitionOrderBy {
  order: OrderByArg;
  type: FormCompetitionOrderByType;
}

export interface FormCompetitionWhereInput {
  AND?: FormCompetitionWhereInput[] | null;
  NOT?: FormCompetitionWhereInput[] | null;
  OR?: FormCompetitionWhereInput[] | null;
  competition?: CompetitionWhereInput | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  image?: StringNullableFilter | null;
  link?: StringFilter | null;
  name?: StringFilter | null;
  prize?: StringNullableFilter | null;
  prizeValue?: IntFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  submitFormCompetition?: SubmitFormCompetitionListRelationFilter | null;
  terms?: StringNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface FormEventOrderBy {
  order: OrderByArg;
  type: FormEventOrderByType;
}

export interface FormEventWhereInput {
  AND?: FormEventWhereInput[] | null;
  NOT?: FormEventWhereInput[] | null;
  OR?: FormEventWhereInput[] | null;
  address?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  event?: EventWhereInput | null;
  id?: IntFilter | null;
  latitude?: StringNullableFilter | null;
  link?: StringFilter | null;
  longitude?: StringNullableFilter | null;
  maxParticipant?: IntNullableFilter | null;
  name?: StringFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  submitFormEvent?: SubmitFormEventListRelationFilter | null;
  timeEvent?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  venue?: StringFilter | null;
}

export interface FormEventWhereUniqueInput {
  AND?: FormEventWhereInput[] | null;
  NOT?: FormEventWhereInput[] | null;
  OR?: FormEventWhereInput[] | null;
  address?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  event?: EventWhereInput | null;
  id?: number | null;
  latitude?: StringNullableFilter | null;
  link?: string | null;
  longitude?: StringNullableFilter | null;
  maxParticipant?: IntNullableFilter | null;
  name?: StringFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  submitFormEvent?: SubmitFormEventListRelationFilter | null;
  timeEvent?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
  venue?: StringFilter | null;
}

export interface IntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntFilter | null;
  notIn?: number[] | null;
}

export interface IntNullableFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntNullableFilter | null;
  notIn?: number[] | null;
}

export interface LogChangePhoneNumberListRelationFilter {
  every?: LogChangePhoneNumberWhereInput | null;
  none?: LogChangePhoneNumberWhereInput | null;
  some?: LogChangePhoneNumberWhereInput | null;
}

export interface LogChangePhoneNumberOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface LogChangePhoneNumberWhereInput {
  AND?: LogChangePhoneNumberWhereInput[] | null;
  NOT?: LogChangePhoneNumberWhereInput[] | null;
  OR?: LogChangePhoneNumberWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  dataNumber?: StringFilter | null;
  id?: StringFilter | null;
  newPhoneNumber?: StringFilter | null;
  oldPhoneNumber?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface LogKycSalesmanCodeListRelationFilter {
  every?: LogKycSalesmanCodeWhereInput | null;
  none?: LogKycSalesmanCodeWhereInput | null;
  some?: LogKycSalesmanCodeWhereInput | null;
}

export interface LogKycSalesmanCodeOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface LogKycSalesmanCodeWhereInput {
  AND?: LogKycSalesmanCodeWhereInput[] | null;
  NOT?: LogKycSalesmanCodeWhereInput[] | null;
  OR?: LogKycSalesmanCodeWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  requestVerificationId?: StringFilter | null;
  salesmanReferralCode?: StringNullableFilter | null;
  status?: EnumStatusVerifyFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LogRequestVerificationListRelationFilter {
  every?: LogRequestVerificationWhereInput | null;
  none?: LogRequestVerificationWhereInput | null;
  some?: LogRequestVerificationWhereInput | null;
}

export interface LogRequestVerificationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface LogRequestVerificationWhereInput {
  AND?: LogRequestVerificationWhereInput[] | null;
  NOT?: LogRequestVerificationWhereInput[] | null;
  OR?: LogRequestVerificationWhereInput[] | null;
  admin?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  logData?: StringFilter | null;
  nextAction?: StringNullableFilter | null;
  note?: StringFilter | null;
  reason?: StringNullableFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  requestVerificationId?: StringFilter | null;
  status?: EnumStatusVerifyFilter | null;
  type?: EnumTypeVerifyFilter | null;
  unverifType?: StringNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  validVisitation?: EnumValidVisitationNullableFilter | null;
  validasiToko?: StringNullableFilter | null;
  verificationResult?: StringNullableFilter | null;
}

export interface LogRollBackVerifyStoreListRelationFilter {
  every?: LogRollBackVerifyStoreWhereInput | null;
  none?: LogRollBackVerifyStoreWhereInput | null;
  some?: LogRollBackVerifyStoreWhereInput | null;
}

export interface LogRollBackVerifyStoreOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface LogRollBackVerifyStoreWhereInput {
  AND?: LogRollBackVerifyStoreWhereInput[] | null;
  NOT?: LogRollBackVerifyStoreWhereInput[] | null;
  OR?: LogRollBackVerifyStoreWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  newStoreManagerCode?: StringNullableFilter | null;
  newStoreManagerId?: StringNullableFilter | null;
  oldStoreManagerCode?: StringNullableFilter | null;
  oldStoreManagerId?: StringNullableFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  type?: EnumTypeRollBackFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LoyaltyActivityWhereInput {
  AND?: LoyaltyActivityWhereInput[] | null;
  NOT?: LoyaltyActivityWhereInput[] | null;
  OR?: LoyaltyActivityWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  desc?: StringNullableFilter | null;
  id?: StringFilter | null;
  internalName?: StringFilter | null;
  loyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  name?: StringFilter | null;
  point?: IntFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LoyaltyMissionPointWhereInput {
  AND?: LoyaltyMissionPointWhereInput[] | null;
  NOT?: LoyaltyMissionPointWhereInput[] | null;
  OR?: LoyaltyMissionPointWhereInput[] | null;
  active?: BoolFilter | null;
  category?: EnumMissionCategoryFilter | null;
  createdAt?: DateTimeFilter | null;
  endDate?: DateTimeFilter | null;
  id?: StringFilter | null;
  loyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  missionActivityLog?: MissionActivityLogListRelationFilter | null;
  missionId?: StringFilter | null;
  name?: StringFilter | null;
  point?: IntFilter | null;
  startDate?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface LoyaltyPointHistoryListRelationFilter {
  every?: LoyaltyPointHistoryWhereInput | null;
  none?: LoyaltyPointHistoryWhereInput | null;
  some?: LoyaltyPointHistoryWhereInput | null;
}

export interface LoyaltyPointHistoryOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface LoyaltyPointHistoryWhereInput {
  AND?: LoyaltyPointHistoryWhereInput[] | null;
  NOT?: LoyaltyPointHistoryWhereInput[] | null;
  OR?: LoyaltyPointHistoryWhereInput[] | null;
  activityName?: StringFilter | null;
  balancePoint?: DecimalFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  competition?: CompetitionWhereInput | null;
  competitionId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  date?: DateTimeFilter | null;
  dokuOrderReferenceId?: StringNullableFilter | null;
  dokuRedeemOrder?: DokuRedeemOrderWhereInput | null;
  dokuRedeemOrderId?: StringNullableFilter | null;
  event?: EventWhereInput | null;
  eventId?: StringNullableFilter | null;
  id?: StringFilter | null;
  isRefunded?: BoolFilter | null;
  loyaltyActivity?: LoyaltyActivityWhereInput | null;
  loyaltyActivityId?: StringNullableFilter | null;
  loyaltyMissionPoint?: LoyaltyMissionPointWhereInput | null;
  loyaltyMissionPointId?: StringNullableFilter | null;
  lumpSumType?: EnumLumpSumTypeNullableFilter | null;
  monetaryValue?: DecimalNullableFilter | null;
  note?: StringNullableFilter | null;
  point?: DecimalFilter | null;
  promotion?: PromotionWhereInput | null;
  promotionId?: StringNullableFilter | null;
  redeemSalesmanCode?: StringNullableFilter | null;
  referenceIdLoyalty?: StringNullableFilter | null;
  source?: EnumLoyaltySourceFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  tadaOrderReferenceId?: StringNullableFilter | null;
  tadaRedeemOrder?: TadaRedeemOrderWhereInput | null;
  tadaRedeemOrderId?: StringNullableFilter | null;
  temporaryGivenPoint?: TemporaryGivenPointWhereInput | null;
  temporaryGivenPointId?: StringNullableFilter | null;
  tonase?: DecimalNullableFilter | null;
  transactionNumber?: StringNullableFilter | null;
  type?: EnumHistoryTypeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface MissionActivityHistoryListRelationFilter {
  every?: MissionActivityHistoryWhereInput | null;
  none?: MissionActivityHistoryWhereInput | null;
  some?: MissionActivityHistoryWhereInput | null;
}

export interface MissionActivityHistoryOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface MissionActivityHistoryWhereInput {
  AND?: MissionActivityHistoryWhereInput[] | null;
  NOT?: MissionActivityHistoryWhereInput[] | null;
  OR?: MissionActivityHistoryWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  endDate?: DateTimeFilter | null;
  id?: StringFilter | null;
  recentStatus?: BoolFilter | null;
  startDate?: DateTimeFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface MissionActivityLogListRelationFilter {
  every?: MissionActivityLogWhereInput | null;
  none?: MissionActivityLogWhereInput | null;
  some?: MissionActivityLogWhereInput | null;
}

export interface MissionActivityLogOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface MissionActivityLogWhereInput {
  AND?: MissionActivityLogWhereInput[] | null;
  NOT?: MissionActivityLogWhereInput[] | null;
  OR?: MissionActivityLogWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  finishedDate?: DateTimeNullableFilter | null;
  id?: StringFilter | null;
  loyaltyMissionPoint?: LoyaltyMissionPointWhereInput | null;
  loyaltyMissionPointId?: StringFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  topUpDate?: DateTimeNullableFilter | null;
  topUpStatus?: BoolFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface MissionTopupRewardDetailListRelationFilter {
  every?: MissionTopupRewardDetailWhereInput | null;
  none?: MissionTopupRewardDetailWhereInput | null;
  some?: MissionTopupRewardDetailWhereInput | null;
}

export interface MissionTopupRewardDetailWhereInput {
  AND?: MissionTopupRewardDetailWhereInput[] | null;
  NOT?: MissionTopupRewardDetailWhereInput[] | null;
  OR?: MissionTopupRewardDetailWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  endDatePeriod?: StringFilter | null;
  id?: StringFilter | null;
  invalidData?: StringNullableListFilter | null;
  invalidDataDetails?: StringNullableListFilter | null;
  missionId?: StringFilter | null;
  missionTopupReward?: MissionTopupRewardWhereInput | null;
  missionTopupRewardId?: StringFilter | null;
  no?: IntFilter | null;
  note?: StringNullableFilter | null;
  startDatePeriod?: StringFilter | null;
  statusProgress?: EnumStatusProgressTempGivenPointNullableFilter | null;
  storeCode?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  valid?: BoolFilter | null;
}

export interface MissionTopupRewardListRelationFilter {
  every?: MissionTopupRewardWhereInput | null;
  none?: MissionTopupRewardWhereInput | null;
  some?: MissionTopupRewardWhereInput | null;
}

export interface MissionTopupRewardOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface MissionTopupRewardWhereInput {
  AND?: MissionTopupRewardWhereInput[] | null;
  NOT?: MissionTopupRewardWhereInput[] | null;
  OR?: MissionTopupRewardWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringFilter | null;
  countInvalid?: IntFilter | null;
  countValid?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  detail?: StringNullableFilter | null;
  fileName?: StringFilter | null;
  id?: StringFilter | null;
  invalidLink?: StringNullableFilter | null;
  link?: StringNullableFilter | null;
  missionTopupRewardDetail?: MissionTopupRewardDetailListRelationFilter | null;
  status?: EnumStatusBulkPointFilter | null;
  updatedAt?: DateTimeFilter | null;
  uploadDate?: DateTimeFilter | null;
}

export interface NestedBoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface NestedBoolNullableFilter {
  equals?: boolean | null;
  not?: NestedBoolNullableFilter | null;
}

export interface NestedDateTimeFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDateTimeFilter | null;
  notIn?: any[] | null;
}

export interface NestedDateTimeNullableFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDateTimeNullableFilter | null;
  notIn?: any[] | null;
}

export interface NestedDecimalFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDecimalFilter | null;
  notIn?: any[] | null;
}

export interface NestedDecimalNullableFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDecimalNullableFilter | null;
  notIn?: any[] | null;
}

export interface NestedIntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntFilter | null;
  notIn?: number[] | null;
}

export interface NestedIntNullableFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntNullableFilter | null;
  notIn?: number[] | null;
}

export interface NestedStringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: NestedStringFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface NestedStringNullableFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: NestedStringNullableFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface NewsListRelationFilter {
  every?: NewsWhereInput | null;
  none?: NewsWhereInput | null;
  some?: NewsWhereInput | null;
}

export interface NewsOrderBy {
  order: OrderByArg;
  type: NewsOrderByType;
}

export interface NewsOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface NewsWhereInput {
  AND?: NewsWhereInput[] | null;
  NOT?: NewsWhereInput[] | null;
  OR?: NewsWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringNullableFilter | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: StringNullableFilter | null;
  city?: StringFilter | null;
  clickCount?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  dedicatedPageImage?: StringNullableFilter | null;
  desc?: StringFilter | null;
  descSalesman?: StringNullableFilter | null;
  endLiveDate?: DateTimeFilter | null;
  externalButton?: StringNullableFilter | null;
  externalLink?: StringNullableFilter | null;
  id?: StringFilter | null;
  image?: StringNullableFilter | null;
  internalLink?: ScreenWhereInput | null;
  internalLinkId?: StringNullableFilter | null;
  liveDate?: DateTimeFilter | null;
  rangeId?: StringNullableFilter | null;
  region?: StringFilter | null;
  slug?: StringFilter | null;
  startDate?: DateTimeFilter | null;
  status?: EnumArchiveStatusFilter | null;
  title?: StringFilter | null;
  typeLink?: EnumNewsTypeLinkNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: StringNullableFilter | null;
}

export interface OrderReferenceListRelationFilter {
  every?: OrderReferenceWhereInput | null;
  none?: OrderReferenceWhereInput | null;
  some?: OrderReferenceWhereInput | null;
}

export interface OrderReferenceOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface OrderReferenceWhereInput {
  AND?: OrderReferenceWhereInput[] | null;
  NOT?: OrderReferenceWhereInput[] | null;
  OR?: OrderReferenceWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  listOrderId?: StringNullableListFilter | null;
  payload?: StringNullableFilter | null;
  referenceId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface OwnerInputData {
  birthDate: any;
  email?: string | null;
  gender: Gender;
  googleSheetFormNumber?: string | null;
  idCardNumber?: string | null;
  idCardType?: IdCardType | null;
  name?: string | null;
  phoneNumber?: string | null;
  yearEstablished?: string | null;
}

export interface PaginationInput {
  first?: number | null;
  last?: number | null;
  skip?: number | null;
}

export interface PopUpListRelationFilter {
  every?: PopUpWhereInput | null;
  none?: PopUpWhereInput | null;
  some?: PopUpWhereInput | null;
}

export interface PopUpWhereInput {
  AND?: PopUpWhereInput[] | null;
  NOT?: PopUpWhereInput[] | null;
  OR?: PopUpWhereInput[] | null;
  brandId?: StringNullableFilter | null;
  city?: StringNullableFilter | null;
  color?: EnumPopUpColorNullableFilter | null;
  conditionBrandId?: StringNullableFilter | null;
  conditionRangeId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  endDate?: DateTimeNullableFilter | null;
  externalLink?: StringNullableFilter | null;
  id?: StringFilter | null;
  image?: StringNullableFilter | null;
  internalLink?: ScreenWhereInput | null;
  internalLinkId?: StringNullableFilter | null;
  linkType?: EnumLinkTypePopUpNullableFilter | null;
  name?: StringFilter | null;
  page?: ScreenWhereInput | null;
  pageId?: StringNullableFilter | null;
  rangeId?: StringNullableFilter | null;
  region?: StringNullableFilter | null;
  startDate?: DateTimeNullableFilter | null;
  status?: EnumArchiveStatusFilter | null;
  textLine1?: StringNullableFilter | null;
  textLine2?: StringNullableFilter | null;
  textLine3?: StringNullableFilter | null;
  title?: StringNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  whatsAppLink?: StringNullableFilter | null;
  who?: StringNullableFilter | null;
}

export interface ProductListRelationFilter {
  every?: ProductWhereInput | null;
  none?: ProductWhereInput | null;
  some?: ProductWhereInput | null;
}

export interface ProductWhereInput {
  AND?: ProductWhereInput[] | null;
  NOT?: ProductWhereInput[] | null;
  OR?: ProductWhereInput[] | null;
  amount?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  dataDraftOrder?: DataDraftOrderWhereInput | null;
  dataDraftOrderId?: StringNullableFilter | null;
  id?: StringFilter | null;
  isFromPromotion?: BoolFilter | null;
  price?: IntFilter | null;
  productId?: StringFilter | null;
  rangeId?: StringFilter | null;
  unit?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface ProfileOrderByWithRelationInput {
  accNumber?: SortOrder | null;
  agreementStatement?: SortOrder | null;
  avatar?: SortOrder | null;
  birthDate?: SortOrder | null;
  createdAt?: SortOrder | null;
  email?: SortOrder | null;
  emailVerified?: SortOrder | null;
  firstEdit?: SortOrder | null;
  gender?: SortOrder | null;
  id?: SortOrder | null;
  idCardNumber?: SortOrder | null;
  idCardPhoto?: SortOrder | null;
  idCardType?: SortOrder | null;
  name?: SortOrder | null;
  reUpload?: SortOrder | null;
  slug?: SortOrder | null;
  tadaCityId?: SortOrder | null;
  tadaPostalCode?: SortOrder | null;
  tadaProvinceId?: SortOrder | null;
  updatedAt?: SortOrder | null;
  uploadAt?: SortOrder | null;
  user?: UserOrderByWithRelationInput | null;
  userId?: SortOrder | null;
}

export interface ProfileWhereInput {
  AND?: ProfileWhereInput[] | null;
  NOT?: ProfileWhereInput[] | null;
  OR?: ProfileWhereInput[] | null;
  accNumber?: StringNullableFilter | null;
  agreementStatement?: BoolNullableFilter | null;
  avatar?: StringNullableFilter | null;
  birthDate?: DateTimeNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringNullableFilter | null;
  emailVerified?: BoolFilter | null;
  firstEdit?: BoolFilter | null;
  gender?: EnumGenderNullableFilter | null;
  id?: StringFilter | null;
  idCardNumber?: StringNullableFilter | null;
  idCardPhoto?: StringNullableFilter | null;
  idCardType?: EnumIdCardTypeNullableFilter | null;
  name?: StringFilter | null;
  reUpload?: BoolNullableFilter | null;
  slug?: StringFilter | null;
  tadaCityId?: StringNullableFilter | null;
  tadaPostalCode?: StringNullableFilter | null;
  tadaProvinceId?: StringNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  uploadAt?: DateTimeNullableFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface PromotionListRelationFilter {
  every?: PromotionWhereInput | null;
  none?: PromotionWhereInput | null;
  some?: PromotionWhereInput | null;
}

export interface PromotionOrderBy {
  order: OrderByArg;
  type: PromotionOrderByType;
}

export interface PromotionOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface PromotionWhereInput {
  AND?: PromotionWhereInput[] | null;
  DataDraftOrder?: DataDraftOrderListRelationFilter | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  NOT?: PromotionWhereInput[] | null;
  OR?: PromotionWhereInput[] | null;
  appUser?: StringFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: StringNullableFilter | null;
  cardSlider?: CardSliderListRelationFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  desc?: StringNullableFilter | null;
  descSalesman?: StringNullableFilter | null;
  endLiveDate?: DateTimeFilter | null;
  heroImage?: StringNullableFilter | null;
  id?: StringFilter | null;
  liveDate?: DateTimeFilter | null;
  name?: StringFilter | null;
  optionalImages?: StringNullableListFilter | null;
  productId?: StringNullableFilter | null;
  productQuantity?: IntNullableFilter | null;
  promotionCode?: StringNullableFilter | null;
  rangeId?: StringNullableFilter | null;
  region?: StringFilter | null;
  sendPushNotifAfterCreate?: BoolFilter | null;
  showInMobile?: BoolFilter | null;
  slug?: StringFilter | null;
  status?: EnumArchiveStatusFilter | null;
  termAndCondition?: StringFilter | null;
  unit?: IntNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  urlDeeplinkSalesman?: StringNullableFilter | null;
}

export interface ReferralStorePhotoVerifAdvanceInput {
  firstRevalidationSubmitDate?: DateFilter | null;
  managerStoreCode?: string | null;
  phoneNumber?: string | null;
  storeName?: string | null;
  storePhotoSalesmanReferralCode?: string | null;
  storeRequestVerifStatus?: StatusVerify | null;
}

export interface RequestAgentDownloadInputType {
  brandId?: string | null;
  cityId?: string | null;
  newStatus?: NewCmsRequestStatusFilter | null;
  orderBy?: RequestAgentOrderByWithRelationInput | null;
  provinceId?: string | null;
  registrationDate?: DateFilter | null;
  requestDate?: DateFilter | null;
  status?: CmsRequestStatusFilter | null;
  storeCode?: string | null;
  storeName?: string | null;
}

export interface RequestAgentListRelationFilter {
  every?: RequestAgentWhereInput | null;
  none?: RequestAgentWhereInput | null;
  some?: RequestAgentWhereInput | null;
}

export interface RequestAgentOrderByInput {
  admin?: AdminOrderByWithRelationInput | null;
  adminId?: SortOrder | null;
  adminVerified?: SortOrder | null;
  agentId?: SortOrder | null;
  agentName?: SortOrder | null;
  agentReassignDate?: SortOrder | null;
  alreadyReject?: SortOrder | null;
  brand?: BrandOrderByWithRelationInput | null;
  brandId?: SortOrder | null;
  businessUnitContact?: BusinessUnitContactOrderByWithRelationInput | null;
  businessUnitContactId?: SortOrder | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  isCancelledInAction?: SortOrder | null;
  orderId?: SortOrder | null;
  rejectReason?: SortOrder | null;
  rejectedBy?: SortOrder | null;
  requestFrom?: SortOrder | null;
  salesmanReferralCode?: SortOrder | null;
  sameRequestAgent?: SortOrder | null;
  status?: SortOrder | null;
  store?: StoreOrderByWithRelationInput | null;
  storeId?: SortOrder | null;
  token?: SortOrder | null;
  unmatchedDate?: SortOrder | null;
  updatedAt?: SortOrder | null;
  user?: UserOrderByWithRelationInput | null;
  userId?: SortOrder | null;
}

export interface RequestAgentOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface RequestAgentOrderByWithRelationInput {
  admin?: AdminOrderByWithRelationInput | null;
  adminId?: SortOrder | null;
  adminVerified?: SortOrder | null;
  agentId?: SortOrder | null;
  agentName?: SortOrder | null;
  agentReassignDate?: SortOrder | null;
  alreadyReject?: SortOrder | null;
  brand?: BrandOrderByWithRelationInput | null;
  brandId?: SortOrder | null;
  businessUnitContact?: BusinessUnitContactOrderByWithRelationInput | null;
  businessUnitContactId?: SortOrder | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  isCancelledInAction?: SortOrder | null;
  orderId?: SortOrder | null;
  rejectReason?: SortOrder | null;
  rejectedBy?: SortOrder | null;
  requestFrom?: SortOrder | null;
  salesmanReferralCode?: SortOrder | null;
  sameRequestAgent?: SortOrder | null;
  status?: SortOrder | null;
  store?: StoreOrderByWithRelationInput | null;
  storeId?: SortOrder | null;
  token?: SortOrder | null;
  unmatchedDate?: SortOrder | null;
  updatedAt?: SortOrder | null;
  user?: UserOrderByWithRelationInput | null;
  userId?: SortOrder | null;
}

export interface RequestAgentVerificationCreateInput {
  agentId?: string | null;
  agentName: string;
  brandId: string;
}

export interface RequestAgentVerificationInput {
  create?: RequestAgentVerificationCreateInput[] | null;
  update?: RequestAgentVerificationUpdateInput[] | null;
}

export interface RequestAgentVerificationUpdateInput {
  agentId?: string | null;
  agentName: string;
  brandId: string;
  requestAgentId: number;
}

export interface RequestAgentWhereInput {
  AND?: RequestAgentWhereInput[] | null;
  NOT?: RequestAgentWhereInput[] | null;
  OR?: RequestAgentWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringNullableFilter | null;
  adminVerified?: BoolFilter | null;
  agentId?: StringNullableFilter | null;
  agentName?: StringNullableFilter | null;
  agentReassignDate?: DateTimeNullableFilter | null;
  alreadyReject?: BoolFilter | null;
  brand?: BrandWhereInput | null;
  brandId?: StringFilter | null;
  businessUnitContact?: BusinessUnitContactWhereInput | null;
  businessUnitContactId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  isCancelledInAction?: BoolFilter | null;
  orderId?: StringNullableFilter | null;
  rejectReason?: StringNullableFilter | null;
  rejectedBy?: EnumRejectedByNullableFilter | null;
  requestFrom?: EnumRequestAgentFromNullableFilter | null;
  salesmanReferralCode?: StringNullableFilter | null;
  sameRequestAgent?: IntNullableFilter | null;
  status?: EnumRequestStatusFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  token?: StringFilter | null;
  unmatchedDate?: DateTimeNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringNullableFilter | null;
}

export interface RequestVerificationListRelationFilter {
  every?: RequestVerificationWhereInput | null;
  none?: RequestVerificationWhereInput | null;
  some?: RequestVerificationWhereInput | null;
}

export interface RequestVerificationOrderBy {
  order: OrderByArg;
  type: RequestVerificationOrderByType;
}

export interface RequestVerificationOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface RequestVerificationOrderByWithRelationInput {
  admin?: AdminOrderByWithRelationInput | null;
  adminId?: SortOrder | null;
  claimAt?: SortOrder | null;
  confirmAt?: SortOrder | null;
  confirmBy?: SortOrder | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  logKycSalesmanCode?: LogKycSalesmanCodeOrderByRelationAggregateInput | null;
  logRequestVerification?: LogRequestVerificationOrderByRelationAggregateInput | null;
  note?: SortOrder | null;
  salesmanReferralCode?: SortOrder | null;
  sendWAMessage?: SortOrder | null;
  status?: SortOrder | null;
  store?: StoreOrderByWithRelationInput | null;
  storeId?: SortOrder | null;
  type?: SortOrder | null;
  unverifiedReasonData?: UnverifiedReasonDataOrderByRelationAggregateInput | null;
  updatedAt?: SortOrder | null;
  user?: UserOrderByWithRelationInput | null;
  userId?: SortOrder | null;
  validVisitation?: SortOrder | null;
  validasiToko?: SortOrder | null;
  verifStorePhotoSalesmanCode?: SortOrder | null;
}

export interface RequestVerificationWhereInput {
  AND?: RequestVerificationWhereInput[] | null;
  NOT?: RequestVerificationWhereInput[] | null;
  OR?: RequestVerificationWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringNullableFilter | null;
  claimAt?: DateTimeNullableFilter | null;
  confirmAt?: DateTimeNullableFilter | null;
  confirmBy?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  logKycSalesmanCode?: LogKycSalesmanCodeListRelationFilter | null;
  logRequestVerification?: LogRequestVerificationListRelationFilter | null;
  note?: StringFilter | null;
  salesmanReferralCode?: StringNullableFilter | null;
  sendWAMessage?: BoolFilter | null;
  status?: EnumStatusVerifyFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringNullableFilter | null;
  type?: EnumTypeVerifyFilter | null;
  unverifiedReasonData?: UnverifiedReasonDataListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: StringNullableFilter | null;
  validVisitation?: EnumValidVisitationNullableFilter | null;
  validasiToko?: StringNullableFilter | null;
  verifStorePhotoSalesmanCode?: StringNullableFilter | null;
}

export interface ScreenWhereInput {
  AND?: ScreenWhereInput[] | null;
  NOT?: ScreenWhereInput[] | null;
  OR?: ScreenWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  internalLink?: PopUpListRelationFilter | null;
  key?: StringFilter | null;
  name?: StringFilter | null;
  news?: NewsListRelationFilter | null;
  page?: PopUpListRelationFilter | null;
  parent?: StringNullableFilter | null;
  type?: EnumAccessScreenFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface SocialMediaCreateInput {
  icon: string;
  link: string;
  order: number;
}

export interface SocialMediaDeleteInput {
  id: string;
}

export interface SocialMediaInput {
  create?: SocialMediaCreateInput[] | null;
  delete?: SocialMediaDeleteInput[] | null;
  update?: SocialMediaUpdateInput[] | null;
}

export interface SocialMediaUpdateInput {
  icon?: string | null;
  id: string;
  link?: string | null;
  order?: number | null;
}

export interface StoreInputData {
  cityId?: string | null;
  districtId?: string | null;
  employee?: SubUserUpdateInput[] | null;
  latitude?: string | null;
  longitude?: string | null;
  ownerPhoneNumber?: string | null;
  postalCode?: string | null;
  provinceId?: string | null;
  referralCode?: string | null;
  rt?: string | null;
  rw?: string | null;
  storeAddress?: string | null;
  storeCategoryId?: string | null;
  storeEmail?: string | null;
  storeName?: string | null;
  storePhoneNumber?: string | null;
  urbanVillageId?: string | null;
  website?: string | null;
}

export interface StoreListRelationFilter {
  every?: StoreWhereInput | null;
  none?: StoreWhereInput | null;
  some?: StoreWhereInput | null;
}

export interface StoreOrderByInput {
  LogRollBackVerifyStore?: LogRollBackVerifyStoreOrderByRelationAggregateInput | null;
  RequestAgent?: RequestAgentOrderByRelationAggregateInput | null;
  User?: UserOrderByRelationAggregateInput | null;
  address?: SortOrder | null;
  autoCompleteManagerStoreId?: SortOrder | null;
  cityId?: SortOrder | null;
  cityName?: SortOrder | null;
  createdAt?: SortOrder | null;
  districtId?: SortOrder | null;
  districtName?: SortOrder | null;
  externalAgent?: ExternalAgentOrderByWithRelationInput | null;
  externalAgentId?: SortOrder | null;
  firstRevalidationSubmitDate?: SortOrder | null;
  id?: SortOrder | null;
  latestNotOrderingNotification?: SortOrder | null;
  latitude?: SortOrder | null;
  longitude?: SortOrder | null;
  loyaltyPointHistories?: LoyaltyPointHistoryOrderByRelationAggregateInput | null;
  managerStoreCode?: SortOrder | null;
  managerStoreId?: SortOrder | null;
  managerStoreTempId?: SortOrder | null;
  missionActivityLog?: MissionActivityLogOrderByRelationAggregateInput | null;
  oldManagerStoreCode?: SortOrder | null;
  oldManagerStoreId?: SortOrder | null;
  onProcessCron?: BatchCronJobOrderByRelationAggregateInput | null;
  point?: SortOrder | null;
  postalCode?: SortOrder | null;
  proofOfBusiness?: SortOrder | null;
  provinceId?: SortOrder | null;
  provinceName?: SortOrder | null;
  reUpload?: SortOrder | null;
  requestVerification?: RequestVerificationOrderByWithRelationInput | null;
  revalidatedDate?: SortOrder | null;
  rt?: SortOrder | null;
  rw?: SortOrder | null;
  salesReferralCode?: SortOrder | null;
  slug?: SortOrder | null;
  storeCategoryId?: SortOrder | null;
  storeEmail?: SortOrder | null;
  storeEmailVerified?: SortOrder | null;
  storeName?: SortOrder | null;
  storePhoneNumber?: SortOrder | null;
  storePhotoFront?: SortOrder | null;
  storePhotoFrontMeta?: SortOrder | null;
  storePhotoInside?: SortOrder | null;
  storePhotoInsideMeta?: SortOrder | null;
  storeVerified?: SortOrder | null;
  successfulCron?: BatchCronJobOrderByRelationAggregateInput | null;
  tadaCard?: TadaCardOrderByWithRelationInput | null;
  updatedAt?: SortOrder | null;
  updatedStoreHistory?: UpdatedStoreHistoryOrderByRelationAggregateInput | null;
  urbanVillageId?: SortOrder | null;
  urbanVillageName?: SortOrder | null;
  verifiedDate?: SortOrder | null;
  verifyAfterRevalidatedDate?: SortOrder | null;
  website?: SortOrder | null;
  yearEstablished?: SortOrder | null;
}

export interface StoreOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface StoreOrderByWithRelationInput {
  LogRollBackVerifyStore?: LogRollBackVerifyStoreOrderByRelationAggregateInput | null;
  RequestAgent?: RequestAgentOrderByRelationAggregateInput | null;
  User?: UserOrderByRelationAggregateInput | null;
  address?: SortOrder | null;
  autoCompleteManagerStoreId?: SortOrder | null;
  cityId?: SortOrder | null;
  cityName?: SortOrder | null;
  createdAt?: SortOrder | null;
  districtId?: SortOrder | null;
  districtName?: SortOrder | null;
  externalAgent?: ExternalAgentOrderByWithRelationInput | null;
  externalAgentId?: SortOrder | null;
  firstRevalidationSubmitDate?: SortOrder | null;
  id?: SortOrder | null;
  latestNotOrderingNotification?: SortOrder | null;
  latitude?: SortOrder | null;
  longitude?: SortOrder | null;
  loyaltyPointHistories?: LoyaltyPointHistoryOrderByRelationAggregateInput | null;
  managerStoreCode?: SortOrder | null;
  managerStoreId?: SortOrder | null;
  managerStoreTempId?: SortOrder | null;
  missionActivityLog?: MissionActivityLogOrderByRelationAggregateInput | null;
  oldManagerStoreCode?: SortOrder | null;
  oldManagerStoreId?: SortOrder | null;
  onProcessCron?: BatchCronJobOrderByRelationAggregateInput | null;
  point?: SortOrder | null;
  postalCode?: SortOrder | null;
  proofOfBusiness?: SortOrder | null;
  provinceId?: SortOrder | null;
  provinceName?: SortOrder | null;
  reUpload?: SortOrder | null;
  requestVerification?: RequestVerificationOrderByWithRelationInput | null;
  revalidatedDate?: SortOrder | null;
  rt?: SortOrder | null;
  rw?: SortOrder | null;
  salesReferralCode?: SortOrder | null;
  slug?: SortOrder | null;
  storeCategoryId?: SortOrder | null;
  storeEmail?: SortOrder | null;
  storeEmailVerified?: SortOrder | null;
  storeName?: SortOrder | null;
  storePhoneNumber?: SortOrder | null;
  storePhotoFront?: SortOrder | null;
  storePhotoFrontMeta?: SortOrder | null;
  storePhotoInside?: SortOrder | null;
  storePhotoInsideMeta?: SortOrder | null;
  storeVerified?: SortOrder | null;
  successfulCron?: BatchCronJobOrderByRelationAggregateInput | null;
  tadaCard?: TadaCardOrderByWithRelationInput | null;
  updatedAt?: SortOrder | null;
  updatedStoreHistory?: UpdatedStoreHistoryOrderByRelationAggregateInput | null;
  urbanVillageId?: SortOrder | null;
  urbanVillageName?: SortOrder | null;
  verifiedDate?: SortOrder | null;
  verifyAfterRevalidatedDate?: SortOrder | null;
  website?: SortOrder | null;
  yearEstablished?: SortOrder | null;
}

export interface StoreWhereInput {
  AND?: StoreWhereInput[] | null;
  LogRollBackVerifyStore?: LogRollBackVerifyStoreListRelationFilter | null;
  NOT?: StoreWhereInput[] | null;
  OR?: StoreWhereInput[] | null;
  RequestAgent?: RequestAgentListRelationFilter | null;
  User?: UserListRelationFilter | null;
  address?: StringNullableFilter | null;
  autoCompleteManagerStoreId?: StringNullableFilter | null;
  cityId?: StringNullableFilter | null;
  cityName?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  districtId?: StringNullableFilter | null;
  districtName?: StringNullableFilter | null;
  externalAgent?: ExternalAgentWhereInput | null;
  externalAgentId?: StringNullableFilter | null;
  firstRevalidationSubmitDate?: DateTimeNullableFilter | null;
  id?: StringFilter | null;
  latestNotOrderingNotification?: DateTimeNullableFilter | null;
  latitude?: StringNullableFilter | null;
  longitude?: StringNullableFilter | null;
  loyaltyPointHistories?: LoyaltyPointHistoryListRelationFilter | null;
  managerStoreCode?: StringNullableFilter | null;
  managerStoreId?: StringNullableFilter | null;
  managerStoreTempId?: StringNullableFilter | null;
  missionActivityLog?: MissionActivityLogListRelationFilter | null;
  oldManagerStoreCode?: StringNullableFilter | null;
  oldManagerStoreId?: StringNullableFilter | null;
  onProcessCron?: BatchCronJobListRelationFilter | null;
  point?: DecimalFilter | null;
  postalCode?: StringNullableFilter | null;
  proofOfBusiness?: StringNullableFilter | null;
  provinceId?: StringNullableFilter | null;
  provinceName?: StringNullableFilter | null;
  reUpload?: BoolNullableFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  revalidatedDate?: DateTimeNullableFilter | null;
  rt?: StringNullableFilter | null;
  rw?: StringNullableFilter | null;
  salesReferralCode?: StringNullableFilter | null;
  slug?: StringNullableFilter | null;
  storeCategoryId?: StringFilter | null;
  storeEmail?: StringNullableFilter | null;
  storeEmailVerified?: BoolFilter | null;
  storeName?: StringFilter | null;
  storePhoneNumber?: StringFilter | null;
  storePhotoFront?: StringNullableFilter | null;
  storePhotoFrontMeta?: StringNullableFilter | null;
  storePhotoInside?: StringNullableFilter | null;
  storePhotoInsideMeta?: StringNullableFilter | null;
  storeVerified?: BoolFilter | null;
  successfulCron?: BatchCronJobListRelationFilter | null;
  tadaCard?: TadaCardWhereInput | null;
  updatedAt?: DateTimeFilter | null;
  updatedStoreHistory?: UpdatedStoreHistoryListRelationFilter | null;
  urbanVillageId?: StringNullableFilter | null;
  urbanVillageName?: StringNullableFilter | null;
  verifiedDate?: DateTimeNullableFilter | null;
  verifyAfterRevalidatedDate?: DateTimeNullableFilter | null;
  website?: StringNullableFilter | null;
  yearEstablished?: StringNullableFilter | null;
}

export interface StringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface StringNullableFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface StringNullableListFilter {
  equals?: string[] | null;
  has?: string | null;
  hasEvery?: string[] | null;
  hasSome?: string[] | null;
  isEmpty?: boolean | null;
}

export interface SubUserUpdateInput {
  email?: string | null;
  id: string;
  name: string;
  privileges: AccessUser[];
  role?: Role | null;
  status?: StatusType | null;
}

export interface SubmitFormCompetitionListRelationFilter {
  every?: SubmitFormCompetitionWhereInput | null;
  none?: SubmitFormCompetitionWhereInput | null;
  some?: SubmitFormCompetitionWhereInput | null;
}

export interface SubmitFormCompetitionWhereInput {
  AND?: SubmitFormCompetitionWhereInput[] | null;
  NOT?: SubmitFormCompetitionWhereInput[] | null;
  OR?: SubmitFormCompetitionWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  formCompetition?: FormCompetitionWhereInput | null;
  formCompetitionId?: IntNullableFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  phoneNumber?: StringFilter | null;
  storeName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface SubmitFormEventListRelationFilter {
  every?: SubmitFormEventWhereInput | null;
  none?: SubmitFormEventWhereInput | null;
  some?: SubmitFormEventWhereInput | null;
}

export interface SubmitFormEventWhereInput {
  AND?: SubmitFormEventWhereInput[] | null;
  NOT?: SubmitFormEventWhereInput[] | null;
  OR?: SubmitFormEventWhereInput[] | null;
  attendees?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  formEvent?: FormEventWhereInput | null;
  formEventId?: IntNullableFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  phoneNumber?: StringFilter | null;
  storeName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TadaCardOrderByWithRelationInput {
  cardNo?: SortOrder | null;
  cardPin?: SortOrder | null;
  createdAt?: SortOrder | null;
  id?: SortOrder | null;
  store?: StoreOrderByWithRelationInput | null;
  storeId?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface TadaCardWhereInput {
  AND?: TadaCardWhereInput[] | null;
  NOT?: TadaCardWhereInput[] | null;
  OR?: TadaCardWhereInput[] | null;
  cardNo?: StringFilter | null;
  cardPin?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  id?: StringFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TadaRedeemOrderWhereInput {
  AND?: TadaRedeemOrderWhereInput[] | null;
  LoyaltyPointHistory?: LoyaltyPointHistoryListRelationFilter | null;
  NOT?: TadaRedeemOrderWhereInput[] | null;
  OR?: TadaRedeemOrderWhereInput[] | null;
  categoryItem?: StringNullableFilter | null;
  categoryItemId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  cronVerified?: BoolFilter | null;
  deviceId?: StringNullableFilter | null;
  handlingFee?: IntNullableFilter | null;
  handlingFeePoint?: IntNullableFilter | null;
  id?: StringFilter | null;
  insuraceFee?: IntNullableFilter | null;
  insuraceFeePoint?: IntNullableFilter | null;
  isRefunded?: BoolFilter | null;
  orderNumber?: StringNullableFilter | null;
  pricePerItem?: IntNullableFilter | null;
  pricePointPerItem?: DecimalNullableFilter | null;
  processingFee?: IntNullableFilter | null;
  processingFeePoint?: IntNullableFilter | null;
  quantity?: IntNullableFilter | null;
  refundReason?: StringNullableFilter | null;
  shippingFee?: IntNullableFilter | null;
  shippingFeePoint?: IntNullableFilter | null;
  tadaOrderId?: IntFilter | null;
  taxFee?: IntNullableFilter | null;
  taxFeePoint?: IntNullableFilter | null;
  totalPrice?: DecimalFilter | null;
  totalPriceRp?: IntNullableFilter | null;
  totalRefunded?: DecimalFilter | null;
  typeItem?: EnumTadaItemTypeNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  utilityFee?: IntNullableFilter | null;
  utilityFeePoint?: IntNullableFilter | null;
}

export interface TemporaryGivenAgentListRelationFilter {
  every?: TemporaryGivenAgentWhereInput | null;
  none?: TemporaryGivenAgentWhereInput | null;
  some?: TemporaryGivenAgentWhereInput | null;
}

export interface TemporaryGivenAgentWhereInput {
  AND?: TemporaryGivenAgentWhereInput[] | null;
  NOT?: TemporaryGivenAgentWhereInput[] | null;
  OR?: TemporaryGivenAgentWhereInput[] | null;
  bulkAgent?: BulkAgentWhereInput | null;
  bulkAgentId?: StringFilter | null;
  city?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringNullableFilter | null;
  id?: StringFilter | null;
  invalidData?: StringNullableListFilter | null;
  name?: StringFilter | null;
  no?: IntFilter | null;
  phoneNumber?: StringFilter | null;
  status?: EnumStatusGivenPointNullableFilter | null;
  statusProgress?: EnumStatusProgressTempGivenPointNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TemporaryGivenPointListRelationFilter {
  every?: TemporaryGivenPointWhereInput | null;
  none?: TemporaryGivenPointWhereInput | null;
  some?: TemporaryGivenPointWhereInput | null;
}

export interface TemporaryGivenPointWhereInput {
  AND?: TemporaryGivenPointWhereInput[] | null;
  NOT?: TemporaryGivenPointWhereInput[] | null;
  OR?: TemporaryGivenPointWhereInput[] | null;
  action?: EnumStatusAddEarnGivenPointNullableFilter | null;
  brand?: StringNullableFilter | null;
  bulkPoint?: BulkPointWhereInput | null;
  bulkPointId?: StringFilter | null;
  codeStore?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  endDate?: StringNullableFilter | null;
  id?: StringFilter | null;
  invalidData?: StringNullableListFilter | null;
  loyaltyPoint?: LoyaltyPointHistoryWhereInput | null;
  name?: StringNullableFilter | null;
  no?: IntFilter | null;
  note?: StringNullableFilter | null;
  sendPushNotif?: BoolFilter | null;
  startDate?: StringNullableFilter | null;
  status?: EnumStatusGivenPointNullableFilter | null;
  statusProgress?: EnumStatusProgressTempGivenPointNullableFilter | null;
  storeName?: StringNullableFilter | null;
  tonase?: DecimalNullableFilter | null;
  totalPoint?: DecimalNullableFilter | null;
  totalTransaction?: DecimalNullableFilter | null;
  type?: EnumLumpSumTypeNullableFilter | null;
  typeId?: StringNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface UnverifiedReason {
  nextAction?: string | null;
  otherReason?: string | null;
  phoneType?: PhoneCategory | null;
  reason?: string | null;
  reasonType?: DocumentProblemType | null;
  storePhotoType?: StorePhotoCategory | null;
}

export interface UnverifiedReasonDataListRelationFilter {
  every?: UnverifiedReasonDataWhereInput | null;
  none?: UnverifiedReasonDataWhereInput | null;
  some?: UnverifiedReasonDataWhereInput | null;
}

export interface UnverifiedReasonDataOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface UnverifiedReasonDataWhereInput {
  AND?: UnverifiedReasonDataWhereInput[] | null;
  NOT?: UnverifiedReasonDataWhereInput[] | null;
  OR?: UnverifiedReasonDataWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  documentProblemType?: EnumDocumentProblemTypeNullableFilter | null;
  id?: StringFilter | null;
  nextAction?: StringFilter | null;
  otherReason?: StringNullableFilter | null;
  phoneCategory?: EnumPhoneCategoryNullableFilter | null;
  problemReason?: StringFilter | null;
  requestVerification?: RequestVerificationWhereInput | null;
  requestVerificationId?: StringFilter | null;
  storePhotoCategory?: EnumStorePhotoCategoryNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface UpdateBUContact {
  buContacts: BUContactInputWithId[];
}

export interface UpdatedStoreHistoryListRelationFilter {
  every?: UpdatedStoreHistoryWhereInput | null;
  none?: UpdatedStoreHistoryWhereInput | null;
  some?: UpdatedStoreHistoryWhereInput | null;
}

export interface UpdatedStoreHistoryOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface UpdatedStoreHistoryWhereInput {
  AND?: UpdatedStoreHistoryWhereInput[] | null;
  NOT?: UpdatedStoreHistoryWhereInput[] | null;
  OR?: UpdatedStoreHistoryWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  currentStoreData?: StringFilter | null;
  id?: StringFilter | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  updatedAttributes?: StringFilter | null;
}

export interface UpdatedUserHistoryListRelationFilter {
  every?: UpdatedUserHistoryWhereInput | null;
  none?: UpdatedUserHistoryWhereInput | null;
  some?: UpdatedUserHistoryWhereInput | null;
}

export interface UpdatedUserHistoryOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface UpdatedUserHistoryWhereInput {
  AND?: UpdatedUserHistoryWhereInput[] | null;
  NOT?: UpdatedUserHistoryWhereInput[] | null;
  OR?: UpdatedUserHistoryWhereInput[] | null;
  admin?: AdminWhereInput | null;
  adminId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  currentUserData?: StringFilter | null;
  id?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  updatedAttributes?: StringFilter | null;
  user?: UserWhereInput | null;
  userId?: StringFilter | null;
}

export interface UserListRelationFilter {
  every?: UserWhereInput | null;
  none?: UserWhereInput | null;
  some?: UserWhereInput | null;
}

export interface UserOrderByRelationAggregateInput {
  _count?: SortOrder | null;
}

export interface UserOrderByWithRelationInput {
  RequestAgent?: RequestAgentOrderByRelationAggregateInput | null;
  accessUser?: SortOrder | null;
  addedBy?: SortOrder | null;
  agreedTnCDate?: SortOrder | null;
  agreedTnCVersion?: SortOrder | null;
  agreementStatementRegal?: AgreementRegalStatementOrderByWithRelationInput | null;
  betaTester?: SortOrder | null;
  createFrom?: SortOrder | null;
  createdAt?: SortOrder | null;
  dokuRedeemOrders?: DokuRedeemOrderOrderByRelationAggregateInput | null;
  draftOrder?: DraftOrderOrderByRelationAggregateInput | null;
  id?: SortOrder | null;
  inactivatedBy?: SortOrder | null;
  inactiveDate?: SortOrder | null;
  logChangePhoneNumber?: LogChangePhoneNumberOrderByRelationAggregateInput | null;
  managerUserId?: SortOrder | null;
  missionActivityLog?: MissionActivityLogOrderByRelationAggregateInput | null;
  orderReference?: OrderReferenceOrderByRelationAggregateInput | null;
  ownerVerified?: SortOrder | null;
  phoneNumber?: SortOrder | null;
  playerIdManager?: SortOrder | null;
  profile?: ProfileOrderByWithRelationInput | null;
  requestVerification?: RequestVerificationOrderByWithRelationInput | null;
  role?: SortOrder | null;
  status?: SortOrder | null;
  store?: StoreOrderByWithRelationInput | null;
  storeId?: SortOrder | null;
  termsAndCondition?: SortOrder | null;
  updateNoticeTnCDate?: SortOrder | null;
  updateNoticeTnCVersion?: SortOrder | null;
  updatedAt?: SortOrder | null;
  updatedUserHistory?: UpdatedUserHistoryOrderByRelationAggregateInput | null;
  verifiedDate?: SortOrder | null;
  whatsAppValidation?: SortOrder | null;
  whatsAppValidationTimeStamp?: SortOrder | null;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  NOT?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  RequestAgent?: RequestAgentListRelationFilter | null;
  accessUser?: EnumAccessUserNullableListFilter | null;
  addedBy?: StringNullableFilter | null;
  agreedTnCDate?: DateTimeNullableFilter | null;
  agreedTnCVersion?: IntNullableFilter | null;
  agreementStatementRegal?: AgreementRegalStatementWhereInput | null;
  betaTester?: BoolNullableFilter | null;
  createFrom?: EnumFlagCreateUserNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  dokuRedeemOrders?: DokuRedeemOrderListRelationFilter | null;
  draftOrder?: DraftOrderListRelationFilter | null;
  id?: StringFilter | null;
  inactivatedBy?: StringNullableFilter | null;
  inactiveDate?: DateTimeNullableFilter | null;
  logChangePhoneNumber?: LogChangePhoneNumberListRelationFilter | null;
  managerUserId?: StringFilter | null;
  missionActivityLog?: MissionActivityLogListRelationFilter | null;
  orderReference?: OrderReferenceListRelationFilter | null;
  ownerVerified?: BoolNullableFilter | null;
  phoneNumber?: StringFilter | null;
  playerIdManager?: StringNullableListFilter | null;
  profile?: ProfileWhereInput | null;
  requestVerification?: RequestVerificationWhereInput | null;
  role?: EnumRoleFilter | null;
  status?: StatusActive | null;
  store?: StoreWhereInput | null;
  storeId?: StringFilter | null;
  termsAndCondition?: EnumTermsAndConditionFilter | null;
  updateNoticeTnCDate?: DateTimeNullableFilter | null;
  updateNoticeTnCVersion?: IntNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  updatedUserHistory?: UpdatedUserHistoryListRelationFilter | null;
  verifiedDate?: DateTimeNullableFilter | null;
  whatsAppValidation?: BoolNullableFilter | null;
  whatsAppValidationTimeStamp?: DateTimeNullableFilter | null;
}

export interface WinnersDataInput {
  createEdit: WinnersInput[];
  delete: DeleteWinners[];
}

export interface WinnersInput {
  email: string;
  id?: string | null;
  name: string;
  notified: boolean;
  storeName: string;
}

export interface WinnersListRelationFilter {
  every?: WinnersWhereInput | null;
  none?: WinnersWhereInput | null;
  some?: WinnersWhereInput | null;
}

export interface WinnersWhereInput {
  AND?: WinnersWhereInput[] | null;
  NOT?: WinnersWhereInput[] | null;
  OR?: WinnersWhereInput[] | null;
  competition?: CompetitionWhereInput | null;
  competitionId?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  email?: StringFilter | null;
  id?: StringFilter | null;
  name?: StringFilter | null;
  notified?: BoolFilter | null;
  storeName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
