import gql from 'graphql-tag';

import { SCREEN_FRAGMENT } from '../fragments';

export const GET_SCREENS = gql`
  query Screens($type: AccessScreen!) {
    screensCMS(type: $type) {
      ...ScreenFragment
    }
  }
  ${SCREEN_FRAGMENT}
`;

export const GET_SCREEN_NEWS_LIST = gql`
  query screenNews {
    screenNewsList {
      ...ScreenFragment
    }
  }
  ${SCREEN_FRAGMENT}
`;
