import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  View,
  StyleSheet,
  // TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MapIcon from '@material-ui/icons/Map';
import CheckIcon from '@material-ui/icons/Check';
import SubjectIcon from '@material-ui/icons/Subject';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useDebounce } from 'use-debounce';
import { ApolloError } from 'apollo-client';

import { Text, TextInput, Button, AttachmentModal } from '../../core-ui';
import SegmentedControl from '../../components/SegmentedControl';
import { colors, spacing } from '../../constants/theme';
import Infotip from '../../core-ui/Infotip';
// import DistributorModal from '../../components/DistributorModal';
import { Checkbox } from '../../../../core-ui';
import { VerificationRequests_requestVerificationAdvance_row as Data } from '../../../../generated/VerificationRequests';
import {
  Dropdown,
  CityDropdown,
  ProvinceDropdown,
  StoreCategoryDropdown,
  DistrictDropdown,
  VillageDropdown,
} from '../../components/dropdowns';
import {
  CHANGE_STORE_CODE,
  ROLLBACK_VERIFIED_STORE,
  UPDATE_REQUEST_VERIFICATION_STORE,
} from '../../graphql/mutations';
import {
  UpdateRequestVerificationStore,
  UpdateRequestVerificationStoreVariables,
} from '../../../../generated/UpdateRequestVerificationStore';
import {
  CheckManagerStoreId,
  CheckManagerStoreIdVariables,
} from '../../../../generated/CheckManagerStoreId';
import {
  AccessUser,
  PhoneCategory,
  RequestAgentVerificationCreateInput,
  RequestAgentVerificationUpdateInput,
  Role,
  RoleAdmin,
  StatusVerify,
  StorePhotoCategory,
  TypeRollBack,
  ValidVisitation,
} from '../../../../generated/globalTypes';
import { VERIFY_STORE_ID } from '../../graphql/queries/store';
import { verifiedStatus, tokoStatus } from '../../constants/tokoStatus';
import { permissions } from '../../constants/permissions';
import { useSnackbar } from '../../context';
import { LINK_MANAGER_STORE_VERIFY } from '../../../../constants/api';
import {
  CheckCanRollback,
  CheckCanRollbackVariables,
} from '../../../../generated/CheckCanRollback';
import { CHECK_CAN_ROLLBACK, REQUEST_MATCHMAKING } from '../../graphql/queries';
import {
  RollbackVerifiedStore,
  RollbackVerifiedStoreVariables,
} from '../../../../generated/RollbackVerifiedStore';
import {
  ChangeVerifiedMergeStoreCode,
  ChangeVerifiedMergeStoreCodeVariables,
} from '../../../../generated/ChangeVerifiedMergeStoreCode';
import {
  BrandDropdown,
  ConfirmationModal,
  DistributorDropdown,
  ErrorModal,
  Option,
} from '../../../../components';
import {
  RequestMatchMaking,
  RequestMatchMakingVariables,
} from '../../../../generated/RequestMatchMaking';
import {
  storeValidation as initialStoreValidation,
  validVisitation as initialValidValidation,
} from '../../constants/storeValidation';
import validateEmail from '../../../../helpers/validateEmail';
import {
  emptyUnverifiedCategories,
  nextActions,
  phoneCategories,
  phoneNotPickedUpProblems,
  phonePickedUpProblems,
  photoCategories,
  photoFrontProblems,
  photoInsideProblems,
  typeCategories,
} from '../../constants/categoryUnverified';
import { useAuth } from '../../../../context';
import { PhotoExifFragment } from '../../../../generated/PhotoExifFragment';
// import { AgentByStoreId_agentByStoreId } from '../../../../generated/AgentByStoreId';

import { StoreStatusEnum, UnverifiedCategory } from './types';

type Props = {
  data: Data;
  unclaimLoading?: boolean;
  // distributors?: Array<AgentByStoreId_agentByStoreId> | null;
  viewMode?: boolean;
  onUnclaim?: () => void;
  onUpdate?: () => void;
  setFormDirty?: (value: boolean) => void;
  onRollbackCompleted?: () => void;
};

type UpdateVerifInput = RequestAgentVerificationUpdateInput & {
  brandName: string;
  selectedDistributorName: string;
  agentSelectName?: string;
};

type CreateVerifInput = RequestAgentVerificationCreateInput & {
  id: string;
};

let tempCreateInput: Array<CreateVerifInput> = [
  {
    id: Math.random().toString(),
    agentId: null,
    agentName: '',
    brandId: '',
  },
];

const TokoDataForm = memo((props: Props) => {
  let {
    data,
    // distributors,
    onUnclaim,
    unclaimLoading,
    onUpdate,
    viewMode = false,
    setFormDirty,
    onRollbackCompleted,
  } = props;
  const { openSnackbar } = useSnackbar();

  let [storeName, setStoreName] = useState('');
  let [userPermissions, setUserPermissions] = useState<
    Array<Array<AccessUser>>
  >([]);
  let [userName, setUserName] = useState<Array<string>>([]);
  let [userEmail, setUserEmail] = useState<Array<string>>([]);
  let [userRole, setUserRole] = useState<Array<Role | null>>([]);
  let [updateVerifInput, setUpdateVerifInput] = useState<Array<
    UpdateVerifInput
  > | null>(null);
  let [createVerifInput, setCreateVerifInput] = useState<
    Array<CreateVerifInput>
  >(tempCreateInput);
  // let [longitude, setLongitude] = useState('');
  // let [latitude, setLatitude] = useState('');
  // let [referral, setReferral] = useState('');
  let [note, setNote] = useState('');
  let [storeStatus, setStoreStatus] = useState(StoreStatusEnum.UNVERIFIED);
  let [storeCategory, setStoreCategory] = useState('');
  let [storePhone, setStorePhone] = useState('');
  let [userPhone, setUserPhone] = useState('');
  let [storeEmail, setStoreEmail] = useState('');
  let [storeWeb, setStoreWeb] = useState('');
  let [address, setAddress] = useState('');
  let [city, setCity] = useState<string | null>(null);
  let [province, setProvince] = useState<string | null>(null);
  let [district, setDistrict] = useState<string | null>(null);
  let [postalCode, setPostalCode] = useState<string | null>(null);
  let [rt, setRT] = useState('');
  let [rw, setRW] = useState('');
  let [village, setVillage] = useState<string | null>(null);
  let [addressLatitude, setAddressLatitude] = useState('');
  let [addressLongitude, setAddressLongitude] = useState('');
  let [existingId, setExistingId] = useState('');
  let [debouncedExistingId] = useDebounce(existingId, 500);
  let [storeValidation, setStoreValidation] = useState('');
  let [validVisitation, setValidVisitation] = useState<ValidVisitation | null>(
    null,
  );
  let [restrictValidVisitation, setRestrictValidVisitation] = useState(false);

  let [attachmentName, setAttachmentName] = useState('');
  let [openModal, setOpenModal] = useState(false);
  let [image, setImage] = useState<string | null>(null);
  let [exif, setExif] = useState<PhotoExifFragment | null>(null);
  let [isIdExisted, setIsIdExisted] = useState<null | boolean>(null);
  const [existingIdName, setExistingIdName] = useState('');
  let [newStoreCode, setNewStoreCode] = useState('');
  let [debouncedNewStoreCode] = useDebounce(newStoreCode, 500);
  let [isNewStoreCodeExisted, setNewStoreCodeExisted] = useState<
    null | boolean
  >(null);
  let [existingNewStoreName, setExistingNewStoreName] = useState('');
  let [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  let [isErrorModalOpen, setErrorModalOpen] = useState(false);
  let [actionError, setActionError] = useState('');
  let [apolloError, setApolloError] = useState<ApolloError>();

  const { role } = useAuth();

  let [unverifiedCategories, setUnverifiedCategories] = useState<
    Array<UnverifiedCategory>
  >([emptyUnverifiedCategories]);

  let emailCheck = storeEmail === '' ? true : validateEmail(storeEmail);
  let userEmailCheck = false;

  let isStoreStatusVerifiedAndNoDataProvided =
    (storeStatus === StoreStatusEnum.VERIFIED ||
      storeStatus === StoreStatusEnum.VERIFIED_MERGE) &&
    (district === '0' ||
      !district ||
      postalCode === '0' ||
      !postalCode ||
      village === '0' ||
      !village);

  for (let i = 0; i < userEmail.length; i++) {
    if (userEmail[i] === '' || validateEmail(userEmail[i])) {
      userEmailCheck = true;
    } else {
      userEmailCheck = false;
      break;
    }
  }

  const onRollbackError = useCallback(
    (errorFrom: 'rollback' | 'changeStore', error: ApolloError) => {
      if (errorFrom === 'rollback') {
        setApolloError(error);
        setActionError(t(['melakukan rollback', 'do rollback']));
      }
      if (errorFrom === 'changeStore') {
        setApolloError(error);
        setActionError(
          t(['melakukan pergantian kode toko', 'do changing store code']),
        );
      }
      setErrorModalOpen(true);
    },
    [setErrorModalOpen],
  );

  const setEmployeeData = useCallback(
    <T extends unknown>(
      setter: React.Dispatch<React.SetStateAction<Array<T>>>,
    ) => (index: number) => (value: T) => {
      setFormDirty?.(true);
      setter((prev) => {
        let newValue = [...prev];
        newValue.splice(index, 1, value);
        return newValue;
      });
    },
    [setFormDirty],
  );
  const setEmployeeName = useMemo(() => setEmployeeData(setUserName), [
    setEmployeeData,
  ]);
  const setEmployeeEmail = useMemo(() => setEmployeeData(setUserEmail), [
    setEmployeeData,
  ]);

  const setEmployeePermissions = useMemo(
    () => setEmployeeData(setUserPermissions),
    [setEmployeeData],
  );

  const employees = useMemo(() => data.store?.User || [], [data]);

  const userPhoneNumber = useMemo(() => {
    let owner = data.store?.User?.find((user) => user.role === Role.TOKO_OWNER);
    if (owner) {
      return owner.phoneNumber;
    } else if (data.store?.User?.length === 0) {
      return '';
    } else {
      return (data.store?.User && data.store?.User[0].phoneNumber) ?? '';
    }
  }, [data]);

  const isStoreUnverified = useMemo(
    () =>
      storeStatus === StoreStatusEnum.UNVERIFIED ||
      storeStatus === StoreStatusEnum.REVALIDATE,
    [storeStatus],
  );

  useEffect(() => {
    if (data.store) {
      setStoreName(data.store.storeManagerBulk?.storeName ?? '');
      setStoreEmail(data.store.storeManagerBulk?.storeEmail ?? '');
      setStoreCategory(data.store.storeManagerBulk?.storeCategoryId ?? '');
      setStorePhone(data.store.storeManagerBulk?.storePhoneNumber ?? '');
      // setLongitude(''); // fill with salesman location
      // setLatitude(''); // fill with salesman location
      setStoreWeb(data.store.website ?? '');
      setUserPhone(userPhoneNumber);
      setCity(data.store.storeManagerBulk?.cityId ?? '');
      setProvince(data.store.storeManagerBulk?.provinceId ?? '');
      setVillage(data.store.storeManagerBulk?.villageId ?? '');
      setPostalCode(data.store.storeManagerBulk?.postalCode ?? '');
      setRT(data.store.rt ?? '00');
      setRW(data.store.rw ?? '00');
      setDistrict(data.store.storeManagerBulk?.districtId ?? '');
      setAddress(data.store.storeManagerBulk?.address ?? '');
      setAddressLatitude(data.store.storeManagerBulk?.latitude ?? '');
      setAddressLongitude(data.store.storeManagerBulk?.longitude ?? '');
      if (data.status === StatusVerify.VERIFIED_MERGE) {
        setStoreStatus(StoreStatusEnum.VERIFIED_MERGE);
        setExistingId(data.store.storeManagerBulk?.storeCode ?? '');
      } else if (data.status === StatusVerify.VERIFIED) {
        setStoreStatus(StoreStatusEnum.VERIFIED);
      } else if (data.status === StatusVerify.REVALIDATION) {
        setStoreStatus(StoreStatusEnum.REVALIDATE);
      } else {
        setStoreStatus(StoreStatusEnum.UNVERIFIED);
      }
      setUserName(employees.map(({ profile }) => profile?.name ?? ''));
      setUserEmail(employees.map(({ profile }) => profile?.email ?? ''));
      setUserRole(employees.map(({ role }) => role));
      setUserPermissions(employees.map(({ accessUser }) => accessUser));
      setCreateVerifInput([
        {
          id: Math.random().toString(),
          agentId: null,
          agentName: '',
          brandId: '',
        },
      ]);
      setStoreValidation(data.validasiToko || '');
      setValidVisitation(data.validVisitation || null);
    }
    setIsIdExisted(null);
    setNote(data.note);
    resetUnverifiedCategories();
  }, [data, employees, userPhoneNumber]);

  // const distributorNameList = useMemo(
  //   () => distributors?.map(({ agentName }) => agentName) ?? [],
  //   [distributors],
  // );

  const onUpdateError = useCallback(() => {
    openSnackbar({
      variant: 'error',
      message: t([
        'Gagal memperbarui data. Mohon coba lagi nanti.',
        'Failed to update data. Please try again later.',
      ]),
    });
  }, [openSnackbar]);

  const [updateData, { loading: updateLoading }] = useMutation<
    UpdateRequestVerificationStore,
    UpdateRequestVerificationStoreVariables
  >(UPDATE_REQUEST_VERIFICATION_STORE, {
    onCompleted: onUpdate,
    onError: onUpdateError,
  });

  const onUpdateData = useCallback(() => {
    let formattedStorePhone = storePhone.replace(/\(|\)/g, '');
    let formattedUserPhone = userPhone.replace(/\(|\)/g, '');
    let employeeData = [];
    if (data.store?.User) {
      for (let index = 0; index < employees.length; index++) {
        employeeData.push({
          id: data.store.User[index].id,
          name: userName[index],
          role: userRole[index],
          email: userEmail[index],
          privileges: userPermissions[index],
        });
      }
    }

    let mappedUpdateInput = updateVerifInput?.map(
      ({ requestAgentId, brandId, agentName, agentSelectName, agentId }) => {
        return {
          requestAgentId,
          brandId,
          agentName: agentId ? agentSelectName || '' : agentName,
          agentId,
        };
      },
    );

    let mappedCreateInput = createVerifInput?.map(
      ({ brandId, agentName, agentId }) => {
        return {
          agentId,
          agentName,
          brandId,
        };
      },
    );

    let unverifiedReason = unverifiedCategories.map(
      ({ problem, detail, action, customProblem }) => {
        return {
          nextAction: action,
          phoneType:
            detail === 'Telepon diangkat'
              ? PhoneCategory.ANSWERED
              : detail === 'Telepon tidak diangkat'
              ? PhoneCategory.NOT_ANSWERED
              : null,
          storePhotoType:
            detail === 'Foto Tampak Depan'
              ? StorePhotoCategory.STORE_PHOTO_FRONT
              : detail === 'Foto Bagian Dalam'
              ? StorePhotoCategory.STORE_PHOTO_INSIDE
              : null,
          reason: problem === 'Lainnya' ? null : problem,
          otherReason: problem === 'Lainnya' ? customProblem : null,
        };
      },
    );

    updateData({
      variables: {
        verificationId: data.id,
        status: verifiedStatus[storeStatus],
        existingStoreCode:
          storeStatus === StoreStatusEnum.VERIFIED_MERGE
            ? existingId
            : undefined,
        note: note,
        storeData: {
          storeAddress: address,
          storeCategoryId: storeCategory,
          storeEmail: storeEmail,
          storeName: storeName,
          storePhoneNumber: formattedStorePhone,
          ownerPhoneNumber: formattedUserPhone,
          cityId: city,
          provinceId: province,
          districtId: district,
          urbanVillageId: village,
          postalCode: postalCode,
          rt: rt,
          rw: rw,
          latitude: addressLatitude,
          longitude: addressLongitude,
          website: storeWeb,
          employee: employeeData,
        },
        requestAgentData: {
          update: mappedUpdateInput ? mappedUpdateInput : [],
          create: mappedCreateInput ? mappedCreateInput : [],
        },
        validasiToko: storeValidation,
        validVisitation: validVisitation,
        unverifiedReason,
      },
    });
  }, [
    data,
    storeName,
    storeEmail,
    storePhone,
    storeWeb,
    storeCategory,
    address,
    province,
    city,
    district,
    postalCode,
    village,
    rt,
    rw,
    existingId,
    storeStatus,
    addressLatitude,
    addressLongitude,
    userPhone,
    updateData,
    note,
    userName,
    userRole,
    userEmail,
    userPermissions,
    employees,
    updateVerifInput,
    createVerifInput,
    storeValidation,
    validVisitation,
    unverifiedCategories,
  ]);

  const [
    verifyStoreId,
    { loading: verifyLoading, data: verifyData },
  ] = useLazyQuery<CheckManagerStoreId, CheckManagerStoreIdVariables>(
    VERIFY_STORE_ID,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        if (verifyData) {
          setIsIdExisted(verifyData.checkManagerStoreCode.valid);
          setExistingIdName(verifyData.checkManagerStoreCode.storeName ?? '');
        }
      },
    },
  );

  useEffect(() => {
    if (debouncedExistingId && !viewMode) {
      verifyStoreId({ variables: { managerStoreCode: debouncedExistingId } });
    } else {
      setIsIdExisted(null);
    }
  }, [debouncedExistingId, viewMode, verifyStoreId]);

  const [checkCanRollback, { data: canRollback }] = useLazyQuery<
    CheckCanRollback,
    CheckCanRollbackVariables
  >(CHECK_CAN_ROLLBACK, {
    variables: {
      id: data.id,
      type:
        storeStatus === StoreStatusEnum.VERIFIED
          ? TypeRollBack.VERIFIED
          : TypeRollBack.VERIFIED_MERGE,
    },
  });

  useEffect(() => {
    viewMode && checkCanRollback();
  }, [viewMode, checkCanRollback]);

  const [
    verifyNewStoreCode,
    { loading: verifyNewStoreCodeLoading, data: verifyNewStoreCodeData },
  ] = useLazyQuery<CheckManagerStoreId, CheckManagerStoreIdVariables>(
    VERIFY_STORE_ID,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data) {
          setNewStoreCodeExisted(data.checkManagerStoreCode.valid);
          setExistingNewStoreName(data.checkManagerStoreCode.storeName ?? '');
        }
      },
    },
  );

  useEffect(() => {
    if (debouncedNewStoreCode && canRollback) {
      verifyNewStoreCode({
        variables: { managerStoreCode: debouncedNewStoreCode },
      });
    } else {
      setNewStoreCodeExisted(null);
    }
  }, [debouncedNewStoreCode, canRollback, verifyNewStoreCode]);

  const [rollbackVerifiedStore, { loading: rollbackLoading }] = useMutation<
    RollbackVerifiedStore,
    RollbackVerifiedStoreVariables
  >(ROLLBACK_VERIFIED_STORE, {
    variables: {
      idVerification: data.id,
    },
    onCompleted: onRollbackCompleted,
    onError: (error) => onRollbackError('rollback', error),
  });

  const [
    changeVerifiedMergeStoreCode,
    { loading: changeStoreCodeLoading },
  ] = useMutation<
    ChangeVerifiedMergeStoreCode,
    ChangeVerifiedMergeStoreCodeVariables
  >(CHANGE_STORE_CODE, {
    variables: {
      idVerification: data.id,
      newManagerStoreCode: debouncedNewStoreCode.trim(),
    },
    onCompleted: onRollbackCompleted,
    onError: (error) => onRollbackError('changeStore', error),
  });

  const togglePermission = useCallback(
    (index: number, permission: AccessUser) => {
      let newUserPermission = [...userPermissions[index]];
      if (newUserPermission.includes(permission)) {
        newUserPermission = newUserPermission.filter(
          (item) => item !== permission,
        );
      } else {
        newUserPermission.push(permission);
      }
      setEmployeePermissions(index)(newUserPermission);
    },
    [userPermissions, setEmployeePermissions],
  );

  let submitDisabled = useMemo(
    () =>
      !storeName ||
      !storePhone ||
      !storeCategory ||
      !address ||
      !province ||
      province === '0' ||
      !city ||
      city === '0' ||
      isStoreStatusVerifiedAndNoDataProvided ||
      (storeStatus === StoreStatusEnum.VERIFIED_MERGE && !isIdExisted) ||
      !note ||
      !emailCheck ||
      !userEmailCheck ||
      (isStoreUnverified &&
        !unverifiedCategories.some(
          ({ type, detail, problem, customProblem, action }) =>
            !!type && !!detail && (!!problem || !!customProblem) && !!action,
        )),
    [
      storeName,
      storePhone,
      storeCategory,
      address,
      province,
      city,
      isStoreStatusVerifiedAndNoDataProvided,
      storeStatus,
      isStoreUnverified,
      note,
      isIdExisted,
      emailCheck,
      userEmailCheck,
      unverifiedCategories,
    ],
  );

  // const disabledOpenMap = useMemo(() => {
  //   return !latitude || !longitude || !addressLatitude || !addressLongitude;
  // }, [latitude, longitude, addressLatitude, addressLongitude]);

  const openStoreMapDisabled = useMemo(
    () => !addressLatitude || !addressLongitude,
    [addressLatitude, addressLongitude],
  );

  const onValueChange = useCallback(
    <T extends unknown>(setter: Dispatch<SetStateAction<T>>) => (params: T) => {
      setFormDirty?.(true);
      setter(params);
    },
    [setFormDirty],
  );
  const onStoreNameChange = useMemo(() => onValueChange(setStoreName), [
    onValueChange,
  ]);
  const onStoreCategoryChange = useMemo(() => onValueChange(setStoreCategory), [
    onValueChange,
  ]);
  const onStoreEmailChange = useMemo(() => onValueChange(setStoreEmail), [
    onValueChange,
  ]);
  const onStoreWebChange = useMemo(() => onValueChange(setStoreWeb), [
    onValueChange,
  ]);
  const onAddressChange = useMemo(() => onValueChange(setAddress), [
    onValueChange,
  ]);
  const onProvinceChange = useMemo(() => onValueChange(setProvince), [
    onValueChange,
  ]);
  const onCityChange = useMemo(() => onValueChange(setCity), [onValueChange]);
  const onDistrictChange = useMemo(() => onValueChange(setDistrict), [
    onValueChange,
  ]);
  const onVillageChange = useMemo(() => onValueChange(setVillage), [
    onValueChange,
  ]);
  const onPostalCodeChange = useMemo(() => onValueChange(setPostalCode), [
    onValueChange,
  ]);
  const onRTChange = useMemo(() => onValueChange(setRT), [onValueChange]);
  const onRWChange = useMemo(() => onValueChange(setRW), [onValueChange]);
  const onAddressLatitudeChange = useMemo(
    () => onValueChange(setAddressLatitude),
    [onValueChange],
  );
  const onAddressLongitudeChange = useMemo(
    () => onValueChange(setAddressLongitude),
    [onValueChange],
  );
  const onStoreStatusChange = useMemo(() => onValueChange(setStoreStatus), [
    onValueChange,
  ]);
  const onExistingIdChange = useMemo(() => onValueChange(setExistingId), [
    onValueChange,
  ]);
  const onNoteChange = useMemo(() => onValueChange(setNote), [onValueChange]);

  let [getMatchMaking, { data: matchMakingData }] = useLazyQuery<
    RequestMatchMaking,
    RequestMatchMakingVariables
  >(REQUEST_MATCHMAKING, {
    variables: { storeId: data.storeId ?? '', isVerified: viewMode },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      let matchArray = res.requestMatchMaking.map(
        ({ agentName, brandId, id, brand: { name } }) => {
          return {
            agentId: null,
            agentName: agentName ?? '',
            brandId,
            requestAgentId: id,
            selectedDistributorName: '',
            brandName: name,
          };
        },
      );
      setUpdateVerifInput(matchArray);
    },
  });

  let onSelectDistributor = (option: Option, id: number) => {
    if (updateVerifInput) {
      let filteredInput = updateVerifInput.filter((item) => {
        if (item.requestAgentId === id) {
          item.agentId = option.value;
          item.agentSelectName = option.label;
        }
        return item;
      });
      setUpdateVerifInput(filteredInput);
    }
  };

  let onSelectBrand = (option: Option, id: string) => {
    let filteredInput = createVerifInput.filter((item) => {
      if (item.id === id) {
        item.brandId = option.value;
      }
      return item;
    });
    setCreateVerifInput(filteredInput);
  };

  let onSelectNewDistributor = (option: Option, id: string) => {
    let filteredInput = createVerifInput.filter((item) => {
      if (item.id === id) {
        item.agentId = option.value;
        item.agentName = option.label;
      }
      return item;
    });
    setCreateVerifInput(filteredInput);
  };

  let addDistributorRow = () => {
    let temp = [
      {
        id: Math.random().toString(),
        agentId: null,
        agentName: '',
        brandId: '',
      },
    ];
    setCreateVerifInput([...createVerifInput, ...temp]);
  };

  let deleteDistributorRow = (selectedId: string) => {
    let filtered = createVerifInput.filter(({ id }) => id !== selectedId);
    setCreateVerifInput(filtered);
  };

  const onOpenAttachment = useCallback(
    (name: string, image = '', exif = null) => {
      setOpenModal(true);
      setAttachmentName(name);
      setImage(image);
      setExif(exif);
    },
    [],
  );
  const onCloseAttachment = useCallback(() => setOpenModal(false), []);

  let resetUnverifiedCategories = () =>
    setUnverifiedCategories([emptyUnverifiedCategories]);

  let onChangeUnverifiedCategories = (
    value: string,
    field: keyof UnverifiedCategory,
    index: number,
  ) => {
    const newUnverifCategories = unverifiedCategories.map(
      (unverifiedCategory, idx) => {
        if (idx === index) {
          if (field === 'type') {
            return {
              ...emptyUnverifiedCategories,
              type: value,
            };
          }

          if (field === 'problem' && value !== 'Lainnya') {
            return {
              ...unverifiedCategory,
              problem: value,
              action:
                nextActions.find((item) => item.problem === value)?.action ||
                '',
            };
          }

          return {
            ...unverifiedCategory,
            [field]: value,
          };
        }

        return unverifiedCategory;
      },
    );

    setUnverifiedCategories(newUnverifCategories);
  };

  let deleteUnverifiedCategories = (index: number) => {
    const newUnverifCategories = unverifiedCategories.filter(
      (_, idx) => idx !== index,
    );
    setUnverifiedCategories(newUnverifCategories);
  };

  useEffect(() => {
    /* Note: status toko unverified atau revalidate */
    if (isStoreUnverified) {
      resetUnverifiedCategories();
    }
  }, [isStoreUnverified]);

  let getProblemFromCategories = (category: string) => {
    switch (category) {
      case 'Foto Bagian Dalam':
        return photoInsideProblems;
      case 'Foto Tampak Depan':
        return photoFrontProblems;
      case 'Telepon diangkat':
        return phonePickedUpProblems;
      case 'Telepon tidak diangkat':
        return phoneNotPickedUpProblems;
      default:
        return [];
    }
  };

  useEffect(() => {
    getMatchMaking();
  }, [data, getMatchMaking]);

  const closeErrorModal = useCallback(() => {
    setErrorModalOpen(false);
  }, []);

  useEffect(() => {
    if (
      storeValidation === 'Valid 2' ||
      storeValidation === 'Invalid 3' ||
      storeValidation === 'Invalid 4' ||
      storeValidation === 'Invalid 5' ||
      storeValidation === 'Invalid 7'
    ) {
      setRestrictValidVisitation(true);
      setValidVisitation(ValidVisitation.NOT_GUARANTEED);
    } else if (
      storeValidation === 'No Need Validation' ||
      storeValidation === 'Sampah' ||
      storeValidation === 'Double Sampah'
    ) {
      setRestrictValidVisitation(true);
      setValidVisitation(null);
    } else {
      setRestrictValidVisitation(false);
    }
  }, [storeValidation]);

  return (
    <>
      {/* <DistributorModal
        openModal={openModal}
        distributors={distributors || []}
        title={`Distributor (${distributors?.length})`}
        onClose={() => setOpenModal(false)}
      /> */}
      <AttachmentModal
        open={openModal}
        title={attachmentName}
        onClose={onCloseAttachment}
        image={image || ''}
        bottomContent={
          <>
            <View style={styles.location}>
              <TextInput
                containerStyle={[
                  styles.lBottomSpacing,
                  styles.attachmentPopupContent,
                ]}
                value={exif?.latitude}
                onChangeText={onAddressLatitudeChange}
                label={t(['Latitude', 'Latitude'])}
                type="coordinate"
                placeholder="0.000000"
                disabled
              />
              <TextInput
                containerStyle={[
                  styles.lBottomSpacing,
                  styles.attachmentPopupContent,
                ]}
                value={exif?.longitude}
                onChangeText={onAddressLongitudeChange}
                label={t(['Longitude', 'Longitude'])}
                type="coordinate"
                placeholder="0.000000"
                disabled
              />
              <Button
                disabled={!exif?.latitude || !exif.longitude}
                preset="secondary"
                //Note: need target to open the maps in new tab
                // ts expect error to handle the target that button don't have
                // @ts-expect-error
                target="_blank"
                titleTextProps={{ color: 'blue' }}
                href={`https://www.google.com/maps/search/?api=1&query=${exif?.latitude},${exif?.longitude}`}
                title="View on Maps"
                iconAndText
                icon={<MapIcon htmlColor={colors.button.background.primary} />}
              />
            </View>
            <Text>{`Tanggal & Waktu: ${new Date(exif?.date).toLocaleString(
              'id',
              {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
                timeZoneName: 'shortGeneric',
              },
            )}`}</Text>
          </>
        }
      />
      <View style={styles.root}>
        {!viewMode && (
          <>
            <View style={styles.title}>
              <Text bold size="s" color="white">
                {t(['Data Toko', 'Toko Data'])}
              </Text>
            </View>

            <View
              style={[
                styles.borderTopZero,
                styles.border,
                styles.lPadding,
                styles.distributorWrapper,
              ]}
            >
              <Text bold size="m">
                {data.store?.storeManagerBulk?.storeName}
              </Text>
              <Text bold size="m">
                {data.store?.storeManagerBulk?.storeCode}
              </Text>
            </View>
          </>
        )}

        {matchMakingData ? (
          <View
            style={
              viewMode
                ? styles.viewMode
                : [styles.borderTopZero, styles.border, styles.lPadding]
            }
          >
            <Text bold size="m" style={styles.lBottomSpacing}>
              {t(['Request Matchmaking', 'Request Matchmaking'])}
            </Text>

            <View>
              <View style={styles.matchMakingRow}>
                <View style={styles.flex}>
                  <Text bold>{t(['Nama Brand', 'Brand Name'])}</Text>
                </View>
                <View style={styles.flex}>
                  <Text bold>
                    {t([
                      'Agent Name (Distributor)',
                      'Nama Agent (Distributor)',
                    ])}
                  </Text>
                </View>
                {!viewMode && (
                  <View style={styles.flex}>
                    <Text bold>
                      {t(['Pilih Distributor', 'Select Distributor'])}
                    </Text>
                  </View>
                )}
              </View>

              {updateVerifInput &&
                updateVerifInput.map((item, i) => {
                  return (
                    <View key={i} style={styles.matchMakingRow}>
                      <View style={styles.flex}>
                        <Text>{item.brandName ? item.brandName : `-`}</Text>
                      </View>
                      <View style={styles.flex}>
                        <Text>{item.agentName ? item.agentName : `-`}</Text>
                      </View>
                      {!viewMode && (
                        <View style={styles.flex}>
                          <View style={styles.flex}>
                            <DistributorDropdown
                              searchable
                              brandId={item.brandId}
                              selectedOption={
                                item.agentId ? item.agentId.toString() : ''
                              }
                              onSelect={(val) => {
                                onSelectDistributor(
                                  val.value
                                    ? val
                                    : { label: item.agentName, value: '' },
                                  item.requestAgentId,
                                );
                              }}
                              withEmptyData={true}
                              type="basic"
                            />
                          </View>
                        </View>
                      )}
                    </View>
                  );
                })}
            </View>
          </View>
        ) : null}

        {!viewMode && (
          <View
            style={
              viewMode
                ? styles.viewMode
                : [styles.borderTopZero, styles.border, styles.lPadding]
            }
          >
            <Text bold size="m" style={styles.lBottomSpacing}>
              {t(['Tambah Distributor', 'Add Distributor'])}
            </Text>

            <View style={styles.matchMakingRow}>
              <View style={styles.chooseBrand}>
                <Text bold>{t(['Pilih Brand', 'Select Brand'])}</Text>
              </View>
              <View style={styles.distributorDropdown}>
                <Text bold>
                  {t(['Pilih Distributor', 'Select Distributor'])}
                </Text>
              </View>
            </View>

            {createVerifInput.map((item, index) => {
              return (
                <View style={styles.distributorDropdownContainer} key={index}>
                  <View style={styles.distributorDropdown}>
                    <BrandDropdown
                      searchable
                      type="basic"
                      selectedOption={item.brandId}
                      onSelect={(val) => onSelectBrand(val, item.id)}
                    />
                  </View>
                  <View style={styles.distributorDropdown}>
                    <DistributorDropdown
                      searchable
                      disabled={!item.brandId}
                      type="basic"
                      brandId={item.brandId}
                      selectedOption={item.agentId}
                      onSelect={(val) => onSelectNewDistributor(val, item.id)}
                    />
                  </View>
                  <Button
                    preset="secondary"
                    title="-"
                    style={styles.deleteDistributorButton}
                    onPress={() => deleteDistributorRow(item.id)}
                  />
                </View>
              );
            })}
            <View style={styles.addDistributorButton}>
              <Button
                title={t(['+ Tambah', '+ Add'])}
                onPress={addDistributorRow}
              />
            </View>
          </View>
        )}

        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <TextInput
            data-testid="sales-code"
            containerStyle={styles.lBottomSpacing}
            value={data.store?.storeManagerBulk?.salesmanReferralCode}
            onChangeText={() => {}}
            label={t(['Kode Referral Sales', 'Sales Referral Code'])}
            disabled={true}
          />
        </View>
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <Text bold size="m" style={styles.lBottomSpacing}>
            {t(['Informasi Toko', 'Toko Information'])}
          </Text>
          <TextInput
            data-testid="store-verification-name"
            containerStyle={styles.lBottomSpacing}
            value={storeName}
            onChangeText={onStoreNameChange}
            label={t(['Nama Toko', 'Toko Name'])}
            disabled={viewMode}
            required
          />
          <View style={[styles.row, styles.lBottomSpacing, styles.flex]}>
            <TextInput
              data-testid="store-verification-phone"
              type="number"
              containerStyle={[styles.sRightSpacing, styles.flex]}
              value={storePhone}
              onChangeText={(phoneNumber) => {
                setStorePhone(phoneNumber);
              }}
              label={t(['Telepon Toko', 'Store Telephone'])}
              disabled={viewMode}
              required
            />
            {/* enable when verifadmin role & waiting || unverified */}
            <TextInput
              data-testid="store-verification-user-phone"
              containerStyle={styles.flex}
              value={userPhone}
              onChangeText={setUserPhone}
              label={t(['Telepon Pengguna', 'User Telephone'])}
              disabled={
                !(
                  role === RoleAdmin.VERIFICATION_ADMIN &&
                  (data.status === StatusVerify.UNVERIFIED ||
                    data.status === StatusVerify.WAITING)
                )
              }
            />
          </View>
          <StoreCategoryDropdown
            data-testid="store-verification-category"
            label={t(['Kategori Toko', 'Store Category'])}
            value={storeCategory ?? ''}
            onChange={onStoreCategoryChange}
            containerStyle={styles.lBottomSpacing}
            disabled={viewMode}
            required
          />
          <TextInput
            data-testid="store-verification-email"
            containerStyle={styles.lBottomSpacing}
            value={storeEmail}
            onChangeText={onStoreEmailChange}
            label={t(['Email Toko (opsional)', 'Store Email (optional)'])}
            disabled={viewMode}
          />
          {!emailCheck && (
            <Text bold style={styles.formatEmailSalah}>
              Format Email Salah!
            </Text>
          )}
          <TextInput
            containerStyle={styles.lBottomSpacing}
            value={storeWeb}
            onChangeText={onStoreWebChange}
            label={t(['Situs (opsional)', 'Website (optional)'])}
            disabled={viewMode}
          />
          <TextInput
            containerStyle={styles.lBottomSpacing}
            value={`${storeValidation} | ${
              validVisitation ? validVisitation : ''
            }`}
            onChangeText={() => {}}
            label={t([
              'Kategori Validasi (opsional)',
              'Validation Category (optional)',
            ])}
            disabled
          />
          <View style={[styles.row, styles.lBottomSpacing, styles.flex]}>
            <Dropdown
              onChange={(value: string) => {
                setStoreValidation(value);
              }}
              label={t(['Validasi Toko', 'Store Validation'])}
              value={storeValidation}
              options={initialStoreValidation}
              disableOption={{
                disable: true,
                nameOption: ['Valid', 'Invalid', 'Others'],
              }}
              disabled={viewMode}
              containerStyle={[styles.sRightSpacing, styles.flex]}
            />
            <Dropdown
              onChange={(value: ValidVisitation) => {
                setValidVisitation(value);
              }}
              label={t(['Valid Visitation', 'Valid Visitation'])}
              value={validVisitation}
              options={initialValidValidation}
              containerStyle={styles.flex}
              disabled={restrictValidVisitation || viewMode}
            />
          </View>
          {/* {!viewMode && (
            <>
              <TextInput
                containerStyle={styles.lBottomSpacing}
                value={referral}
                onChangeText={setReferral}
                disabled
                label={t(['Kode Referensi Salesman', 'Salesman Referral Code'])}
              />
              <View style={styles.location}>
                <TextInput
                  containerStyle={[styles.lBottomSpacing, styles.dropdownWidth]}
                  value={longitude}
                  onChangeText={setLongitude}
                  disabled
                  label={t(['Longitude', 'Longitude'])}
                />
                <TextInput
                  containerStyle={[styles.lBottomSpacing, styles.dropdownWidth]}
                  value={latitude}
                  onChangeText={setLatitude}
                  disabled
                  label={t(['Latitude', 'Latitude'])}
                />
                <Button
                  disabled={disabledOpenMap}
                  preset="secondary"
                  titleTextProps={{ color: 'blue' }}
                  onPress={() => {}}
                  href={`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${addressLatitude},${addressLongitude}`}
                  title="View on Maps"
                  iconAndText
                  icon={
                    <MapIcon htmlColor={colors.button.background.primary} />
                  }
                />
              </View>
            </>
          )} */}
        </View>
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <Text bold size="m" style={styles.lBottomSpacing}>
            {t(['Alamat', 'Address'])}
          </Text>
          <TextInput
            data-testid="store-verification-address"
            containerStyle={styles.lBottomSpacing}
            value={address}
            onChangeText={onAddressChange}
            label={t(['Alamat', 'Address'])}
            disabled={viewMode}
            required
          />

          <View style={[styles.row, styles.lBottomSpacing, styles.center]}>
            <ProvinceDropdown
              data-testid="store-verification-region"
              containerStyle={styles.dropdownWidth}
              label={t(['Provinsi', 'Region'])}
              value={province}
              onChange={onProvinceChange}
              disabled={viewMode}
              required
            />
            <CityDropdown
              data-testid="store-verification-city"
              containerStyle={styles.dropdownWidth}
              label={t(['Kabupaten/Kota', 'City'])}
              value={city}
              onChange={onCityChange}
              provinceID={province ?? undefined}
              disabled={viewMode}
              required
            />
            <DistrictDropdown
              data-testid="input-field-kecamatan"
              containerStyle={styles.dropdownWidth}
              label={t(['Kecamatan', 'District'])}
              value={district}
              onChange={onDistrictChange}
              cityID={city ?? undefined}
              disabled={viewMode}
              required
            />
          </View>
          <View style={[styles.row, styles.lBottomSpacing]}>
            <VillageDropdown
              data-testid="input-field-kelurahan"
              containerStyle={styles.villageDropdown}
              label={t(['Kelurahan', 'Urban Village'])}
              value={village}
              onChange={onVillageChange}
              districtID={district ?? undefined}
              disabled={viewMode}
              required
            />
            <TextInput
              data-testid="input-field-kode-pos"
              type="number"
              containerStyle={styles.dropdownWidth}
              value={postalCode}
              onChangeText={onPostalCodeChange}
              label={t(['Kode Pos', 'PostalCode'])}
              disabled={viewMode}
              required
            />
            <TextInput
              data-testid="store-verification-rt"
              type="number"
              containerStyle={styles.halfDropdownWidth}
              value={rt}
              onChangeText={onRTChange}
              label={t(['RT', 'RT'])}
              disabled={viewMode}
            />
            <TextInput
              data-testid="store-verification-rw"
              type="number"
              containerStyle={styles.halfDropdownWidth}
              value={rw}
              onChangeText={onRWChange}
              label={t(['RW', 'RW'])}
              disabled={viewMode}
            />
          </View>
          <View style={styles.location}>
            <TextInput
              containerStyle={[styles.lBottomSpacing, styles.dropdownWidth]}
              value={addressLatitude}
              onChangeText={onAddressLatitudeChange}
              label={t(['Latitude', 'Latitude'])}
              type="coordinate"
              placeholder="0.000000"
            />
            <TextInput
              containerStyle={[styles.lBottomSpacing, styles.dropdownWidth]}
              value={addressLongitude}
              onChangeText={onAddressLongitudeChange}
              label={t(['Longitude', 'Longitude'])}
              type="coordinate"
              placeholder="0.000000"
            />
            <Button
              disabled={openStoreMapDisabled}
              preset="secondary"
              //Note: need target to open the maps in new tab
              // ts expect error to handle the target that button don't have
              // @ts-expect-error
              target="_blank"
              titleTextProps={{ color: 'blue' }}
              href={`https://www.google.com/maps/search/?api=1&query=${addressLatitude},${addressLongitude}`}
              title="View on Maps"
              iconAndText
              icon={<MapIcon htmlColor={colors.button.background.primary} />}
            />
          </View>
        </View>
        {!!employees.length && (
          <View
            style={
              viewMode
                ? styles.viewMode
                : [styles.borderTopZero, styles.border, styles.lPadding]
            }
          >
            <Text bold size="m" style={styles.lBottomSpacing}>
              {t(['Informasi Karyawan', 'Employee Information'])}
            </Text>
            {employees.map((_, userIndex) => (
              <View
                key={userIndex}
                style={userIndex > 0 && styles.employeeSection}
              >
                <TextInput
                  data-testid="store-verification-user-name"
                  containerStyle={styles.lBottomSpacing}
                  value={userName[userIndex]}
                  onChangeText={setEmployeeName(userIndex)}
                  label={t(['Nama', 'Name'])}
                  disabled={viewMode}
                />
                <TextInput
                  data-testid="store-verification-user-role"
                  containerStyle={styles.lBottomSpacing}
                  value={userRole[userIndex]}
                  onChangeText={setEmployeeName(userIndex)}
                  label={t(['Role', 'Role'])}
                  disabled={true}
                />
                <TextInput
                  data-testid="store-verification-email"
                  containerStyle={styles.lVerticalSpacing}
                  value={userEmail[userIndex]}
                  onChangeText={setEmployeeEmail(userIndex)}
                  label={t(['Email (opsional)', 'Email (optional)'])}
                  disabled={viewMode}
                />
                {userEmail[userIndex] !== '' ? (
                  !validateEmail(userEmail[userIndex]) ? (
                    <Text bold style={styles.formatEmailSalah}>
                      Format Email Salah!
                    </Text>
                  ) : null
                ) : null}
                <Text>{t(['Permission', 'Permission'])}</Text>
                {permissions.map((permission, permissionIndex) => {
                  let isChecked = userPermissions[userIndex]?.includes(
                    permission.value,
                  );
                  return (
                    <Checkbox
                      data-testid={`store-verification-permission-${permissionIndex}`}
                      key={permissionIndex}
                      checked={isChecked}
                      labelNode={<Text>{permission.label}</Text>}
                      onPress={() =>
                        togglePermission(userIndex, permission.value)
                      }
                      disabled={viewMode}
                    />
                  );
                })}
              </View>
            ))}
          </View>
        )}
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <Text bold size="m" style={styles.lBottomSpacing}>
            {t(['Data Pendukung', ''])}
          </Text>
          <View style={[styles.row, styles.lBottomSpacing, styles.flex]}>
            <Button
              data-testid="validasi-foto-tampak-depan"
              title="Tampak Depan"
              titleTextProps={{ color: 'black' }}
              preset="secondary"
              iconAndText
              icon={
                <AttachFileIcon htmlColor={colors.button.background.primary} />
              }
              style={styles.sRightSpacing}
              onPress={() =>
                onOpenAttachment(
                  'Tampak Depan',
                  data.store?.storePhotoFront,
                  data.store?.frontExif,
                )
              }
            />
            <Button
              data-testid="validasi-foto-bagian-dalam"
              title="Bagian Dalam"
              titleTextProps={{ color: 'black' }}
              preset="secondary"
              iconAndText
              icon={
                <AttachFileIcon htmlColor={colors.button.background.primary} />
              }
              onPress={() =>
                onOpenAttachment(
                  'Bagian Dalam',
                  data.store?.storePhotoInside,
                  data.store?.insideExif,
                )
              }
            />
          </View>
        </View>
        <View
          style={
            viewMode
              ? styles.viewMode
              : [styles.borderTopZero, styles.border, styles.lPadding]
          }
        >
          <View style={styles.tokoStatusWrapper}>
            <Text bold size="m" style={styles.xxsRightSpacing}>
              {t(['Status Toko', 'TOKO Status'])}
            </Text>
            <Infotip />
          </View>
          <Text size="s" style={styles.tokoStatusDesc}>
            {t([
              'Cek status TOKO di Manager: ',
              'Check TOKO status in Manager: ',
            ])}
            <Button
              disableRipple
              preset="transparent"
              title={LINK_MANAGER_STORE_VERIFY}
              titleTextProps={{ bold: false, color: 'blue' }}
              onPress={() => {
                window.open(LINK_MANAGER_STORE_VERIFY, '_blank');
              }}
            />
          </Text>
          <SegmentedControl
            data-testid="btn-status"
            containerStyle={styles.segmentedControl}
            selected={storeStatus}
            setSelected={onStoreStatusChange}
            options={tokoStatus}
            disabled={viewMode}
          />
          {storeStatus === StoreStatusEnum.VERIFIED_MERGE && (
            <TextInput
              data-testid="field-kode-toko-verified-merge"
              disabled={viewMode}
              containerStyle={styles.lBottomSpacing}
              value={existingId}
              onChangeText={onExistingIdChange}
              rightNode={
                verifyLoading ? (
                  <ActivityIndicator />
                ) : isIdExisted === true ? (
                  <View style={styles.tokoExists}>
                    <CheckIcon htmlColor={colors.icon.green} />
                    <Text bold color="green" style={styles.tokoExistsName}>
                      {existingIdName}
                    </Text>
                  </View>
                ) : isIdExisted === false ? (
                  <Text color="red" bold>
                    {verifyData?.checkManagerStoreCode.message?.[0]?.includes(
                      'not found',
                    )
                      ? t(['Tidak ditemukan', 'No match found'])
                      : t(['Telah digunakan', 'Has been used'])}
                  </Text>
                ) : null
              }
              label={t(['Kode Toko Terdaftar', 'Existing Toko Code'])}
            />
          )}
          {canRollback?.checkCanRollBackVerified.valid &&
            storeStatus === StoreStatusEnum.VERIFIED_MERGE && (
              <View style={styles.row}>
                <Button
                  data-testid="store-verification-change-code"
                  style={styles.changeCodeButton}
                  isLoading={changeStoreCodeLoading}
                  disabled={!isNewStoreCodeExisted}
                  preset="secondary"
                  title={t(['Ubah Kode', 'Change Code'])}
                  onPress={() => {
                    setConfirmationModalOpen(true);
                  }}
                />
                <TextInput
                  containerStyle={styles.flex}
                  value={newStoreCode}
                  onChangeText={setNewStoreCode}
                  rightNode={
                    verifyNewStoreCodeLoading ? (
                      <ActivityIndicator />
                    ) : isNewStoreCodeExisted === true ? (
                      <View style={styles.tokoExists}>
                        <CheckIcon htmlColor={colors.icon.green} />
                        <Text bold color="green" style={styles.tokoExistsName}>
                          {existingNewStoreName}
                        </Text>
                      </View>
                    ) : isNewStoreCodeExisted === false ? (
                      <Text color="red" bold>
                        {verifyNewStoreCodeData?.checkManagerStoreCode.message?.[0]?.includes(
                          'not found',
                        )
                          ? t(['Tidak ditemukan', 'No match found'])
                          : t(['Telah digunakan', 'Has been used'])}
                      </Text>
                    ) : null
                  }
                />
              </View>
            )}
        </View>
        {isStoreUnverified && (
          <View
            style={
              viewMode
                ? styles.viewMode
                : [styles.borderTopZero, styles.border, styles.lPadding]
            }
          >
            <View style={styles.row}>
              <Text bold size="m" style={styles.lBottomSpacing}>
                Kategori Unverified/Revalidate
              </Text>
              <Text bold size="m" style={[styles.lBottomSpacing, styles.red]}>
                *
              </Text>
            </View>
            {unverifiedCategories.map(
              ({ type, detail, problem, action, customProblem }, idx) => (
                <>
                  <View
                    style={[
                      styles.row,
                      styles.alignItemsEnd,
                      styles.mBottomSpacing,
                    ]}
                  >
                    <Dropdown
                      containerStyle={[styles.w20, styles.mRightSpacing]}
                      label="Tipe Kategori"
                      value={type}
                      onChange={(value: string) =>
                        onChangeUnverifiedCategories(value, 'type', idx)
                      }
                      options={typeCategories}
                      disabled={viewMode}
                      required
                    />
                    <Dropdown
                      containerStyle={[styles.w20, styles.mRightSpacing]}
                      label="Detail Kategori"
                      value={detail}
                      onChange={(value: string) =>
                        onChangeUnverifiedCategories(value, 'detail', idx)
                      }
                      options={
                        type === 'Telepon' ? phoneCategories : photoCategories
                      }
                      disabled={viewMode || !type}
                      required
                    />
                    <Dropdown
                      containerStyle={[styles.w40, styles.mRightSpacing]}
                      label="Permasalahan Ditemukan"
                      value={problem}
                      onChange={(value: string) =>
                        onChangeUnverifiedCategories(value, 'problem', idx)
                      }
                      options={getProblemFromCategories(detail)}
                      disabled={viewMode || !detail}
                      required
                    />
                    {idx > 0 ? (
                      <Button
                        large
                        preset="secondary"
                        title="-"
                        style={styles.w10}
                        onPress={() => deleteUnverifiedCategories(idx)}
                      />
                    ) : null}
                  </View>
                  {problem === 'Lainnya' ? (
                    <>
                      <TextInput
                        containerStyle={styles.xxsBottomSpacing}
                        label="Lainnya"
                        value={customProblem}
                        onChangeText={(value: string) =>
                          onChangeUnverifiedCategories(
                            value,
                            'customProblem',
                            idx,
                          )
                        }
                        placeholder="Keterangan"
                        disabled={viewMode}
                        required
                      />
                      <TextInput
                        containerStyle={styles.mBottomSpacing}
                        label="Next Action"
                        value={action}
                        onChangeText={(value: string) =>
                          onChangeUnverifiedCategories(value, 'action', idx)
                        }
                        placeholder="Masukan Aksi Berikutnya"
                        disabled={viewMode}
                        required
                      />
                    </>
                  ) : (
                    problem.length > 0 && (
                      <Text style={styles.mBottomSpacing}>
                        {`Next Action: ${action}`}
                      </Text>
                    )
                  )}
                  <View style={[styles.border, styles.mBottomSpacing]} />
                </>
              ),
            )}
            <Text
              color={
                unverifiedCategories.length < 3 && !viewMode ? 'blue' : 'grey'
              }
              onPress={() => {
                if (unverifiedCategories.length < 3 && !viewMode) {
                  setUnverifiedCategories([
                    ...unverifiedCategories,
                    emptyUnverifiedCategories,
                  ]);
                }
              }}
            >
              + Tambah Kategori
            </Text>
          </View>
        )}
      </View>
      <View style={[styles.border, styles.bottomContainer]}>
        <TextInput
          data-testid="filter-text-status"
          containerStyle={!viewMode && styles.lBottomSpacing}
          value={note}
          onChangeText={onNoteChange}
          leftNode={
            <SubjectIcon
              htmlColor={colors.border.primary}
              style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}
            />
          }
          placeholder={t(['Catatan (Wajib)', 'Add Notes (Required)'])}
          disabled={viewMode}
          multiline
          rows={3}
        />
        {!viewMode && (
          <View style={styles.distributorWrapper}>
            {onUnclaim && (
              <Button
                data-testid="btn-batal-klaim"
                style={styles.buttonWidth}
                isLoading={unclaimLoading}
                preset="secondary"
                title={t(['Batal Klaim', 'Unclaim'])}
                onPress={onUnclaim}
              />
            )}
            <Button
              data-testid="btn-perbarui-data"
              style={styles.buttonWidth}
              isLoading={updateLoading}
              disabled={submitDisabled}
              preset="primary"
              title={t(['Perbarui Data', 'Update Data'])}
              onPress={onUpdateData}
            />
          </View>
        )}
      </View>
      {canRollback?.checkCanRollBackVerified.valid &&
        storeStatus === StoreStatusEnum.VERIFIED && (
          <View style={styles.rollbackWrapper}>
            <Button
              data-testid="store-verification-rollback"
              style={[styles.buttonWidth, styles.rollbackButton]}
              isLoading={rollbackLoading}
              preset="secondary"
              title={t(['Rollback', 'Rollback'])}
              onPress={() => {
                setConfirmationModalOpen(true);
              }}
            />
          </View>
        )}
      <ConfirmationModal
        data-testid="rollback-confirmation-modal"
        open={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={() => {
          storeStatus === StoreStatusEnum.VERIFIED
            ? rollbackVerifiedStore()
            : changeVerifiedMergeStoreCode();
        }}
        title={t([
          'Fitur ini dapat menyebabkan order dan request distributor dari toko tersebut gagal diproses. Mohon koordinasikan dengan tim terkait sebelum melanjutkan.',
          "This feature will cause store's orders and distributor requests to fail. Please coordinate with related team before continuing.",
        ])}
      />

      <ErrorModal
        onClose={closeErrorModal}
        open={isErrorModalOpen}
        error={apolloError}
        action={actionError}
      />
    </>
  );
});

const styles = StyleSheet.create({
  root: { width: 529, backgroundColor: colors.background.white },
  flex: { flex: 1 },
  w10: { width: '10%' },
  w20: { width: '20%' },
  w40: { width: '40%' },
  row: { flexDirection: 'row' },
  alignItemsEnd: { alignItems: 'flex-end' },
  red: { color: colors.text.red },
  distributorWrapper: { flexDirection: 'row', justifyContent: 'space-between' },
  xxsRightSpacing: { marginRight: spacing.xxs },
  sRightSpacing: { marginRight: spacing.s },
  mRightSpacing: { marginRight: spacing.m },
  distributor: { maxWidth: 300 },
  tokoStatusWrapper: {
    flexDirection: 'row',
    marginBottom: spacing.xs,
    alignItems: 'center',
  },
  tokoStatusDesc: { marginBottom: spacing.l },
  villageDropdown: { width: 150, marginRight: spacing.s },
  location: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  segmentedControl: { marginBottom: spacing.l, width: '100%' },
  buttonWidth: {
    width: 230,
  },
  title: {
    height: 42,
    justifyContent: 'center',
    backgroundColor: colors.background.blue,
    alignItems: 'center',
  },
  borderTopZero: {
    borderTopWidth: 0,
  },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  lBottomSpacing: {
    marginBottom: spacing.l,
  },
  mBottomSpacing: {
    marginBottom: spacing.m,
  },
  xxsBottomSpacing: {
    marginBottom: spacing.xxs,
  },
  lVerticalSpacing: {
    marginVertical: spacing.l,
  },
  lPadding: {
    padding: spacing.l,
  },
  button: {
    minWidth: 149,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
    justifyContent: 'flex-start',
  },
  bottomContainer: {
    marginTop: 24,
    padding: 24,
    width: 529,
    backgroundColor: colors.background.white,
  },
  leftIcon: { width: 24, height: 24 },
  greenCheck: { width: 20, height: 20 },
  halfDropdownWidth: { width: 70, marginLeft: spacing.s },
  dropdownWidth: { width: 150 },
  attachmentPopupContent: { width: '30%' },
  attachmentIcon: { height: 24, width: 24, marginRight: 8 },
  tokoExists: { flexDirection: 'row', alignItems: 'center' },
  tokoExistsName: { paddingLeft: 4 },
  viewMode: {
    borderTopColor: colors.button.border.primary,
    borderTopWidth: 1,
    paddingTop: spacing.l,
    paddingBottom: spacing.m,
  },
  center: { justifyContent: 'space-between' },
  employeeSection: {
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
    paddingTop: spacing.l,
    marginTop: spacing.l,
  },
  rollbackWrapper: { alignItems: 'flex-end' },
  rollbackButton: {
    marginTop: spacing.m,
    borderColor: colors.text.red,
  },
  changeCodeButton: {
    width: 200,
    marginRight: spacing.m,
    height: 40,
  },
  matchMakingRow: {
    flexDirection: 'row',
    marginBottom: spacing.xxs,
  },
  chooseBrand: { width: 200, marginRight: 12 },
  distributorDropdown: { width: 200 },
  distributorDropdownContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  addDistributorButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  deleteDistributorButton: { width: 40 },
  formatEmailSalah: {
    fontSize: 12,
    color: colors.icon.declineRed,
    lineHeight: 17,
    paddingBottom: 26,
  },
});

export default TokoDataForm;
