import React, { CSSProperties, useCallback, memo } from 'react';
import clsx from 'clsx';
import { View, StyleSheet, ViewProps } from 'react-native';
import {
  MuiPickersUtilsProvider,
  DatePicker as MDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, createMuiTheme, IconButton } from '@material-ui/core';
import { ThemeProvider, Theme, createStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import { colors, fonts, spacing } from '../constants/theme';

import Text from './Text';

// NOTE: These block is to enable us override some styles that are missing from the .d.ts
type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersBasePicker: {
    pickerView: {
      minWidth?: number;
      minHeight?: number;
    };
  };
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      minWidth?: number;
      minHeight?: number;
    };
  };
};

declare module '@material-ui/core/styles/overrides' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ComponentNameToClassKey extends overridesNameToClassKey {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends CustomType {}
}
// END NOTE

type Props = {
  'data-testid'?: string;
  selectedDate: Date | null;
  onChange: (date: Date) => void;
  label?: string;
  markedDates?: Array<Date>;
  disabled?: boolean;
  disablePast?: boolean;
  style?: ViewProps['style'];
  minDate?: Date;
  required?: boolean;
  error?: boolean;
  helperText?: string | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    day: {
      width: 32,
      height: 32,
      padding: 8, // NOTE: this centers the day
      margin: '0 2px',
      fontFamily: 'Inter',
      fontSize: theme.typography.caption.fontSize,
      color: 'inherit',
      borderRadius: '50%',
    },
    selected: {
      border: `1px solid ${colors.datePicker.primary}`,
    },
    today: {
      color: colors.datePicker.primary,
    },
    disabled: {
      color: theme.palette.text.disabled,
    },
    input: {
      fontFamily: 'Inter',
      fontSize: fonts.sizes.s,
      color: colors.datePicker.text,
      maxHeight: 36,
    },
  }),
);

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: 'Inter',
      },
      h4: {
        fontFamily: 'Inter',
      },
      subtitle1: {
        fontFamily: 'Inter',
      },
      caption: {
        fontFamily: 'Inter',
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: 0,
        minHeighgt: 0,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 0,
        minHeight: 0,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: { width: 32 },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.datePicker.primary,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: colors.datePicker.primary,
      },
    },
  },
});

const DatePicker = memo((props: Props) => {
  let classes = useStyles();
  let {
    selectedDate,
    label,
    disabled,
    disablePast = false,
    'data-testid': dataCy,
    style,
    minDate,
    required,
    error,
    helperText,
  } = props;

  let onChange = useCallback(
    (date: MaterialUiPickersDate) => {
      date && props.onChange(date);
    },
    [props],
  );

  let renderDay = useCallback(
    (
      day: MaterialUiPickersDate,
      selectedDay: MaterialUiPickersDate,
      isInCurrentMonth: boolean,
      dayComponent: JSX.Element,
    ) => {
      let isSelected = day?.valueOf() === selectedDay?.valueOf();
      let isToday = day?.toDateString() === new Date().toDateString();
      let isDisabled = dayComponent.props.disabled || !isInCurrentMonth;

      const dayClassName = clsx(classes.day, {
        [classes.today]: isToday && !isSelected,
        [classes.disabled]: isDisabled,
        [classes.selected]: isSelected && isInCurrentMonth,
      });

      return (
        <IconButton
          data-testid={
            isInCurrentMonth && day
              ? `${dataCy}-day-${day.getDate()}`
              : undefined
          }
          className={dayClassName}
          disabled={isDisabled || disabled}
        >
          {isInCurrentMonth && day?.getDate()}
        </IconButton>
      );
    },
    [
      dataCy,
      disabled,
      classes.today,
      classes.day,
      classes.selected,
      classes.disabled,
    ],
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <View style={style}>
          {!!label && (
            <View style={styles.label}>
              <Text size="xs">{label}</Text>
              {required && (
                <Text size="xs" style={[styles.title, styles.red]}>
                  *
                </Text>
              )}
            </View>
          )}
          <View
            style={[
              styles.container,
              {
                borderColor: error ? colors.text.red : colors.datePicker.border,
              },
            ]}
          >
            <MDatePicker
              disabled={disabled}
              value={selectedDate}
              onChange={onChange}
              disablePast={disablePast}
              minDate={minDate}
              minDateMessage="" // NOTE: to remove the default message which messes up layout
              format="dd/MM/yyyy"
              variant="inline"
              autoOk
              style={StyleSheet.flatten(styles.datepicker) as CSSProperties}
              inputProps={{ 'data-testid': props['data-testid'] }}
              InputProps={{
                className: classes.input,
                disableUnderline: true,
              }}
              renderDay={renderDay}
            />
          </View>
          {helperText ? (
            <Text size="xs" style={styles.helperText}>
              {helperText}
            </Text>
          ) : (
            <></>
          )}
        </View>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
});

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
    borderWidth: 1,
    borderRadius: 2,
  },
  datepicker: {
    paddingLeft: spacing.m,
    height: 42,
    justifyContent: 'center',
  },
  label: {
    marginBottom: spacing.xxs,
    flexDirection: 'row',
  },
  separator: {
    margin: 4,
  },
  red: { color: colors.text.red },
  title: { paddingBottom: spacing.xxs },
  helperText: {
    paddingTop: spacing.xxs,
    paddingLeft: spacing.m,
    color: colors.text.red,
  },
});

export default DatePicker;
