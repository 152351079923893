import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, StyleSheet, Image, ActivityIndicator } from 'react-native';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { emailIcon } from '../../../assets';
import { Button, Text, TextInput } from '../../core-ui';
import {
  Header,
  StepHeader,
  CityDropdown,
  RegionDropdown,
  UsersDropdown,
  Option,
  RadioGroup,
  LiveDateRadioGroup,
  BrandContactDropdown,
  PromotionDropdown,
  ConfirmationModal,
  ErrorMessage,
  ErrorModal,
} from '../../components';
import { colors, spacing } from '../../constants/theme';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { GET_SLIDERS, EMAIL_SLIDER_TO_BU } from '../../graphql/queries';
import {
  CREATE_SLIDER,
  UPDATE_SLIDER,
  UPLOAD,
  ARCHIVE_SLIDER,
  TOGGLE_ACTIVE_SLIDER,
} from '../../graphql/mutations';
import { SliderFragment } from '../../generated/SliderFragment';
import { Sliders, SlidersVariables } from '../../generated/Sliders';
import {
  CreateCardSlider,
  CreateCardSliderVariables,
} from '../../generated/CreateCardSlider';
import {
  UpdateCardSlider,
  UpdateCardSliderVariables,
} from '../../generated/UpdateCardSlider';
import { Upload, UploadVariables } from '../../generated/Upload';
import { Time, ArchiveStatus, FolderType } from '../../generated/globalTypes';
import {
  ArchiveCardSlider,
  ArchiveCardSliderVariables,
} from '../../generated/ArchiveCardSlider';
import {
  ToggleActiveSlider,
  ToggleActiveSliderVariables,
} from '../../generated/ToggleActiveSlider';
import {
  EmailSliderToBU,
  EmailSliderToBUVariables,
} from '../../generated/EmailSliderToBU';

import HomepageSliderRow from './HomepageSliderRow';

export type SliderItem = {
  id: string | null;
  image: FileWithPreview | string | null;
  name: string;
  promotionLink: string | null;
  externalLink: string;
  buContact: string | null;
  brandID: string | null;
  appUsers: Array<string>;
  provinces: Array<string>;
  cities: Array<string>;
  liveStartDate: Date;
  liveEndDate: Date;
  originalData?: SliderFragment;
};
type Props = {
  refetchToggle: boolean;
  editSliderData: SliderFragment | null;
  setEditSlider: (slider: SliderFragment | null) => void;
  onSubmit: () => void;
  onCancel: () => void;
};

const defaultEditIndex = {
  allDay: -1,
  morning: -1,
  day: -1,
  evening: -1,
};

const HomepageSliderForm = (props: Props) => {
  let {
    refetchToggle,
    editSliderData,
    setEditSlider: setEditSliderProps,
    onSubmit,
    onCancel,
  } = props;

  const [users, setUsers] = useState<Array<string>>([]);
  const [regions, setRegions] = useState<Array<string>>([]);
  const [regionNames, setRegionNames] = useState<Array<string>>([]);
  const [cities, setCities] = useState<Array<string>>([]);
  const [cityNames, setCityNames] = useState<Array<string>>([]);
  const [selectedTime, setSelectedTime] = useState<'allDay' | 'timeRange'>(
    'allDay',
  );
  const [allDaySliders, setAllDaySliders] = useState<Array<SliderItem | null>>(
    new Array(5).fill(null),
  );
  const [morningSliders, setMorningSliders] = useState<
    Array<SliderItem | null>
  >(new Array(5).fill(null));
  const [daySliders, setDaySliders] = useState<Array<SliderItem | null>>(
    new Array(5).fill(null),
  );
  const [eveningSliders, setEveningSliders] = useState<
    Array<SliderItem | null>
  >(new Array(5).fill(null));
  const [editSlider, setEditSlider] = useState<SliderItem | null>(null);
  const [editSliderIndex, setEditSliderIndex] = useState(defaultEditIndex);
  const [name, setName] = useState('');
  const [linkType, setLinkType] = useState<'internal' | 'external'>('internal');
  const [promotion, setPromotion] = useState<string | null>();
  const [externalLink, setExternalLink] = useState('');
  const [brandBU, setBrandBU] = useState<string | null>(null);
  const [brandID, setBrandID] = useState<string | null>(null);
  const [liveStartDate, setLiveStartDate] = useState(new Date());
  const [liveEndDate, setLiveEndDate] = useState(new Date());
  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorInstance(error);
      setErrorAction(action);
    },
    [],
  );
  const onUploadError = useMemo(() => {
    return openErrorModal(
      t(['mengunggah gambar slider', 'upload homepage slider image']),
    );
  }, [openErrorModal]);
  const onCreateError = useMemo(() => {
    return openErrorModal(
      t(['membuat data slider beranda', 'create homepage slider data']),
    );
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(
      t(['mengubah data slider beranda', 'update homepage slider data']),
    );
  }, [openErrorModal]);
  const onToggleError = useMemo(() => {
    return openErrorModal(
      t([
        'mengubah waktu aktif slider beranda',
        'change homepage slider active time',
      ]),
    );
  }, [openErrorModal]);
  const onArchiveError = useMemo(() => {
    return openErrorModal(
      t([
        'mengubah status arsip data slider beranda',
        'change archive status of the homepage slider data',
      ]),
    );
  }, [openErrorModal]);
  const onSendEmailError = useMemo(() => {
    return openErrorModal(
      t(['mengirim email ke principal', 'send email to principal']),
    );
  }, [openErrorModal]);

  const clearForm = useCallback(() => {
    setUsers([]);
    setRegions([]);
    setRegionNames([]);
    setCities([]);
    setCityNames([]);
    setName('');
    setLinkType('internal');
    setPromotion(null);
    setExternalLink('');
    setBrandBU(null);
    setBrandID(null);
    setLiveStartDate(new Date());
    setLiveEndDate(new Date());
  }, []);

  const createSetMultipleSelected = useCallback(
    (
      previousValues: Array<string>,
      setter: (value: Array<string>) => void,
      previousNames?: Array<string>,
      nameSetter?: (value: Array<string>) => void,
      ...emptySetters: Array<(value: Array<string>) => void>
    ) => (selected: Option) => {
      if (previousValues.includes(selected.value)) {
        setter(previousValues.filter((v) => v !== selected.value));
        nameSetter?.(previousNames?.filter((v) => v !== selected.label) ?? []);
      } else {
        setter([...previousValues, selected.value]);
        if (previousNames) {
          nameSetter?.([...previousNames, selected.label]);
        }
      }
      for (let setEmpty of emptySetters) {
        setEmpty([]);
      }
    },
    [],
  );

  const submitDisabled = useMemo(() => {
    return (
      !users ||
      !regionNames ||
      !cityNames ||
      !name ||
      (linkType === 'internal' && !promotion) ||
      (linkType === 'external' && !externalLink) ||
      !brandBU ||
      !liveStartDate ||
      !liveEndDate
    );
  }, [
    users,
    regionNames,
    cityNames,
    name,
    linkType,
    promotion,
    externalLink,
    brandBU,
    liveStartDate,
    liveEndDate,
  ]);

  const separator = <View style={styles.separator} />;

  const getSliderState = useCallback(
    (type: Time) => {
      switch (type) {
        case Time.MORNING:
          return [morningSliders, setMorningSliders] as const;
        case Time.DAY:
          return [daySliders, setDaySliders] as const;
        case Time.EVENING:
          return [eveningSliders, setEveningSliders] as const;
        default:
          return [allDaySliders, setAllDaySliders] as const;
      }
    },
    [morningSliders, daySliders, eveningSliders, allDaySliders],
  );

  const onSubmitCompleted = useCallback(() => {
    onSubmit();
    clearForm();
    setEditSlider(null);
    setEditSliderIndex(defaultEditIndex);
  }, [onSubmit, clearForm]);
  const onToggleCompleted = useCallback(() => {
    onSubmit();
    setVisible(false);
  }, [onSubmit]);
  const onArchiveCompleted = useCallback(() => {
    onSubmit();
    setEditSlider(null);
    setEditSliderIndex(defaultEditIndex);
  }, [onSubmit]);

  const {
    data: sliderData,
    loading: sliderLoading,
    error: sliderError,
    refetch: sliderRefetch,
  } = useQuery<Sliders, SlidersVariables>(GET_SLIDERS, {
    variables: { archiveStatus: ArchiveStatus.NORMAL },
    notifyOnNetworkStatusChange: true,
  });
  const [createSlider, { loading: createSliderLoading }] = useMutation<
    CreateCardSlider,
    CreateCardSliderVariables
  >(CREATE_SLIDER, { onCompleted: onSubmitCompleted, onError: onCreateError });
  const [updateSlider, { loading: updateSliderLoading }] = useMutation<
    UpdateCardSlider,
    UpdateCardSliderVariables
  >(UPDATE_SLIDER, { onCompleted: onSubmitCompleted, onError: onUpdateError });
  const [toggleActiveSlider, { loading: toggleLoading }] = useMutation<
    ToggleActiveSlider,
    ToggleActiveSliderVariables
  >(TOGGLE_ACTIVE_SLIDER, {
    onCompleted: onToggleCompleted,
    onError: onToggleError,
  });

  const [uploadFile, { loading: uploadFileLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, { onError: onUploadError });
  const [archiveSlider, { loading: archiveLoading }] = useMutation<
    ArchiveCardSlider,
    ArchiveCardSliderVariables
  >(ARCHIVE_SLIDER, {
    onCompleted: onArchiveCompleted,
    onError: onArchiveError,
  });
  const [emailBu] = useLazyQuery<EmailSliderToBU, EmailSliderToBUVariables>(
    EMAIL_SLIDER_TO_BU,
    {
      onError: onSendEmailError,
    },
  );

  const refetchSliders = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await sliderRefetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [sliderRefetch]);
  useEffect(() => {
    refetchSliders();
  }, [refetchToggle, refetchSliders]);

  useEffect(() => {
    // NOTE: maps fetch result to the data the component uses
    if (sliderData) {
      let newAllDaySliders = new Array(5).fill(null);
      let newMorningSliders = new Array(5).fill(null);
      let newDaySliders = new Array(5).fill(null);
      let newEveningSliders = new Array(5).fill(null);

      let allDayActive = true;

      for (let slider of sliderData.cardSliderAdvanceSearch.row) {
        let newSliderData: SliderItem = {
          id: slider.id,
          image: slider.image ?? null,
          name: slider.name,
          promotionLink: slider.promotionLink?.id ?? null,
          externalLink: slider.externalLink ?? '',
          buContact: slider.brand?.businessUnitContact?.id ?? null,
          brandID: slider.brand?.id ?? null,
          appUsers: slider.appUser.map(({ name }) => name),
          provinces: slider.region.map(({ name }) => name),
          cities: slider.city.map(({ name }) => name),
          liveStartDate: new Date(slider.liveDate),
          liveEndDate: new Date(slider.endLiveDate),
          originalData: slider,
        };
        switch (slider.timeActive) {
          case Time.ALL_DAY: {
            newAllDaySliders[slider.numberOrder - 1] = newSliderData;
            allDayActive = slider.active;
            break;
          }
          case Time.MORNING: {
            newMorningSliders[slider.numberOrder - 1] = newSliderData;
            break;
          }
          case Time.DAY: {
            newDaySliders[slider.numberOrder - 1] = newSliderData;
            break;
          }
          case Time.EVENING: {
            newEveningSliders[slider.numberOrder - 1] = newSliderData;
            break;
          }
        }
      }

      setSelectedTime(allDayActive ? 'allDay' : 'timeRange');
      setAllDaySliders(newAllDaySliders);
      setMorningSliders(newMorningSliders);
      setDaySliders(newDaySliders);
      setEveningSliders(newEveningSliders);
    }
  }, [sliderData, refetchToggle]);

  const convertTimeToSlideIndex = useCallback((type: Time) => {
    switch (type) {
      case Time.ALL_DAY:
        return 'allDay';
      case Time.MORNING:
        return 'morning';
      case Time.DAY:
        return 'day';
      case Time.EVENING:
        return 'evening';
    }
  }, []);

  const nameMapper = useCallback(({ name }: { name: string }) => name, []);

  const onEditSlider = useCallback(
    (type: Time) => (index: number) => () => {
      const sliderTypeName = convertTimeToSlideIndex(type);
      if (index === editSliderIndex[sliderTypeName]) {
        setEditSlider(null);
        setEditSliderIndex(defaultEditIndex);
        setEditable(false);
        onCancel();
      } else {
        let [sliders] = getSliderState(type);
        let slider = sliders[index];
        if (slider != null) {
          setUsers(slider.originalData?.appUser.map(nameMapper) ?? []);
          setRegionNames(slider.originalData?.region.map(nameMapper) ?? []);
          setCityNames(slider.originalData?.city.map(nameMapper) ?? []);
          setName(slider.name);
          setLinkType(slider.promotionLink ? 'internal' : 'external');
          setPromotion(slider.promotionLink);
          setExternalLink(slider.externalLink ?? '');
          setBrandBU(slider.buContact);
          setBrandID(slider.brandID);
          setLiveStartDate(slider.liveStartDate);
          setLiveEndDate(slider.liveEndDate);
        }
        setEditSliderProps(null);
        setEditSlider(slider);
        setEditSliderIndex({
          ...defaultEditIndex, // NOTE: reset others, can only edit one slider at one time
          [sliderTypeName]: index,
        });
        setEditable(true);
      }
    },
    [
      editSliderIndex,
      setEditSliderProps,
      convertTimeToSlideIndex,
      getSliderState,
      nameMapper,
      onCancel,
    ],
  );

  useEffect(() => {
    // NOTE: handles pressing edit in Table
    if (editSliderData) {
      // NOTE: this happens when pressing edit in Table
      if (editSlider?.id !== editSliderData.id) {
        onEditSlider(editSliderData.timeActive)(
          editSliderData.numberOrder - 1,
        )();
      }
    } else if (!editSlider) {
      // NOTE: otherwise we should clear form and edit slider information. but, before removing
      //       all of those, we should check whether we're editing something else in the form
      clearForm();
      setEditSlider(null);
      setEditSliderIndex(defaultEditIndex);
    }
  }, [
    editSliderData,
    editSlider,
    editSliderIndex,
    clearForm,
    onEditSlider,
    convertTimeToSlideIndex,
  ]);

  const onDrop = useCallback(
    (type: Time) => (index: number) => (file: FileWithPreview) => {
      let [sliders, setSliders] = getSliderState(type);
      let newSliderList = sliders.map((slider, i) => {
        if (i === index) {
          if (
            editSlider &&
            editSlider.originalData?.timeActive === type &&
            (editSlider.originalData?.numberOrder ?? 0) - 1 === index
          ) {
            let newSlider = { ...editSlider, image: file };
            setEditSlider(newSlider);
            return newSlider;
          } else {
            let newSlider = {
              // NOTE: generate an initial form state
              id: null,
              image: file,
              name: '',
              promotionLink: null,
              externalLink: '',
              buContact: null,
              brandID: null,
              appUsers: [],
              provinces: [],
              cities: [],
              liveStartDate: new Date(),
              liveEndDate: new Date(),
            };
            clearForm();
            setEditSlider(newSlider);
            return newSlider;
          }
        } else {
          return slider;
        }
      });
      setSliders(newSliderList);
      const slideType = convertTimeToSlideIndex(type);
      const newSliderIndex = {
        ...defaultEditIndex, // NOTE: reset others, can only edit one slider at one time
        [slideType]: index,
      };
      setEditSliderIndex(newSliderIndex);
      setEditable(true);
    },
    [editSlider, getSliderState, convertTimeToSlideIndex, clearForm],
  );

  const onEmail = () => {
    if (editSlider && editSlider.id) {
      emailBu({ variables: { sliderId: editSlider.id } });
    }
  };

  const onSave = useCallback(async () => {
    let { allDay, morning, day, evening } = editSliderIndex;

    let updatedSlider: SliderItem | null = null;
    let timeActive = Time.ALL_DAY;
    let numberOrder = 0;
    let active = false;

    if (allDay > -1) {
      updatedSlider = allDaySliders[allDay];
      timeActive = Time.ALL_DAY;
      numberOrder = allDay;
      active = selectedTime === 'allDay';
    } else if (morning > -1) {
      updatedSlider = morningSliders[morning];
      timeActive = Time.MORNING;
      numberOrder = morning;
      active = selectedTime === 'timeRange';
    } else if (day > -1) {
      updatedSlider = daySliders[day];
      timeActive = Time.DAY;
      numberOrder = day;
      active = selectedTime === 'timeRange';
    } else {
      updatedSlider = eveningSliders[evening];
      timeActive = Time.EVENING;
      numberOrder = evening;
      active = selectedTime === 'timeRange';
    }

    if (updatedSlider) {
      let image = '';
      if (typeof updatedSlider.image === 'string') {
        image = updatedSlider.image;
      } else if (updatedSlider.image != null) {
        const { data, errors } = await uploadFile({
          variables: {
            file: updatedSlider.image.file,
            folder: FolderType.CARD_SLIDER,
          },
        });
        if (errors) {
          return;
        }
        image = data?.upload.link ?? '';
      }
      const commonVariables: Omit<CreateCardSliderVariables, 'active'> = {
        brandId: brandID,
        promotionName: name,
        appUser: users,
        region: regionNames,
        city: cityNames,
        promotionLinkId: linkType === 'internal' ? promotion : null,
        externalLink: linkType === 'external' ? externalLink : null,
        image,
        liveDate: liveStartDate,
        endLiveDate: liveEndDate,
        timeActive,
        numberOrder: numberOrder + 1, // NOTE: 0-based index (arrays) to 1-based index (server)
      };
      if (updatedSlider.originalData) {
        updateSlider({
          variables: {
            ...commonVariables,
            cardSliderId: updatedSlider.originalData.id,
          },
        });
      } else {
        createSlider({
          variables: { ...commonVariables, active },
        });
      }
    }
  }, [
    allDaySliders,
    morningSliders,
    daySliders,
    eveningSliders,
    editSliderIndex,
    brandID,
    name,
    users,
    regionNames,
    cityNames,
    linkType,
    promotion,
    externalLink,
    liveStartDate,
    liveEndDate,
    selectedTime,
    uploadFile,
    createSlider,
    updateSlider,
  ]);

  const onEdit = useCallback(() => {
    if (editable && editSlider) {
      setUsers(editSlider.originalData?.appUser.map(nameMapper) ?? []);
      setRegionNames(editSlider.originalData?.region.map(nameMapper) ?? []);
      setCityNames(editSlider.originalData?.city.map(nameMapper) ?? []);
      setName(editSlider.name);
      setLinkType(editSlider.promotionLink ? 'internal' : 'external');
      setPromotion(editSlider.promotionLink);
      setExternalLink(editSlider.externalLink ?? '');
      setBrandBU(editSlider.buContact);
      setBrandID(editSlider.brandID);
      setLiveStartDate(editSlider.liveStartDate);
      setLiveEndDate(editSlider.liveEndDate);
    }
    setEditable(!editable);
  }, [editSlider, editable, nameMapper]);

  const onArchive = useCallback(
    (type: Time) => (index: number) => () => {
      let [sliders] = getSliderState(type);
      let slider = sliders[index];
      if (slider && slider.id) {
        archiveSlider({ variables: { cardSliderId: slider.id } });
      }
    },
    [getSliderState, archiveSlider],
  );

  const createSetSelected = useCallback(
    (setter: (value: string) => void) => (selected: Option) => {
      setter(selected.value);
    },
    [],
  );

  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);
  const cancelSwitch = useCallback(() => {
    setVisible(false);
    setSelectedTime(selectedTime === 'allDay' ? 'timeRange' : 'allDay');
  }, [selectedTime]);

  const onSelectTime = useCallback(
    (value: 'allDay' | 'timeRange') => {
      if (selectedTime !== value) {
        openModal();
        setSelectedTime(value);
      }
    },
    [selectedTime, openModal],
  );

  const setBrandContact = useCallback((option: Option) => {
    setBrandBU(option.value);
    setBrandID(option.subValue === '-' ? null : option.subValue ?? null);
  }, []);

  const changeActiveSliders = useCallback(async () => {
    toggleActiveSlider({
      variables: { allDayActive: selectedTime === 'allDay' },
    });
  }, [selectedTime, toggleActiveSlider]);

  const getRegionValues = useMemo(() => {
    // NOTE: when we get data from table, we will only get region names no region IDs
    if (regionNames.length > 0 && regions.length === 0) {
      return setRegions;
    }
  }, [regionNames, regions]);

  return (
    <View>
      <ErrorModal
        action={errorAction}
        open={errorOpen}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <ConfirmationModal
        keepOpenAfterConfirm
        title={t([
          'Anda akan mengubah slider promosi. Apakah anda yakin ingin melanjutkan?',
          'You are going to change the slider for promotion. Are you sure you want to continue?',
        ])}
        onClose={closeModal}
        onConfirm={changeActiveSliders}
        onCancel={cancelSwitch}
        loading={toggleLoading}
        open={visible}
      />
      <Header
        title={t(['Slider Promosi Beranda', 'Homepage Promotion Slider'])}
        subtitle={t(['Promosi', 'Promotion'])}
        subtitleColor="default"
      />
      <Text style={styles.step}>
        {t([
          'Maksimum 5 spanduk iklan di Beranda setiap periode waktu',
          'Maximum 5 live banners on Homepage for each time period',
        ])}
      </Text>
      <View style={styles.row}>
        <View style={styles.flex}>
          {editSlider && (
            <>
              <View style={styles.step}>
                <StepHeader
                  step={1}
                  title={t(['Pilih Pengguna', 'Select Users'])}
                />
                <UsersDropdown
                  data-testid="slider-form-user"
                  multiple
                  selectedOptions={users}
                  onSelect={createSetMultipleSelected(users, setUsers, [])}
                  disabled={!editable}
                />
              </View>
              <View style={styles.step}>
                <StepHeader
                  step={2}
                  title={t(['Pilih Provinsi', 'Select Region'])}
                />
                <RegionDropdown
                  data-testid="slider-form-region"
                  multiple
                  dataKey="label"
                  selectedOptions={regionNames}
                  getSelectedValues={getRegionValues}
                  onSelect={createSetMultipleSelected(
                    regions,
                    setRegions,
                    regionNames,
                    setRegionNames,
                    setCities,
                    setCityNames,
                  )}
                  disabled={!editable}
                />
              </View>
              <View>
                <StepHeader
                  step={3}
                  title={t(['Pilih Kota', 'Select Cities'])}
                />
                <CityDropdown
                  data-testid="slider-form-city"
                  multiple
                  dataKey="label"
                  provincesID={regions}
                  selectedOptions={cityNames}
                  onSelect={createSetMultipleSelected(
                    cities,
                    setCities,
                    cityNames,
                    setCityNames,
                  )}
                  disabled={!editable}
                />
              </View>
            </>
          )}
        </View>
        <View style={styles.content}>
          {sliderLoading && <ActivityIndicator />}
          {!!sliderError && (
            <ErrorMessage
              action={t([
                'mengambil data slider beranda',
                'retrieve the homepage slider data',
              ])}
              error={sliderError}
              onPress={refetchSliders}
            />
          )}
          {!!sliderData && (
            <RadioGroup
              selectedOption={selectedTime}
              setSelectedOption={onSelectTime}
              radioStyle={styles.radioSpacing}
              separator={separator}
              options={[
                {
                  'data-testid': 'slider-radio-all-day',
                  value: 'allDay',
                  label: (
                    <HomepageSliderRow
                      showHeader
                      edit={editSliderIndex.allDay}
                      editable={editable}
                      backgroundColor={colors.homepageSlider.allDay}
                      color="contrast"
                      title={t(['24 JAM', '24 HOUR'])}
                      subtitle={t(['SEPANJANG HARI', 'ALL DAY'])}
                      sliders={allDaySliders}
                      archiveLoading={archiveLoading}
                      onDrop={onDrop(Time.ALL_DAY)}
                      onEdit={onEditSlider(Time.ALL_DAY)}
                      onArchive={onArchive(Time.ALL_DAY)}
                    />
                  ),
                },
                {
                  'data-testid': 'slider-radio-time-range',
                  value: 'timeRange',
                  label: (
                    <>
                      <HomepageSliderRow
                        edit={editSliderIndex.morning}
                        editable={editable}
                        backgroundColor={colors.homepageSlider.morning}
                        title={t(['PAGI', 'MORNING'])}
                        subtitle="04.00 - 12.00"
                        sliders={morningSliders}
                        archiveLoading={archiveLoading}
                        onDrop={onDrop(Time.MORNING)}
                        onEdit={onEditSlider(Time.MORNING)}
                        onArchive={onArchive(Time.MORNING)}
                      />
                      <HomepageSliderRow
                        edit={editSliderIndex.day}
                        editable={editable}
                        backgroundColor={colors.homepageSlider.day}
                        title={t(['SIANG', 'DAY'])}
                        subtitle="12.00 - 18.00"
                        sliders={daySliders}
                        archiveLoading={archiveLoading}
                        onDrop={onDrop(Time.DAY)}
                        onEdit={onEditSlider(Time.DAY)}
                        onArchive={onArchive(Time.DAY)}
                      />
                      <HomepageSliderRow
                        edit={editSliderIndex.evening}
                        editable={editable}
                        backgroundColor={colors.homepageSlider.evening}
                        title={t(['MALAM', 'EVENING'])}
                        subtitle="18.00 - 04.00"
                        sliders={eveningSliders}
                        archiveLoading={archiveLoading}
                        onDrop={onDrop(Time.EVENING)}
                        onEdit={onEditSlider(Time.EVENING)}
                        onArchive={onArchive(Time.EVENING)}
                      />
                    </>
                  ),
                },
              ]}
            />
          )}
          {editSlider && (
            <View style={styles.editorWrapper}>
              <View style={styles.flex} />
              <View style={[styles.editorBox, styles.row]}>
                <View style={styles.flex}>
                  <Text bold color="link" style={styles.editorField}>
                    {t(['Promosi:', 'Promotion:'])}
                  </Text>
                  <TextInput
                    data-testid="slider-form-name"
                    containerStyle={styles.editorField}
                    maxLength={35}
                    label={t(['Nama', 'Name'])}
                    sublabel={t(['maks 35 karakter', 'max 35 characters'])}
                    value={name}
                    onChangeText={setName}
                    disabled={!editable}
                  />
                  <RadioGroup
                    selectedOption={linkType}
                    setSelectedOption={setLinkType}
                    options={[
                      {
                        'data-testid': 'slider-form-radio-internal',
                        value: 'internal',
                        label: (
                          <View>
                            <Text bold style={styles.editorFieldLabel}>
                              {t([
                                'Tautan Pelajari Lebih Lanjut',
                                'Learn More Link',
                              ])}
                            </Text>
                            <PromotionDropdown
                              data-testid="slider-form-internal"
                              type="basic"
                              selectedOption={promotion ?? undefined}
                              onSelect={createSetSelected(setPromotion)}
                              disabled={!editable || linkType === 'external'}
                            />
                          </View>
                        ),
                      },
                      {
                        'data-testid': 'slider-form-radio-external',
                        value: 'external',
                        label: (
                          <TextInput
                            data-testid="slider-form-external"
                            containerStyle={styles.editorField}
                            label={t(['Website Eksternal', 'External Website'])}
                            sublabel={t(['(opsional)', '(optional)'])}
                            value={externalLink}
                            onChangeText={setExternalLink}
                            disabled={!editable || linkType === 'internal'}
                          />
                        ),
                      },
                    ]}
                  />
                  <View style={styles.row}>
                    <View style={styles.flex}>
                      <View style={[styles.row, styles.editorFieldLabel]}>
                        <Text bold style={styles.flex}>
                          {t(['Brand', 'Brand'])}
                        </Text>
                        <Text bold style={[styles.flex, styles.buText]}>
                          {t(['Principal', 'Principal'])}
                        </Text>
                      </View>
                      <BrandContactDropdown
                        data-testid="slider-form-brand"
                        selectedOption={brandBU ?? undefined}
                        onSelect={setBrandContact}
                        disabled={!editable}
                        isOptional
                      />
                    </View>
                    <Button
                      data-testid="slider-form-mail"
                      preset="transparent"
                      title="mail"
                      icon={
                        <Image
                          source={{ uri: emailIcon }}
                          style={[
                            styles.mail,
                            { opacity: !editSlider.id ? 0.3 : 1 },
                          ]}
                          resizeMode="contain"
                        />
                      }
                      onPress={onEmail}
                      style={styles.mailButton}
                      disabled={!editSlider.id}
                    />
                  </View>
                </View>
                <View style={[styles.flex, styles.editorColumn]}>
                  <Text bold color="link" style={styles.editorField}>
                    {t(['Tayang di Aplikasi:', 'Go Live on App:'])}
                  </Text>
                  <View style={styles.flex}>
                    <LiveDateRadioGroup
                      withEndDate
                      liveDate={liveStartDate}
                      endDate={liveEndDate}
                      setLiveDate={setLiveStartDate}
                      setEndDate={setLiveEndDate}
                      disabled={!editable}
                    />
                  </View>
                  <View style={styles.row}>
                    <Button
                      data-testid="slider-form-submit"
                      isLoading={
                        createSliderLoading ||
                        updateSliderLoading ||
                        uploadFileLoading
                      }
                      disabled={submitDisabled || !editable}
                      title={t(['Kirim', 'Submit'])}
                      onPress={onSave}
                      style={styles.actionButton}
                    />
                    <Button
                      data-testid="slider-form-edit-cancel"
                      title={
                        editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])
                      }
                      preset="secondary"
                      onPress={onEdit}
                      style={styles.flex}
                    />
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: 'row' },
  content: { flex: 5, paddingLeft: spacing.xlarge },
  separator: {
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
    marginTop: spacing.medium,
    paddingTop: spacing.medium,
  },
  step: { paddingBottom: spacing.large },
  radioSpacing: { marginTop: 70 }, // NOTE: this is the exact value of the box's paddings + (height / 2) - the radio's (padding + height) / 2
  editorWrapper: {
    paddingTop: spacing.small,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editorColumn: {
    marginLeft: spacing.medium,
  },
  editorBox: {
    flex: 2,
    borderWidth: 1,
    borderColor: colors.border.primary,
    padding: spacing.small,
  },
  editorFieldLabel: { paddingBottom: spacing.xsmall },
  editorField: { paddingBottom: spacing.small },
  actionButton: { flex: 1, marginRight: spacing.small },
  buText: { paddingRight: spacing.medium, paddingBottom: spacing.xxsmall },
  mailButton: { marginTop: 32 },
  mail: { width: 38, height: 20 },
});

export default HomepageSliderForm;
