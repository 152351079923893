import React, { useState, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  SearchHeader,
  Header,
  Option,
  BrandDropdown,
  UsersDropdown,
  RegionDropdown,
  CityDropdown,
  SearchByText,
  SearchByDateRange,
  Dropdown,
} from '../../components';
import { theme, spacing, colors } from '../../constants/theme';
import { SliderFragment } from '../../generated/SliderFragment';
import { Time } from '../../generated/globalTypes';
import { SlidersVariables } from '../../generated/Sliders';

import HomepageSliderTable from './HomepageSliderTable';

type Props = {
  refetchToggle?: boolean;
  hideEdit?: boolean;
  hideArchiveCheck?: boolean;
  queryVariables?: SlidersVariables;
  onArchive?: (id?: string | null) => void;
  setEditSlider?: (value: SliderFragment | null) => void;
};

const HomepageSliderSearch = (props: Props) => {
  let {
    refetchToggle,
    hideEdit,
    hideArchiveCheck,
    queryVariables,
    onArchive,
    setEditSlider,
  } = props;

  // NOTE: these are used for filter state
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [regionName, setRegionName] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [cityName, setCityName] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<Time | null>(null);
  const [inputPromotion, setInputPromotion] = useState<string | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);

  // NOTE: these are used to filter the queries
  const [searchArchived, setSearchArchived] = useState<boolean | null>(null);
  const [searchByUser, setSearchByUser] = useState<string | null>(null);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  const [searchByRegion, setSearchByRegion] = useState<string | null>(null);
  const [searchByCity, setSearchByCity] = useState<string | null>(null);
  const [searchByTime, setSearchByTime] = useState<Time | null>(null);
  const [searchByPromotion, setSearchByPromotion] = useState<string | null>(
    null,
  );
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);

  const createSetSelected = useCallback(
    (
      setter: (value: string) => void,
      labelSetter?: (value: string) => void,
      ...nullSetters: Array<(value: null) => void>
    ) => (option: Option) => {
      setter(option.value);
      labelSetter?.(option.label);
      for (let setNull of nullSetters) {
        setNull(null);
      }
    },
    [],
  );

  const clearSearch = useCallback(() => {
    setIncludeArchived(false);
    setSelectedUser(null);
    setSelectedBrand(null);
    setSelectedRegion(null);
    setRegionName(null);
    setSelectedCity(null);
    setCityName(null);
    setSelectedTime(null);
    setInputPromotion(null);
    setDateFrom(null);
    setDateUntil(null);
  }, []);

  const submitSearch = useCallback(() => {
    setSearchArchived(includeArchived);
    setSearchByUser(selectedUser);
    setSearchByBrand(selectedBrand);
    setSearchByRegion(regionName);
    setSearchByCity(cityName);
    setSearchByTime(selectedTime);
    setSearchByPromotion(inputPromotion);
    if (dateFrom) {
      setSearchByDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
    } else {
      setSearchByDateFrom(null);
    }
    if (dateUntil) {
      setSearchByDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchByDateUntil(null);
    }
  }, [
    includeArchived,
    selectedUser,
    selectedBrand,
    regionName,
    cityName,
    selectedTime,
    inputPromotion,
    dateFrom,
    dateUntil,
  ]);

  const timeOptions = useMemo(
    () => [
      { label: t(['Sepanjang hari', 'All day']), value: Time.ALL_DAY },
      { label: t(['Pagi', 'Morning']), value: Time.MORNING },
      { label: t(['Siang', 'Day']), value: Time.DAY },
      { label: t(['Malam', 'Evening']), value: Time.EVENING },
    ],
    [],
  );
  const setTime = useCallback((option: Option) => {
    setSelectedTime(option ? (option.value as Time) : null);
  }, []);

  return (
    <>
      <SearchHeader
        data-testid="slider-search"
        archivable={!hideArchiveCheck}
        includeArchived={includeArchived}
        title={t(['Slider Promosi Beranda', 'Homepage Promotion Slider'])}
        onArchivePress={setIncludeArchived}
        onClearPress={clearSearch}
        onSearchPress={submitSearch}
        disabled={searchDisabled}
      >
        <View style={styles.separator}>
          <UsersDropdown
            data-testid="slider-search-user"
            type="side"
            title={t(['Cari Pengguna', 'Search by Users'])}
            selectedOption={selectedUser ?? undefined}
            onSelect={createSetSelected(setSelectedUser)}
          />
        </View>
        <View style={styles.separator}>
          <BrandDropdown
            data-testid="slider-search-brand"
            type="side"
            title={t(['Cari Brand', 'Search by Brand'])}
            selectedOption={selectedBrand ?? undefined}
            onSelect={createSetSelected(setSelectedBrand)}
          />
        </View>
        <View style={styles.separator}>
          <RegionDropdown
            data-testid="slider-search-region"
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={selectedRegion ?? undefined}
            onSelect={createSetSelected(
              setSelectedRegion,
              setRegionName,
              setSelectedCity,
              setCityName,
            )}
          />
        </View>
        <CityDropdown
          data-testid="slider-search-city"
          type="side"
          provincesID={selectedRegion ? [selectedRegion] : []}
          title={t(['Cari Kota', 'Search by City'])}
          selectedOption={selectedCity ?? undefined}
          onSelect={createSetSelected(setSelectedCity, setCityName)}
        />
        <View style={styles.separator}>
          <Dropdown
            data-testid="slider-search-time"
            type="side"
            title={t(['Cari Waktu', 'Search by Time'])}
            options={timeOptions}
            selectedOption={selectedTime ?? undefined}
            onSelect={setTime}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="slider-search-name"
            label={t(['Cari Promosi', 'Search by Promotion'])}
            value={inputPromotion ?? ''}
            setValue={setInputPromotion}
          />
        </View>
        <View style={styles.spacing}>
          <SearchByDateRange
            data-testid="slider-search-date"
            from={dateFrom}
            until={dateUntil}
            setFrom={setDateFrom}
            setUntil={setDateUntil}
            setDisabled={setSearchDisabled}
          />
        </View>
      </SearchHeader>
      <Header
        withSeparator
        title={t(['Hasil', 'Results'])}
        titleColor="link"
        infotipContent={t([
          'Seluruh data Homepage Slider akan ditampilkan pada daftar berikut. Klik Judul Kolom untuk penyortiran berdasarkan judul kolom yang di kilik. Klik tulisan “Lihat” untuk melihat detail konten judul tersebut. Klik “Icon” Arsip untuk mengirimkan data ke halaman folder arsip. Data yang sudah berada di Folder Arsip tidak akan nampak pada daftar ini, Anda dapat mengembalikan ke daftar ini dengan cara masuk pada menu “Halaman Folder Arsip”.',
          'All Homepage Slider data will be displayed in the following list. Click Column Headers for sorting based on the column headings that are clicked. Click "View" to see the detailed content of the title. Click the Archive “Icon” to send the data to the archive folder page. The data that is already in the Archive Folder will not appear in this list, you can return the data to this list by going to the "Archive Folder Page" menu.',
        ])}
        style={styles.topPadding}
      />
      <HomepageSliderTable
        refetchToggle={refetchToggle}
        hideEdit={hideEdit}
        queryVariables={queryVariables}
        onArchive={onArchive}
        setEditSlider={setEditSlider}
        searchArchived={searchArchived ?? undefined}
        searchByUser={searchByUser ?? undefined}
        searchByBrand={searchByBrand ?? undefined}
        searchByRegion={searchByRegion ?? undefined}
        searchByCity={searchByCity ?? undefined}
        searchByTime={searchByTime ?? undefined}
        searchByPromotion={searchByPromotion ?? undefined}
        searchByDateFrom={searchByDateFrom ?? undefined}
        searchByDateUntil={searchByDateUntil ?? undefined}
      />
    </>
  );
};

const styles = StyleSheet.create({
  topPadding: { paddingTop: theme.spacing.xlarge },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xxsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  spacing: { paddingBottom: spacing.xsmall },
});

export default HomepageSliderSearch;
