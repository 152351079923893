import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Text } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { FileWithPreview } from '../../core-ui/Dropzone';

import HomepageSlider from './HomepageSlider';
import { SliderItem } from './HomepageSliderForm';

type Props = {
  backgroundColor: string;
  color?: keyof typeof colors.text;
  edit?: number;
  editable: boolean;
  title: string;
  subtitle: string;
  showHeader?: boolean;
  sliders: Array<SliderItem | null>;
  archiveLoading: boolean;
  onDrop: (index: number) => (file: FileWithPreview) => void;
  onEdit: (index: number) => () => void;
  onArchive: (index: number) => () => void;
};

const HomepageSlideRow = (props: Props) => {
  let {
    backgroundColor,
    color,
    edit,
    editable,
    title,
    subtitle,
    sliders,
    showHeader,
    archiveLoading,
    onDrop,
    onEdit,
    onArchive,
  } = props;

  return (
    <>
      <View style={[styles.row, styles.spacedAround, styles.headerRow]}>
        {showHeader && (
          <>
            <View style={styles.header} />
            <View style={styles.header}>
              <Text bold style={styles.headerText}>
                1
              </Text>
            </View>
            <View style={styles.header}>
              <Text bold style={styles.headerText}>
                2
              </Text>
            </View>
            <View style={styles.header}>
              <Text bold style={styles.headerText}>
                3
              </Text>
            </View>
            <View style={styles.header}>
              <Text bold style={styles.headerText}>
                4
              </Text>
            </View>
            <View style={styles.header}>
              <Text bold style={styles.headerText}>
                5
              </Text>
            </View>
          </>
        )}
      </View>
      <View style={[styles.row, styles.spacedAround]} data-testid="slider-row">
        <View style={[styles.box, { backgroundColor }]}>
          <Text color={color} size="medium">
            {title}
          </Text>
          <Text color={color}>{subtitle}</Text>
        </View>
        {sliders.map((slider, index) => (
          <HomepageSlider
            key={index}
            id={slider?.id}
            image={slider?.image}
            liveEndDate={slider?.liveEndDate}
            edit={edit === index}
            editable={editable}
            archiveLoading={archiveLoading}
            onDrop={onDrop(index)}
            onEdit={onEdit(index)}
            onArchive={onArchive(index)}
          />
        ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  row: { flexDirection: 'row' },
  spacedAround: { justifyContent: 'space-around' },
  headerRow: { height: 35 },
  header: { width: 150, paddingBottom: spacing.small },
  headerText: { alignSelf: 'center' },
  box: {
    width: 150,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default HomepageSlideRow;
