import React, { useState, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  SearchHeader,
  Header,
  Option,
  UsersDropdown,
  BrandDropdown,
  RegionDropdown,
  CityDropdown,
  SearchByText,
  SearchByDateRange,
  Dropdown,
  SearchByViewers,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { NewsFragment } from '../../generated/NewsFragment';
import { NewsVariables } from '../../generated/News';

import NewsTable from './NewsTable';

type Props = {
  refetchToggle?: boolean;
  hideEdit?: boolean;
  hideArchiveCheck?: boolean;
  queryVariables?: NewsVariables;
  setEditNews?: (value: NewsFragment) => void;
};

export default function SearchNews(props: Props) {
  let {
    refetchToggle,
    hideEdit,
    hideArchiveCheck,
    queryVariables,
    setEditNews,
  } = props;
  let [includeArchived, setIncludeArchived] = useState(false);
  let [selectedUser, setSelectedUser] = useState<Option | null>(null);
  let [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  let [selectedRegion, setSelectedRegion] = useState<Option | null>(null);
  let [selectedCity, setSelectedCity] = useState<Option | null>(null);
  let [selectedStats, setSelectedStats] = useState<Option | null>(null);
  let [newsItem, setNewsItem] = useState('');
  let [dateFrom, setDateFrom] = useState<Date | null>(null);
  let [dateUntil, setDateUntil] = useState<Date | null>(null);
  let [viewersFrom, setViewersFrom] = useState('');
  let [viewersUntil, setViewersUntil] = useState('');
  let [searchDisabled, setSearchDisabled] = useState(false);

  let [searchArchived, setSearchArchived] = useState(false);
  let [searchByUser, setSearchByUser] = useState<string | null>(null);
  let [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  let [searchByRegion, setSearchByRegion] = useState<string | null>(null);
  let [searchByCity, setSearchByCity] = useState<string | null>(null);
  let [searchByNewsItem, setSearchByNewsItem] = useState<string | null>(null);
  let [searchDateFrom, setSearchDateFrom] = useState<Date | null>(null);
  let [searchDateUntil, setSearchDateUntil] = useState<Date | null>(null);
  let [searchViewersFrom, setSearchViewersFrom] = useState<number | null>(null);
  let [searchViewersUntil, setSearchViewersUntil] = useState<number | null>(
    null,
  );

  let onSearchPress = useCallback(() => {
    setSearchArchived(includeArchived);
    setSearchByUser(selectedUser?.label ?? null);
    setSearchByBrand(selectedBrand?.value.toString() ?? null);
    setSearchByRegion(selectedRegion?.label ?? null);
    setSearchByCity(selectedCity?.label ?? null);
    setSearchByNewsItem(newsItem);
    if (dateFrom) {
      setSearchDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
    } else {
      setSearchDateFrom(null);
    }
    if (dateUntil) {
      setSearchDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchDateUntil(null);
    }
    if (viewersFrom) {
      setSearchViewersFrom(Number(viewersFrom));
    } else {
      setSearchViewersFrom(null);
    }
    if (viewersUntil) {
      setSearchViewersUntil(Number(viewersUntil));
    } else {
      setSearchViewersUntil(null);
    }
  }, [
    includeArchived,
    selectedUser,
    selectedBrand,
    selectedRegion,
    selectedCity,
    newsItem,
    dateFrom,
    dateUntil,
    viewersUntil,
    viewersFrom,
  ]);

  let onClearPress = useCallback(() => {
    setIncludeArchived(false);
    setSelectedUser(null);
    setSelectedBrand(null);
    setSelectedRegion(null);
    setSelectedCity(null);
    setNewsItem('');
    setDateFrom(null);
    setDateUntil(null);
    setSelectedStats(null);
    setViewersFrom('');
    setViewersUntil('');
  }, []);

  const onSelectRegion = useCallback((selected: Option) => {
    setSelectedRegion(selected);
    setSelectedCity(null);
  }, []);

  return (
    <>
      <SearchHeader
        data-testid="search-news"
        archivable={!hideArchiveCheck}
        includeArchived={includeArchived}
        onArchivePress={setIncludeArchived}
        title={t(['Berita', 'News'])}
        onSearchPress={onSearchPress}
        onClearPress={onClearPress}
        disabled={searchDisabled}
      >
        <View>
          <UsersDropdown
            data-testid="search-user-dropdown"
            type="side"
            title={t(['Cari Pengguna', 'Search by User Group'])}
            selectedOption={selectedUser?.value}
            onSelect={setSelectedUser}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View>
          <BrandDropdown
            data-testid="search-brand-dropdown"
            type="side"
            title={t(['Cari Brand', 'Search by Brand'])}
            selectedOption={selectedBrand?.value}
            onSelect={setSelectedBrand}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View>
          <RegionDropdown
            data-testid="search-region-dropdown"
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={selectedRegion?.value}
            onSelect={onSelectRegion}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View>
          <CityDropdown
            type="side"
            data-testid="search-city-dropdown"
            provincesID={selectedRegion ? [selectedRegion.value] : []}
            title={t(['Cari Kota', 'Search by City'])}
            selectedOption={selectedCity?.value}
            onSelect={setSelectedCity}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-testid="search-news-item"
            label={t(['Cari Berita', 'Search by News Item'])}
            value={newsItem}
            setValue={setNewsItem}
          />
        </View>
        <View style={styles.separator}>
          <SearchByDateRange
            data-testid="search-news-date"
            from={dateFrom}
            until={dateUntil}
            setFrom={setDateFrom}
            setUntil={setDateUntil}
            setDisabled={setSearchDisabled}
          />
        </View>
        <View>
          <Dropdown
            data-testid="search-stats-dropdown"
            type="side"
            options={[
              {
                label: 'All users who clicked on news item',
                value: 'All users who clicked on news item',
              },
            ]}
            title={t(['Cari Statistik', 'Search by Statistic'])}
            selectedOption={selectedStats?.value}
            onSelect={setSelectedStats}
            style={styles.width65}
          />
          <View style={styles.divider} />
        </View>
        {selectedStats ? (
          <View style={[styles.separator, styles.largeBottomSpacing]}>
            <SearchByViewers
              data-testid="search-viewers"
              from={viewersFrom}
              until={viewersUntil}
              setFrom={setViewersFrom}
              setUntil={setViewersUntil}
            />
          </View>
        ) : (
          <View style={styles.smallBottomSpacing} />
        )}
      </SearchHeader>
      <Header
        withSeparator
        title={t(['Hasil', 'Results'])}
        titleColor="link"
        infotipContent={t([
          'Seluruh data Berita akan ditampilkan pada daftar berikut. Klik Judul Kolom untuk penyortiran berdasarkan judul kolom yang di kilik. Klik tulisan “Lihat” untuk melihat detail konten judul tersebut. Klik “Icon” Arsip untuk mengirimkan data ke halaman folder arsip. Data yang sudah berada di Folder Arsip tidak akan nampak pada daftar ini, Anda dapat mengembalikan ke daftar ini dengan cara masuk pada menu “Halaman Folder Arsip”.',
          'All News data will be displayed in the following list. Click Column Headers for sorting based on the column headings that are clicked. Click "View" to see the detailed content of the title. Click the Archive “Icon” to send the data to the archive folder page. The data that is already in the Archive Folder will not appear in this list, you can return the data to this list by going to the "Archive Folder Page" menu.',
        ])}
        style={styles.marginTop}
      />
      <NewsTable
        setEditNews={setEditNews}
        hideEdit={hideEdit}
        queryVariables={queryVariables}
        refetchToggle={refetchToggle}
        searchArchived={searchArchived}
        searchByUser={searchByUser ?? undefined}
        searchByBrand={searchByBrand ?? undefined}
        searchByRegion={searchByRegion ?? undefined}
        searchByCity={searchByCity ?? undefined}
        searchByNewsItem={searchByNewsItem ?? undefined}
        searchDateFrom={searchDateFrom ?? undefined}
        searchDateUntil={searchDateUntil ?? undefined}
        searchViewersFrom={searchViewersFrom ?? undefined}
        searchViewersUntil={searchViewersUntil ?? undefined}
      />
    </>
  );
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: spacing.xlarge,
  },
  smallBottomSpacing: {
    marginBottom: spacing.small,
  },
  largeBottomSpacing: {
    marginBottom: spacing.large,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginBottom: spacing.small,
    paddingBottom: spacing.small,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    marginVertical: spacing.small,
    width: '65%',
  },
  width65: { width: '65%' },
});
