import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { logEvent } from 'expo-firebase-analytics';

import {
  HeaderNavigationBar,
  SearchHeader,
  SearchByDateRange,
  Dropdown,
  Option,
  PromotionDropdown,
  ErrorMessage,
  BrandDropdown,
  ConfirmationModal,
  EventDropdown,
  CompetitionsDropdown,
  ErrorModal,
} from '../../components';
import { routePaths } from '../../constants/routes';
import { spacing, colors } from '../../constants/theme';
import { Text, TextInput, Button } from '../../core-ui';
import {
  StoreDetails,
  StoreDetailsVariables,
} from '../../generated/StoreDetails';
import {
  GET_STORE_DETAILS,
  GET_POINT_RATE,
  DOWNLOAD_POINT_HISTORY,
} from '../../graphql/queries';
import {
  UpdateStorePoint,
  UpdateStorePointVariables,
} from '../../generated/UpdateStorePoint';
import { UPDATE_STORE_POINT } from '../../graphql/mutations/loyaltyPoint';
import { LumpSumAction, LumpSumType } from '../../generated/globalTypes';
import { SegmentedControl } from '../../features/verification/components';
import { PointRate, PointRateVariables } from '../../generated/PointRate';
import { pageTitle } from '../../constants/pageTitle';
import {
  DownloadPointHistory,
  DownloadPointHistoryVariables,
} from '../../generated/DownloadPointHistory';

import HistoryTable from './HistoryTable';

const actionOptions = [
  { label: t(['Tambah Poin', 'Add Point']), value: LumpSumAction.ADD },
  {
    label: t(['Kurangi Poin', 'Deduct Point']),
    value: LumpSumAction.DEDUCT,
  },
];

const typeOptions = [
  {
    label: t(['Promosi', 'Promotion']),
    value: LumpSumType.PROMOTION,
  },
  {
    label: t(['Event', 'Event']),
    value: LumpSumType.EVENT,
  },
  {
    label: t(['Kompetisi', 'Competition']),
    value: LumpSumType.COMPETITION,
  },
  {
    label: t(['Transaksi', 'Transaction']),
    value: LumpSumType.TRANSACTION,
  },
  {
    label: t(['Reward DBO', 'Reward DBO']),
    value: LumpSumType.REWARD,
  },
];

const PointHistoryScene = () => {
  const history = useHistory();
  const { id } = useParams();
  const [selectedAction, setSelectedAction] = useState(LumpSumAction.ADD);
  const [selectedType, setSelectedType] = useState<LumpSumType | null>(null);
  const [selectedPromotion, setSelectedPromotion] = useState<string | null>(
    null,
  );
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [selectedCompetition, setSelectedCompetition] = useState<string | null>(
    null,
  );
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  const [amount, setAmount] = useState('');
  const [point, setPoint] = useState('');
  const [note, setNote] = useState('');
  const [activityName, setActivityName] = useState('');
  const [buttonTitle, setButtonTitle] = useState(
    t(['Tambah Poin', 'Add Point']),
  );
  const [inputFocus, setInputFocus] = useState('');
  const [selectedSearchBrand, setSelectedSearchBrand] = useState<string | null>(
    null,
  );
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(0);
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [errorAction, setErrorAction] = useState('');
  const [pointRate, setPointRate] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  const { data, loading, error: storeError, refetch } = useQuery<
    StoreDetails,
    StoreDetailsVariables
  >(GET_STORE_DETAILS, {
    variables: { storeId: id },
    onError: (error) => {
      setErrorInstance(error);
      setErrorAction(
        t(['mengambil data rincian toko', 'retrieve the store detail data']),
      );
    },
  });

  // Not Brand Endpoint will need a brandId to avoid triggering an error from backend
  // Temporary Fix for this is to skip the query when selectedBrand is null
  const {
    data: pointRateData,
    error: pointRateError,
    refetch: refetchPointRate,
  } = useQuery<PointRate, PointRateVariables>(GET_POINT_RATE, {
    skip: !selectedBrand,
    variables: { brandId: selectedBrand },
    onError: (error) => {
      setErrorInstance(error);
      setErrorAction(
        t(['mengambil data nilai poin', 'retrieve the point rate data']),
      );
    },
  });

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.PointHistoryScene });
    };
    eventLog();
  }, []);

  const [
    updateStorePoint,
    { loading: updateLoading, error: updateError },
  ] = useMutation<UpdateStorePoint, UpdateStorePointVariables>(
    UPDATE_STORE_POINT,
    {
      onCompleted: () => {
        refetchStoreDetails();
        setRefetchToggle(!refetchToggle);
        setSelectedType(null);
        setSelectedPromotion(null);
        setSelectedEvent(null);
        setSelectedBrand(null);
        setPoint('');
        setAmount('');
        setNote('');
      },
      onError: () => {
        setErrorOpen(true);
        setErrorAction(
          t(['{action} poin pada toko', '{action} point to store'], {
            action:
              selectedAction === LumpSumAction.ADD
                ? t(['menambah', 'add'])
                : t(['mengurangi', 'deduct']),
          }),
        );
      },
    },
  );

  const refetchStoreDetails = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const createSetSelected = useCallback(
    <T extends unknown>(
      valueSetter?: (value: T) => void,
      labelSetter?: (value: string) => void,
    ) => (selected: Option) => {
      valueSetter && valueSetter(selected.value as T);
      labelSetter && labelSetter(selected.label);
      if (selectedAction !== selected.value) {
        setActivityName('');
        setNote('');
      }
    },
    [selectedAction],
  );

  const submitSearch = useCallback(() => {
    if (dateFrom && dateUntil) {
      setSearchByDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
      setSearchByDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchByDateFrom(null);
      setSearchByDateUntil(null);
    }
    setSearchByBrand(selectedSearchBrand);
  }, [dateFrom, dateUntil, selectedSearchBrand]);

  const clearSearch = useCallback(() => {
    setDateFrom(null);
    setDateUntil(null);
    setSelectedSearchBrand(null);
  }, []);

  const confirmationModalMessage = useMemo(() => {
    return t(
      [
        'Anda akan {action} {point} poin {preposition} {name}',
        'You are about to {action} {point}pts {preposition} {name}',
      ],
      {
        action:
          selectedAction === LumpSumAction.ADD
            ? t(['menambah', 'add'])
            : t(['mengurangi', 'deduct']),
        point,
        preposition:
          selectedAction === LumpSumAction.ADD
            ? t(['kepada', 'to'])
            : t(['pada', 'on']),
        name: data?.store?.storeManager?.storeName,
      },
    );
  }, [selectedAction, point, data]);

  const actionDisabled = useMemo(() => {
    return selectedAction === LumpSumAction.ADD
      ? !selectedType ||
          (selectedType === LumpSumType.PROMOTION && !selectedPromotion) ||
          (selectedType === LumpSumType.EVENT && !selectedEvent) ||
          (selectedType === LumpSumType.COMPETITION && !selectedCompetition) ||
          !selectedBrand ||
          !amount ||
          !point ||
          !pointRate ||
          !!errorMessage
      : !point || !!errorMessage;
  }, [
    selectedAction,
    selectedCompetition,
    selectedType,
    selectedPromotion,
    selectedEvent,
    amount,
    selectedBrand,
    point,
    pointRate,
    errorMessage,
  ]);

  const hideInputs = useMemo(() => {
    return (
      selectedAction === LumpSumAction.ADD &&
      (!selectedType ||
        (selectedType === LumpSumType.PROMOTION && !selectedPromotion) ||
        (selectedType === LumpSumType.EVENT && !selectedEvent) ||
        (selectedType === LumpSumType.COMPETITION && !selectedCompetition) ||
        !selectedBrand)
    );
  }, [
    selectedAction,
    selectedBrand,
    selectedCompetition,
    selectedEvent,
    selectedPromotion,
    selectedType,
  ]);

  const hideInputsNameActivity = useMemo(() => {
    return (
      selectedAction === LumpSumAction.ADD &&
      (!selectedType || selectedType === LumpSumType.REWARD || !selectedBrand)
    );
  }, [selectedAction, selectedBrand, selectedType]);

  const onActionPress = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onDownloadError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunduh file', 'download file']));
    setErrorInstance(error);
  }, []);

  const saveData = useCallback(
    (historyData: DownloadPointHistory) => {
      if (searchByDateFrom && searchByDateUntil) {
        saveAs(
          historyData.downloadLoyaltyPoint.link,
          data?.store?.storeManager?.storeName +
            ' Loyalty Point History ' +
            new Date(searchByDateFrom) +
            '-' +
            new Date(searchByDateUntil) +
            '.csv',
        );
      } else {
        saveAs(
          historyData.downloadLoyaltyPoint.link,
          data?.store?.storeManager?.storeName + ' Loyalty Point History.csv',
        );
      }
    },
    [data, searchByDateFrom, searchByDateUntil],
  );

  const [getExportLink, { loading: exportLinkLoading }] = useLazyQuery<
    DownloadPointHistory,
    DownloadPointHistoryVariables
  >(DOWNLOAD_POINT_HISTORY, {
    onCompleted: saveData,
    onError: onDownloadError,
  });

  const refetchLink = useCallback(() => {
    getExportLink({
      variables: {
        startDate: searchByDateFrom,
        endDate: searchByDateUntil,
        storeId: id,
      },
    });
  }, [getExportLink, searchByDateUntil, searchByDateFrom, id]);

  const onExportPress = useCallback(() => {
    refetchLink();
  }, [refetchLink]);

  const onConfirmAction = useCallback(() => {
    updateStorePoint({
      variables: {
        storeId: id,
        action: selectedAction,
        lumpSumType: selectedType,
        promotionId: selectedPromotion,
        eventId: selectedEvent,
        brandId: selectedBrand,
        competitionId: selectedCompetition,
        point: Number(point),
        note,
        nameActivity: activityName || null,
      },
    });
  }, [
    id,
    note,
    point,
    selectedAction,
    selectedCompetition,
    selectedBrand,
    selectedEvent,
    selectedPromotion,
    selectedType,
    updateStorePoint,
    activityName,
  ]);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);

  useEffect(() => {
    pointRateData && setPointRate(pointRateData?.brand.pointRate);
  }, [pointRateData]);

  useEffect(() => {
    if (inputFocus === 'amount') {
      let newPoint = amount
        ? Math.floor(Number(amount) / pointRate).toString()
        : '';
      setPoint(newPoint);
    }
  }, [amount, inputFocus, pointRate]);

  useEffect(() => {
    if (inputFocus === 'point') {
      let newAmount = point ? (Number(point) * pointRate).toString() : '';
      setAmount(newAmount);
    }
    if (
      selectedAction === LumpSumAction.DEDUCT &&
      data &&
      data.store &&
      Number(point) > data.store.point
    ) {
      setErrorMessage(
        t([
          'Pengurangan poin tidak boleh lebih besar dari total poin toko',
          `Point deduction should not be greater than store's points`,
        ]),
      );
    } else {
      setErrorMessage('');
    }
  }, [point, inputFocus, pointRate, selectedAction, data]);

  useEffect(() => {
    setSelectedPromotion(null);
    setSelectedEvent(null);
    setSelectedCompetition(null);
  }, [selectedType, selectedBrand]);

  useEffect(() => {
    setSelectedBrand(null);
    setPoint('');
    setAmount('');
  }, [selectedAction]);

  useEffect(() => {
    setPoint('');
    setAmount('');
  }, [pointRate]);

  useEffect(() => {
    setDateFrom(null);
    setDateUntil(null);
    setSelectedSearchBrand(null);
    setSearchByDateFrom(null);
    setSearchByDateUntil(null);
    setSearchByBrand(null);
  }, [selectedHistory]);

  const onBackPress = useCallback(
    () => history.push(routePaths.loyaltyStoreList),
    [history],
  );

  if (storeError) {
    return (
      <View style={styles.root}>
        <ErrorMessage
          action={errorAction}
          error={errorInstance}
          onPress={refetchStoreDetails}
        />
      </View>
    );
  }

  return (
    <View style={styles.root}>
      <HeaderNavigationBar
        backDestinationText={t([
          'Hadiah Promosi Lumpsum',
          'Promotion Lumpsum Reward',
        ])}
        onBackButtonPress={onBackPress}
      />
      {loading && <ActivityIndicator />}
      {data?.store && (
        <>
          <Text bold size="large" style={styles.separator}>
            {data?.store?.storeManager?.storeName}
          </Text>
          <Text bold style={styles.smallBottomSpacing}>
            {t(['Ringkasan', 'Summary'])}
          </Text>
          <View style={[styles.row, styles.smallBottomSpacing]}>
            <Text bold>{t(['Total Poin', 'Total Point'])}</Text>
          </View>
          <View style={[styles.row, styles.separator]}>
            <Text>{data?.store?.point.toLocaleString('id')} pts</Text>
          </View>

          <Text bold style={styles.smallBottomSpacing}>
            {t(['Aksi', 'Action'])}
          </Text>

          <View
            style={[
              selectedAction === LumpSumAction.DEDUCT && styles.row,
              styles.separator,
            ]}
          >
            <View
              style={[
                styles.row,
                selectedAction === LumpSumAction.ADD && styles.xsBottomSpacing,
              ]}
            >
              <View style={[styles.dropdown, styles.xsRightSpacing]}>
                <Dropdown
                  data-testid="form-action-dropdown"
                  type="basic"
                  options={actionOptions}
                  selectedOption={selectedAction}
                  onSelect={createSetSelected(
                    setSelectedAction,
                    setButtonTitle,
                  )}
                  title={t(['Pilih aksi', 'Select action'])}
                />
              </View>
              {selectedAction === LumpSumAction.ADD && (
                <View style={styles.row}>
                  <View style={[styles.dropdown, styles.xsRightSpacing]}>
                    <BrandDropdown
                      type="basic"
                      data-testid="form-brand-dropdown"
                      selectedOption={selectedBrand ?? undefined}
                      onSelect={createSetSelected(setSelectedBrand)}
                      showLocalBrandData={true}
                    />
                  </View>
                  <View style={[styles.dropdown, styles.xsRightSpacing]}>
                    <Dropdown
                      data-testid="form-type-dropdown"
                      type="basic"
                      options={typeOptions}
                      selectedOption={selectedType ?? undefined}
                      onSelect={createSetSelected(setSelectedType)}
                      title={t(['Pilih tipe', 'Select type'])}
                      disabled={!selectedBrand}
                    />
                  </View>
                  <View
                    style={
                      !!selectedType &&
                      selectedType !== LumpSumType.TRANSACTION && [
                        styles.dropdown,
                        styles.xsRightSpacing,
                      ]
                    }
                  >
                    {selectedType === LumpSumType.PROMOTION && (
                      <PromotionDropdown
                        data-testid="form-promotion-dropdown"
                        type="basic"
                        selectedOption={selectedPromotion ?? undefined}
                        onSelect={createSetSelected(setSelectedPromotion)}
                        brandId={selectedBrand ?? undefined}
                        titleIfEmpty={t([
                          '- Tidak Ada Promosi -',
                          '- No Promotion Available -',
                        ])}
                      />
                    )}
                    {selectedType === LumpSumType.EVENT && (
                      <EventDropdown
                        data-testid="form-event-dropdown"
                        type="basic"
                        selectedOption={selectedEvent ?? undefined}
                        onSelect={createSetSelected(setSelectedEvent)}
                        brandId={selectedBrand ?? undefined}
                        titleIfEmpty={t([
                          '- Tidak Ada Event -',
                          '- No Event Available -',
                        ])}
                      />
                    )}
                    {selectedType === LumpSumType.COMPETITION && (
                      <CompetitionsDropdown
                        data-testid="form-competition-dropdown"
                        type="basic"
                        selectedOption={selectedCompetition ?? undefined}
                        onSelect={createSetSelected(setSelectedCompetition)}
                        brandId={selectedBrand ?? undefined}
                        titleIfEmpty={t([
                          '- Tidak Ada Kompetisi -',
                          '- No Competition Available -',
                        ])}
                      />
                    )}
                  </View>
                </View>
              )}
            </View>
            {!hideInputs && (
              <View style={styles.row}>
                {hideInputsNameActivity && (
                  <TextInput
                    data-testid="form-activityName"
                    value={activityName}
                    onChangeText={setActivityName}
                    placeholder={t(['Nama Hadiah', 'Reward Name'])}
                    containerStyle={styles.smallRightSpacing}
                  />
                )}
                {selectedAction === LumpSumAction.ADD && (
                  <>
                    <TextInput
                      noDecimalPoint
                      data-testid="form-amount"
                      type="number"
                      value={amount}
                      onChangeText={setAmount}
                      containerStyle={styles.xsRightSpacing}
                      InputProps={{
                        startAdornment: <Text>Rp </Text>,
                      }}
                      onFocus={() => setInputFocus('amount')}
                    />
                    <Text style={styles.xsRightSpacing}>=</Text>
                  </>
                )}
                <TextInput
                  noDecimalPoint
                  data-testid="form-point"
                  type="number"
                  value={point}
                  onChangeText={setPoint}
                  containerStyle={styles.xsRightSpacing}
                  InputProps={{
                    startAdornment: selectedAction === LumpSumAction.DEDUCT && (
                      <Text>-</Text>
                    ),
                    endAdornment: <Text>pts</Text>,
                  }}
                  onFocus={() => setInputFocus('point')}
                  error={!!errorMessage}
                  errorMessage={errorMessage}
                />
                <TextInput
                  data-testid="form-note"
                  value={note}
                  onChangeText={setNote}
                  placeholder={t(['Tambah Catatan', 'Add Notes'])}
                  containerStyle={styles.smallRightSpacing}
                />
                <Button
                  data-testid="submit-button"
                  preset="transparent"
                  title={buttonTitle}
                  onPress={onActionPress}
                  isLoading={updateLoading}
                  disabled={actionDisabled}
                />
              </View>
            )}
          </View>
          {!!pointRateError && !!selectedBrand && (
            <ErrorMessage
              action={errorAction}
              error={errorInstance}
              onPress={refetchPointRate}
            />
          )}
          <View style={styles.separator}>
            <Text bold style={styles.smallBottomSpacing}>
              {t(['Cari', 'Search'])}
            </Text>
            <SearchHeader
              data-testid="search-history"
              onSearchPress={submitSearch}
              onClearPress={clearSearch}
              disabled={searchDisabled}
            >
              {selectedHistory === 1 ? (
                <View style={styles.separator}>
                  <BrandDropdown
                    data-testid="search-brand-dropdown"
                    dataKey="label"
                    type="side"
                    selectedOption={selectedSearchBrand ?? undefined}
                    onSelect={createSetSelected(
                      undefined,
                      setSelectedSearchBrand,
                    )}
                  />
                </View>
              ) : null}
              <View style={selectedHistory === 1 && styles.separator}>
                <SearchByDateRange
                  data-testid="search-date"
                  from={dateFrom}
                  until={dateUntil}
                  setFrom={setDateFrom}
                  setUntil={setDateUntil}
                  setDisabled={setSearchDisabled}
                />
              </View>
            </SearchHeader>
            {selectedHistory === 0 && (
              <View style={styles.exportButton}>
                <Button
                  data-testid="request-agent-download"
                  title={t(['Ekspor Semua Data', 'Export All Data'])}
                  onPress={onExportPress}
                  style={{ width: 240 }}
                  isLoading={exportLinkLoading}
                />
              </View>
            )}
          </View>
          <SegmentedControl
            data-testid="segmented-control"
            options={[
              t(['Riwayat Poin', 'Point History']),
              t(['Riwayat Transaksi', 'Transaction History']),
            ]}
            selected={selectedHistory}
            setSelected={setSelectedHistory}
            containerStyle={styles.segmentedControl}
            ButtonComponent={Button}
          />
          <HistoryTable
            storeId={id}
            storeName={data?.store?.storeManager?.storeName}
            selectedHistory={selectedHistory}
            searchByBrand={searchByBrand ?? undefined}
            searchByDateFrom={searchByDateFrom ?? undefined}
            searchByDateUntil={searchByDateUntil ?? undefined}
            refetchToggle={refetchToggle}
          />
          <ConfirmationModal
            data-testid="confirmation-modal"
            open={modalOpen}
            title={confirmationModalMessage}
            onClose={() => setModalOpen(false)}
            onConfirm={onConfirmAction}
          />
          <ErrorModal
            action={errorAction}
            open={errorOpen}
            error={updateError}
            onClose={closeErrorModal}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  separator: {
    paddingBottom: spacing.medium,
    marginBottom: spacing.medium,
    borderBottomWidth: 1,
    borderBottomColor: colors.separator,
  },
  dropdown: {
    width: 180,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  smallBottomSpacing: {
    paddingBottom: spacing.small,
  },
  xsBottomSpacing: {
    paddingBottom: spacing.xsmall,
  },
  // totalPurchase: {
  //   width: '15%',
  // },
  xsRightSpacing: {
    paddingRight: spacing.xsmall,
  },
  smallRightSpacing: {
    paddingRight: spacing.small,
  },
  segmentedControl: {
    minWidth: 320,
    marginBottom: spacing.small,
  },
  exportButton: {
    paddingTop: 10,
    flex: 1,
    alignItems: 'flex-end',
  },
});

export default PointHistoryScene;
