import gql from 'graphql-tag';

import {
  COMPETITION_ONLINE_FORM_FRAGMENT,
  EVENT_ONLINE_FORM_FRAGMENT,
} from '../fragments';

export const GET_COMPETITION_ONLINE_FORMS = gql`
  query CompetitionOnlineForms(
    $id: ID
    $region: String
    $city: String
    $name: String
    $brandID: ID
    $date: DateFilter
    $archiveStatus: ArchiveStatus
    $first: Int
    $skip: Int
    $orderBy: FormCompetitionOrderBy
  ) {
    formCompetitionAdvanceSearch(
      id: $id
      region: $region
      city: $city
      competitionName: $name
      brandId: $brandID
      archiveStatus: $archiveStatus
      date: $date
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
    ) {
      row {
        ...CompetitionFormFragment
      }
      total
    }
  }
  ${COMPETITION_ONLINE_FORM_FRAGMENT}
`;

export const GET_EVENT_ONLINE_FORMS = gql`
  query EventOnlineForms(
    $id: ID
    $region: String
    $city: String
    $name: String
    $brandID: ID
    $date: DateFilter
    $archiveStatus: ArchiveStatus
    $first: Int
    $skip: Int
    $orderBy: FormEventOrderBy
  ) {
    formEventAdvanceSearch(
      id: $id
      region: $region
      city: $city
      eventName: $name
      brandId: $brandID
      archiveStatus: $archiveStatus
      date: $date
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
    ) {
      row {
        ...EventFormFragment
      }
      total
    }
  }
  ${EVENT_ONLINE_FORM_FRAGMENT}
`;

export const GET_COMPETITION = gql`
  query formCompetition($link: String!) {
    formCompetitionAdmin(link: $link) {
      id
      name
      image
      link
      prize
      prizeValue
      terms
      status
      competition {
        id
        name
      }
    }
  }
`;

export const GET_EVENT = gql`
  query formEvent($where: FormEventWhereUniqueInput!) {
    formEvent(where: $where) {
      id
      name
      address
      latitude
      longitude
      venue
      link
      timeEvent
      maxParticipant
      status
      event {
        id
        name
        eventDate
      }
    }
  }
`;

export const GET_SUBMIT_FORM_COMPETITION = gql`
  query SubmitFormCompetitionAdvanceSearch(
    $formId: Int
    $first: Int
    $skip: Int
  ) {
    SubmitFormCompetitionAdvanceSearch(
      formId: $formId
      pagination: { first: $first, skip: $skip }
    ) {
      row {
        id
        name
        phoneNumber
        storeName
      }
      total
    }
  }
`;

export const GET_SUBMIT_FORM_EVENT = gql`
  query SubmitFormEventAdvanceSearch($formId: Int, $first: Int, $skip: Int) {
    SubmitFormEventAdvanceSearch(
      formId: $formId
      pagination: { first: $first, skip: $skip }
    ) {
      row {
        id
        name
        phoneNumber
        storeName
        attendees
      }
      total
    }
  }
`;
