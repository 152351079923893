import React, { memo, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { View, StyleSheet } from 'react-native';

import { Text } from '../core-ui';
import { spacing } from '../constants/theme';

import DateTimePicker from './DateTimePicker';

type Props = {
  'data-testid'?: string;
  title?: string;
  from: Date | null;
  until: Date | null;
  setFrom: (value: Date) => void;
  setUntil: (value: Date) => void;
  setDisabled: (disable: boolean) => void;
};

const SearchByDateRange = memo((props: Props) => {
  let { title, from, until, setFrom, setUntil, setDisabled } = props;

  useEffect(() => {
    if ((!!from && !!until && from <= until) || (!from && !until)) {
      // NOTE: both have values or both are empty
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [from, until, setDisabled]);

  return (
    <View style={[styles.flex, styles.row, styles.root]}>
      <View style={[styles.flex, styles.row]}>
        <SearchIcon color="inherit" fontSize="default" />
        <Text style={[styles.flex, styles.label]}>
          {title ? title : t(['Cari Rentang Tanggal', 'Search by Date Range'])}
        </Text>
      </View>
      <View style={[styles.flex, styles.row]}>
        <DateTimePicker
          data-testid={`${props['data-testid']}-from`}
          label=""
          type="date"
          selectedDate={from}
          onChange={setFrom}
          disablePast={false}
        />
        <Text style={styles.to}>{t(['hingga', 'to'])}</Text>
        <DateTimePicker
          data-testid={`${props['data-testid']}-until`}
          label=""
          type="date"
          selectedDate={until}
          onChange={setUntil}
          disablePast={false}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: { height: 36, paddingHorizontal: spacing.xsmall },
  flex: { flex: 1 },
  row: { flexDirection: 'row', alignItems: 'center' },
  label: { paddingLeft: spacing.small },
  to: { paddingHorizontal: spacing.xsmall },
});

export default SearchByDateRange;
