import React, { ReactNode, CSSProperties, Fragment } from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';
import {
  Radio,
  RadioGroup as MRadioGroup,
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
} from '@material-ui/core';

import { Text } from '../core-ui';
import { colors } from '../constants/theme';

type RadioOption<T extends unknown> = {
  'data-testid'?: string;
  value: T;
  label: ReactNode;
};

type Props<T extends unknown> = {
  containerStyle?: ViewProps['style'];
  disabled?: boolean;
  horizontal?: boolean;
  label?: string;
  optionLabelPlacement?: FormControlLabelProps['labelPlacement'];
  options: Array<RadioOption<T>>;
  selectedOption: T;
  radioStyle?: ViewProps['style'];
  separator?: ReactNode;
  setSelectedOption: (value: T) => void;
};

const useStyles = makeStyles({
  control: { alignItems: 'flex-start' },
  controlStart: { marginLeft: 0, marginRight: 16 },
  label: {
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 9, // NOTE: this is === the Radio component's padding
  },
  labelStart: {
    paddingLeft: 0,
    paddingRight: 16,
  },
  radio: {
    color: colors.radio.border,
    '&$checked': { color: colors.radio.selected },
    '&$disabled': { color: colors.radio.disabled },
  },
  checked: {},
  disabled: {},
});

export default function RadioGroup<T extends unknown>(props: Props<T>) {
  let classes = useStyles();
  let {
    containerStyle,
    disabled,
    horizontal,
    label,
    optionLabelPlacement,
    options,
    selectedOption,
    radioStyle,
    separator,
    setSelectedOption,
  } = props;

  const controlClasses = {
    root:
      optionLabelPlacement === 'start' ? classes.controlStart : classes.control,
    label:
      optionLabelPlacement === 'start' ? classes.labelStart : classes.label,
  };

  return (
    <View style={containerStyle}>
      <Text bold>{label}</Text>
      <MRadioGroup name="livedate" value={selectedOption}>
        <View style={horizontal && styles.row}>
          {options.map(({ value, label, 'data-testid': dataCy }, index) => (
            <Fragment key={index}>
              {index > 0 && separator}
              <FormControlLabel
                disabled={disabled}
                value={value}
                labelPlacement={optionLabelPlacement}
                control={
                  <Radio
                    style={StyleSheet.flatten(radioStyle) as CSSProperties}
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                      disabled: classes.disabled,
                    }}
                    inputProps={
                      { 'data-testid': dataCy } as React.HTMLAttributes<
                        HTMLInputElement
                      >
                    } // NOTE: somehow this inputProps type doesn't accept data-*
                    onClick={() => setSelectedOption(value)}
                  />
                }
                label={
                  <div
                    // NOTE: this style overrides the FormControlLabel cursor = pointer that made it look clickable
                    style={{ cursor: 'default' }}
                    // NOTE: this onClick overrides Radio's onClick so we can only change the radio group's value by
                    //       pressing the Radio button (previously also works by pressing anywhere in the label node)
                    onClick={(e) => e.preventDefault()}
                  >
                    {label}
                  </div>
                }
                classes={controlClasses}
              />
            </Fragment>
          ))}
        </View>
      </MRadioGroup>
    </View>
  );
}

const styles = StyleSheet.create({
  row: { flexDirection: 'row', alignItems: 'center' },
});
