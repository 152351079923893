import React, { useCallback } from 'react';
import { View, StyleSheet, Image, Clipboard } from 'react-native';

import { Button, Dropzone, Text, TextInput } from '../../core-ui';
import {
  DateTimePicker,
  RadioGroup,
  Option,
  BrandContactDropdown,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { emailIcon } from '../../../assets';

type Props = {
  name: string;
  venue: string;
  dateTime: Date;
  registrationRequired: boolean;
  registrationDeadline: Date;
  externalLink: string;
  brandBU: string | null;
  image: FileWithPreview | string | null;
  dedicatedPageImage: FileWithPreview | string | null;
  emailDisabled: boolean;
  editable: boolean;
  externalButton: string;
  salesmanAppDeepLink: string;
  setName: (text: string) => void;
  setVenue: (text: string) => void;
  setDateTime: (dateTime: Date) => void;
  setRegistrationRequired: (value: boolean) => void;
  setRegistrationDeadline: (dateTime: Date) => void;
  setExternalLink: (text: string) => void;
  setBrandBU: (value: Option) => void;
  setImage: (image: FileWithPreview) => void;
  setDedicatedPageImage: (image: FileWithPreview) => void;
  onSendEmail: () => void;
  setExternalButton: (value: string) => void;
  setSalesmanAppDeepLink: (value: string) => void;
};

const requiredOptions = [
  {
    value: true,
    label: <Text>{t(['Ya', 'Yes'])}</Text>,
    'data-testid': 'form-event-radio-registration-true',
  },
  {
    value: false,
    label: <Text>{t(['Tidak', 'No'])}</Text>,
    'data-testid': 'form-event-radio-registration-false',
  },
];

const StepSixEvent = (props: Props) => {
  let {
    name,
    venue,
    dateTime,
    registrationRequired,
    registrationDeadline,
    externalLink,
    brandBU,
    image,
    dedicatedPageImage,
    emailDisabled,
    editable,
    externalButton,
    salesmanAppDeepLink,
    setName,
    setVenue,
    setDateTime,
    setRegistrationRequired,
    setRegistrationDeadline,
    setExternalLink,
    setBrandBU,
    setImage,
    setDedicatedPageImage,
    onSendEmail,
    setExternalButton,
    setSalesmanAppDeepLink,
  } = props;

  const onCopyText = useCallback(() => {
    Clipboard.setString(salesmanAppDeepLink);
  }, [salesmanAppDeepLink]);

  return (
    <View style={styles.flex}>
      <TextInput
        data-testid="form-event-name"
        label={t(['Nama Event', 'Event Name'])}
        value={name}
        onChangeText={setName}
        containerStyle={styles.field}
        disabled={!editable}
      />
      <TextInput
        data-testid="form-event-venue"
        label={t(['Lokasi', 'Venue'])}
        value={venue}
        onChangeText={setVenue}
        containerStyle={styles.field}
        disabled={!editable}
      />
      <View style={styles.field}>
        <DateTimePicker
          data-testid="form-event-date"
          type="datetime"
          selectedDate={dateTime}
          onChange={setDateTime}
          label={t(['Tanggal / Waktu', 'Date / Time'])}
          disabled={!editable}
        />
      </View>
      <RadioGroup
        horizontal
        label={t(['Wajib Registrasi', 'Registration Required'])}
        options={requiredOptions}
        optionLabelPlacement="start"
        selectedOption={registrationRequired}
        setSelectedOption={setRegistrationRequired}
        containerStyle={styles.xSmallBottomSpacing}
        disabled={!editable}
      />
      <View style={styles.field}>
        <DateTimePicker
          data-testid="form-event-registration-date"
          label={t(['Tanggal Tutup Registrasi', 'Registration Deadline'])}
          selectedDate={registrationDeadline}
          onChange={setRegistrationDeadline}
          disabled={!editable || !registrationRequired}
        />
      </View>
      <TextInput
        data-testid="form-event-external-link"
        label={t(['Link Eksternal', 'External Link'])}
        value={externalLink}
        onChangeText={setExternalLink}
        containerStyle={styles.field}
        disabled={!editable || !registrationRequired}
      />
      <TextInput
        data-testid="news-external-button"
        value={externalButton}
        onChangeText={setExternalButton}
        label={t(['Tombol Eksternal', 'External Button'])}
        containerStyle={styles.field}
        disabled={!editable || !registrationRequired}
        maxLength={35}
      />
      <View style={[styles.row, styles.field]}>
        <View style={styles.flex}>
          <View style={[styles.row, styles.fieldLabel]}>
            <Text bold style={styles.flex}>
              {t(['Brand', 'Brand'])}
            </Text>
            <Text bold style={[styles.flex, styles.brandSubLabel]}>
              {t(['Principal', 'Principal'])}
            </Text>
          </View>
          <View>
            <View style={styles.flex}>
              <BrandContactDropdown
                isOptional={true}
                data-testid="form-event-principal-dropdown"
                selectedOption={brandBU ?? undefined}
                onSelect={setBrandBU}
                disabled={!editable}
              />
            </View>
          </View>
        </View>
        <Button
          preset="transparent"
          title="mail"
          icon={
            <Image
              source={{ uri: emailIcon }}
              style={[styles.emailIcon, { opacity: emailDisabled ? 0.3 : 1 }]}
              resizeMode="contain"
            />
          }
          onPress={onSendEmail}
          style={styles.emailButton}
          disabled={emailDisabled}
        />
      </View>
      <View style={styles.field}>
        <Text style={styles.fieldLabel}>
          <Text bold>{t(['Gambar', 'Image'])}</Text>
        </Text>
        <View style={styles.imageContainer}>
          <View style={styles.paddingRight}>
            <Text style={styles.fieldLabel}>{t(['Homepage', 'HomePage'])}</Text>
            <Text style={styles.sizeHelper}>
              {t([
                'Size: 136 x 84px (opsional)',
                'Size: 136 x 84px (optional)',
              ])}
            </Text>
            <Dropzone
              data-testid="form-event-image"
              withUploadText
              type="image"
              imageSize={styles.imageRes}
              containerStyle={styles.dropZone}
              source={image}
              getPreview={setImage}
              disabled={!editable}
            />
          </View>
          <View style={styles.paddingLeft}>
            <Text style={styles.fieldLabel}>
              {t(['Dedicated Page', 'Dedicated Page'])}
            </Text>
            <Text style={styles.sizeHelper}>
              {t([
                'Size: 375 x 180px (opsional)',
                'Size: 375 x 180px (optional)',
              ])}
            </Text>
            <Dropzone
              withUploadText
              type="image"
              imageSize={styles.dedicatedImageRes}
              containerStyle={styles.dropZone}
              source={dedicatedPageImage}
              getPreview={setDedicatedPageImage}
              disabled={!editable}
            />
          </View>
        </View>
      </View>
      <TextInput
        label={t([
          'URL Deeplink - Konten Salesman App',
          'URL Deeplink - Salesman App Content',
        ])}
        value={salesmanAppDeepLink}
        onChangeText={setSalesmanAppDeepLink}
        containerStyle={styles.field}
        disabled={true}
      />
      <Button
        onPress={onCopyText}
        style={styles.copyLink}
        title={t(['Salin', 'Copy'])}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: 'row' },
  xSmallBottomSpacing: { marginBottom: spacing.xsmall },
  field: { paddingBottom: spacing.small },
  fieldLabel: { paddingBottom: spacing.xxsmall },
  brandSubLabel: { paddingRight: spacing.medium }, // NOTE: this is === arrow down icon size
  emailIcon: { width: 38, height: 38 },
  emailButton: { marginTop: 23 },
  sizeHelper: { color: colors.text.disabled },
  imageContainer: { flexDirection: 'row', alignContent: 'stretch' },
  paddingLeft: { paddingLeft: 10 },
  paddingRight: { paddingRight: 10 },
  dropZone: { width: 140, height: 120 },
  imageRes: { width: 136, height: 84 },
  dedicatedImageRes: { width: 375, height: 180 },
  copyLink: {
    color: colors.text.link,
    marginTop: 4,
  },
});

export default StepSixEvent;
