import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { logEvent } from 'expo-firebase-analytics';

import { Table, Button } from '../../core-ui';
import {
  HeaderNavigationBar,
  SearchHeader,
  CityDropdown,
  RegionDropdown,
  SearchByText,
  Option,
  ErrorMessage,
  DistrictDropdown,
  VillageDropdown,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { GET_STORES } from '../../graphql/queries';
import {
  Stores,
  StoresVariables,
  Stores_storeSearchAdvance_row,
} from '../../generated/Stores';
import { pageTitle } from '../../constants/pageTitle';
import { OrderByArg } from '../../generated/globalTypes';

const LoyaltyPointStoreList = () => {
  const history = useHistory();
  const [region, setRegion] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [district, setDistrict] = useState<string | null>(null);
  const [village, setVillage] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [storeCode, setStoreCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [orderByType, setOrderByType] = useState('createdAt');
  const [order, setOrder] = useState<OrderByArg>(OrderByArg.asc);
  const [queryVariables, setQueryVariables] = useState<
    Partial<StoresVariables>
  >({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableStructure = useMemo(
    () => ({
      storeName: {
        headerTitle: 'toko',
        dataPath: 'storeManager.storeName',
        orderable: true,
      },
      provinceName: {
        headerTitle: t(['provinsi', 'region']),
        dataPath: 'storeManager.province',
        orderable: true,
      },
      cityName: {
        headerTitle: t(['kota', 'city']),
        dataPath: 'storeManager.city',
        orderable: true,
      },
      point: {
        headerTitle: t(['total poin', 'total point']),
        valueProcessor: (value: string) =>
          t(['{value} poin', '{value} pts'], { value }),
        orderable: true,
      },
      action: {
        noHeaderTitle: true,
        render: ({ id }: Stores_storeSearchAdvance_row) => (
          <Button
            preset="transparent"
            title={t(['lihat rincian', 'view details'])}
            onPress={() => {
              history.push(`/loyalty-point/${id}`);
            }}
          />
        ),
      },
    }),
    [history],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.LoyaltyPointStoreList,
      });
    };
    eventLog();
  }, []);

  useEffect(() => {
    setPage(0);
  }, [queryVariables]);

  const { data, loading, error, refetch } = useQuery<Stores, StoresVariables>(
    GET_STORES,
    {
      variables: {
        pagination: { skip: page * rowsPerPage, first: rowsPerPage },
        orderBy: {
          [orderByType]: order,
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchData = useCallback(
    (_skip?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            ...queryVariables,
            pagination: { skip: page * rowsPerPage, first: rowsPerPage },
            orderBy: {
              [orderByType]: order,
            },
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [page, rowsPerPage, orderByType, order, queryVariables, refetch],
  );

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  const createOnSelect = useCallback(
    <T extends unknown>(
      setter: (value: T) => void,
      ...nullSetter: Array<(value: null) => void>
    ) => (selected: Option | null) => {
      setter((selected?.value ?? null) as T);
      for (let setToNull of nullSetter) {
        setToNull(null);
      }
    },
    [],
  );
  const onSelectRegion = useMemo(() => createOnSelect(setRegion, setCity), [
    createOnSelect,
  ]);
  const onSelectCity = useMemo(() => createOnSelect(setCity), [createOnSelect]);
  const onSelectDistrict = useMemo(() => createOnSelect(setDistrict), [
    createOnSelect,
  ]);
  const onSelectVillage = useMemo(() => createOnSelect(setVillage), [
    createOnSelect,
  ]);
  const onSearch = useCallback(() => {
    setQueryVariables({
      storeName: name,
      storeRegionId: region,
      storeCityId: city,
      storeCode,
      storePhoneNumber: phoneNumber,
      storeDistrictId: district,
      storeUrbanVillageId: village,
    });
  }, [region, city, name, storeCode, phoneNumber, district, village]);
  const onClear = useCallback(() => {
    setRegion(null);
    setCity(null);
    setName('');
    setStoreCode('');
    setPhoneNumber('');
    setDistrict(null);
    setVillage(null);
    setQueryVariables({
      storeName: undefined,
      storeRegionId: undefined,
      storeCityId: undefined,
      storeCode: undefined,
      storePhoneNumber: undefined,
      storeDistrictId: undefined,
      storeUrbanVillageId: undefined,
    });
  }, []);
  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };
  const onRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: string) => {
      // NOTE: this follows the same logic as the Table's onRequest sort to keep the same UX
      if (orderByType === property) {
        if (order === OrderByArg.asc) {
          setOrder(OrderByArg.desc);
        } else {
          setOrderByType('createdAt');
          setOrder(OrderByArg.asc);
        }
      } else {
        setOrder(OrderByArg.asc);
        // TODO: sort by total purchase when BE supports
        setOrderByType(property);
      }
    },
    [orderByType, order],
  );
  const handlePhone = (
    phone: string,
    setStateFunction: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const phoneRegex = /^[0-9]*$/;

    if (phoneRegex.test(phone)) {
      setStateFunction(phone);
    }
  };

  let leftSide = () => {
    return (
      <>
        <View style={styles.separator}>
          <SearchByText
            label={t(['Cari Toko', 'Search by Toko'])}
            value={name}
            setValue={setName}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            label={t(['Cari Kode Toko', 'Search by Kode Toko'])}
            value={storeCode}
            setValue={setStoreCode}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            label={t(['Cari No HP', 'Search by Phone'])}
            value={phoneNumber}
            setValue={(value) => handlePhone(value, setPhoneNumber)}
          />
        </View>
      </>
    );
  };

  let rightSide = () => {
    return (
      <>
        <View style={styles.separator}>
          <RegionDropdown
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={region ?? undefined}
            onSelect={onSelectRegion}
          />
        </View>
        <View style={styles.separator}>
          <CityDropdown
            type="side"
            provincesID={region ? [region] : []}
            title={t(['Cari Kota', 'Search by City'])}
            selectedOption={city ?? undefined}
            onSelect={onSelectCity}
          />
        </View>
        <View style={styles.separator}>
          <DistrictDropdown
            type="side"
            cityID={city ?? undefined}
            title={t(['Cari Kecamatan', 'Search by District'])}
            selectedOption={district ?? undefined}
            onSelect={onSelectDistrict}
          />
        </View>
        <View style={styles.separator}>
          <VillageDropdown
            type="side"
            districtID={district ?? undefined}
            title={t(['Cari Kelurahan', 'Search by Urban Village'])}
            selectedOption={village ?? undefined}
            onSelect={onSelectVillage}
          />
        </View>
      </>
    );
  };

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <SearchHeader
        title={t(['Hadiah Promosi Lumpsum', 'Promotion Lumpsum Reward'])}
        onSearchPress={onSearch}
        onClearPress={onClear}
        containerStyle={styles.searchSection}
      >
        {leftSide()}
        {rightSide()}
      </SearchHeader>
      <Table
        data-testid="store-list-table"
        data={data?.storeSearchAdvance.row ?? []}
        dataCount={data?.storeSearchAdvance.total ?? 0}
        error={!!error}
        loading={loading}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderByType}
        structure={tableStructure}
        onRequestSort={onRequestSort}
        onChangePage={setPage}
        onChangeRowsPerPage={onRowsChange}
      />
      {!!error && (
        <ErrorMessage
          error={error}
          action={t([
            'mengambil data loyalti toko',
            'retrieve the store loyalty data',
          ])}
          onPress={refetchCurrentPage}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  searchSection: {
    paddingBottom: spacing.medium,
    marginBottom: spacing.medium,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xxsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
});

export default LoyaltyPointStoreList;
