import React, { memo } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { ApolloError } from 'apollo-client';

import { errorIcon } from '../../assets';
import { Text, Button } from '../core-ui';
import { spacing } from '../constants/theme';

import Modal from './Modal';

type Props = {
  'data-testid'?: string;
  action?: string;
  error?: ApolloError;
  open: boolean;
  onClose: () => void;
  message?: string;
  buttonTitle?: string;
};

const ErrorModal = memo((props: Props) => {
  let {
    action,
    error,
    open,
    onClose,
    message,
    buttonTitle = t(['Tutup', 'Close']),
  } = props;

  let messageToDisplay = '';
  if (error?.networkError?.name === 'TypeError' && !navigator.onLine) {
    // NOTE: "ServerError" with invalid GraphQL Request, "TypeError" with wifi off, unsure about other cases
    // NOTE: network error takes precedence
    messageToDisplay = t([
      'Oops, terjadi kesalahan jaringan. Mohon periksa jaringan Anda dan coba lagi.',
      'Oops, a network error occurred. Please check your connection and try again.',
    ]);
  } else if (message) {
    // NOTE: custom error message is next
    messageToDisplay = message;
  } else {
    // NOTE: otherwise use default error message with optional action information
    messageToDisplay = t(
      [
        'Oops, terjadi kesalahan{prefix}{action}. Mohon coba lagi nanti.',
        'Oops, something went wrong{prefix}{action}. Please try again later.',
      ],
      {
        prefix: !!action ? t([' saat ', ' while trying to ']) : '',
        action,
      },
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <View data-testid={`${props['data-testid']}`} style={styles.center}>
        <Image source={errorIcon} style={styles.icon} />
        <Text style={styles.message}>{messageToDisplay}</Text>
        <Button
          data-testid={`${props['data-testid']}-close`}
          preset="secondary"
          title={buttonTitle}
          onPress={onClose}
        />
      </View>
    </Modal>
  );
});

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  message: {
    textAlign: 'center',
    marginVertical: spacing.small,
  },
  icon: { width: 56, height: 50 },
});

export default ErrorModal;
