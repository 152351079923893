import React, { memo, useState, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  Table as MTable,
  TableContainer,
  IconButton,
  makeStyles,
  TablePaginationProps,
  ButtonBase,
} from '@material-ui/core';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import RightIcon from '@material-ui/icons/ChevronRight';

import { colors, spacing } from '../constants/theme';
import { Order } from '../../../helpers';
import { Dropdown } from '../components';
import { Props } from '../../../core-ui/table/Table';
import TableHead from '../../../core-ui/table/TableHead';
import TableBody from '../../../core-ui/table/TableBody';
import TableFooter from '../../../core-ui/table/TableFooter';

import Text from './Text';

const useStyles = makeStyles({
  table: { borderCollapse: 'separate', borderSpacing: `0 ${spacing.xxs}px` },
  container: { width: '100%', overflowX: 'scroll' },
  cell: {
    borderWidth: 0,
    padding: `${spacing.m}px ${spacing.l}px`,
    'tfoot &': { padding: `${spacing.xs}px 0 0 0`, height: 52 },
    'tbody &:last-child': {
      borderRight: `1px solid ${colors.table.row.border}`,
    },
    'tbody &:only-child': { borderRightWidth: 0 }, // NOTE: this is for empty table
    'thead &:last-child': {
      // NOTE: this is necessary so the table doesn't become scrollable by 1px difference
      borderRight: `1px solid transparent`,
    },
    'tbody &': {
      borderColor: colors.table.row.border,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: 'solid',
    },
  },
  row: { backgroundColor: colors.table.row.background },
  caption: { fontFamily: 'Inter', fontSize: 14 },
});

type TablePaginationSelectProps = {
  rowsPerPage?: number;
  onChange: (rows: number) => void;
};
type TablePaginationPageProps = {
  page: number;
  selected?: boolean;
  noPadding?: boolean;
  onPress: () => void;
};
type TablePaginationPageListProps = {
  page: number;
  lastPage: number;
  onPress: (page: number) => void;
};

const TablePaginationSelect = memo(
  ({ rowsPerPage, onChange }: TablePaginationSelectProps) => {
    const options = useMemo(
      () => [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '20', value: 20 },
      ],
      [],
    );
    return (
      <Dropdown
        options={options}
        value={rowsPerPage ?? null}
        onChange={onChange}
        containerStyle={styles.dropdown}
      />
    );
  },
);

const TablePaginationPage = memo(
  ({ page, selected, noPadding, onPress }: TablePaginationPageProps) => (
    <View style={!noPadding && styles.pageButtonPadding}>
      <ButtonBase onClick={onPress}>
        <View
          style={[styles.pageButton, selected && styles.pageButtonSelected]}
        >
          <Text color={selected ? 'blue' : 'black'}>{page}</Text>
        </View>
      </ButtonBase>
    </View>
  ),
);

const TablePaginationPageList = memo(
  ({ page, lastPage, onPress }: TablePaginationPageListProps) => {
    const buildPageArray = useCallback(
      (start: number, end: number, lastNoPadding?: boolean) => {
        let pages = [];
        for (let p = start; p <= end; p++) {
          pages.push(
            <TablePaginationPage
              key={p}
              page={p + 1}
              selected={page === p}
              onPress={() => onPress(p)}
              noPadding={p === end && lastNoPadding}
            />,
          );
        }
        return pages;
      },
      [page, onPress],
    );

    const pageList = useMemo(() => {
      // case 1: no more than 5 pages (1,2,3,4,5)
      if (lastPage <= 4) {
        return buildPageArray(0, lastPage, true);
      }
      // case 2: more than 5 pages, page 1 ~ 3 (1,2,3,4,...,n)
      if (page < 3) {
        let pages = buildPageArray(0, 3);
        pages.push(
          <Text style={styles.pageButtonEllipsis} key="right...">
            ...
          </Text>,
        );
        pages.push(
          <TablePaginationPage
            key={lastPage}
            page={lastPage + 1}
            selected={page === lastPage}
            onPress={() => onPress(lastPage)}
          />,
        );
        return pages;
      }
      // case 3: more than 5 pages, page 4 ~ last-3 (1,...,p-1,p,p+1,...,n)
      if (page >= 3 && page <= lastPage - 3) {
        let pages = [
          <TablePaginationPage
            key={0}
            page={1}
            selected={page === 0}
            onPress={() => onPress(0)}
          />,
        ];
        pages.push(
          <Text style={styles.pageButtonEllipsis} key="left...">
            ...
          </Text>,
        );
        pages.push(...buildPageArray(page - 1, page + 1));
        pages.push(
          <Text style={styles.pageButtonEllipsis} key="right...">
            ...
          </Text>,
        );
        pages.push(
          <TablePaginationPage
            noPadding
            key={lastPage}
            page={lastPage + 1}
            selected={page === lastPage}
            onPress={() => onPress(lastPage)}
          />,
        );
        return pages;
      }
      // case 4: more than 5 pages, last-2 ~ last (1,...,n-3,n-2,n-1,n)
      if (page > lastPage - 3) {
        let pages = [
          <TablePaginationPage
            key={0}
            page={1}
            selected={page === 0}
            onPress={() => onPress(0)}
          />,
        ];
        pages.push(
          <Text style={styles.pageButtonEllipsis} key="left...">
            ...
          </Text>,
        );
        pages.push(...buildPageArray(lastPage - 3, lastPage, true));
        return pages;
      }
    }, [page, lastPage, buildPageArray, onPress]);

    return <>{pageList}</>;
  },
);

const TablePaginationActions: TablePaginationProps['ActionsComponent'] = memo(
  (props) => {
    const { count, page, rowsPerPage, onChangePage } = props;
    const onPrevPress = useCallback(() => {
      onChangePage(null, page - 1);
    }, [page, onChangePage]);
    const onNextPress = useCallback(() => {
      onChangePage(null, page + 1);
    }, [page, onChangePage]);
    const onPagePress = useCallback(
      (newPage: number) => {
        onChangePage(null, newPage);
      },
      [onChangePage],
    );
    const onSelectPage = useCallback(
      (value: number) => {
        onChangePage(null, value);
      },
      [onChangePage],
    );

    const lastPage = useMemo(() => Math.ceil(count / rowsPerPage) - 1, [
      count,
      rowsPerPage,
    ]);
    const pageOptions = useMemo(() => {
      let options = [{ label: '1', value: 0 }];
      for (let p = 1; p <= lastPage; p++) {
        options.push({ label: (p + 1).toString(), value: p });
      }
      return options;
    }, [lastPage]);

    return (
      <View style={styles.actionRow} testID="paginationAction">
        <IconButton disabled={page === 0} onClick={onPrevPress}>
          <LeftIcon htmlColor={colors.icon.blue} />
        </IconButton>
        <TablePaginationPageList
          page={page}
          lastPage={lastPage}
          onPress={onPagePress}
        />
        <IconButton disabled={page >= lastPage} onClick={onNextPress}>
          <RightIcon htmlColor={colors.icon.blue} />
        </IconButton>
        <Text>{t(['Pindah ke Halaman', 'Go to Page'])} </Text>
        <Dropdown
          value={page}
          options={pageOptions}
          onChange={onSelectPage}
          containerStyle={styles.dropdown}
        />
      </View>
    );
  },
);

const Table = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ObjectOf<any>, // NOTE: the shape of the data, ObjectOf<unknown> doesn't work with interfaces from codegen
  U extends { [key in keyof T | string]: unknown } // NOTE: the shape of the flattened data
>(
  props: Omit<
    Props<T, U>,
    'withSeparator' | 'disablePadding' | 'onChangeRowsPerPage'
  > & {
    onChangeRowsPerPage: (rows: number) => void;
  },
) => {
  let {
    data,
    dataCount,
    error,
    loading,
    emptyPlaceholder = t(['Tidak ada data', 'No data available']),
    stickyHeader,
    structure,
    rowsPerPage,
    page,
    onRequestSort,
    onChangeRowsPerPage,
    onChangePage,
    order: orderProp,
    orderBy: orderByProp,
  } = props;
  const { table, container, cell, row, caption } = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const containerClasses = useMemo(() => ({ root: container }), [container]);
  const tableClasses = useMemo(() => ({ root: table }), [table]);
  const rowClasses = useMemo(() => ({ root: row }), [row]);
  const cellClasses = useMemo(() => ({ root: cell }), [cell]);

  const handleRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: string) => {
      if (orderBy === property) {
        // NOTE: currently selected order property

        if (order === 'asc') {
          // NOTE: if currently in ascending, change to descending
          setOrder('desc');
        } else {
          // NOTE: otherwise, reset the order property to remove ordering
          setOrderBy(null);
        }
      } else {
        // NOTE: newly selected order property
        setOrder('asc');
        setOrderBy(property);
      }
    },
    [orderBy, order],
  );

  const SelectInputComponent = useCallback(
    () => (
      <TablePaginationSelect
        rowsPerPage={rowsPerPage}
        onChange={onChangeRowsPerPage}
      />
    ),
    [rowsPerPage, onChangeRowsPerPage],
  );

  const paginationProps = useMemo(
    () => ({
      classes: { caption },
      labelRowsPerPage: t(['Baris per Halaman', 'Rows per Page']),
      labelDisplayedRows: ((params) =>
        t(['{from}-{to} dari {count}', '{from}-{to} of {count}'], {
          ...params,
        })) as TablePaginationProps['labelDisplayedRows'],
      SelectProps: { inputComponent: SelectInputComponent },
      ActionsComponent: TablePaginationActions,
    }),
    [caption, SelectInputComponent],
  );

  return (
    <View>
      <TableContainer classes={containerClasses}>
        <MTable
          stickyHeader={stickyHeader}
          size="small"
          data-testid={props['data-testid']}
          classes={tableClasses}
        >
          <TableHead
            noUpperCase
            data-testid={`${props['data-testid']}-head`}
            data={data}
            structure={structure}
            order={orderProp ?? order}
            orderBy={orderByProp ?? orderBy}
            cellClasses={cellClasses}
            TextComponent={Text}
            onRequestSort={onRequestSort ?? handleRequestSort}
          />
          <TableBody
            data-testid={`${props['data-testid']}-body`}
            data={data}
            loading={loading}
            error={error}
            structure={structure}
            order={order}
            orderBy={orderBy}
            cellClasses={cellClasses}
            rowClasses={rowClasses}
            TextComponent={Text}
            emptyPlaceholder={emptyPlaceholder}
          />
        </MTable>
      </TableContainer>
      <TableContainer>
        <MTable>
          <TableFooter
            page={page}
            data-testid={`${props['data-testid']}-footer`}
            onChangePage={onChangePage || (() => {})}
            count={dataCount}
            rowsPerPage={rowsPerPage}
            cellClasses={cellClasses}
            disableLoadMore={!onChangePage}
            paginationProps={paginationProps}
          />
        </MTable>
      </TableContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  actionRow: { flexDirection: 'row', alignItems: 'center' },
  pageButton: {
    width: 42,
    height: 42,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.table.pagination.background,
    borderColor: colors.table.pagination.border.primary,
    borderWidth: 1,
    borderRadius: 2,
  },
  pageButtonSelected: { borderColor: colors.table.pagination.border.selected },
  pageButtonEllipsis: {
    width: 42,
    textAlign: 'center',
    paddingRight: spacing.xxs,
  },
  pageButtonPadding: { paddingRight: spacing.xxs },
  dropdown: { width: 85 },
});

export default Table;
