import React, { useState, useCallback, useMemo } from 'react';
import { View, Image, StyleSheet, LayoutChangeEvent } from 'react-native';
import { InputAdornment } from '@material-ui/core';

import { logo } from '../../../assets';
import { Text, TextInput, Button, Dropzone } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { FileWithPreview } from '../../core-ui/Dropzone';

type Props = {
  // NOTE: common props
  editable?: boolean;

  // NOTE: admin facing props
  image: FileWithPreview | string | null;
  competitionName: string;
  prize: string;
  prizeValue: string;
  toEnter: string;
  setImage?: (preview: FileWithPreview) => void;
  setCompetitionName?: (value: string) => void;
  setPrize?: (value: string) => void;
  setPrizeValue?: (value: string) => void;
  setToEnter?: (value: string) => void;

  // NOTE: toko facing props
  participantName?: string;
  storeName?: string;
  phoneNumber?: string;
  submitFormLoading?: boolean;
  setParticipantName?: (value: string) => void;
  setStoreName?: (value: string) => void;
  setPhoneNumber?: (value: string) => void;
  onSubmitForm?: () => void;
};

const emptyFn = () => {};

const CompetitionOnlineForm = (props: Props) => {
  let {
    editable,
    image,
    competitionName,
    prize,
    prizeValue,
    toEnter,
    participantName,
    storeName,
    phoneNumber,
    submitFormLoading,
    setImage,
    setCompetitionName,
    setPrize,
    setPrizeValue,
    setToEnter,
    setParticipantName,
    setStoreName,
    setPhoneNumber,
    onSubmitForm = emptyFn,
  } = props;

  const [width, setWidth] = useState(0);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      let { width: layoutWidth } = event.nativeEvent.layout;
      if (layoutWidth !== width) {
        setWidth(layoutWidth);
      }
    },
    [width, setWidth],
  );

  const registerDisabled = useMemo(
    () => !participantName || !storeName || !phoneNumber,
    [participantName, storeName, phoneNumber],
  );

  return (
    <>
      <View style={styles.border}>
        <View style={styles.content}>
          <View onLayout={onLayout}>
            <View style={[styles.row, styles.header]}>
              <Image source={logo} style={styles.logo} />
              <Text bold size="large" color="link" style={styles.headerText}>
                {t(['Menangkan Kompetisi!', 'Competition to Win!'])}
              </Text>
            </View>
            <View style={styles.field}>
              {typeof image === 'string' && !setImage ? ( // NOTE: assuming the data from BE is string
                <View style={styles.pseudoDropzone}>
                  <Image
                    resizeMode="contain"
                    source={{ uri: image }}
                    style={[styles.image, { width }]}
                  />
                </View>
              ) : (
                <Dropzone
                  data-testid="form-olform-image"
                  source={image}
                  containerStyle={[styles.image, { width }]}
                  getPreview={setImage}
                  disabled={!editable}
                />
              )}
            </View>
            {setCompetitionName ? (
              <TextInput
                data-testid="form-olform-name"
                label={t(['Kompetisi', 'Competition'])}
                value={competitionName}
                onChangeText={setCompetitionName}
                containerStyle={styles.field}
                disabled={!editable}
              />
            ) : (
              <View style={styles.field}>
                <Text bold style={styles.label}>
                  {t(['Kompetisi', 'Competition'])}
                </Text>
                <Text style={styles.pseudoTextInput}>{competitionName}</Text>
              </View>
            )}
            {setPrize ? (
              <TextInput
                data-testid="form-olform-prize"
                label={t(['Hadiah', 'Prize'])}
                value={prize}
                onChangeText={setPrize}
                containerStyle={styles.field}
                disabled={!editable}
              />
            ) : (
              <View style={styles.field}>
                <Text bold style={styles.label}>
                  {t(['Hadiah', 'Prize'])}
                </Text>
                <Text style={styles.pseudoTextInput}>{prize}</Text>
              </View>
            )}
            <View style={styles.row}>
              <View style={styles.flex} />
              <View style={styles.flex}>
                {setPrizeValue ? (
                  <TextInput
                    data-testid="form-olform-prize-value"
                    label={t(['Nilai Hadiah', 'Prize Value'])}
                    value={prizeValue}
                    onChangeText={setPrizeValue}
                    disabled={!editable}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Text>Rp</Text>
                        </InputAdornment>
                      ),
                    }}
                    type="number"
                  />
                ) : (
                  <>
                    <Text bold style={styles.label}>
                      {t(['Nilai Hadiah', 'Prize Value'])}
                    </Text>
                    <Text style={styles.pseudoTextInput}>{prizeValue}</Text>
                  </>
                )}
              </View>
            </View>
            <Text bold size="large" color="link" style={styles.enterHere}>
              {t(['Bergabung di Sini', 'Enter Here'])}
            </Text>
            <TextInput
              data-testid="form-olform-user-name"
              label={t(['Nama Anda', 'Your Name'])}
              value={participantName ?? ''}
              onChangeText={setParticipantName ?? emptyFn}
              containerStyle={styles.field}
              disabled={!setParticipantName}
            />
            <View style={[styles.row, styles.field]}>
              <View style={styles.storeName}>
                <TextInput
                  data-testid="form-olform-user-store"
                  label={t(['Toko Anda', 'Your Toko'])}
                  value={storeName ?? ''}
                  onChangeText={setStoreName ?? emptyFn}
                  disabled={!setStoreName}
                />
              </View>
              <View style={styles.flex}>
                <TextInput
                  data-testid="form-olform-user-account"
                  label={t(['Nomor Telepon', 'Phone Number'])}
                  value={phoneNumber ?? ''}
                  onChangeText={setPhoneNumber ?? emptyFn}
                  disabled={!setPhoneNumber}
                />
              </View>
            </View>
            {setToEnter ? (
              <TextInput
                data-testid="form-olform-terms"
                label={t(['Syarat Bergabung', 'To Enter'])}
                value={toEnter}
                onChangeText={setToEnter}
                containerStyle={styles.field}
                disabled={!editable}
              />
            ) : (
              <View style={styles.field}>
                <Text bold style={styles.label}>
                  {t(['Syarat Bergabung', 'To Enter'])}
                </Text>
                <Text style={styles.pseudoTextInput}>{toEnter}</Text>
              </View>
            )}
            <View style={styles.row}>
              <Text bold>
                {t([
                  'Pendaftaran akan divalidasi dengan resi pembayaran terhadap order yang memenuhi syarat bergabung kompetisi.',
                  'Your entry will be validated on receipt of a confirmed payment for an order that qualifies for this competition.',
                ])}
              </Text>
              <Button
                title={t(['Daftar', 'Register'])}
                onPress={onSubmitForm}
                disabled={registerDisabled}
                isLoading={submitFormLoading}
              />
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.border, styles.footer]}>
        <Text color="footer" size="xsmall">
          {t(
            [
              'Tim layanan pelanggan DBO: {wa}',
              'DBO customer service team:  {wa}',
            ],
            { wa: '000 000 0000' }, // TODO: get DBO wa num
          )}
        </Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  border: { borderWidth: 1, borderColor: colors.border.primary },
  content: { padding: spacing.large },
  flex: { flex: 1 },
  row: { flexDirection: 'row' },
  header: { paddingBottom: spacing.large, alignItems: 'center' },
  headerText: { paddingLeft: spacing.medium },
  logo: { width: 80, height: 80 },
  field: { paddingBottom: spacing.small },
  enterHere: { paddingTop: spacing.medium, paddingBottom: spacing.small },
  storeName: { flex: 1.5, paddingRight: spacing.xsmall },
  label: { paddingBottom: spacing.xsmall },
  image: { height: 180 },
  pseudoTextInput: {
    flex: 1,
    padding: spacing.xsmall,
    borderWidth: 1,
    borderColor: colors.border.primary,
    borderRadius: 4,
  },
  pseudoDropzone: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    borderRadius: 4,
  },
  footer: { padding: spacing.small, alignItems: 'center' },
});

export default CompetitionOnlineForm;
