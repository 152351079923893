import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, StyleSheet, Clipboard } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { Dropdown, Option, StepHeader, ErrorModal } from '../../components';
import { spacing, colors } from '../../constants/theme';
import { Button, Text } from '../../core-ui';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { FormType } from '../../components/dropdowns/FormLinkDropdown';
import {
  CREATE_COMPETITION_ONLINE_FORM,
  UPLOAD,
  UPDATE_COMPETITION_ONLINE_FORM,
  CREATE_EVENT_ONLINE_FORM,
  UPDATE_EVENT_ONLINE_FORM,
} from '../../graphql/mutations';
import { Upload, UploadVariables } from '../../generated/Upload';
import {
  CreateCompetitionOnlineForm,
  CreateCompetitionOnlineFormVariables,
} from '../../generated/CreateCompetitionOnlineForm';
import {
  UpdateCompetitionOnlineForm,
  UpdateCompetitionOnlineFormVariables,
} from '../../generated/UpdateCompetitionOnlineForm';
import {
  CreateEventOnlineForm,
  CreateEventOnlineFormVariables,
} from '../../generated/CreateEventOnlineForm';
import {
  UpdateEventOnlineForm,
  UpdateEventOnlineFormVariables,
} from '../../generated/UpdateEventOnlineForm';
import { CompetitionFormFragment } from '../../generated/CompetitionFormFragment';
import { EventFormFragment } from '../../generated/EventFormFragment';
import { FolderType } from '../../generated/globalTypes';

import CompetitionOnlineForm from './CompetitionOnlineForm';
import EventOnlineForm from './EventOnlineForm';

type Props = {
  editCompetition: CompetitionFormFragment | null;
  editEvent: EventFormFragment | null;
  // TODO: editInformation
  // editInformation?: ObjectOf<unknown>;
  selectedFormType: FormType | null;
  setSelectedFormType: (value: Option | null) => void;
  formLink: string;
  setFormLink: (value: string) => void;
  onSubmitCompleted: () => void;
};

export default function CreateOnlineForm(props: Props) {
  let {
    editCompetition,
    editEvent,
    // editInformation,
    selectedFormType,
    setSelectedFormType,
    formLink,
    setFormLink,
    onSubmitCompleted,
  } = props;
  // NOTE: state for Competition and Event online forms
  let [editable, setEditable] = useState(true);
  const [name, setName] = useState('');
  // NOTE: state for Competition online form
  const [image, setImage] = useState<FileWithPreview | string | null>(null);
  const [prize, setPrize] = useState('');
  const [prizeValue, setPrizeValue] = useState('');
  const [toEnter, setToEnter] = useState('');
  // NOTE: state for Event online form
  const [venue, setVenue] = useState('');
  const [eventDate, setEventDate] = useState(new Date());
  const [maxParticipant, setMaxParticipant] = useState('');
  const [location, setLocation] = useState('');
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );
  const onUploadError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);
  const onCreateError = useMemo(() => {
    return openErrorModal(
      t(['membuat data formulir online', 'create online form data']),
    );
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(
      t(['mengubah data formulir online', 'update online form data']),
    );
  }, [openErrorModal]);

  useEffect(() => {
    if (editCompetition) {
      setName(editCompetition.name);
      setImage(editCompetition.image);
      setPrize(editCompetition.prize ?? '');
      setPrizeValue(editCompetition.prizeValue.toString());
      setToEnter(editCompetition.terms ?? '');
    } else {
      if (selectedFormType === 'event') {
        setName('');
        setImage('');
        setPrize('');
        setPrizeValue('');
        setToEnter('');
      }
    }
  }, [editCompetition, selectedFormType]);

  useEffect(() => {
    if (editEvent) {
      setName(editEvent.name);
      setVenue(editEvent.venue);
      setEventDate(new Date(editEvent.event?.eventDate ?? null));
      setMaxParticipant((editEvent.maxParticipant ?? 0).toString());
      setLocation(editEvent.address || '');
      setLatitude(Number(editEvent.latitude));
      setLongitude(Number(editEvent.longitude));
    } else {
      if (selectedFormType === 'competition') {
        setName('');
        setVenue('');
        setEventDate(new Date());
        setMaxParticipant('');
        setLocation('');
        setLatitude(null);
        setLongitude(null);
      }
    }
  }, [editEvent, selectedFormType]);

  // TODO: set state with editInformation values
  // useEffect(() => {
  //   if (editInformation) {
  //   }
  // }, [editInformation]);

  const submitTitle = useMemo(() => {
    return !!editEvent || !!editCompetition
      ? t(['Kirim', 'Submit'])
      : t(['Buat Tautan', 'Create Link']);
  }, [editEvent, editCompetition]);

  const [uploadFile, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, { onError: onUploadError });

  const [
    createCompetitionOnlineForm,
    { loading: createCompLoading },
  ] = useMutation<
    CreateCompetitionOnlineForm,
    CreateCompetitionOnlineFormVariables
  >(CREATE_COMPETITION_ONLINE_FORM, {
    onCompleted: onSubmitCompleted,
    onError: onCreateError,
  });

  const [
    updateCompetitionOnlineForm,
    { loading: updateCompLoading },
  ] = useMutation<
    UpdateCompetitionOnlineForm,
    UpdateCompetitionOnlineFormVariables
  >(UPDATE_COMPETITION_ONLINE_FORM, {
    onCompleted: onSubmitCompleted,
    onError: onUpdateError,
  });

  const [createEventOnlineForm, { loading: createEvtLoading }] = useMutation<
    CreateEventOnlineForm,
    CreateEventOnlineFormVariables
  >(CREATE_EVENT_ONLINE_FORM, {
    onCompleted: onSubmitCompleted,
    onError: onCreateError,
  });

  const [updateEventOnlineForm, { loading: updateEvtLoading }] = useMutation<
    UpdateEventOnlineForm,
    UpdateEventOnlineFormVariables
  >(UPDATE_EVENT_ONLINE_FORM, {
    onCompleted: onSubmitCompleted,
    onError: onUpdateError,
  });

  const clearForm = useCallback(() => {
    setName('');
    setImage(null);
    setPrize('');
    setPrizeValue('');
    setToEnter('');
    setVenue('');
    setEventDate(new Date());
    setMaxParticipant('');
    setLocation('');
    setLatitude(null);
    setLongitude(null);
    setFormLink('');
  }, [
    setName,
    setImage,
    setPrize,
    setPrizeValue,
    setToEnter,
    setVenue,
    setEventDate,
    setMaxParticipant,
    setLocation,
    setLatitude,
    setLongitude,
    setFormLink,
  ]);

  const submitCompetitionOnlineForm = useCallback(async () => {
    let imageLink;
    if (image) {
      if (typeof image === 'string') {
        // NOTE: image already exists in BE, return that link
        imageLink = image;
      } else {
        // NOTE: need to upload a new image, return the result link
        const result = await uploadFile({
          variables: { file: image.file, folder: FolderType.COMPETITION },
        });
        imageLink = result?.data?.upload.link;
      }
    }
    const numPrizeValue = Number(prizeValue.replace(/\D/, ''));
    const commonVariables = {
      competitionName: name,
      image: imageLink,
      prize,
      prizeValue: numPrizeValue,
      terms: toEnter,
    };

    if (editCompetition) {
      updateCompetitionOnlineForm({
        variables: {
          formID: editCompetition.id,
          link: editCompetition.link,
          ...commonVariables,
        },
      });
    } else {
      let result = await createCompetitionOnlineForm({
        variables: commonVariables,
      });
      if (result?.data) {
        setFormLink(result.data.createOnlineFormCompetition.link);
      }
    }
  }, [
    image,
    name,
    prize,
    prizeValue,
    toEnter,
    editCompetition,
    uploadFile,
    createCompetitionOnlineForm,
    updateCompetitionOnlineForm,
    setFormLink,
  ]);

  const submitEventOnlineForm = useCallback(async () => {
    const numMaxParticipant = Number(maxParticipant.replace(/\D/, ''));
    const commonVariables = {
      eventName: name,
      venue,
      maxParticipant: numMaxParticipant,
      address: location,
      latitude: latitude ? latitude.toString() : null,
      longitude: longitude ? longitude.toString() : null,
      eventDate,
    };

    if (editEvent) {
      updateEventOnlineForm({
        variables: {
          formID: editEvent.id,
          link: editEvent.link,
          ...commonVariables,
        },
      });
    } else {
      let result = await createEventOnlineForm({
        variables: commonVariables,
      });
      if (result?.data) {
        setFormLink(result.data.createOnlineFormEvent.link);
      }
    }
  }, [
    name,
    venue,
    eventDate,
    maxParticipant,
    location,
    latitude,
    longitude,
    editEvent,
    createEventOnlineForm,
    updateEventOnlineForm,
    setFormLink,
  ]);

  const submitInformationOnlineForm = useCallback(async () => {
    // TODO: connect BE for Information Request online form
  }, []);

  let onCreateLink = useMemo(() => {
    switch (selectedFormType) {
      case 'event': {
        return submitEventOnlineForm;
      }
      case 'competition': {
        return submitCompetitionOnlineForm;
      }
      case 'information': {
        return submitInformationOnlineForm;
      }
      default: {
        return () => {};
      }
    }
  }, [
    selectedFormType,
    submitEventOnlineForm,
    submitCompetitionOnlineForm,
    submitInformationOnlineForm,
  ]);

  let onEdit = () => {
    setEditable(!editable);
    window.scrollTo({ top: 170 });
  };

  let formTemplate = null;
  let submitDisabled = useMemo(() => {
    switch (selectedFormType) {
      case 'event': {
        return (
          !name ||
          !venue ||
          !maxParticipant ||
          !location ||
          !latitude ||
          !longitude
        );
      }
      case 'competition': {
        return !image || !name || !prize || !prizeValue || !toEnter;
      }
      default: {
        return true;
      }
    }
  }, [
    selectedFormType,
    name,
    venue,
    maxParticipant,
    location,
    latitude,
    longitude,
    image,
    prize,
    prizeValue,
    toEnter,
  ]);
  switch (selectedFormType) {
    case 'event': {
      formTemplate = (
        <EventOnlineForm
          editable={editable}
          eventName={name}
          venue={venue}
          eventDate={eventDate}
          maxParticipant={maxParticipant}
          latitude={latitude}
          longitude={longitude}
          location={location}
          setEventName={setName}
          setVenue={setVenue}
          setMaxParticipant={setMaxParticipant}
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setLocation={setLocation}
        />
      );
      break;
    }
    case 'competition': {
      formTemplate = (
        <CompetitionOnlineForm
          editable={editable}
          competitionName={name}
          image={image}
          prize={prize}
          prizeValue={prizeValue}
          toEnter={toEnter}
          setCompetitionName={setName}
          setImage={setImage}
          setPrize={setPrize}
          setPrizeValue={setPrizeValue}
          setToEnter={setToEnter}
        />
      );
      break;
    }
    case 'information': {
      // TODO: render information request online form template
      formTemplate = null;
      break;
    }
  }

  return (
    <>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <View style={styles.row}>
        <View style={[styles.flex, styles.stepSpacing]}>
          <StepHeader
            step={1}
            title={t(['Pilih Tipe Formulir', 'Select Form Type'])}
            style={styles.stepHeader}
          />
          <Dropdown
            data-testid="form-olform-type"
            title={t(['Pilih tipe formulir', 'Select form type'])}
            options={[
              { label: t(['Event', 'Event']), value: 'event' },
              { label: t(['Kompetisi', 'Competition']), value: 'competition' },
              {
                label: t(['Permintaan Informasi', 'Information Request']),
                value: 'information',
              },
            ]}
            selectedOption={selectedFormType ?? undefined}
            onSelect={setSelectedFormType}
            disabled={!editable}
          />
        </View>
        <View style={[styles.flex, styles.stepSpacing]}>
          <StepHeader
            step={2}
            title={t(['Lengkapi Formulir', 'Complete Form Data'])}
            style={styles.stepHeader}
          />
          {formTemplate}
        </View>
        <View style={styles.flex}>
          <StepHeader
            step={3}
            title={t(['Tautan Formulir', 'Form Link'])}
            style={styles.stepHeader}
          />
          <Text style={styles.formLink}>{formLink}</Text>
          <Button
            data-testid="form-olform-copy"
            preset="transparent"
            title={t(['Salin tautan', 'Copy link'])}
            onPress={() => Clipboard.setString(formLink)}
            style={styles.copyLink}
          />
        </View>
      </View>
      <View style={[styles.row, styles.buttonWrapper]}>
        <Button
          data-testid="form-olform-submit"
          title={submitTitle}
          onPress={onCreateLink}
          style={styles.buttonSpacing}
          disabled={submitDisabled}
          isLoading={
            uploadLoading ||
            createCompLoading ||
            updateCompLoading ||
            createEvtLoading ||
            updateEvtLoading
          }
        />
        <Button
          data-testid="form-olform-edit-cancel"
          title={editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])}
          onPress={onEdit}
          preset="secondary"
          style={styles.buttonSpacing}
        />
        <Button
          data-testid="form-olform-clear"
          title={t(['Bersihkan', 'Clear'])}
          onPress={clearForm}
          preset="secondary"
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  stepSpacing: {
    marginRight: spacing.xlarge,
  },
  stepHeader: {
    marginBottom: spacing.small,
  },
  row: {
    flexDirection: 'row',
  },
  formLink: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    padding: spacing.xsmall,
    minHeight: 36,
  },
  copyLink: {
    marginTop: spacing.xsmall,
    alignSelf: 'flex-end',
    width: 'fit-content',
  },
  buttonWrapper: {
    justifyContent: 'center',
    marginTop: spacing.xlarge,
  },
  buttonSpacing: {
    marginRight: spacing.small,
  },
});
