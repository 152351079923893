import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { saveAs } from 'file-saver';
import { logEvent } from 'expo-firebase-analytics';

import {
  HeaderNavigationBar,
  ErrorMessage,
  ConfirmationModal,
  ErrorModal,
} from '../../../components';
import { spacing, colors } from '../../../constants/theme';
import { Text, Button, Table } from '../../../core-ui';
import {
  CANCEL_MISSION_TOPUP_REWARD,
  SEND_MISSION_POINT_TO_DB,
} from '../../../graphql/mutations';
import {
  DOWNLOAD_INVALID_DATA_TOP_UP_REWARD,
  GET_TOPUP_REWARD_DETAIL,
} from '../../../graphql/queries';
import { routePaths } from '../../../constants/routes';
import { pageTitle } from '../../../constants/pageTitle';
import {
  MissionTopupRewardDetail,
  MissionTopupRewardDetailVariables,
} from '../../../generated/MissionTopupRewardDetail';
import {
  DownloadInvalidDataTopUpRewardCSV,
  DownloadInvalidDataTopUpRewardCSVVariables,
} from '../../../generated/DownloadInvalidDataTopUpRewardCSV';
import {
  SendMissionPointToDB,
  SendMissionPointToDBVariables,
} from '../../../generated/SendMissionPointToDB';
import {
  CancelMissionTopupReward,
  CancelMissionTopupRewardVariables,
} from '../../../generated/CancelMissionTopupReward';
import { StatusBulkPoint } from '../../../generated/globalTypes';

import { ActiveContentGamification } from './HeaderGamification';

const InvalidDataHistory = () => {
  const history = useHistory();
  const { state } = useLocation<{ id: string; statusPoint: StatusBulkPoint }>();
  const { id, statusPoint } = state;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sendOpen, setSendOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [actionsDisabled, setActionsDisabled] = useState(
    statusPoint !== StatusBulkPoint.READY_TO_REVIEW,
  );

  const [cancelOpen, setCancelOpen] = useState(false);

  const openSendModal = useCallback(() => setSendOpen(true), []);
  const closeSendModal = useCallback(() => setSendOpen(false), []);
  const closeCancelModal = useCallback(() => setCancelOpen(false), []);
  const openCancelModal = useCallback(() => setCancelOpen(true), []);
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorInstance(error);
      setErrorAction(action);
    },
    [],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.BulkPointReviewScene,
      });
    };
    eventLog();
  }, []);

  const onSendToDatabaseError = useMemo(() => {
    return openErrorModal(
      t(['mengirim data ke database', 'send data to database']),
    );
  }, [openErrorModal]);

  const { data, loading, refetch, error } = useQuery<
    MissionTopupRewardDetail,
    MissionTopupRewardDetailVariables
  >(GET_TOPUP_REWARD_DETAIL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      topupRewardId: id,
      skip: page * rowsPerPage,
      first: rowsPerPage,
    },
  });

  const [
    downloadInvalidDataCSV,
    { data: downloadData, loading: downloadLoading },
  ] = useLazyQuery<
    DownloadInvalidDataTopUpRewardCSV,
    DownloadInvalidDataTopUpRewardCSVVariables
  >(DOWNLOAD_INVALID_DATA_TOP_UP_REWARD, {
    variables: { topUpRewardId: id },
    onCompleted: (data) => {
      if (data) {
        saveAs(data.downloadInvalidDataTopUpRewardCSV.link, 'invalid.csv');
      }
    },
    onError: () => {
      return openErrorModal(t(['mengunduh data', 'downloading data']));
    },
  });

  useEffect(() => {
    if (
      data?.missionTopupRewardDetail.row[0].missionTopupReward.status ===
      StatusBulkPoint.READY_TO_REVIEW
    ) {
      setActionsDisabled(false);
    } else {
      setActionsDisabled(true);
    }
  }, [data]);

  const onDownloadInvalidData = useCallback(() => {
    if (downloadData) {
      saveAs(
        downloadData.downloadInvalidDataTopUpRewardCSV.link,
        'Invalid_topup_reward.csv',
      );
    } else {
      downloadInvalidDataCSV();
    }
  }, [downloadInvalidDataCSV, downloadData]);

  const [sendMissionPoint, { loading: sendMissionPointLoading }] = useMutation<
    SendMissionPointToDB,
    SendMissionPointToDBVariables
  >(SEND_MISSION_POINT_TO_DB, {
    variables: {
      missionTopupRewardId: id,
    },
    onError: onSendToDatabaseError,
    onCompleted: (data) => {
      if (data.sendMissionPointToDB.success) {
        setActionsDisabled(true);
        closeSendModal();
      }
    },
  });

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const onBackPress = useCallback(
    () =>
      history.push(routePaths.gamification, {
        activeContent: ActiveContentGamification.TOP_UP_REWARD,
      }),
    [history],
  );

  const onCancelError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunggah berkas', 'upload file']));
    setErrorInstance(error);
  }, []);

  const onSubmitCompleted = useCallback(() => {
    setSendOpen(false);
    setCancelOpen(false);
    history.goBack();
  }, [history]);

  const [cancelMissionReward, { loading: cancelLoading }] = useMutation<
    CancelMissionTopupReward,
    CancelMissionTopupRewardVariables
  >(CANCEL_MISSION_TOPUP_REWARD, {
    variables: { missionTopupRewardId: id },
    onCompleted: onSubmitCompleted,
    onError: onCancelError,
  });

  const sortedData = useMemo(() => {
    return data?.missionTopupRewardDetail.row.sort((a, b) => a.no - b.no);
  }, [data]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar
        backDestinationText={t(['Top Up Reward', 'Top Up Reward'])}
        onBackButtonPress={onBackPress}
      />
      {error && (
        <ErrorMessage
          action={t(['mengambil data invalid', 'fetching invalid data'])}
          error={error}
          onPress={refetchData}
        />
      )}
      {!!data?.missionTopupRewardDetail.row.length && (
        <View>
          <Text bold size="large">
            {data?.missionTopupRewardDetail.row[0].missionTopupReward.fileName}
          </Text>
          <View style={styles.row}>
            <Button
              data-testid="send-to-db"
              title={t(['Kirim ke Database', 'Send to Database'])}
              onPress={openSendModal}
              style={styles.button}
              disabled={actionsDisabled}
            />
            {actionsDisabled ? (
              <Button
                preset="secondary"
                title={t(['Unduh Data Invalid', 'Download Invalid Data'])}
                titleTextProps={{ color: 'link' }}
                onPress={onDownloadInvalidData}
                style={styles.button}
                isLoading={downloadLoading}
              />
            ) : (
              <Button
                preset="secondary"
                title={t(['Batal', 'Cancel'])}
                titleTextProps={{ color: 'error' }}
                onPress={openCancelModal}
                style={styles.button}
                isLoading={cancelLoading}
              />
            )}
          </View>
          <ConfirmationModal
            data-testid="send-modal"
            keepOpenAfterConfirm
            title={t([
              'Apakah anda yakin ingin mengirim data ke database?',
              'Are you sure you want to send this data to database?',
            ])}
            open={sendOpen}
            onClose={closeSendModal}
            onConfirm={sendMissionPoint}
            loading={sendMissionPointLoading}
          />

          <ConfirmationModal
            data-testid="cancel-modal"
            keepOpenAfterConfirm
            title={t([
              'Apakah anda yakin ingin batal mengirim data ke database?',
              'Are you sure you want to cancel sending this data to database?',
            ])}
            open={cancelOpen}
            onClose={closeCancelModal}
            onConfirm={cancelMissionReward}
            loading={cancelLoading}
          />
          <ErrorModal
            action={errorAction}
            open={errorOpen}
            error={errorInstance}
            onClose={closeErrorModal}
          />
          <Text bold style={styles.smallBottomSpacing}>
            {t(['Pratinjau', 'Preview'])}
          </Text>
          <Table
            data-testid="bulk-point-review-table"
            data={sortedData ?? []}
            dataCount={data.missionTopupRewardDetail.total}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
            onChangePage={setPage}
            onChangeRowsPerPage={onRowsChange}
            structure={{
              no: {
                headerTitle: t(['No.', 'No.']),
                render: ({ no }) => {
                  return <Text>{no}</Text>;
                },
              },
              codeStore: {
                headerTitle: t(['Kode Toko', 'Store Code']),
                render: ({ storeCode, invalidData }) => {
                  return (
                    <Text
                      color={
                        invalidData.includes(INVALID_DATA.codeStore)
                          ? 'error'
                          : undefined
                      }
                    >
                      {storeCode}
                    </Text>
                  );
                },
              },
              missionID: {
                headerTitle: t(['ID Mission', 'ID Mission']),
                render: ({ missionId }) => {
                  return <Text>{missionId}</Text>;
                },
              },
              note: {
                headerTitle: t(['Catatan', 'Notes']),
                valueProcessor: (value) => value ?? '-',
                render: ({ note }) => {
                  return <Text>{note || '-'}</Text>;
                },
              },
              valid: {
                headerTitle: t(['Valid', 'Valid']),
                valueProcessor: (value) => value ?? '-',
                render: ({ valid }) => {
                  return <Text>{valid ? 'Valid' : 'Invalid'}</Text>;
                },
              },
            }}
          />
        </View>
      )}
    </View>
  );
};

const INVALID_DATA = {
  typeId: 'typeId',
  codeStore: 'codeStore',
  startDate: 'startDate',
  endDate: 'endDate',
  totalPoint: 'totalPoint',
  action: 'action',
  type: 'type',
  brand: 'brand',
};

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    paddingBottom: spacing.medium,
    marginBottom: spacing.medium,
    marginTop: spacing.small,
  },
  button: {
    width: 180,
    marginRight: spacing.small,
  },
  smallBottomSpacing: {
    marginBottom: spacing.small,
  },
});

export default InvalidDataHistory;
