import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import { View, StyleSheet } from 'react-native';

import { Text } from '../core-ui';
import { colors } from '../constants/theme';

import DateTimePicker from './DateTimePicker';

type Props = {
  liveDate?: Date;
  setLiveDate: (value: Date) => void;
  withEndDate?: boolean;
  endDate?: Date;
  setEndDate?: (value: Date) => void;
  disabled?: boolean;
  isEdit?: boolean;
  horizontal?: boolean;
};

const useStyles = makeStyles({
  control: { alignItems: 'flex-start' },
  label: {
    paddingLeft: 16,
    paddingTop: 9, // NOTE: this is === the Radio component's padding
  },
  radio: {
    color: colors.radio.border,
    '&$checked': { color: colors.radio.selected },
    '&$disabled': { color: colors.radio.disabled },
  },
  checked: {},
  disabled: {},
});

const LiveDateRadioGroup = memo((props: Props) => {
  let classes = useStyles();
  let {
    liveDate,
    setLiveDate,
    withEndDate,
    endDate,
    setEndDate,
    disabled,
    isEdit,
    horizontal = false,
  } = props;
  let [prevLiveDate, setPrevLiveDate] = useState(liveDate);
  let [selectedStartDate, setSelectedStartDate] = useState(new Date());
  let [selectedRadio, setSelectedRadio] = useState('live');
  let [isError, setError] = useState(false);

  // NOTE: this is to set the correct selectedStartDate when a `liveDate` is provided on first render
  useEffect(() => {
    if (
      liveDate &&
      prevLiveDate &&
      prevLiveDate.valueOf() === liveDate.valueOf() &&
      // NOTE: hacky way to know if the `liveDate` prop is actually value from BE or just new Date
      Math.abs(selectedStartDate.valueOf() - liveDate.valueOf()) > 100
    ) {
      setSelectedStartDate(liveDate);
    }
  }, [liveDate, prevLiveDate, selectedStartDate]);

  useEffect(() => {
    if (
      liveDate &&
      // NOTE: the part bellow is necessary for forms that support create and edit,
      //       start with liveDate=undefined (for create), and then provide actual value (for edit)
      (!prevLiveDate ||
        (prevLiveDate && liveDate.valueOf() !== prevLiveDate.valueOf()))
    ) {
      // NOTE: when liveDate is changed (for example when switching multiple data in edit),
      //       update prevLiveDate, selectedStartDate, and selectedRadio
      setPrevLiveDate(liveDate);
      setSelectedStartDate(liveDate);
      setSelectedRadio('datetime');
    }
  }, [liveDate, prevLiveDate]);

  useEffect(() => {
    if (
      withEndDate &&
      endDate &&
      selectedRadio === 'datetime' &&
      selectedStartDate > endDate
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [selectedStartDate, endDate, selectedRadio, withEndDate]);

  let onRadioChange = (_event: React.ChangeEvent, value: string) => {
    setSelectedRadio(value);
    if (value === 'live') {
      setLiveDate(new Date());
      setPrevLiveDate(new Date());
    } else {
      setLiveDate(selectedStartDate);
      setPrevLiveDate(selectedStartDate);
    }
  };

  let renderEndDatePicker = () => {
    return (
      withEndDate &&
      endDate &&
      setEndDate && (
        <View
          style={
            horizontal ? styles.marginLeft52 : styles.endDatePickerContainer
          }
          testID="endDate"
        >
          {!horizontal && (
            <Text bold color="link" style={styles.endLabel}>
              {t(['Akhir', 'Ends'])}
            </Text>
          )}
          <View style={styles.flex}>
            <DateTimePicker
              data-testid="live-end-date"
              disablePast={isEdit}
              selectedDate={endDate}
              onChange={setEndDate}
              disabled={disabled}
              label={horizontal ? t(['Hingga', 'To']) : undefined}
            />
          </View>
        </View>
      )
    );
  };

  const onStartDateChange = useCallback(
    (date: Date) => {
      setLiveDate(date);
      setSelectedStartDate(date);
    },
    [setLiveDate, setSelectedStartDate],
  );

  return (
    <RadioGroup name="livedate" value={selectedRadio} onChange={onRadioChange}>
      <FormControlLabel
        disabled={disabled}
        value={'live'}
        control={
          <Radio
            classes={{
              root: classes.radio,
              checked: classes.checked,
              disabled: classes.disabled,
            }}
          />
        }
        label={<Text bold>{t(['Live', 'Live'])}</Text>}
        classes={{
          root: classes.control,
          label: classes.label,
        }}
      />
      <FormControlLabel
        disabled={disabled}
        value={'datetime'}
        control={
          <Radio
            classes={{
              root: classes.radio,
              checked: classes.checked,
              disabled: classes.disabled,
            }}
          />
        }
        label={
          <View testID="startDate" style={horizontal && styles.row}>
            <DateTimePicker
              data-testid="live-start-date"
              disablePast={isEdit}
              selectedDate={selectedStartDate}
              onChange={onStartDateChange}
              disabled={selectedRadio === 'live' || disabled}
              label={horizontal ? t(['Dari', 'From']) : undefined}
            />
            {horizontal && renderEndDatePicker()}
          </View>
        }
        classes={{
          root: classes.control,
          label: classes.label,
        }}
      />
      {!horizontal && renderEndDatePicker()}
      {isError && (
        <FormHelperText error={isError}>
          {t([
            'Tanggal/waktu akhir harus lebih besar dari tanggal/waktu mulai',
            'End date/time should be greater than start date/time',
          ])}
        </FormHelperText>
      )}
    </RadioGroup>
  );
});

const styles = StyleSheet.create({
  flex: { flex: 1 },
  endDatePickerContainer: {
    flexDirection: 'row',
    marginTop: 32,
    marginBottom: 8,
  },
  endLabel: {
    marginRight: 12,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginLeft52: {
    marginLeft: 52,
  },
});

export default LiveDateRadioGroup;
