import React, { useState, useCallback, useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { logEvent } from 'expo-firebase-analytics';

import { useAuth } from '../context';
import { TextInput, Button } from '../core-ui';
import { logo } from '../../assets';
import { Login, LoginVariables } from '../generated/Login';
import { LOGIN } from '../graphql/mutations';
import { spacing } from '../constants/theme';
import { ErrorModal } from '../components';
import { pageTitle } from '../constants/pageTitle';

export default function LoginScene() {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const { login } = useAuth();

  const openErrorModal = useCallback(() => setErrorOpen(true), []);
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);

  const [loginAdmin, { loading, error }] = useMutation<Login, LoginVariables>(
    LOGIN,
    {
      variables: {
        email,
        password,
      },
      onCompleted: ({ loginAdmin }) => {
        login(loginAdmin.token);
      },
      onError: openErrorModal,
    },
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.LoginScene });
    };
    eventLog();
  }, []);

  return (
    <View style={styles.flex}>
      <Image source={logo} resizeMode="contain" style={styles.logo} />
      <TextInput
        data-testid="email-input"
        label={t(['Email', 'Email'])}
        value={email}
        onChangeText={setEmail}
        containerStyle={styles.textInput}
      />
      <TextInput
        data-testid="password-input"
        label={t(['Kata Sandi', 'Password'])}
        type="password"
        value={password}
        onChangeText={setPassword}
        containerStyle={styles.textInput}
        onSubmitEditing={loginAdmin}
      />
      <Button
        data-testid="submit"
        type="submit"
        title={t(['Masuk', 'Log In'])}
        onPress={loginAdmin}
        style={styles.button}
        isLoading={loading}
      />
      <ErrorModal
        data-testid="login-error"
        message={t([
          'Email atau kata sandi salah',
          'Email or password is incorrect',
        ])}
        open={errorOpen}
        error={error}
        onClose={closeErrorModal}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 160,
    height: 160,
    marginBottom: 48,
  },
  textInput: {
    marginBottom: 20,
    width: 360,
  },
  button: {
    width: 360,
    marginBottom: spacing.medium,
  },
});
