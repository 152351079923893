import { useQuery } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { ErrorMessage } from '../../../components';
import { spacing } from '../../../constants/theme';
import { Table } from '../../../core-ui';
import {
  MissionActivityHistories,
  MissionActivityHistoriesVariables,
} from '../../../generated/MissionActivityHistories';
import { GET_ACTIVITY_MISSION_HISTORY } from '../../../graphql/queries';

export default function ActivityHistory() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  let {
    data: historyData,
    loading: historyLoading,
    error: historyError,
    refetch: refetchHistory,
  } = useQuery<MissionActivityHistories, MissionActivityHistoriesVariables>(
    GET_ACTIVITY_MISSION_HISTORY,
    {
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchHistoryData = useCallback(
    (_skip?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetchHistory({
            skip: page * rowsPerPage,
            first: rowsPerPage,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [page, rowsPerPage, refetchHistory],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };
  const tableStructure = useMemo(
    () => ({
      recentStatus: {
        headerTitle: 'Status',
        render: ({ recentStatus }: { recentStatus: boolean }) => {
          return recentStatus ? 'Aktif' : 'Nonaktif';
        },
      },
      startDate: {
        headerTitle: 'Tanggal Mulai',
        dataPath: 'startDate',
      },
      endDate: {
        headerTitle: 'Tanggal Berakhir',
        dataPath: 'endDate',
      },
      adminName: {
        headerTitle: 'Nama Admin',
        dataPath: 'admin.name',
      },
    }),
    [],
  );

  useEffect(() => {
    refetchHistoryData();
  }, [refetchHistoryData]);

  return (
    <View style={styles.container}>
      <Table
        data-testid="store-list-table"
        data={historyData?.missionActivityHistories.row ?? []}
        dataCount={historyData?.missionActivityHistories.total || 0}
        error={!!historyError}
        loading={historyLoading}
        structure={{
          ...tableStructure,
          startDate: {
            ...tableStructure.startDate,
            valuePreProcessor: (value) =>
              value ? new Date(value as string) : '-',
          },
          endDate: {
            ...tableStructure.endDate,
            valuePreProcessor: (value) =>
              value ? new Date(value as string) : '-',
          },
        }}
        onChangePage={setPage}
        onChangeRowsPerPage={onRowsChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
      {!!historyError && (
        <ErrorMessage
          error={historyError}
          action="mengambil data misi history"
          onPress={refetchHistoryData}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.medium,
  },
});
