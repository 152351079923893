import React, { useState, useCallback, useMemo } from 'react';
import {
  View,
  StyleSheet,
  ViewProps,
  TextStyle,
  LayoutChangeEvent,
} from 'react-native';
import {
  FormControl,
  Select,
  MenuItem,
  InputBase,
  withStyles,
  makeStyles,
  SelectProps,
} from '@material-ui/core';

import { Text } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';

type Option<T extends string | number> = { value: T; label: string };
export type Props<T extends string | number> = {
  'data-testid'?: string;
  containerStyle?: ViewProps['style'];
  label?: string;
  options: Array<Option<T>>;
  placeholder?: string;
  disabled?: boolean;
  disableOption?: { disable: boolean; nameOption: Array<string | number> };
  required?: boolean;
  error?: boolean;
  helperText?: string | null;
} & (
  | {
      multiple: true;
      value: Array<T>;
      onChange: (value: Array<T>) => void;
    }
  | {
      multiple?: false;
      value: T | null;
      onChange: (value: T, label: string) => void;
    }
);

const useSelectStyles = makeStyles({
  icon: { paddingRight: spacing.xxs },
  iconOpen: { paddingLeft: spacing.xxs },
});
const useListItemStyles = makeStyles({
  disabled: { display: 'none' },
});

const StyledInput = withStyles({
  root: {
    'label &': { display: 'none' },
    fontFamily: 'Inter',
  },
  input: {
    backgroundColor: (props: { disabled: boolean }) =>
      props.disabled
        ? colors.textInput.background.disabled
        : colors.dropdown.background,
    border: `1px solid ${colors.dropdown.border}`,
    borderRadius: 2,
    height: 20,
    padding: `${spacing.xs}px ${spacing.xxs}px ${spacing.xs}px ${spacing.m}px`,
    '&:focus': {
      backgroundColor: colors.dropdown.background,
    },
  },
})(InputBase);
const StyledMenuItem = withStyles({
  root: {
    '&:hover': { backgroundColor: colors.dropdown.hover },
    '&:focus': { backgroundColor: colors.dropdown.focus },
  },
  selected: { backgroundColor: `${colors.dropdown.selected} !important` },
})(MenuItem);

const Dropdown = <T extends string | number>(props: Props<T>) => {
  const selectClasses = useSelectStyles();
  const listItemClasses = useListItemStyles();
  const {
    label,
    options,
    multiple,
    value,
    placeholder,
    containerStyle,
    disabled,
    disableOption,
    required,
    error,
    helperText,
  } = props;

  const [width, setWidth] = useState(-1);
  const handleLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (width === -1) {
        setWidth(e.nativeEvent.layout.width);
      }
    },
    [width],
  );
  const handleChange = useCallback(
    ((e) => {
      if (!props.multiple) {
        props.onChange?.(
          e.target.value as T,
          (e.nativeEvent.target as HTMLLIElement)?.innerText, // NOTE: this is the label of the option, needed for FilterModal
        );
      } else {
        props.onChange?.(e.target.value as Array<T>);
      }
    }) as NonNullable<SelectProps['onChange']>,
    [props.multiple, props.onChange],
  );
  const getLabelOfValue = useCallback(
    (findValue: T) => {
      return (
        options.find(({ value: optionValue }) => optionValue === findValue)
          ?.label ?? ''
      );
    },
    [options],
  );
  const displayValueStyle = useMemo(
    // NOTE: long texts cause the component to grow wider; the solution is to define width and change display (is inline)
    //       changing display to flex fixes it, but the text-overflow doesn't work properly (no ellipsis at the end)
    //       changing display to block or inline-block fixes all that, but RN doesn't have block or inline-block in its Style type
    //       hence the need to cast to unknown then to TextStyle
    () => (({ width, display: 'block' } as unknown) as TextStyle),
    [width],
  );
  const renderValue = useCallback(() => {
    let displayValue = placeholder;
    // NOTE: use props.multiple and props.value to enable type narrowing
    if (!props.multiple) {
      if (props.value != null) {
        displayValue = getLabelOfValue(props.value);
      }
    } else {
      if (props.value != null && props.value.length > 0) {
        displayValue = props.value.map(getLabelOfValue).join(', ');
      }
    }
    return (
      <Text style={displayValueStyle} numberOfLines={1}>
        {displayValue}
      </Text>
    );
  }, [
    props.multiple,
    props.value,
    placeholder,
    displayValueStyle,
    getLabelOfValue,
  ]);
  const getIsSelected = useMemo(() => {
    // NOTE: use props.multiple and props.value to enable type narrowing
    if (!props.multiple) {
      return (optionValue: T) => props.value === optionValue;
    } else {
      return (optionValue: T) => !!props.value?.includes(optionValue);
    }
  }, [props.multiple, props.value]);
  const selectInput = useMemo(() => <StyledInput disabled={!!disabled} />, [
    disabled,
  ]);
  const menuProps = useMemo(
    () =>
      ({
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      } as const),
    [],
  );

  const disableOptions = useMemo(() => {
    return (optionValue: T) =>
      disableOption && disableOption.disable
        ? disableOption.nameOption.includes(optionValue)
        : false;
  }, [disableOption]);

  return (
    <View style={containerStyle} onLayout={handleLayout}>
      <FormControl fullWidth>
        {label && (
          <View style={styles.row}>
            <Text size="xs" style={styles.title}>
              {label}
            </Text>
            {required && (
              <Text size="xs" style={[styles.title, styles.red]}>
                *
              </Text>
            )}
          </View>
        )}
        <Select
          data-testid={props['data-testid']}
          displayEmpty
          multiple={multiple}
          value={value ?? ''} // NOTE: allow passing null to this component but change that to empty string because null is not accepted
          input={selectInput}
          classes={selectClasses}
          MenuProps={menuProps}
          renderValue={renderValue}
          onChange={handleChange}
          disabled={disabled}
          style={
            error
              ? { border: `1px solid ${colors.text.red}` }
              : { border: `1px solid ${colors.dropdown.border}` }
          }
        >
          <StyledMenuItem // NOTE: this is the selected item when value is null but it is not displayed at all
            disabled
            value=""
            // NOTE: there's an open issue with Material UI's typescript definition of Menu regarding ListItemClasses https://github.com/mui-org/material-ui/issues/21801
            // @ts-expect-error
            ListItemClasses={listItemClasses}
          />
          {!options.length && (
            <StyledMenuItem disabled>
              <Text>{t(['Tidak ada data', 'No data available'])}</Text>
            </StyledMenuItem>
          )}
          {options.map(({ label: optionLabel, value: optionValue }, index) => {
            return (
              <StyledMenuItem
                data-testid={`${props['data-testid']}-${index}`}
                disableRipple
                key={index}
                value={optionValue}
                selected={getIsSelected(optionValue)}
                disabled={disableOptions(optionValue)}
              >
                <Text>{optionLabel}</Text>
              </StyledMenuItem>
            );
          })}
        </Select>
      </FormControl>
      {helperText ? (
        <Text size="xs" style={styles.helperText}>
          {helperText}
        </Text>
      ) : (
        <></>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  row: { flexDirection: 'row' },
  red: { color: colors.text.red },
  title: { paddingBottom: spacing.xxs },
  helperText: {
    paddingTop: spacing.xxs,
    paddingLeft: spacing.m,
    color: colors.text.red,
  },
});

export default Dropdown;
