import React, { useCallback, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { Text, Dropzone, Button } from '../../../core-ui';
import { FileWithPreview } from '../../../core-ui/Dropzone';
import { ErrorModal } from '../../../components';
import { colors, spacing } from '../../../constants/theme';
import { DownloadTemplateTopUpRewardCSV } from '../../../generated/DownloadTemplateTopUpRewardCSV';
import { DOWNLOAD_TEMPLATE_TOP_UP_REWARD } from '../../../graphql/queries';
import { useUploadWithProgress } from '../../../hooks';
import { UPLOAD_TOPUP_REWARD } from '../../../graphql/mutations';

import { TopUpRewardTable } from './TopUpRewardTable';

export default function TopUpReward() {
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [file, getFile] = useState<FileWithPreview>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const onUploadError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunggah berkas', 'upload file']));
    setErrorInstance(error);
  }, []);
  const toggleRefetch = useCallback(() => setRefetchToggle(!refetchToggle), [
    refetchToggle,
  ]);
  const onDownloadError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunduh template', 'download template']));
    setErrorInstance(error);
  }, []);

  const [
    downloadTemplateCSV,
    { data: downloadData, loading: downloadLoading },
  ] = useLazyQuery<DownloadTemplateTopUpRewardCSV>(
    DOWNLOAD_TEMPLATE_TOP_UP_REWARD,
    {
      onCompleted: (data) => {
        if (data) {
          saveAs(
            data.downloadTemplateTopUpRewardCSV.link,
            'Template_Poin_Gamifikasi.csv',
          );
        }
      },
      onError: onDownloadError,
    },
  );

  const onDownloadPress = useCallback(() => {
    if (downloadData) {
      saveAs(
        downloadData.downloadTemplateTopUpRewardCSV.link,
        'Template_Poin_Gamifikasi.csv',
      );
    } else {
      downloadTemplateCSV();
    }
  }, [downloadTemplateCSV, downloadData]);

  const {
    upload,
    reset,
    loading: uploadLoading,
    progress,
    done,
  } = useUploadWithProgress(UPLOAD_TOPUP_REWARD, {
    onCompleted: toggleRefetch,
    onError: onUploadError,
  });

  const onFileDropped = useCallback(
    (file: File) => {
      reset();
      upload({ variables: { file } });
    },
    [upload, reset],
  );

  return (
    <>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <View style={styles.container}>
        <View style={styles.downloadRow}>
          <Text bold style={styles.downloadText}>
            {t([
              'Unduh template CSV dan lengkapi rincian data.',
              'Download CSV template and fill in the details.',
            ])}
          </Text>
          <Button
            iconAndText
            icon={<DownloadIcon htmlColor={colors.icon.secondary} />}
            title={t(['Unduh CSV', 'Download CSV'])}
            isLoading={downloadLoading}
            onPress={onDownloadPress}
          />
        </View>
        <View style={styles.uploadBlock}>
          <Text bold style={styles.uploadText}>
            {t(['Unggah Berkas CSV', 'Upload CSV File'])}
          </Text>
          <Dropzone
            data-testid="file-dropzone"
            withUploadText
            type="csv"
            uploadLoading={uploadLoading}
            progress={progress}
            done={done}
            source={file}
            getPreview={getFile}
            onDropSuccess={onFileDropped}
            containerStyle={styles.dropzone}
          />
        </View>
        <TopUpRewardTable
          refetchToggle={refetchToggle}
          onSubmit={toggleRefetch}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: spacing.small,
  },
  downloadRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: spacing.small,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  downloadText: { paddingRight: spacing.large },
  uploadBlock: { paddingTop: spacing.xsmall, paddingBottom: spacing.medium },
  uploadText: { paddingBottom: spacing.xsmall },
  dropzone: { width: 250, height: 150, borderRadius: 0 },
});
